import * as React from 'react';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { Controller, useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import { DateInput } from 'src/Components/Inputs/DateInput';
import useGetTree from 'src/Hooks/useGetTree';
import { Cascader } from 'rsuite';
import i18next from 'i18next';

interface OutboundFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const OutboundForm = ({ data, onSubmit }: OutboundFormProps) => {
    const resource = 'materialOutbounds'
    const { control } = useForm();
    const { config } = React.useContext(ConfigContext);
    const { data: places } = useGetTree(api, '/places/tree')

    const onSubmitFn = (formData) => {
        onSubmit(formData)
    }

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmitFn}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={6} md={3}>
                            <DateInput
                                name="outbound_date" 
                                control={control}
                                label={`resources.${resource}.fields.outbound_date`}
                                defaultValue={data?.outbound_date}
                                hasTime={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {places &&
                                <Controller
                                    name={"placeId"}
                                    control={control}
                                    defaultValue={data?.placeId}
                                    render={({ field }) => (
                                        <Cascader
                                            parentSelectable
                                            block={true}
                                            menuWidth={200}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t(`resources.${resource}.fields.placeId`)}
                                            data={places} 
                                            onChange={field.onChange}
                                            value={field.value}
                                            defaultValue={data?.parentId}
                                        />
                                    )}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"description"}
                                control={control}
                                label={`resources.${resource}.fields.description`}
                                defaultValue={data?.description}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </SimpleContainer>
    );
}