import { HttpError, fetchUtils } from "react-admin";

export type FinancialHttpClientOptions = {
    [key: string]: any;
}

export type FinancialHttpClientResponse = {
    status: number;
    headers: Headers;
    body: string;
    json: any;
}

const httpClient = async(url: string, options: FinancialHttpClientOptions = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const authToken = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${authToken}`);

    let result: FinancialHttpClientResponse = {
        status: 400,
        headers: new Headers(),
        body: 'Erro ao processar requisição',
        json: {}
    };

    try{
        result = await fetchUtils.fetchJson(url, options);
    }catch(e: any) {
        let responseMessage = e.message;

        if (Array.isArray(responseMessage)) {
            responseMessage = responseMessage[0];
        }

        throw new HttpError(responseMessage, result.status, result.body);
    }

    return result;
   
};

export default httpClient;