import * as React from 'react';
import { formatCurrency } from "src/Utils/Formatters"
import { TextField, TextFieldProps } from "../TextField"

export const CurrencyField = (props: TextFieldProps) => {
    const newProps = {
        ...props,
        ...{
            value: formatCurrency(props.value)
        }
    }
    return <TextField {...newProps}/>
}