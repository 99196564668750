import * as React from 'react';
import { ChargeForm } from './ChargeForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const ChargeEdit = () => {
    const { id } = useParams()
    const { data } = useGetOne(api, '/charges', id);
    const navigate = useNavigate(); 
    
    const onSubmit = (formData) => {
        api.put(`/charges/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    navigate(`/marketing/charges/${result.data.id}/show`)
                    showSuccessMessage(`resources.charges.actions.edit.success`)
                }
            })
    }

    return (
        <>
        {data && (
            <ChargeForm
                data={data}
                onSubmit={onSubmit}
            />  
        )}
        </>
    );
}