import * as React from 'react';
import { Cascader } from 'rsuite';
import i18next from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import useGetOne from 'src/Hooks/useGetOne';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import useGetTree from 'src/Hooks/useGetTree';
import { Form } from 'src/Components/Admin/Form';
import { showSuccessMessage } from 'src/Hooks/useMessages';

export const HiringConfirm = () => {
    const { control } = useForm();
    const navigate = useNavigate(); 
    const resource = 'hirings'
    const { id } = useParams()
    const { data } = useGetOne(api, '/hirings', id);
    const [ costcenterId, setCostcenterId] = React.useState(data?.costitem?.costcenterId)
    const { data: categories } = useGetTree(api, '/categories/tree/expenses')
    const { options: costcenters } = useGetManyOptions(api, '/costcenters')
    const { options: costitems } = useGetManyOptions(api, '/costitems', { costcenterId })

    const onSubmit = (formData) => {
        api.put(`/hirings/${id}/confirm`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    navigate(`/hr/hirings/${result.data.id}/show`)
                    showSuccessMessage(`resources.hirings.actions.confirm.success`)
                }
            })
    }

    return (
        <>
        {data && (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmit}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {categories &&
                                <Controller
                                    name={"categoryId"}
                                    control={control}
                                    defaultValue={data.categoryId}
                                    render={({ field }) => (
                                        <Cascader
                                            block={true}
                                            menuWidth={250}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t(`resources.${resource}.fields.categoryId`)}
                                            data={categories.filter(item => item.sequence != '2')} 
                                            onChange={field.onChange}
                                            value={field.value}
                                            defaultValue={data.categoryId}
                                        />
                                    )}
                                />
                            }   
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={6}>
                            {costcenters &&
                                <ReferenceInput
                                    name={"costcenterId"}
                                    control={control}
                                    label={`resources.${resource}.fields.costcenterId`}
                                    defaultValue={data?.costitem?.costcenterId}
                                    options={costcenters}
                                    onChange={value => { 
                                        setCostcenterId(value)
                                    }}
                                />
                            }
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {costitems && costitems.length &&
                                <ReferenceInput
                                    name={"costitemId"}
                                    control={control}
                                    label={`resources.${resource}.fields.costitemId`}
                                    defaultValue={data?.costitemId}
                                    options={costitems}
                                />
                            }
                        </Grid>
                    
                    </Grid>
                </Form>
            )}
        </SimpleContainer>  
        )}
        </>
    );
}