import React from 'react';
import * as Styled from './styles';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

interface IAddButtonProps {
  handleClick?: () => void;
  link?: string;
}

export const AddButton = (data: IAddButtonProps) => {
  return (
    <Styled.ButtonDiv>
      <Link to={data.link ?? 'create'}>
        <Styled.StyledButton
          onClick={data.handleClick}
          variant="contained"
          color="primary"
          size="medium"
        >
          {i18next.t(`actions.create`)}
        </Styled.StyledButton>
      </Link>
    </Styled.ButtonDiv>
  );
};
