import * as React from 'react';
import { Menu } from 'react-admin';
import SubMenu from './SubMenu';
import {
  Home,
  AccountBalance,
  AccountBalanceWallet,
  AccountCircle,
  Badge,
  Campaign,
  Category,
  Compare,
  Extension,
  Groups,
  HowToVote,
  Inventory,
  Label,
  ListAlt,
  Map,
  Outbound,
  Paid,
  People,
  Person,
  PriceChange,
  PriceCheck,
  Print,
  QueryStats,
  Search,
  ShoppingBasket,
  VolunteerActivism,
  Work
} from '@mui/icons-material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Can } from './Can';
import i18next from 'i18next';
import { ROLE_ADMIN, ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE, ROLE_SUPERVISOR } from 'src/Enums/Roles';
import { UserHave } from '../Auth/UserHave';

export const MainMenu = () => {

  return (
    <>
      <Menu>
        <Menu.Item
          to="/"
          primaryText={i18next.t('menu.home')}
          leftIcon={<Home />}
        />
        {/* Eleição */}
        <SubMenu
          primaryText={i18next.t('menu.elections.title')}
          leftIcon={<Map />}
        >
          <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
            <Menu.Item
              to="/election/comparative"
              primaryText={i18next.t('menu.elections.comparative')}
              leftIcon={<Compare />}
            />
            <Menu.Item
              to="/election/intention-stats"
              primaryText={i18next.t('menu.elections.intentionStats')}
              leftIcon={<HowToVote />}
            />
          </UserHave>

            <Menu.Item
              to="/election/maps"
              primaryText={i18next.t('menu.elections.maps')}
              leftIcon={<HowToVote />}
            />

            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/election/resume-table"
                primaryText={i18next.t('menu.elections.resumeResults')}
                leftIcon={<HowToVote />}
              />
            </UserHave>
          <SubMenu
            primaryText={i18next.t('menu.elections.register')}
            leftIcon={<Map />}
          >
            <UserHave role={ROLE_ADMIN}>
              <Menu.Item
                to="/election/elections"
                primaryText={i18next.t('menu.elections.elections')}
                leftIcon={<HowToVote />}
              />
            </UserHave>
            <UserHave role={ROLE_CANDIDATE}>
              <Menu.Item
                id="menu"
                to="/election/campaigns"
                primaryText={i18next.t('menu.financial.campaigns')}
                leftIcon={<Groups />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER, ROLE_SUPERVISOR]}>
              <Menu.Item
                to="/election/users"
                primaryText={i18next.t('menu.elections.users')}
                leftIcon={<Person />}
              />
            </UserHave>
              <Menu.Item
                to="/election/voters"
                primaryText={i18next.t('menu.elections.voters')}
                leftIcon={<Groups />}
              />
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/election/bases"
                primaryText={i18next.t('menu.elections.bases')}
                leftIcon={<LibraryBooksIcon />}
              />
            </UserHave>
            <UserHave role={[ROLE_ADMIN]}>
              <Menu.Item
                to="/election/parties"
                primaryText={i18next.t('menu.elections.parties')}
                leftIcon={<People />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/election/candidates"
                primaryText={i18next.t('menu.elections.candidates')}
                leftIcon={<AccountCircle />}
              />
              <Menu.Item
                to="/election/research"
                primaryText={i18next.t('menu.elections.researches')}
                leftIcon={<Search />}
              />
            </UserHave>
          </SubMenu>
        </SubMenu>

        <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER]}>
          <SubMenu
            primaryText={i18next.t('menu.financial.title')}
            leftIcon={<Paid />}
          >
            
            <UserHave role={ROLE_CANDIDATE}>
              <Menu.Item
                id="menu"
                to="/financial/accounts"
                primaryText={i18next.t('menu.financial.accounts')}
                leftIcon={<PriceChange />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/financial/expense-types"
                primaryText={i18next.t('menu.financial.expense_types')}
                leftIcon={<Label />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/financial/categories"
                primaryText={i18next.t('menu.financial.categories')}
                leftIcon={<Category />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/financial/costcenters"
                primaryText={i18next.t('menu.financial.costcenters')}
                leftIcon={<AccountBalance />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/financial/costitems"
                primaryText={i18next.t('menu.financial.costitems')}
                leftIcon={<AccountBalanceWallet />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/financial/persons"
                primaryText={i18next.t('menu.financial.persons')}
                leftIcon={<People />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/financial/donations"
                primaryText={i18next.t('menu.financial.donations')}
                leftIcon={<VolunteerActivism />}
              />
            </UserHave>
            <Can resource="expenses">
              <Menu.Item
                to="/financial/expenses"
                primaryText={i18next.t('menu.financial.expenses')}
                leftIcon={<Paid />}
              />
            </Can>
          </SubMenu>
        </UserHave>

        <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER]}>
          <SubMenu
            primaryText={i18next.t('menu.stock.title')}
            leftIcon={<Inventory />}
          >
            <UserHave role={[ROLE_ADMIN]}>
              <Menu.Item
                to="/stock/place-types"
                primaryText={i18next.t('menu.stock.placeTypes')}
                leftIcon={<Map />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/stock/places"
                primaryText={i18next.t('menu.stock.places')}
                leftIcon={<Map />}
              />
              <Menu.Item
                to="/stock/material-types"
                primaryText={i18next.t('menu.stock.materialTypes')}
                leftIcon={<Extension />}
              />
              <Menu.Item
                to="/stock/materials"
                primaryText={i18next.t('menu.stock.materials')}
                leftIcon={<Extension />}
              />
            </UserHave>
            <Menu.Item
              to="/stock/inbounds"
              primaryText={i18next.t('menu.stock.inbounds')}
              leftIcon={<ShoppingBasket />}
            />
            <Menu.Item
              to="/stock/outbounds"
              primaryText={i18next.t('menu.stock.outbounds')}
              leftIcon={<Outbound />}
            />
          </SubMenu>
        
          <SubMenu
            primaryText={i18next.t('menu.hr.title')}
            leftIcon={<Badge />}
          >
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/hr/positions"
                primaryText={i18next.t('menu.hr.positions')}
                leftIcon={<Work />}
              />
            </UserHave>
            <Menu.Item
              to="/hr/hirings"
              primaryText={i18next.t('menu.hr.hirings')}
              leftIcon={<Work />}
            />
            <Menu.Item
              to="/hr/hirings-report"
              primaryText={i18next.t('menu.hr.reports.hirings')}
              leftIcon={<Print />}
            />
            <Menu.Item
              to="/hr/payments-report"
              primaryText={i18next.t('menu.hr.reports.payments')}
              leftIcon={<Print />}
            />
          </SubMenu>
        
          <SubMenu
            primaryText={i18next.t('menu.marketing.title')}
            leftIcon={<Campaign />}
          >
            <Menu.Item
              to="/marketing/charges"
              primaryText={i18next.t('menu.marketing.charges')}
              leftIcon={<ShoppingBasket />}
            />
          </SubMenu>
        
          <SubMenu
            primaryText={i18next.t('menu.reports.title')}
            leftIcon={<ListAlt />}
          >
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <Menu.Item
                to="/reports/financial-resume"
                primaryText={i18next.t('resources.reports.resume.name')}
                leftIcon={<PriceCheck />}
              />
            </UserHave>
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER]}>
              <Menu.Item
                to="/reports/financial-resume-account"
                primaryText={i18next.t('resources.reports.financial.resumeAccount.name')}
                leftIcon={<PriceCheck />}
              />
            </UserHave>
            <Menu.Item
              to="/reports/financial-movements"
              primaryText={i18next.t('resources.reports.movements.name')}
              leftIcon={<PriceCheck />}
            />
            <Menu.Item
              to="/reports/cost-by-city"
              primaryText={i18next.t('resources.reports.costByCity.name')}
              leftIcon={<PriceCheck />}
            />
            <Menu.Item
              to="/reports/chart-of-accounts"
              primaryText={i18next.t('resources.reports.chartOfAccounts.name')}
              leftIcon={<PriceCheck />}
            />
            <Menu.Item
              to="/reports/stock-movements"
              primaryText={i18next.t('resources.reports.stock.movements.name')}
              leftIcon={<Inventory />}
            />
            <Menu.Item
              to="/reports/voters-by-period"
              primaryText={i18next.t('resources.reports.elections.votersByPeriod.name')}
              leftIcon={<QueryStats />}
            />
          </SubMenu>
        </UserHave>
      </Menu>
    </>
  );
};
