import styled from "styled-components";

export const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 24px;
  margin-top: 30px;
  gap: 15px;
`;

export const Filter = styled.div`
`;

export const SpanBox = styled.span`
    display: flex;
    margin: 1em 1em 1em 0;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 350px;
    min-width: 200px;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin: 1em 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;