import * as React from 'react';
import { useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Api } from 'src/Services/api';
import { showErrorMessage, showSuccessMessage } from 'src/Hooks/useMessages';
import IconButton from '@mui/material/IconButton';
import i18next from 'i18next';
import useLoader from 'src/Hooks/useLoader';

interface PrintButtonProps {
    api: Api,
    endpoint: string,
    destFile: string,
    successMessage: string,
    errorMessage: string
}

const DownloadAsPdfButton = ({ api, endpoint, destFile, successMessage, errorMessage }: PrintButtonProps) => 
{
    const { Loader, loading, showLoader, hideLoader } = useLoader()
    
    const handleClick = async() => {
        showLoader()
        api.get(endpoint, { 
            responseType: 'arraybuffer' 
        })
            .then(response => 
            {       
                hideLoader()             
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = destFile;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                showSuccessMessage(i18next.t(successMessage))
            })
            .catch(error => {
                hideLoader()
                console.log(error)
                showErrorMessage(i18next.t(errorMessage))
            })
            .finally(() => {
                hideLoader()
            });
    };
    return (
        <>
            {loading && <Loader /> }
            <IconButton color="primary" onClick={handleClick} style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
                <PictureAsPdfIcon />
            </IconButton>
        </>
    );
};

export default DownloadAsPdfButton;