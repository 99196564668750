import * as React from 'react';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { Controller, useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import 'rsuite/dist/rsuite.min.css';
import i18next from 'i18next';
import { Cascader } from 'rsuite';
import useGetTree from 'src/Hooks/useGetTree';

interface PlaceFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const PlaceForm = ({ data, onSubmit }: PlaceFormProps) => {
    const resource = 'places'
    const { control } = useForm();
    const { config } = React.useContext(ConfigContext);
    const { options: types } = useGetManyOptions(api, '/place-types')
    const { data: places } = useGetTree(api, '/places/tree')

    const onSubmitFn = (formData) => {
        onSubmit(formData)
    }

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmitFn}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {types &&
                                <ReferenceInput
                                    name={"typeId"}
                                    control={control}
                                    label={`resources.${resource}.fields.typeId`}
                                    defaultValue={data?.typeId}
                                    options={types}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"name"}
                                control={control}
                                label={`resources.${resource}.fields.name`}
                                defaultValue={data?.name}
                            />
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {places &&
                                <Controller
                                    name={"parentId"}
                                    control={control}
                                    defaultValue={data?.parentId}
                                    render={({ field }) => (
                                        <Cascader
                                            parentSelectable
                                            block={true}
                                            menuWidth={200}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t(`resources.${resource}.fields.parentId`)}
                                            data={places} 
                                            onChange={field.onChange}
                                            value={field.value}
                                            defaultValue={data?.parentId}
                                        />
                                    )}
                                />
                            }
                        </Grid>
                    </Grid>

                </Form>
            )}
        </SimpleContainer>
    );
}