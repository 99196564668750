import * as React from "react";
import { api } from "src/Services/Financial";
import { Link, useParams } from 'react-router-dom';
import i18next from "i18next";
import useGetOne from "src/Hooks/useGetOne";
import { View } from "src/Components/Admin/View";
import { SimpleContainer } from "src/Components/Layout/SimpleContainer";
import { SimpleShowLayout } from "src/Components/Admin/View/Layouts/SimpleShowLayout";
import { ListAction } from "src/Components/Admin/Actions/ListAction";
import { EditAction } from "src/Components/Admin/Actions/EditAction";
import { DeleteAction } from "src/Components/Admin/Actions/DeleteAction";
import { TextField } from "src/Components/Admin/Fields/TextField"
import { DateField } from "src/Components/Admin/Fields/DateField"
import ChargeStatuses from "src/Enums/ChargeStatuses";
import { OptionsField } from "src/Components/Admin/Fields/OptionsField";
import { showSuccessMessage } from "src/Hooks/useMessages";
import ReplayIcon from '@mui/icons-material/Replay';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { ChargeItemsSearch } from "./ChargeItemsSearch";
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import { ChargeItemList } from "./ChargeItemsList";
import { ChargeItemForm } from "./ChargeItemForm";
import { UserHave } from "src/Components/Auth/UserHave";
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles";

interface SelectedItemProps {
    id: string,
    chargeId: string
}

export const ChargeShow = () => {
    const resource = 'charges'
    const { id } = useParams()
    const { data, fetchData } = useGetOne(api, '/charges', id);
    const [acao, setAcao] = React.useState('list')
    const [selected, setSelected] =  React.useState<SelectedItemProps | null>(null)

    const cancelConfirmCharge = () => {
        if (confirm(i18next.t(`resources.${resource}.actions.cancelConfirm.dialog`))) {
            api.put(`charges/${id}/cancel-confirm`)
                .then(result => {
                    if (result && result.status == 200) 
                    {
                        fetchData()
                        showSuccessMessage(`resources.${resource}.actions.cancelConfirm.success`)
                    }
                })
        }
    }
    
    return (
        <SimpleContainer 
            title={`resources.${resource}.name_one`}
        >
            {(acao == 'list') && ( 
                <>
                {data && (
                    <View
                        baseUrl="/marketing/charges"
                        backendUrl="/charges"
                        actions={[
                            (data.status !== 'APPROVED') && (
                                <Link key={4} 
                                    to={'#'} 
                                    title={i18next.t(`resources.${resource}.actions.add.title`)} 
                                    onClick={() => setAcao('search')}
                                >
                                    <AddIcon />
                                </Link>
                            ),
                            <ListAction key={0} />,
                            (data.status !== 'APPROVED') && <EditAction key={1} />,   
                            (data.status !== 'APPROVED') && <DeleteAction key={2} api={api} successMessage={`resources.${resource}.actions.delete.success`}/>,
                            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]} key={5}>
                                {(data.status != 'APPROVED') ?
                                    <Link 
                                        to={`/marketing/charges/${id}/confirm`} 
                                        title={i18next.t(`resources.${resource}.actions.confirm.title`)} 
                                        style={{ color: '#428F32' }}
                                    >
                                        <ThumbUpAltIcon />
                                    </Link>
                                    :
                                    <Link 
                                        to={'#'} 
                                        title={i18next.t(`resources.${resource}.actions.cancelConfirm.title`)} 
                                        onClick={cancelConfirmCharge}
                                        style={{ color: 'red' }}
                                    >
                                      <ReplayIcon />
                                     </Link>
                                }
                                </UserHave>
                        ]}
                    >
                        <SimpleShowLayout>
                            <TextField value={data?.title} label={`resources.${resource}.fields.title`} />
                            <TextField value={data?.description} label={`resources.${resource}.fields.description`} />
                            <DateField value={data?.due_date} label={`resources.${resource}.fields.due_date`} />
                            <TextField value={data?.type?.name} label={`resources.${resource}.fields.typeId`} />
                            {(data.status == 'APPROVED') &&
                                <>
                                <TextField value={data?.person?.fullName} label={`resources.${resource}.fields.personId`} />
                                <TextField value={data?.category?.name} label={`resources.${resource}.fields.categoryId`} />
                                <TextField value={data?.costitem?.costcenter?.name + ' - ' + data?.costitem?.name} label={`resources.costcenters.name_one`} /> 
                                <TextField value={data?.invoice_number} label={`resources.${resource}.fields.invoice_number`} />
                                </>
                            }
                            <TextField value={`${data?.city?.name} (${data?.state?.name})`} label={`resources.${resource}.fields.cityId`} />
                            <OptionsField value={data?.status} label={`resources.${resource}.fields.status`} options={ChargeStatuses} />
                            <DateField showTime value={data?.created_at} label={`resources.${resource}.fields.created_at`} />
                        </SimpleShowLayout>

                    </View>
                )}

                <Divider flexItem />

                
                <ChargeItemList 
                    chargeId={id as string} 
                    isPending={data?.status == 'PENDING'}
                    setSelected={setSelected} 
                    setAcao={setAcao}
                />
                </>
            )}

            {(acao == 'search') && (
                <ChargeItemsSearch chargeId={id as string} setAcao={setAcao} />
            )}

            {(acao == 'form') && selected && (
                <ChargeItemForm 
                    chargeId={selected.chargeId as string} 
                    id={selected.id as string}
                    onCancel={() => {
                        setSelected(null)
                        setAcao('list')
                    }} 
                />
            )}


        </SimpleContainer>
    );
}