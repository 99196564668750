import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { View } from 'src/Components/Admin/View';
import {
  DeleteAction,
  EditAction,
  ListAction
} from 'src/Components/Admin/Actions';
import { api } from 'src/Services/Financial';
import useGetOne from 'src/Hooks/useGetOne';
import { useParams } from 'react-router-dom';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import { Upload } from 'src/Components/Admin/Upload';
import { useForm } from 'react-hook-form';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { CurrencyField } from 'src/Components/Admin/Fields/CurrencyField';
import DownloadAsPdfButton from 'src/Components/Buttons/DownloadAsPdfButton';
import i18next from 'i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import banks from 'src/Enums/Banks';
import { OptionsField } from 'src/Components/Admin/Fields/OptionsField';
import { SimpleSuspense } from 'src/Components/Loading/SimpleSuspense';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const DonationShow = () => {
  const resource = 'donations';
  const endpoint = 'donations';
  const { id } = useParams();
  const { control } = useForm();
  const { data } = useGetOne(api, '/' + endpoint, id);
  const [tab, setTab] = React.useState(0);

  return (
    <SimpleContainer title={`resources.${resource}.name_one`} mdSize={900}>
      <Tabs
        value={tab}
        onChange={(event, value) => setTab(value)}
        aria-label="basic tabs example"
      >
        <Tab
          label={i18next.t(`resources.donations.tabs.data`)}
          {...a11yProps(0)}
        />
        <Tab
          label={i18next.t(`resources.donations.tabs.proof`)}
          {...a11yProps(1)}
        />
      </Tabs>

      {data && (
        <>
          <CustomTabPanel value={tab} index={0}>
            {data && (
              <View
                baseUrl={`/financial/donations`}
                backendUrl={'/donations'}
                actions={[
                  <ListAction key={0} />,
                  <EditAction key={1} />,
                  <DeleteAction
                    key={2}
                    api={api}
                    successMessage={`resources.${resource}.actions.delete.success`}
                  />,
                  <DownloadAsPdfButton
                    key={3}
                    api={api}
                    endpoint={`donations/${id}/view`}
                    destFile="donation.pdf"
                    successMessage={`resources.${resource}.actions.print.success`}
                    errorMessage={`resources.${resource}.actions.print.error`}
                  />
                ]}
              >
                <SimpleShowLayout>
                  <Box>
                    <TextField
                      value={data.person.fullName}
                      label={`resources.${resource}.fields.personId`}
                    />
                    <TextField
                      value={i18next.t(`const.donationTypes.${data.type}`)}
                      label={`resources.${resource}.fields.type`}
                    />
                    <TextField
                      value={data.description}
                      label={`resources.${resource}.fields.description`}
                    />
                    <DateField
                      value={data.donation_date}
                      label={`resources.${resource}.fields.donation_date`}
                    />
                    <TextField
                      value={data.receipt_number}
                      label={`resources.${resource}.fields.receipt_number`}
                    />
                  </Box>
                  <Box borderTop={1} borderColor={'#ddd'}>
                    <Typography variant="caption">{i18next.t(`resources.${resource}.fields.bank_data`)}</Typography>
                    <CurrencyField
                      value={data.amount}
                      label={`resources.${resource}.fields.amount`}
                    />
                    <TextField
                      value={i18next.t(`const.donationNatures.${data.nature}`)}
                      label={`resources.${resource}.fields.nature`}
                    />
                    <OptionsField
                      value={data.bank}
                      label={`resources.${resource}.fields.bank`}
                      options={banks}
                    />
                    <TextField
                      value={data.agency + '-' + data.agency_digit}
                      label={`resources.${resource}.fields.agency`}
                    />
                    <TextField
                      value={data.bank_account + '-' + data.bank_account_digit}
                      label={`resources.${resource}.fields.bank_account`}
                    />
                    <TextField
                      value={data.document_number}
                      label={`resources.${resource}.fields.document_number`}
                    />
                  </Box>
                  <Box borderTop={1} borderColor={'#ddd'}>
                    <Typography variant="caption">Outros dados</Typography>

                    <TextField
                      value={data.category.name}
                      label={`resources.${resource}.fields.categoryId`}
                    />
                    <TextField
                      value={data.aditionalInfo}
                      label={`resources.${resource}.fields.aditionalInfo`}
                    />
                    <DateField
                      value={data?.created_at}
                      label={`resources.${resource}.fields.created_at`}
                    />
                  </Box>
                </SimpleShowLayout>
              </View>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={tab} index={1}>
            {data && (
              <Upload
                api={api}
                control={control}
                name="file"
                picture={data.picture}
                src={`/donations/${data.id}/upload`}
                uploadSuccessMessage={`resources.${resource}.actions.upload.success`}
                uploadRemoveMessage={`resources.${resource}.actions.deleteUpload.success`}
                destFileName={data.image?.filename}
              />
            )}
          </CustomTabPanel>
        </>
      )}
    </SimpleContainer>
  );
};
