import { Route, Routes } from 'react-router-dom';
import { ValidateRoute } from 'src/Components/Auth/ValidateRoute';
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from 'src/Enums/Roles';
import { ChargeConfirm } from 'src/Pages/Marketing/Charges/ChargeConfirm';
import { ChargeCreate } from 'src/Pages/Marketing/Charges/ChargeCreate';
import { ChargeEdit } from 'src/Pages/Marketing/Charges/ChargeEdit';
import { ChargeList } from 'src/Pages/Marketing/Charges/ChargeList';
import { ChargeShow } from 'src/Pages/Marketing/Charges/ChargeShow';

export const ChargeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ChargeList />} />
      <Route path="/create" element={<ChargeCreate />} />
      <Route path=":id" element={<ChargeEdit />} />
      <Route path=":id/confirm" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <ChargeConfirm />)} />
      <Route path=":id/show" element={<ChargeShow />} />
    </Routes>
  );
};
