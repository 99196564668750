import React from 'react';
import { useGetIdentity } from 'react-admin';
import { useForm } from 'react-hook-form';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useInfiniteFetchWithParams } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import Grid from '@mui/material/Grid';
import {
  ROLE_ADMIN,
  ROLE_CAMPAIGN_MANAGER,
  ROLE_OPERATOR
} from 'src/Enums/Roles';
import { DateInput } from 'src/Components/Inputs/DateInput';
import i18next from 'i18next';
import { showErrorMessage } from 'src/Hooks/useMessages';

interface HomeVotersPageProps {
  dateRef: string;
  setDateRef: any;
}

export function HomeVotersPage({ dateRef, setDateRef }: HomeVotersPageProps) {
  const endpoint = '/home/voters';
  const resource = 'home.voters';
  const { control } = useForm();
  const { data: identity } = useGetIdentity();
  const { options: zones } = useGetManyOptions(api, '/zones');

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t('resources.home.messages.invalid_date'));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(start).toISOString();
  };

  const { data, fetchData, hasMore, filters, setFilters, setParams } =
    useInfiniteFetchWithParams(api, endpoint, { date: null }, 25, {
      date: getDateBounds(dateRef)
    });

  React.useEffect(() => {
    if (dateRef) {
      const date = getDateBounds(dateRef);

      if (date) {
        setParams({ date });
        setFilters({
          ...filters,
          ...{
            date
          }
        });
      }
    }
  }, [dateRef]);

  let columns = [
    {
      header: `resources.${resource}.results.name`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.name}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.cpf`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.cpf}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.email`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.email}
        </InfiniteListStyles.StyledTd>
      )
    }
  ];

  if (
    identity &&
    (identity.role?.name == ROLE_ADMIN ||
      identity.role?.name == ROLE_CAMPAIGN_MANAGER)
  ) {
    columns = columns.concat([
      {
        header: `resources.${resource}.results.basename`,
        value: (item, index) => (
          <InfiniteListStyles.StyledTd key={index}>
            {item.basename}
          </InfiniteListStyles.StyledTd>
        )
      }
    ]);
  }

  if (identity && identity.role?.name != ROLE_OPERATOR) {
    columns = columns.concat([
      {
        header: `resources.${resource}.results.username`,
        value: (item, index) => (
          <InfiniteListStyles.StyledTd key={index}>
            {item.username}
          </InfiniteListStyles.StyledTd>
        )
      }
    ]);
  }

  columns = columns.concat([
    {
      header: `resources.${resource}.results.publicPlace`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.publicPlace}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.postalCode`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.postalCode}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.birthDate`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          <DateField value={item.birthDate} />
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.zoneId`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.zoneName}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.electoralZone`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.electoralZoneId}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.genderId`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.genderName}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.telphone`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.telphone}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `resources.${resource}.results.created_at`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          <DateField showTime={true} value={item.created_at} />
        </InfiniteListStyles.StyledTd>
      )
    }
  ]);

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      filters={
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={3} data-id="grid">
            <DateInput
              control={control}
              name="dateStart"
              label={`resources.${resource}.filters.date`}
              onChange={e => {
                setDateRef(e.target.value);
              }}
              defaultValue={dateRef}
              data-id="datefilter"
            />
          </Grid>
          <Grid item xs={12} md={6} data-id="grid">
            <SearchInput
              name="search"
              label={i18next.t(`resources.${resource}.filter.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={3} data-id="grid">
            {zones && (
              <ReferenceInput
                name={'zoneId'}
                control={control}
                label={`resources.${resource}.filters.zoneId`}
                defaultValue={null}
                options={zones}
                style={{ minWidth: 150 }}
                onChange={value => {
                  const newFilters = {
                    ...filters,
                    ...{ zoneId: value }
                  };
                  setFilters(newFilters);
                }}
              />
            )}
          </Grid>
        </Grid>
      }
      columns={columns}
    />
  );
}
