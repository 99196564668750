import { Layout, AppBar } from 'react-admin';
import { MainMenu } from './Menu';
import { Box } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import LogoToolbar from 'src/Assets/logoToolbar';
import LanguageSelector from './LanguageSelector';
import AccountSelector from './AccountSelector';

const MyAppBar = () => {
  return (
    <AppBar>
      <LogoToolbar />
      <Box component="span" flex={1} />
      <LanguageSelector />
      <AccountSelector />
    </AppBar>
  );
};

export const LhinceLayout = (props: any) => {
  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout
      {...props}
      menu={MainMenu}
      className="success"
      appBar={MyAppBar}
      sx={{
        '& .RaLayout-content': {
          paddingTop: 1,
          paddingLeft: isSmaller ? 0 : 3,
          paddingRight: isSmaller ? 0 : 3
        },
        '& .RaUserMenu-userButton': {
          display: 'none'
        },
        '& .RaLoadingIndicator-loadedIcon': {
          display: 'none'
        }
      }}
    />
  );
};
