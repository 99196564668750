import { Route, Routes } from "react-router-dom";
import { OutboundCreate, OutboundEdit, OutboundList, OutboundShow } from "src/Pages/Stock/Outbounds";

export const OutboundRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<OutboundList />} />
          <Route path="/create" element={<OutboundCreate />} />
          <Route path=":id" element={<OutboundEdit />} />
          <Route path=":id/show" element={<OutboundShow />} />
        </Routes>
    )
  }