import i18next from "i18next";

export enum HiringStatuses {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    CANCELED = 'CANCELED'
}

export default [
    { 
        value: 'PENDING', label: i18next.t('resources.hirings.statuses.PENDING'),
    },
    {
        value: 'APPROVED', label: i18next.t('resources.hirings.statuses.APPROVED') 
    },
    {
        value: 'CANCELED', label: i18next.t('resources.hirings.statuses.CANCELED') 
    },
];