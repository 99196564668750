import * as React from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { CustomInput } from 'src/Components/Inputs/CustomInput';

interface PositionFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const PositionForm = ({ data, onSubmit }: PositionFormProps) => {
    const resource = 'positions'
    const { control } = useForm()

    const onSubmitFn = (formData) => {
        onSubmit(formData)
    }

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmitFn}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"name"}
                                control={control}
                                label={`resources.${resource}.fields.name`}
                                defaultValue={data?.name}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"description"}
                                control={control}
                                label={`resources.${resource}.fields.description`}
                                defaultValue={data?.description}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </SimpleContainer>
    );
}