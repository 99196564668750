import movements from './movements.json';
import costByCity from './costByCity.json';
import chartOfAccounts from './chartOfAccounts.json';
import stockMovements from './stock/movements.json';
import research from './elections/research.json';
import comparative from './elections/comparative.json';
import maps from './elections/maps.json';
import hirings from './hr/hirings.json';
import payments from './hr/payments.json';
import financial from './financial';
import intentions from './elections/intentions.json'
import resumeResults from './elections/resumeResults.json';
import votersByPeriod from './elections/votersByPeriod.json';

const reports = {
  name: 'Reports',
  resume: {
    name: 'Campaign Resume'
  },
  movements,
  financial,
  costByCity,
  chartOfAccounts,
  stock: {
    movements: stockMovements
  },
  elections: {
    research,
    comparative,
    maps,
    intentions,
    resumeResults,
    votersByPeriod
  },
  hr: {
    hirings,
    payments
  }
};

export default reports;
