import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial'
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { showSuccessMessage } from 'src/Hooks/useMessages'
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { AddButton } from 'src/Components/Buttons';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { CurrencyField } from 'src/Components/Admin/Fields/CurrencyField';
import HiringPersonPaymentForm from './HiringPersonPaymentForm';
import { UserHave } from 'src/Components/Auth/UserHave';
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from 'src/Enums/Roles';

interface HiringPersonPaymentListProps {
  hiringId: string,
  hiringPersonId: string,
  isPending: boolean
}

export const HiringPersonPaymentList = ({ hiringId, hiringPersonId, isPending }: HiringPersonPaymentListProps) => 
{
  const endpoint = `/hirings/${hiringId}/persons/${hiringPersonId}/payments`
  const resource = 'hiringPersonPayments'
  const {data, setData, fetchData, hasMore } = useInfiniteFetch(api, endpoint, 25)
  const [open, setOpen] = React.useState(false);
  const [payment, setPayment] = React.useState<any>(null)

  const handleDelete = async (id: number) => {
    try {
        api.delete(endpoint + '/' + id)
          .then(response => {
            if(response && (response.status == 200)) {
              setData(data.filter((item) => item.id !== id));
              showSuccessMessage(`resources.${resource}.actions.delete.success`);
            }
          })
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = () => {
    setPayment({
      due_date: null,
      amount: 0
    })
    setOpen(true);
  }

  const handleEdit = (item) => {
    setPayment(item)
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const fetchPayments = () => {
    setData([])
    fetchData()
  }
  
  return (
    <React.Fragment>
      <InfiniteList
        data={data}
        loadMoreData={fetchData} 
        hasMore={hasMore}
        actions={
          isPending ?
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}> 
              <AddButton link='#' handleClick={handleCreate} /> 
            </UserHave>
          : null
        }
        columns={
          [
            {
              header: `resources.${resource}.fields.due_date`,
              value: (item, index) => 
                <InfiniteListStyles.StyledTd key={index}>
                  <DateField value={item.due_date} />
                </InfiniteListStyles.StyledTd>
            },
            {
              header: `resources.${resource}.fields.amount`,
              value: (item, index) => 
                <InfiniteListStyles.StyledTd key={index}>
                  <CurrencyField value={item.amount} />
                </InfiniteListStyles.StyledTd>
            },
            {
              type: 'action',
              value: (item, index) => (
                  <InfiniteListStyles.StyledActionTd key={index}>
                    {isPending && (
                      <>
                      <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}> 
                        <EditButton url='#' handleEdit={() => handleEdit(item)}/>
                        <DeleteButton 
                          handleDelete={() => handleDelete(item.id)}
                        />
                      </UserHave>
                      </>
                    )}
                  </InfiniteListStyles.StyledActionTd>
              )
            }
          ]
        }
      />
      {payment &&
        <HiringPersonPaymentForm 
          hiringId={hiringId}
          hiringPersonId={hiringPersonId}
          fetchPayments={fetchPayments}
          data={payment}
          open={open} 
          handleClose={handleClose} 
        />
      }
    </React.Fragment>
  );
}