import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import background from 'src/Assets/background.png';

import Logo from 'src/Assets/logo';
import { sendEmailForConfirmation } from 'src/Services/ResetPassword';
import { Alert, Snackbar } from '@mui/material';

const SendEmailPage = (props: any) => {
  const [email, setEmail] = useState('');
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const defaultTheme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            borderColor: '#FF6A46',
            width: '100%',
            backgroundColor: '#FFF',
            borderRadius: '6px',
            height: '3rem',
            '& .MuiInputBase-input': {
              backgroundColor: '#fff',
              borderRadius: 8,
              paddingTop: 0,
              paddingBottom: 0,
              height: '3rem'
            }
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              textTransform: 'none',
              height: '2.5rem',
              fontWeight: 700,
              borderRadius: 8,
              background: '#FF6A46',
              '&:hover': {
                backgroundColor: '#f18468'
              },
              '&:focus': {
                backgroundColor: '#f18468'
              }
            }
          }
        ]
      }
    }
  });

  const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = () => {
    if (email.length && isValidEmail(email)) {
      setIsLoading(true);

      const body = {
        email: email
      };
      sendEmailForConfirmation(body)
        .then(() => {
          localStorage.setItem('resetEmail', email);
          props.changePage('CONFIRM_CODE');
        })
        .catch(() => {
          setErrorMessage('E-mail não cadastrado');
          setOpenError(true);
        })
        .finally(() => setIsLoading(false));
    } else {
      setErrorMessage('Insira um e-mail valido');
      setOpenError(true);
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={5}
          md={8}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundColor: '#ff3402',
            backgroundSize: 'cover'
          }}
        />

        <Grid
          item
          xs={12}
          sm={7}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{ backgroundColor: '#323B46', color: '#fff' }}
        >
          <Box
            sx={{
              my: 30,
              mx: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Logo />

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" gutterBottom>
                  Confirme seu e-mail para continuar
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: '#FFF'
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Enviando...
                      </Typography>
                      {'  '}
                      <CircularProgress color="inherit" size={16} />
                    </Box>
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="text"
                  sx={{ mt: 2, mb: 2, color: '#FF6A46' }}
                  onClick={() => props.changePage('LOGIN')}
                >
                  Voltar ao login
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key={'bottom' + 'right'}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default SendEmailPage;
