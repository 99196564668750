import * as React from 'react';
import { memo } from 'react';
import { Typography } from '@mui/material';
import i18next from "i18next";
import * as Styled from './styles';

interface TextFieldProps {
    label?: string;
    value: any;
    emptyText?: string;
    locales?: any;
    showTime?: boolean;
    showDate?: boolean;
    [key: string]: any;
}

export const DateField = memo((props: TextFieldProps) => {
    const {
        label,
        value,
        emptyText,
        locales,
        showTime = false,
        showDate = true,
        ...rest
    } = props;

    if (!showTime && !showDate) {
        throw new Error(
            '<DateField> cannot have showTime and showDate false at the same time'
        );
    }

    let dateValue: any = null;

    if (value == null || value === '') {
        dateValue = emptyText ? (
            <Typography
                component="span"
                variant="body2"
                sx={{ fontWeight: 600, fontSize: '15px', color: '#4F4F4F' }}
                {...rest}
            >
                {emptyText && i18next.t(emptyText, { _: emptyText })}
            </Typography>
        ) : null;
    } else {
        const date = value instanceof Date ? value : new Date(value);
        let dateOptions = rest.options;
        if (
            typeof value === 'string' &&
            value.length <= 10 &&
            !showTime &&
            !rest.options
        ) {
            dateOptions = { timeZone: 'UTC' };
        }
        let dateString = '';
        if (showTime && showDate) {
            dateString = toLocaleStringSupportsLocales
                ? date.toLocaleString(locales, rest.options)
                : date.toLocaleString();
        } else if (showDate) {
            dateString = toLocaleStringSupportsLocales
                ? date.toLocaleDateString(locales, dateOptions)
                : date.toLocaleDateString();
        } else if (showTime) {
            dateString = toLocaleStringSupportsLocales
                ? date.toLocaleTimeString(locales, rest.options)
                : date.toLocaleTimeString();
        }

        dateValue = (
            <Styled.StyledValue>
                <Typography
                    component="span"
                    variant="body2"
                    sx={{ fontWeight: (label ? 600: 400), fontSize: '15px', color: '#4F4F4F' }}
                    {...rest}
                >
                    {dateString}
                </Typography>
            </Styled.StyledValue>
        );
    }

    return <Styled.StyledDateField>
        {label && 
            <Styled.StyledLabel>
                <Typography
                    component="span"
                    color="textSecondary"
                    variant="body1"
                    sx={{ fontSize: '14px' }}
                    {...rest}
                >{i18next.t(label)}
                </Typography>
            </Styled.StyledLabel>
        }
        {dateValue}
    </Styled.StyledDateField>;
});

DateField.displayName = 'DateField';

const toLocaleStringSupportsLocales = (() => {
    try {
        new Date().toLocaleString('i');
    } catch (error) {
        return error instanceof RangeError;
    }
    return false;
})();
