import styled from "styled-components";

export const InputBox = styled.input`
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 8px;
    cursor: pointer;
`;

export const SpanBox = styled.span`
    display: flex;
    margin: 1em 1em 1em;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 350px;
    min-width: 200px;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin: 1em 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;