import * as React from 'react';
import { api } from 'src/Services/Financial'
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { showErrorMessage, showSuccessMessage } from 'src/Hooks/useMessages';
import { DateInput } from 'src/Components/Inputs/DateInput';
import moment from 'moment';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import useLoader from 'src/Hooks/useLoader';
import { useGetIdentity } from 'react-admin';
import { ROLE_BASE_MANAGER } from 'src/Enums/Roles';
import i18next from 'i18next';

export const HiringsReport = () => 
{
    const resource = 'hr.hirings'
    const { control, formState: { errors } } = useForm();
    const { config } = React.useContext(ConfigContext);
    const [uf, setUf] = useState('')
    const { options: positions } = useGetManyOptions(api, '/positions')
    const [electionId, setElectionId] = React.useState(config?.electionId);
    const [accountId, setAccountId] = React.useState(config?.accountId);
    const [campaignId, setCampaignId] = React.useState(config?.campaignId);
    const { options: states } = useGetManyOptions(api, '/state', {}, 'id', 'uf')
    const { options: cities } = useGetManyOptions(api, '/city', { uf })
    const { options: elections } = useGetManyOptions(api, '/elections');
    const { options: campaigns } = useGetManyOptions(api, '/campaigns', { electionId });
    const { options: accounts } = useGetManyOptions(api, '/accounts', { campaignId });
    const { data: identity } = useGetIdentity()
    const loader = useLoader()

    const onSubmit = async(form) => 
    {    
        const startDateAux = moment(form.data.dateStart)
        const endDateAux = moment(form.data.dateEnd)
        const params = {} 

        if (form.data.campaignId) {
            params['campaignId'] = form.data.campaignId
        }

        if (form.data.accountId) {
            params['accountId'] = form.data.accountId
        }

        if (form.data.dateStart) {
            params['startDate'] = startDateAux.toDate()
        }

        if (form.data.dateEnd) {
            params['endDate'] = endDateAux.toDate()
        }

        if (form.data.positionId) {
            params['positionId'] = form.data.positionId
        }

        if (form.data.state) {
            params['state'] = form.data.state
        }

        if (form.data.city) {
            params['city'] = form.data.city
        }

        loader.showLoader()
        
        api.get('hr/hirings-report', {
            params,
            responseType: 'arraybuffer'
        }).then(async response => {

            loader.hideLoader()

            if (response) {   
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.setAttribute('target', '_blank');
                a.download = 'hirings.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);    

                showSuccessMessage(`resources.reports.${resource}.messages.success`)
            }
        }).
        catch(error => {
            loader.hideLoader()
            console.log(error)
            showErrorMessage(`resources.reports.${resource}.messages.error`);
        })
    }

    return (
        <SimpleContainer
          title={`resources.reports.${resource}.name`}
          mdSize={900}
        >
            <loader.Loader />
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
                submitButtonLabel='actions.export'
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item md={5} xs={12} sm={12}>
                        {elections && (
                            <ReferenceInput
                                name={'electionId'}
                                label={i18next.t(`resources.reports.${resource}.fields.electionId`)}
                                control={control}
                                defaultValue={electionId ?? ''}
                                options={elections}
                                rules={{ required: 'Pleito Obrigatório' }}
                                helperText={errors.electionId ? errors.electionId?.message : ''}
                                onChange={value => { 
                                setElectionId(value)
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item md={7} xs={12} sm={12}>
                        {electionId && campaigns && (
                            <ReferenceInput
                                name={'campaignId'}
                                label={i18next.t(`resources.reports.${resource}.fields.campaignId`)}
                                control={control}
                                defaultValue={campaignId ?? ''}
                                options={campaigns}
                                rules={{ required: 'Campanha Obrigatória' }}
                                helperText={errors.campaignId ? errors.campaignId?.message : ''}
                                onChange={value => { 
                                setCampaignId(value)
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        {campaignId && accounts && identity && (
                            <ReferenceInput
                                name={'accountId'}
                                label={i18next.t(`resources.reports.${resource}.fields.accountId`)}
                                control={control}
                                defaultValue={accountId ?? null}
                                options={accounts}
                                rules={ (identity.role.name == ROLE_BASE_MANAGER) ? { required: 'Conta Obrigatória' }: {}}
                                helperText={errors.accountId ? errors.accountId?.message : ''}
                                onChange={value => {
                                setAccountId(value);
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={12}>
                        {positions &&
                            <ReferenceInput
                                name={"positionId"}
                                control={control}
                                label={`resources.reports.${resource}.fields.positionId`}
                                options={positions}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={2}>
                        {states && (
                            <ReferenceInput 
                                name="stateId" 
                                control={control}
                                options={states}
                                label={`resources.reports.${resource}.fields.stateId`}
                                onChange={value => setUf(value)}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {cities && (cities.length > 0) && (
                            <ReferenceInput
                                name="cityId" 
                                control={control}
                                options={cities}
                                label={`resources.reports.${resource}.fields.cityId`}
                            />
                        )}
                    </Grid>
                </Grid>       
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={3}>
                        <DateInput 
                            control={control} 
                            name="dateStart" 
                            label={`resources.reports.${resource}.fields.dateStart`}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DateInput 
                            control={control} 
                            name="dateEnd" 
                            label={`resources.reports.${resource}.fields.dateEnd`} 
                        />    
                    </Grid>
                </Grid>
            </Form>
        </SimpleContainer>
    );
}