import i18next from 'i18next';
import { showErrorMessage } from 'src/Hooks/useMessages'

export const handleError = async(error) => 
{
    if (error.response) {

        if (error.response.status == 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
            localStorage.removeItem('permission');
            window.location.reload();
            return false;
        }

        if (error.response.status == 403) {
            window.location.href = '/403';
        }

        const body = error.response.data;

        let errorMessage: string;

        if (body.errors) {
            errorMessage = body.errors[0]
        } else {
            errorMessage = body.message 
                ? (Array.isArray(body.message) 
                    ? body.message[0]
                    : body.message
                ) : i18next.t('core.api.errorMessage');
        }

        showErrorMessage(errorMessage)
        
    } else if (error.request) {
        console.error('No response received:', error.request);
    } else {
        console.error('Request setup error:', error.message);
        // throw new Error('Request setup error');
    }
}