import * as React from 'react';
import { HiringPersonForm } from './HiringPersonForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';

export const HiringPersonCreate = () => 
{
    const navigate = useNavigate()
    const { hiringId } = useParams()

    const onSubmit = (formData) => {
        formData.data.hiringId = hiringId
        api.post(`/hirings/${hiringId}/persons`, formData.data)
            .then(result => {
                if (result?.status == 201) {
                    showSuccessMessage(i18next.t(`resources.hiringsPersons.actions.create.success`) )
                    navigate(`/hr/hirings/${hiringId}/show`)
                }
            })
    }

    return (
        <HiringPersonForm
            data={{
                personId: null
            }}
            onSubmit={onSubmit}
        />  
    );
}