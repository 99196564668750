import React from 'react';
import * as Styled from './styles';
import { Title as PageTitle } from 'react-admin'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import i18next from "i18next";

interface TitleProps {
    text: string;
}

export const Title = ({ text }: TitleProps) => {
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <PageTitle title={i18next.t(text, {count: 2})} />
            {!isSmScreen && (
                <Styled.Title>
                    {i18next.t(text, {count: 2})}
                </Styled.Title>
            )}
        </>
    );
}