import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 2px 1px -1px #00000033;
  box-shadow: 0px 1px 1px 0px #00000024;
  box-shadow: 0px 1px 3px 0px #0000001F;

  @media (max-width: 767px) {
    /* Estilos específicos para dispositivos móveis */
    /* Adicione os estilos desejados aqui */
    flex-direction: column;
    padding: 20px;
    height: auto;
  }
`;

export const Graph = styled.div`
  margin-top: 1em;
  padding-top: 1em;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 550px;
  background-color: #fff;

  @media (max-width: 767px) {
    /* Estilos específicos para dispositivos móveis */
    /* Adicione os estilos desejados aqui */
    flex-direction: column;
    height: auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 24px;
  border-bottom: 1px solid #ACACAC;

  @media (max-width: 768px) { 
    flex-direction: column; 
  }
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #505D6F;
  height: 40px;
`;

export const Filters = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 50;

  @media (max-width: 768px) { 
    width: 100; 
  }
`;

export const HeatMapLegend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;

export const HeatMap = styled.div`
  width: 100%; 
  height: auto;
  max-width: 1500px;
  overflow: auto !important; 

  @media (max-width: 768px) { 
    max-width: 300px; 
  }
`;

export const StyledSVG = styled.svg`
  text {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 12px;
    fill: black; 
  }
`;

