import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationPtBr from 'src/I18n/pt-BR';
import translationEnUs from 'src/I18n/en-US';
import translationEs from 'src/I18n/es';
import translationPtPt from 'src/I18n/pt-PT';

const resources = {
  'pt-BR': {
    translation: translationPtBr
  },
  'en-US': {
    translation: translationEnUs
  },
  es: {
    translation: translationEs
  },
  'pt-PT': {
    translation: translationPtPt
  }
};

i18n.use(initReactI18next).init({
  initImmediate: false,
  resources,
  lng: localStorage.getItem('language') ? localStorage.getItem('language') : 'pt-BR',
  debug: false,
  react: {
    useSuspense: false
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
