import { defaultTheme } from 'react-admin';

export const theme = {
  ...defaultTheme,
  sidebar: {
    width: 280,
    closedWidth: 50
  },
  components: {
    ...defaultTheme.components,
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#383F4B'
        }
      }
    },
    RaMenu: {
      styleOverrides: {
        root: {
          '&.RaMenu-open': {
            width: '280px'
          }
        }
      }
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&.RaMenuItemLink-active': {
            color: '#fff',
            textDecoration: 'none',
            background: '#FF6A46',
          },
          '&.RaMenuItemLink-active:hover': {
            backgroundColor: '#FF6A46',
            color: '#fff',
            textDecoration: 'none'
          },
          '&#menu:hover': {
            backgroundColor: 'transparent',
            color: '#FF6A46',
            textDecoration: 'none'
          },
          '& .RaMenuItemLink-icon': {
            color: '#818181'
          },
          '& .RaMenuItemLink-icon:hover': {
            color: '#fff'
          },
          '&.MuiMenuItem-root:hover': {
            backgroundColor: '#FF6A46',
            color: '#fff',
            textDecoration: 'none'
          }
        }
      }
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: '#383F4B',
          height: 'unset',
          top: '12vh'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiListItem-root:hover': {
            backgroundColor: '#FF6A46',
            color: '#fff',
            textDecoration: 'none'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: '#FF6A46'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF6A46',
          color: 'white',
          borderColor: 'transparent',
          "&:hover": {
            backgroundColor: '#383F4B',
            color: '#FF6A46',
            borderColor: 'transparent',
          },
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#FF6A46'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          color: '#FF6A46'
        },
        root: {
          '&.Mui-selected': {
            color: '#FF6A46',
            fontWeight: 'bold'
          }
        }
      }
    }
  }
};

const mediaQueryMaxWidth425 = `@media (max-width: 425px)`;

theme.components.RaSidebar.styleOverrides.root[mediaQueryMaxWidth425] = {
  backgroundColor: '#383F4B',
  width: 280
};

theme.components.RaMenu.styleOverrides.root[mediaQueryMaxWidth425] = {
  backgroundColor: '#383F4B'
};

theme.components.RaMenuItemLink.styleOverrides.root[mediaQueryMaxWidth425] = {
  backgroundColor: '#383F4B',
  width: 280
};
