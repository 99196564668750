import { combineDataProviders } from 'react-admin';
import financialDataProvider from './FinancialRestProvider';
import i18next from 'i18next';

const dataProvider = combineDataProviders(resource => {
  switch (resource) {
    case 'accounts':
    case 'expense-types':
    case 'categories':
    case 'costcenters':
    case 'costitems':
    case 'donations':
    case 'persons':
      return financialDataProvider;
    default:
      throw new Error(
        `${i18next.t('components.invalid_resource')}: ${resource}`
      );
  }
});

export default dataProvider;
