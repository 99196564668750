import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import i18next from "i18next";

interface DateInputProps {
  name: string, 
  control: any, 
  label: string, 
  defaultValue?: any, 
  onChange?: (event) => void, 
  rules?: any,
  hasTime?: boolean,
  [key: string]: any;
}

export const DateInput = ({ name, control, label, defaultValue, onChange, rules = {}, hasTime, ...rest }: DateInputProps) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({
          field,
          fieldState: { error }
        }) => (
          <TextField
            helperText={error ? error.message : null}
            size="small"
            error={!!error}
            fullWidth
            label={i18next.t(label)}
            type={hasTime ? "datetime-local": "date"}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
              if (onChange) {
                onChange(event)
              }
            }}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            {...rest}
          />
        )}
      />
    );
  };