import { Route, Routes } from "react-router-dom";
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from "src/Pages/Financial/Categories";

export const CategoryRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<CategoryList />} />
          <Route path="/create" element={<CategoryCreate />} />
          <Route path=":id" element={<CategoryEdit />} />
          <Route path=":id/show" element={<CategoryShow />} />
        </Routes>
    )
  }