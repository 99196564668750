import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import i18next from 'i18next';
import {Typography} from '@mui/material'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartProps {
  data: any[];
  labels: string[];
  colors?: string[];
  options?: any;
  plugins?: any;
}

export const BarChartComponents: React.FC<BarChartProps> = ({
  data,
  labels,
  colors,
  options,
  plugins
}) => {
  const chartRef = useRef<any>(null);
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destrói o gráfico anterior, se existir
        const chartInstance = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: [],
            datasets: []
          },
          options: options,
          plugins
        });
        chartInstance.destroy();
      }
    }
  }, []);

  useEffect(() => {
    setChartData({
      labels,
      datasets: data.map(x => {
        return {
          label: x.label,
          data: x.data, // define os dados que virá de uma requisição
          backgroundColor: x.color, // define as cores
          barThickness: 30, // define o tamanho das barras
          borderRadius: x.borderRadius,
          datalabels: {
            color: '#FFF'
          }
        };
      })
    });
  }, [data]);

  return (
    <>
      {data.length <= 0 ? (
        <Typography gutterBottom align={"center"} variant="body1" component="div">
          {i18next.t('components.list.no_register_found')}
        </Typography>
      ) : (
        <Bar
          ref={chartRef}
          data={chartData}
          options={options}
          plugins={plugins}
        />
      )}
    </>
  );
};
