import i18next from 'i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { IsActiveStatus } from 'src/Components/Election/IsActiveStatus';
import { Menu } from 'src/Components/Election/Menu';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { userCan } from 'src/Components/Layout/Can';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';

export const BaseList = () => {
  const endpoint = '/bases';
  const resource = 'bases';
  const { control } = useForm();
  const { options: campaigns } = useGetManyOptions(api, '/campaigns')
  const [tab, setTab] = React.useState(2);
  const { data, setData, fetchData, hasMore, filters, setFilters } =
    useInfiniteFetch(api, endpoint, 25);

  const handleDelete = async (id: number) => {
    try {
      api.delete(endpoint + '/' + id).then(response => {
        if (response && response.status == 200) {
          setData(data.filter(item => item.id !== id));
          showSuccessMessage(`resources.${resource}.actions.delete.success`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name`}>
      <Menu tab={tab} setTab={setTab} />
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton />}
        filters={
          <>
            {campaigns &&
              <ReferenceInput
                name="campaignId"
                control={control}
                label={`resources.accounts.fields.campaignId`}
                options={campaigns}
                style={{ minWidth: 300 }}
                onChange={value => {
                  const newFilters = {
                    ...filters,
                    ...{ campaignId: value }
                  };
                  setFilters(newFilters);
                }}
              />
            }
            <SearchInput
              name="search"
              label={i18next.t(`resources.${resource}.filter.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ minWidth: 300 }}
            />
          </>
        }
        columns={[
          {
            header: `resources.${resource}.fields.campaignId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.campaign.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.name`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.supervisorTeamId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.supervisorTeam?.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.userCampaignId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.userCampaignManager?.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.number`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.number}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.publicPlace`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.publicPlace}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.neighborhood`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.neighborhood}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.zoneId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.zone.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.cityId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.city.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.status`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <IsActiveStatus isActive={item.isActive} />
              </InfiniteListStyles.StyledTd>
            )
          },

          {
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`/election/bases/${item.id}/show`} />
                  <EditButton url={`/election/bases/${item.id}`} />
                  <DeleteButton handleDelete={() => handleDelete(item.id)} />
                </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
};
