import React from 'react';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';
import { VotersForm } from 'src/Pages/Election/Voters/VotersForm';
import { useRedirect } from 'react-admin';
import { removePhoneMask } from 'src/Utils/Formatters';

export const VotersCreate = () => {
  const redirect = useRedirect();
  const endpoint = '/voters';
  const resource = 'voters';

  const onSubmit = formData => {
    const cleanedTelphone = removePhoneMask(formData.data.telphone);

    const data = {
      ...formData.data,
      telphone: cleanedTelphone
    };

    api.post(endpoint, data).then(result => {
      if (result?.status === 201) {
        showSuccessMessage(
          `resources.${resource}.actions.create.success`
        );
        redirect(`/election/voters`);
      }
    });
  };

  return <VotersForm data={{ id: null }} onSubmit={onSubmit} />;
};
