import React, { useEffect, useState } from "react";
import * as Styled from './styles';
import { api } from "src/Services/Elections";
import useGetManyOptions from "src/Hooks/useGetManyOptions";
import ReferenceInput from "src/Components/Inputs/ReferenceInput";
import { useForm } from "react-hook-form";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from 'chartjs-plugin-annotation';

interface ResearchRankingProps {
  dateStart: string,
  dateEnd: string
}

interface RankingDataProps {
  candidateId: string;
  candidateName: string;
  researchName: string;
  votes: number;
  percentage: string;
  profileImageUrl: string;
  blob?: any;
}

const colors = [
  '#8FC6FF',
  '#838F9E'  
]

Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);

export const ResearchRanking = ({dateStart, dateEnd}: ResearchRankingProps) => {

    const [researchId, setResearchId] = useState()
    const { control } = useForm();
    const [data, setData] = useState<RankingDataProps[]>([]);
    const [options, setOptions] = useState<any>({});
    const [images, setImages] = useState<any>([])
    const [barData, setBarData] = useState<any>({
      datasets: [],
      labels: []
    })

    const { options: researches } = useGetManyOptions(api, '/research', {}, 'id', 'description');

    useEffect(() => {

      if (!researchId || !dateStart || !dateEnd) return;

      api.get(`pooling-intention/ranking-candidates?researchId=${researchId}&periodFrom=${dateStart}&periodTo=${dateEnd}`)
        .then(async(response) => {
          if (response) {
            setData(response.data)
            
            const promises = response.data.map(async (item, index) => {
              const img = new Image();
              img.src = (api.getUrl(item.profileImageUrl))
              return { index, img };
            })

            const resolvedImages = await Promise.all(promises);

            setImages(resolvedImages);
          }
        })
    }, [researchId, dateStart, dateEnd])

    useEffect(() => {
      if (images) {
        const imgs = images
          .sort((a, b) => a.index - b.index)
          .map(image => image.img)

        const annotationsarray = data.map((item, index) => {
          return {
            type: 'label',
            drawTime: 'afterDraw',
            content: imgs[index],
            width: 50,
            height: 50,
            xValue: index,
            yValue: parseInt(item.percentage) + 9,
            xAdjust: 0,
            yAdjust: 0,
            callout: {
              display: true,
              position: 'left'
            }
          }
        })

        let annotations = {}
        annotationsarray.forEach((annotation, index) => {
          annotations = {
            ...annotations,
            ...{[index]: annotation}
          }
        })

        setOptions({
          animation: false,
          scales: {
            x: {
              grid:{
                  color: '#ccc',
              },
              border: {
                  dash: [2,4],
              },  
              
            },
            y: {
                grid: {
                    color: '#ccc',
                },
                border: {
                    dash: [2,4],
                }, 
                ticks: {
                    callback: function(value) {
                        return value + '%';
                    }
                }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: "black",
              formatter: function(value) {
                return parseFloat(value).toFixed(0) + '%';
              },
              anchor: "end",
              offset: -20,
              align: "start"
            },
            annotation: {
              clip: false,
              annotations
            }
          }
        })

        const candidates = data.map((x) => x.candidateName)

        if (data.length) {
          setBarData({
            labels: candidates,
            datasets: [
              {
                label: data[0].researchName,
                data: data.map(item => item.percentage),
                backgroundColor: colors[0],
                borderRadius: 8,
                barThickness: 40
              }
            ]
          })
        }
      }
    }, [images])

    return (  
      <Styled.Container>

        <Styled.Header>
          <Styled.Title>
            Pesquisa
          </Styled.Title>
          <Styled.Filters>
            {researches && (
              <ReferenceInput
                name={'researchId'}
                control={control}
                label={`resources.reports.elections.research.fields.researchId.label`}
                defaultValue={null}
                options={researches}
                onChange={
                  value => { setResearchId(value) }
                }
                style={{ minWidth: 300 }}
              />
            )}
          </Styled.Filters>
        </Styled.Header>
        {data && (data.length > 0) && images && (images.length > 0) && (barData) && (
          <Styled.Graph>
            <Bar data={barData} options={options} plugins={[ChartDataLabels]} width={100} height={80} />
          </Styled.Graph>
        )}
      </Styled.Container>
    )
}