import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import i18next from 'i18next';
import statuses from 'src/Enums/ExpenseStatuses';
import { SelectInput } from 'src/Components/Inputs/SelectInput';
import { useForm } from 'react-hook-form';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { DecimalField } from 'src/Components/Admin/Fields/DecimalField';
import { ConfigContext } from 'src/Hooks/useConfigContext';

export const ExpenseList = () => {
  const { config } = React.useContext(ConfigContext);
  const { control } = useForm();
  const endpoint = '/expenses';
  const resource = 'expenses';
  const { data, setData, fetchData, hasMore, filters, setFilters } =
    useInfiniteFetch(api, endpoint, 25);

  const handleDelete = async (id: number) => {
    try {
      await api.delete(endpoint + '/' + id);
      setData(data.filter(item => item.id !== id));
      showSuccessMessage(`resources.${resource}.actions.delete.success`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name`}>
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton />}
        filters={
          <>
            <SearchInput
              name="search"
              label={i18next.t(`resources.expenses.filters.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ minWidth: 300 }}
            />
            <SelectInput
              name="state"
              control={control}
              options={statuses}
              label={`resources.${resource}.fields.status`}
              style={{ minWidth: 150 }}
              onChange={e => {
                const newFilters = {
                  ...filters,
                  ...{ status: e.target.value }
                };
                setFilters(newFilters);
              }}
            />
            <DateInput
              control={control}
              name="schedule_dateStart"
              label={i18next.t(
                `resources.expenses.filters.schedule_start_date`
              )}
              onChange={e => {
                const newFilters = {
                  ...filters,
                  ...{ schedule_dateStart: e.target.value }
                };
                setFilters(newFilters);
              }}
            />
            <DateInput
              control={control}
              name="schedule_dateEnd"
              label={i18next.t(`resources.expenses.filters.schedule_end_date`)}
              onChange={e => {
                const newFilters = {
                  ...filters,
                  ...{ schedule_dateEnd: e.target.value }
                };
                setFilters(newFilters);
              }}
            />
          </>
        }
        columns={[
          {
            header: `resources.${resource}.fields.scheduled_date`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DateField value={item.scheduled_date} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.personId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.person.fullName}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.description`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.description}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.quantity`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DecimalField value={item.quantity} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.amount`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DecimalField value={item.amount} hasSymbol={true} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.status`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {i18next.t(`resources.expenses.statuses.${item.status}`)}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`${item.id}/show`} />
                <EditButton url={`${item.id}`} />
                <DeleteButton handleDelete={() => handleDelete(item.id)} />
              </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
};
