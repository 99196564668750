import { Route, Routes } from "react-router-dom"
import { AccountRoutes } from "../Financial/AccountRoutes"
import { CategoryRoutes } from "./CategoryRoutes"
import { GuardedComponent } from "src/Components/Financial/Guarded"
import { PermittedRoute } from "src/Components/Auth/PermittedRoute"
import { CostcenterRoutes } from "./CostcenterRoutes"
import { CostitemRoutes } from "./CostitemRoutes"
import { DonationRoutes } from "./DonationRoutes"
import { ExpenseRoutes } from "./ExpenseRoutes"
import { ExpenseTypeRoutes } from "./ExpenseTypeRoutes"
import { PersonRoutes } from "./PersonRoutes"
import { FinancialResume } from "src/Pages/Financial/Reports/Resume"
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles"
import { ValidateRoute } from "src/Components/Auth/ValidateRoute"

export const FinancialRoutes = () => 
{    
    return (
        <Routes>
            
            <Route path="/accounts/*" element={ValidateRoute(ROLE_CANDIDATE, <AccountRoutes />)} />

            <Route 
                path="/categories/*" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<CategoryRoutes />))} 
            />
            <Route 
                path="/costcenters/*" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<CostcenterRoutes />))} 
            />
            <Route 
                path="/costitems/*" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<CostitemRoutes />))} 
            />
            <Route 
                path="/donations/*" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<DonationRoutes />))} 

            />
            <Route 
                path="/expenses/*" 
                element={PermittedRoute('expenses', GuardedComponent(<ExpenseRoutes />))} 
            />
            <Route 
                path="/expense-types/*" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<ExpenseTypeRoutes />))} 

            />
            <Route 
                path="/persons/*" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<PersonRoutes />))} 
            />            
            <Route path="/" element={GuardedComponent(<FinancialResume />)} />

            <Route path="/resume" 
                element={PermittedRoute('reports', GuardedComponent(<FinancialResume />))} 
            />

            <Route path="/*" element={GuardedComponent(<FinancialResume />)} />
        </Routes>
    );
}