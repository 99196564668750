import { Route, Routes } from "react-router-dom";
import { CostitemCreate, CostitemEdit, CostitemList, CostitemShow } from "src/Pages/Financial/Costitems";

export const CostitemRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<CostitemList />} />
          <Route path="/create" element={<CostitemCreate />} />
          <Route path=":id" element={<CostitemEdit />} />
          <Route path=":id/show" element={<CostitemShow />} />
        </Routes>
    )
  }