import * as React from 'react';
import { HiringPersonForm } from './HiringPersonForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const HiringPersonEdit = () => {
    const { hiringId, id } = useParams()
    const { data } = useGetOne(api, `/hirings/${hiringId}/persons`, id);
    const navigate = useNavigate(); 
    
    const onSubmit = (formData) => {
        api.put(`/hirings/${hiringId}/persons/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    navigate(`/hr/hirings/${hiringId}/show/persons/${id}/show`)
                    showSuccessMessage(`resources.hiringPersons.actions.edit.success`)
                }
            })
    }

    return (
        <>
        {data && (
            <HiringPersonForm
                data={data}
                onSubmit={onSubmit}
            />  
        )}
        </>
    );
}