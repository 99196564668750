import list from './list.json';
import calendarRange from './calendarRange.json';

const messages = {
  others: 'Otros',
  no_research: 'No hay investigaciones para mostrar',
  description: 'Descripción',
  invalid_resource: 'Recurso inválido',
  loading: 'Cargando...',
  list,
  calendarRange
};

export default messages;
