import React from 'react';
import * as Styled from './styles';
import img from '../../../Assets/download.png'

interface TotalVotesProps {
    quantity: string;
    color: string;
    title: string;
}

export const TotalVotesCardComponents: React.FC<TotalVotesProps> = ({ quantity, color, title }) => {
    return (
        <Styled.Container color={color}>
            <Styled.CardContainer color={color}>
                <Styled.Values>{quantity}</Styled.Values>
            </Styled.CardContainer>

            <Styled.Title>{title}</Styled.Title>
            <Styled.Image src={img} />
        </Styled.Container>
    );
};

