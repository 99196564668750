import * as React from 'react';
import { PlaceForm } from './PlaceForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

export const PlaceCreate = () => 
{
    const navigate = useNavigate()

    const onSubmit = (formData) => {
        api.post('/places', formData.data)
        .then(result => {
            if (result?.status == 201) {
                showSuccessMessage(i18next.t(`resources.places.actions.create.success`) )
                navigate(`/stock/places/${result.data.id}/show`)
            }
        })
    }

    return (
        <PlaceForm
            data={{
                personId: null
            }}
            onSubmit={onSubmit}
        />  
    );
}