import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import i18next from 'i18next';

export const PasswordInput = ({
  name,
  control,
  label,
  defaultValue,
  onChange,
  ...rest
}: any) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type={showPassword ? 'text' : 'password'}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          fullWidth
          label={i18next.t(label)}
          value={field.value}
          onChange={event => {
            field.onChange(event.target.value);
            if (onChange) {
              onChange(event);
            }
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )
          }}
          {...rest}
        />
      )}
    />
  );
};
