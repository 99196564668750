import tabs from './tabs.json';
import bases from './bases.json';
import voters from './voters.json';
import zones from './zones.json';
import workers from './workers.json';
import totals from './totals.json';
import messages from './messages.json';

export default {
  title: 'Data Ranking',
  tabs,
  bases,
  voters,
  zones,
  workers,
  totals,
  messages
};
