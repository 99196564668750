import { Route, Routes } from "react-router-dom";
import { UserHave } from "src/Components/Auth/UserHave";
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles";
import { HiringCreate, HiringEdit, HiringList, HiringShow } from "src/Pages/Hr/Hirings";
import { HiringPersonEdit } from "src/Pages/Hr/Hirings/HIringPersonEdit";
import { HiringConfirm } from "src/Pages/Hr/Hirings/HiringConfirm";
import { HiringPersonComplete } from "src/Pages/Hr/Hirings/HiringPersonComplete";
import { HiringPersonCreate } from "src/Pages/Hr/Hirings/HiringPersonCreate";
import { HiringPersonShow } from "src/Pages/Hr/Hirings/HiringPersonShow";

export const HiringRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<HiringList />} />
          <Route path="/create" element={<HiringCreate />} />
          <Route path=":id" element={<HiringEdit />} />
          <Route path=":id/show" element={<HiringShow />} />
          <Route path=":hiringId/show/persons/create" element={<HiringPersonCreate />} />
          <Route path=":hiringId/show/persons/:id" element={<HiringPersonEdit/>} />
          <Route path=":hiringId/show/persons/:id/show" element={<HiringPersonShow/>} />
          <Route path=":id/confirm" element={
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <HiringConfirm/>
            </UserHave>
            } />
          <Route path=":hiringId/show/persons/:id/complete" element={
            <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
              <HiringPersonComplete/>
            </UserHave>
            } />
        </Routes>
    )
  }