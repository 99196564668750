import styled from 'styled-components';

interface ContainerProps {
  backgroundColor: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50px;
  padding: 3px 4px;
  max-width: 100px;
`;
