import styled from "styled-components";

export const StyledDateField = styled.div`
    margin-bottom: 8px;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    gap: 60px;
`;
export const StyledLabel = styled.div`
    font-size: 0.75em;
    min-width: 200px;
    text-align: right;
`

export const StyledValue = styled.div`
    font-weight: bold;
    font-size: 0.75em;
`