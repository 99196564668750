import * as React from "react";
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ReturnActionProps {
    title?: string,
    label?: string,
    onClick: VoidFunction
}

export const ReturnAction = ({ title, label, onClick }: ReturnActionProps) => {
    return (
        <Link title={title} to={'#'} onClick={() => { onClick ? onClick() : history.back() }}>
            <ArrowBackIcon/> { label }
        </Link>
    );
}

ReturnAction.defaultProps = {
    title: '',
    label: 'Retornar'
}