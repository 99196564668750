import React from 'react';
import { useGetIdentity } from 'react-admin';
import { useForm } from 'react-hook-form';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { useInfiniteFetchWithParams } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import { DateInput } from 'src/Components/Inputs/DateInput';
import Grid from '@mui/material/Grid';
import { showErrorMessage } from 'src/Hooks/useMessages';
import i18next from 'i18next';

interface VotersByPeriodZonesPageProps {
  dateStart: string;
  setDateStart: any;
  dateEnd: string;
  setDateEnd: any;
}

export function VotersByPeriodZonesPage({ dateStart, setDateStart, dateEnd, setDateEnd }: VotersByPeriodZonesPageProps) {
  const endpoint = '/voters-by-period/zones';
  const resource = 'resources.reports.elections.votersByPeriod';
  const { control } = useForm();
  const [userId] = React.useState<any>(null);
  const { data: identity } = useGetIdentity();
  const { data, fetchData, hasMore, setParams, filters, setFilters } =
    useInfiniteFetchWithParams(api, endpoint, { userId }, 25);

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t('resources.home.messages.invalid_date'));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(start).toISOString();
  };

  React.useEffect(() => {
    if (dateStart && dateEnd) {
      const dateStartBound = getDateBounds(dateStart);
      const dateEndBound = getDateBounds(dateEnd);

      if (dateStartBound) {
        setParams({ dateStart: dateStartBound, dateEnd: dateEndBound });
        setFilters({
          ...filters,
          ...{
            dateStart: dateStartBound, dateEnd: dateEndBound
          }
        });
      }
    }
  }, [dateStart, dateEnd]);

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      filters={
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateStart"
              label={`${resource}.filters.dateStart`}
              onChange={e => {
                setDateStart(e.target.value);
              }}
              defaultValue={dateStart}
              data-id="datefilter"
            />
          </Grid>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateEnd"
              label={`${resource}.filters.dateEnd`}
              onChange={e => {
                setDateEnd(e.target.value);
              }}
              defaultValue={dateEnd}
              data-id="datefilterEnd"
            />
          </Grid>
        </Grid>
      }
      columns={[
        {
          header: `${resource}.zones.results.zoneName`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.zoneName}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.zones.results.neighborhood`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.neighborhood}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.zones.results.publicPlace`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.publicPlace}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.zones.results.counter`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.counter}
            </InfiniteListStyles.StyledTd>
          )
        }
      ]}
    />
  );
}
