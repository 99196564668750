import React, { useEffect, useState } from "react";
import PoolingIntentionDataProvider from "src/Providers/PoolingIntentionDataProvider";
import { PieChartComponents } from "src/Components/Reports/PieChartComponents";
import { BarChartComponents } from "src/Components/Reports/BarChartComponents";
import { IBarChart } from "src/Interfaces/PanelPage/bar-chart.interface";
import { IDonnutChart } from "src/Interfaces/PanelPage/donnut-chart.interface";
import { format, parse } from 'date-fns';
import { IElectionChartFilters } from "src/Interfaces/Election/Charts/filters.interface";
import {
  Grid,
  Typography
} from '@mui/material';
import i18n from "src/i18n";

export const StatsByUsers = ({dateStart, dateEnd}: IElectionChartFilters) => {
  const resource = 'resources.reports.elections.intentions';

    const [stats, setStats] = useState([
        {
          period: '',
          category: 0,
          totalRecords: 0,
        },
      ]);

    const [barData, setBarData] = useState<IBarChart>({
      data: [],
      labels: [],
      colors: []
    })

    const [pieData, setPieData] = useState<IDonnutChart>({
      data: [],
      labels: [],
      colors: []
    })

    useEffect(() => {

      const getStats = async () => {
          const filters = {
              periodFrom: dateStart,
              periodTo: dateEnd
          };

          PoolingIntentionDataProvider.getStatsByUsers(filters).then((response) => {
            if (response)
              setStats(response.data)
            else
              setStats([])
        });
      }

      getStats()
    }, [dateStart, dateEnd])

    useEffect(() => {

      const colors = [
        '#ED874D',
        '#5AC2DE',
        '#485778',
        '#FED769',
      ].sort(() => Math.random() - 0.5);

      const periods = stats
        .map(x => x.period.toString())
        .map(period => 
            period ? format(
                parse(period.toString(), 'yyyy-MM-dd', new Date()),
              'dd/MM/yyyy'
            ) : ''
          )
        .filter((value, index, self) => value && self.indexOf(value) === index);

      const categories = stats
                          .map((x) => x.category.toString())
                          .filter((value, index, self) => value && self.indexOf(value) === index);
      
      const data = categories.map((category, index) => ({
        label: category,
        data: stats
                .filter(item => item.category.toString() == category)
                .map(item => item.totalRecords),
        color: colors[index % 13]
      }))

        setBarData({
          labels: periods,
          data: data,
          colors
        })

        setPieData({
          labels: categories,
          data: categories
                .map((category) => stats
                  .filter(item => item.category.toString() == category)
                  .reduce((acc, { totalRecords }) => acc + totalRecords, 0)
                ),
          colors
        })
  
    }, [stats])
  
    return (  
      <Grid container>
        <Grid item md={6} xs={12} sm={12}>
          <Typography
            gutterBottom 
            variant="h5" 
            component="h5">{i18n.t(`${resource}.charts.user.title`)}</Typography>
        </Grid>
        <Grid item container
          spacing={6}
          justifyContent="center"
          alignItems="center" 
          columns={{ xs: 1, sm: 2, md: 12 }}>
            <Grid item md={6} xs={6} sm={12}>
              <PieChartComponents
                  data={pieData.data}
                  labels={pieData.labels}
                  colors={pieData.colors}
              />
            </Grid>
            <Grid item md={6} xs={6} sm={12}>
              <BarChartComponents
                  labels={barData.labels}
                  data={barData.data}
                  colors={barData.colors}
              />
            </Grid>
        </Grid>
        
      </Grid>
    )
}