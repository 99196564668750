import React from 'react';

const LogoToolbar = () => {
  return (
    <svg
      width="125"
      height="50"
      viewBox="0 0 625 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_546_5378)">
        <path
          d="M141.98 166.902L150.655 94.6777L150.518 94.7151L115.112 131.885C127.788 139.914 137.444 152.275 141.98 166.902Z"
          fill="#A09FA2"
        />
        <path
          d="M0.878403 98.0884L18.5876 171.271L19.1965 170.549C22.5768 156.819 30.3937 144.856 41.0317 136.242C21.856 118.379 2.19572 99.9058 0.878403 98.0884Z"
          fill="#A09FA2"
        />
        <path
          d="M142.875 39.0467L142.328 35.4367H142.254C139.843 21.3081 137.494 7.95125 136.139 0.283203C126.396 24.6069 103.331 41.7106 76.4128 41.7106C49.4948 41.7106 27.8958 25.6401 17.668 2.52387L6.7939 61.3786C4.68123 72.6318 2.46913 84.5695 1.42522 90.4699L0.729279 94.2168L0.803844 94.1546C0.803844 94.1546 0.803844 94.2044 0.803844 94.2168C0.803844 94.2417 0.828699 94.2915 0.878409 94.3537C2.19572 96.1712 21.856 114.644 41.0193 132.507C59.8221 150.034 78.1526 166.964 78.1526 166.964L115.112 128.163L150.518 90.9927L150.667 90.8434C151.363 89.9969 147.125 63.9679 142.888 39.0591L142.875 39.0467Z"
          fill="#F26A49"
        />
      </g>
      <path
        d="M561.867 45.4243H623.412V59.2254H581.263V77.7511H619.309V91.5521H581.263V112.316H624.656V126.117H561.867V45.4243Z"
        fill="#A09FA2"
      />
      <path
        d="M523.364 127.36C519.716 127.36 516.442 127.195 513.541 126.863C510.723 126.532 507.615 125.91 504.216 124.998C500.403 124.003 497.253 122.719 494.767 121.144C492.28 119.486 490.208 117.207 488.55 114.305C486.809 111.404 485.939 107.964 485.939 103.986V68.5506C485.939 52.3043 498.414 44.1812 523.364 44.1812C529.746 44.1812 538.284 45.0929 548.976 46.9165V61.4635C539.361 59.1426 530.906 57.9822 523.612 57.9822C520.545 57.9822 518.142 58.0651 516.401 58.2309C514.743 58.3966 513.002 58.8111 511.179 59.4742C507.283 60.8004 505.335 63.743 505.335 68.3019V102.618C505.335 109.912 512.008 113.559 525.353 113.559C531.072 113.559 539.112 112.358 549.474 109.954V124.625C540.604 126.449 531.901 127.36 523.364 127.36Z"
        fill="#A09FA2"
      />
      <path
        d="M394.308 45.4243H413.953L452.994 100.504V45.4243H470.276V126.117H450.631L411.59 70.1667V126.117H394.308V45.4243Z"
        fill="#A09FA2"
      />
      <path
        d="M357.517 67.7007H376.913V126.117H357.517V67.7007Z"
        fill="#A09FA2"
      />
      <path
        d="M357.517 44.1812H376.913V59.8061H357.517V44.1812Z"
        fill="#F26A49"
      />
      <path
        d="M267.06 45.4243H286.456V77.7511H320.772V45.4243H340.168V126.117H320.772V91.5521H286.456V126.117H267.06V45.4243Z"
        fill="#A09FA2"
      />
      <path
        d="M194.937 45.4243H214.333V112.316H253.995V126.117H194.937V45.4243Z"
        fill="#A09FA2"
      />
      <defs>
        <clipPath id="clip0_546_5378">
          <rect
            width="150"
            height="171"
            fill="white"
            transform="translate(0.729279 0.270996)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoToolbar;
