import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';
import { BaseForm } from './BaseForm';

export const BaseEdit = () => {
  const { id } = useParams();
  const { data } = useGetOne(api, '/bases', id);
  const navigate = useNavigate();

  const onSubmit = (formData) => {
    api.put(`/bases/${id}`, formData.data).then((result) => {
      if (result?.status == 200) {
        showSuccessMessage(`resources.bases.actions.edit.success`);
        navigate(`/election/bases/${result.data.id}/show`);
      }
    });
  };

  return <>{data && <BaseForm data={data} onSubmit={onSubmit} />}</>;
};
