import React from 'react';
import {Card, CardContent} from '@mui/material';

interface CardData {
    label: string,
    value: string,
    backgroundColor?: string
}

export const StatCard = (data: CardData) => {
    return <Card sx={{ backgroundColor: (data.backgroundColor ?? 'rgba(220, 220, 220, 1)') }}>
        <CardContent>
            <p>{ data.label }</p>
            <h1 style={{ fontSize: 24 }}>{ data.value }</h1>
        </CardContent>
    </Card>;
}