import * as React from 'react';
import PropTypes from 'prop-types';
import { ReactNode } from "react";
import * as Styled from './styles';
import { handleError } from 'src/Services/Handlers/handleError'
import i18next from 'i18next';

interface CreateFormProps {
    control: any,
    headers?: any, 
    children: ReactNode,
    showSaveButton?: boolean,
    showCancelButton?: boolean,
    submitButtonLabel?: string | null,
    onCancel?: () => void,
    [key: string]: any;
}

export const Form = ({ 
        control,
        headers, 
        children,
        showSaveButton = true,
        showCancelButton = true,
        submitButtonLabel = null,
        onCancel,
        ...rest 
    }: CreateFormProps) => 
{
    const token = localStorage.getItem('token');

    headers = {
        ...{
            'Authorization': `Bearer ${token}`,
        },
        ...headers
    }

    return <>
        <Styled.StyledForm
            control={control}
            headers={headers}
            onError={response => rest.onError ? rest.onError(response) : handleError(response)}
            encType='application/json'
            {...rest}
        >
            {children}
            <Styled.StyledActions>
                {showSaveButton && 
                    <Styled.SaveButton 
                        type='submit'
                        variant='contained'
                        color='primary'
                        size='medium'
                    >{i18next.t(submitButtonLabel ?? 'actions.submit')}
                    </Styled.SaveButton>
                }
                {showCancelButton && 
                    <Styled.CancelButton 
                        type='button'
                        variant='outlined'
                        color='primary'
                        size='medium'
                        onClick={() => { onCancel? onCancel() : history.back() }}
                    >{i18next.t('actions.cancel')}
                    </Styled.CancelButton>
                }
                {rest.buttons}
            </Styled.StyledActions>
        </Styled.StyledForm>
    </>
}

Form.propTypes = {
    showSaveButton: PropTypes.bool,
    showCancelButton: PropTypes.bool,
};