import { Route, Routes } from "react-router-dom";
import { CostcenterCreate, CostcenterEdit, CostcenterList, CostcenterShow } from "src/Pages/Financial/Costcenters";

export const CostcenterRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<CostcenterList />} />
          <Route path="/create" element={<CostcenterCreate />} />
          <Route path=":id" element={<CostcenterEdit />} />
          <Route path=":id/show" element={<CostcenterShow />} />
        </Routes>
    )
  }