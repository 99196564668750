// ForbiddenPage.jsx

import React from 'react';
import { Typography, Box } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';

const ForbiddenPage = () => {
  return (
    <SimpleContainer 
        title='Acesso negado' 
        maxWidth="md" 
        sx={{ textAlign: 'center', mt: 5 }}
        hasBreadcrumbs={false}
    >
      <Box sx={{ fontSize: 60, color: 'warning.main' }}>
        <ReportProblemOutlined fontSize='large' />
      </Box>
      <Typography variant="subtitle1" component="p">
        Desculpe, você não possui permissão ao recurso solicitado.
      </Typography>
    </SimpleContainer>
  );
};

export default ForbiddenPage;

