import styled from "styled-components";
import { Form } from "react-hook-form"
import Button from '@mui/material/Button';

export const StyledForm = styled(Form)`
    padding: 30px 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 12px;

    box-shadow: 0px 2px 1px -1px #00000033;
    box-shadow: 0px 1px 1px 0px #00000024;
    box-shadow: 0px 1px 3px 0px #0000001F;
`;

export const StyledActions = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
    margin-top: 15px;
`;

export const SaveButton = styled(Button)`
`;

export const CancelButton = styled(Button)`
`;