import { Route, Routes } from "react-router-dom";
import { ExpenseTypeCreate, ExpenseTypeEdit, ExpenseTypeList, ExpenseTypeShow } from "src/Pages/Financial/ExpenseTypes";

export const ExpenseTypeRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<ExpenseTypeList />} />
          <Route path="/create" element={<ExpenseTypeCreate />} />
          <Route path=":id" element={<ExpenseTypeEdit />} />
          <Route path=":id/show" element={<ExpenseTypeShow />} />
        </Routes>
    )
  }