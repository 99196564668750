import styled from "styled-components";

export const AccountsBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-left: 20px; 
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid rgb(221, 221, 221);
`;

export const AccountBox = styled.div`
  display: flex;
  padding: 5px;
  width: 100%;
  align-items: center;
  flex-wrap: no-wrap;
  flex-direction: column;
  margin-bottom: 10px;

  & i {
    display: none;
    float: right;
    margin: 10px;

    & svg {
      margin-left: 10px;
    }
  }

  &:hover {
    background-color: #efefef;

    & i {
      display: inline;
    }
  }
`;

export const AccountData = styled.div`
  display: flex;
  padding: 5px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  & i {
    display: none;
    float: right;
    margin: 0 10px;

    & svg {
      margin-left: 10px;
    }
  }

  &:hover {
    background-color: #efefef;

    & i {
      display: inline;
    }
  }
`;

export const AccountName= styled.div`
  width: 50%;
  float: left;

  & svg {
    margin-top: -2px;
    margin-right: 5px;
  }
`