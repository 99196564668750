import React from 'react';
import TextField from '@mui/material/TextField';

interface SearchInputProps {
    name?: string;
    label?: string;
    filters: any;
    setFilters: (values: any) => void,
    [key: string]: any;
}

export const SearchInput = ({name, label, filters, setFilters, ...rest}: SearchInputProps) => {
    return (
        <TextField
            label={label ?? "Pesquisa"}
            defaultValue={filters[name ?? 'search']}
            variant="outlined" 
            onChange={e => {
                const newFilters = {
                ...filters,
                [name ?? 'search']: e.target.value
                }
                setFilters(newFilters);
            }}
            {...rest}
        />
    );
}