import { api } from 'src/Services/Financial';

const apiUrl = String(process.env.REACT_APP_API_URL)

interface ListMovementResponse {
    data: any[];
}

interface GetBalanceResponse {
    totalDonations: number;
    totalExpenses: number;
    totalIncomeTransfers: number;
    totalTransfers: number;
}

export default {
    listMovement: (accountId: string, startDate: Date, endDate: Date) => 
        api.get(`reports?accountId=${accountId}&startDate=${startDate}&endDate=${endDate}`),
    listExpensesGraph: (accountId: string, startDate?: Date, endDate?: Date) => 
        api.get(`reports/movement/expenses/graph?accountId=${accountId}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate: ''}`),
    listCampaignExpensesGraph: (startDate?: string, endDate?: string) => 
        api.get(`reports/movement/expenses/campaign-graph?${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate: ''}`),
    listDonationsGraph: (startDate?: Date, endDate?: Date) => 
        api.get(`reports/movement/donations/graph?n=1${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate: ''}`),
    getBalance: (accountId: string, date: Date) => 
        api.get(`reports/balance?accountId=${accountId}&date=${date}`),
    getTotalBalance: (accountId: string | null)  => 
        api.get(`reports/balance/total?accountId=${accountId}`),
    getTotalCampaignBalance: ()  => 
        api.get(`reports/balance/total-campaign`),
    exportCostByZone: (accountId: string, state: string, startDate: Date, endDate: Date) =>
        api.post(`reports/cost-by-city`, {
            accountId,
            state,
            startDate,
            endDate
        }, { 
            responseType: 'arraybuffer' 
        }),
    listChartOfAccounts: (accountId: number, startDate: Date, endDate: Date) => 
        api.post(`reports/chart-of-accounts`, {
            accountId,
            startDate,
            endDate
        })
}