import React from 'react';
import { useForm } from 'react-hook-form';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { useInfiniteFetchWithParams } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import i18next from 'i18next';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { GoalAchieved } from 'src/Components/Election/GoalAchieved';
import Grid from '@mui/material/Grid';
import { showErrorMessage } from 'src/Hooks/useMessages';

interface HomeWorkersPageProps {
  dateRef: string;
  setDateRef: any;
}

export function HomeWorkersPage({ dateRef, setDateRef }: HomeWorkersPageProps) {
  const endpoint = '/home/workers';
  const resource = 'home.workers';
  const { control } = useForm();

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t('resources.home.messages.invalid_date'));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(start).toISOString();
  };

  const { data, fetchData, hasMore, setParams, filters, setFilters } =
    useInfiniteFetchWithParams(api, endpoint, { date: null }, 25, {
      date: getDateBounds(dateRef)
    });

  React.useEffect(() => {
    if (dateRef) {
      const date = getDateBounds(dateRef);

      if (date) {
        setParams({ date });
        setFilters({
          ...filters,
          ...{
            date
          }
        });
      }
    }
  }, [dateRef]);

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      filters={
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={4} data-id="grid">
            <DateInput
              control={control}
              name="dateStart"
              label={`resources.${resource}.filters.date`}
              onChange={e => {
                setDateRef(e.target.value);
              }}
              defaultValue={dateRef}
              data-id="datefilter"
            />
          </Grid>
          <Grid item xs={12} md={8} data-id="grid">
            <SearchInput
              name="search"
              label={i18next.t(`resources.${resource}.filters.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      }
      columns={[
        {
          header: `resources.${resource}.results.name`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.name}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.baseName`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.baseName}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.zoneName`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.zoneName}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.telphone`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.telphone}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.email`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.email}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.totalDay`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd
              key={index}
              style={{ textAlign: 'center' }}
            >
              <GoalAchieved goal={item.goal} value={item.totalDay} />
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.totalWeek`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd
              key={index}
              style={{ textAlign: 'center' }}
            >
              {item?.totalWeek}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.totalMonth`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd
              key={index}
              style={{ textAlign: 'center' }}
            >
              {item?.totalMonth}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.total`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index} style={{ textAlign: 'center'}}>
              <GoalAchieved goal={item.totalGoal} value={item.total} />
            </InfiniteListStyles.StyledTd>
          )
        }
      ]}
    />
  );
}
