import * as React from 'react';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { SelectInput } from 'src/Components/Inputs/SelectInput';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import { paymentTypes } from 'src/Enums/HiringPersonPaymentTypes';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import useGetOne from 'src/Hooks/useGetOne';
import { useNavigate, useParams } from 'react-router-dom';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import { View } from 'src/Components/Admin/View';
import { ViewAction } from 'src/Components/Admin/Actions/ViewAction';

export const HiringPersonComplete = () => 
{
    const resource = 'hiringPersons.complete'
    const { hiringId, id } = useParams()
    const { control } = useForm();
    const basePath = `/hr/hirings/${hiringId}/show`
    const navigate = useNavigate(); 
    const { data } = useGetOne(api, `/hirings/${hiringId}/persons`, id);
    const [paymentType, setPaymentType] = React.useState(data?.payment_type)
    
    const onSubmit = (formData) => {
        api.put(`/hirings/${hiringId}/persons/${id}/complete`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    navigate(`/hr/hirings/${hiringId}/show/persons/${id}/show`)
                    showSuccessMessage(`resources.${resource}.success`)
                }
            })
    }

    React.useEffect(() => {
        if (data) setPaymentType(data.payment_type)
    }, [data])

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={900}
        >
            {data && (
                <>
                <View
                    baseUrl={basePath}
                    backendUrl={`/hirings/${hiringId}/persons/${id}`}
                    actions={[
                        <ViewAction 
                            key={0} 
                            title='resources.hiringPersons.actions.view'
                            url={`/hr/hirings/${hiringId}/show/persons`}
                            id={id}
                        />,
                    ]}
                >
                    <SimpleShowLayout>
                        <TextField value={data?.person.fullName} label={`resources.hiringPersons.fields.personId`} />
                        <TextField value={data?.position.name} label={`resources.hiringPersons.fields.positionId`} />
                    </SimpleShowLayout>
                </View>
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmit}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }} style={{ marginTop: 10 }}>
                        <Grid item xs={12} md={3}>
                            <SelectInput 
                                name="payment_type" 
                                control={control}
                                options={paymentTypes}
                                label={`resources.${resource}.fields.payment_type`}
                                defaultValue={data?.payment_type}
                                helperText={"Recorrência de pagamento"}
                                onChange={event => setPaymentType(event.target.value)}
                            />
                        </Grid>
                        {console.log(paymentType)}
                        {data && paymentType && (paymentType !== 'UNIQUE') && 
                        <Grid item xs={6} md={3}>
                            <CustomInput
                                name={"installments"}
                                control={control}
                                label={`resources.${resource}.fields.installments`}
                                defaultValue={data?.installments}
                                type={"number"}
                            />
                        </Grid>
                        }
                        <Grid item xs={6} md={3}>
                            <DateInput
                                name="due_date" 
                                control={control}
                                label={`resources.${resource}.fields.due_date`}
                                defaultValue={data?.due_date}
                                helperText={'Data de pagamento ou da primeira parcela'}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DecimalInput
                                name={"base_amount"}
                                control={control}
                                label={`resources.${resource}.fields.base_amount`}
                                defaultValue={data?.base_amount}
                                style={{ width: '100%' }}
                                helperText={'Valor a pagar ou valor base das parcelas'}
                            />
                        </Grid>
                    </Grid>
                </Form>
                </>
            )}
        </SimpleContainer>
    );
}