import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigContext, useConfigContext } from './Hooks/useConfigContext';
import { CustomProvider } from 'rsuite';
import ptBR from 'rsuite/locales/pt_BR';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LhinceRoutes } from './Routes';

const queryClient = new QueryClient()

const App = () => 
{
  const value = useConfigContext()

  return <ConfigContext.Provider value={value}>
          <QueryClientProvider client={queryClient}>
            <CustomProvider locale={ptBR}>
              <BrowserRouter>
                <Routes>
                  <Route path='/*' element={<LhinceRoutes />} />
                </Routes>
              </BrowserRouter>
            </CustomProvider>
          </QueryClientProvider>
        </ConfigContext.Provider>
};

export default App;
