import * as Styled from './styles';

type IsActiveStatusProps = {
  isActive: boolean;
};

export const IsActiveStatus = ({ isActive }: IsActiveStatusProps) => {
  const backgroundColor = isActive ? '#2E7D32' : '#EF6C00';

  return (
    <Styled.Container backgroundColor={backgroundColor}>
      {isActive ? <span>Ativo</span> : <span>Desativado</span>}
    </Styled.Container>
  );
};
