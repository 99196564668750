import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import styled from 'styled-components';

export const StyledInfiniteList = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px #00000033;
  box-shadow: 0px 1px 1px 0px #00000024;
  box-shadow: 0px 1px 3px 0px #0000001f;
  border-radius: 4px;
  padding: 12px;
`;

export const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'Roboto', sans-serif;
  padding: 16px;
`;

export const StyledTh = styled(Th)`
  padding: 10px;
  border-top: none;
  border-bottom: 1px solid #ddd;
  color: #333;
  margin-top: 1em auto;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
`;

export const StyledTd = styled(Td)`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  color: #555;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-align: left;

  &:nth-child(3) {
    cursor: pointer;
  }
  &:nth-child(4) {
    cursor: pointer;
  }
`;

export const StyledActionTd = styled(Td)`
  padding: 20px;
  border-bottom: 1px solid #ddd;
  color: #555;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  gap: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const StyledActionTh = styled(Td)`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  color: #555;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  gap: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const StyledThead = styled(Thead)``;

export const StyledTbody = styled(Tbody)``;

export const StyledTr = styled(Tr)`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  gap: 18px;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 10;
  }
`;

export const Filters = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
  }
`;

export const ImageNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;
