
import L from 'leaflet';
import icon from '../../../../Assets/marker.png';

const customIcon = new L.Icon({
  iconUrl: icon,
  iconSize: [32, 32], // tamanho do ícone
  iconAnchor: [16, 32], // ponto de ancoragem para posicionar o ícone
  popupAnchor: [0, -32], // ponto onde o popup deve ser ancorado em relação ao ícone
});

export default customIcon;