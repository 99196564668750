import * as React from 'react';
import { View } from 'src/Components/Admin/View';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import {
  DeleteAction,
  EditAction,
  ListAction
} from 'src/Components/Admin/Actions';
import { api } from 'src/Services/Financial';
import useGetOne from 'src/Hooks/useGetOne';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Upload } from 'src/Components/Admin/Upload';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { CurrencyField } from 'src/Components/Admin/Fields/CurrencyField';
import { DecimalField } from 'src/Components/Admin/Fields/DecimalField';
import DownloadAsPdfButton from 'src/Components/Buttons/DownloadAsPdfButton';
import ConfirmButton from 'src/Components/Financial/Expenses/Buttons/Confirm';
import i18n from 'src/i18n';
import i18next from 'i18next';
import { UserHave } from 'src/Components/Auth/UserHave';
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from 'src/Enums/Roles';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const ExpenseShow = () => {
  const resource = 'expenses';
  const endpoint = 'expenses';
  const { id } = useParams();
  const { control } = useForm();
  const { data, setData } = useGetOne(api, '/' + endpoint, id);
  const [tab, setTab] = React.useState(0);

  return (
    <SimpleContainer title={`resources.${resource}.name_one`} mdSize={900}>
      <Tabs
        value={tab}
        onChange={(event, value) => setTab(value)}
        aria-label="basic tabs example"
      >
        <Tab
          label={i18next.t(`resources.expenses.tabs.data`)}
          {...a11yProps(0)}
        />
        <Tab
          label={i18next.t(`resources.expenses.fields.proof`)}
          {...a11yProps(1)}
        />
      </Tabs>

      {data && (
        <>
          <CustomTabPanel value={tab} index={0}>
            {data && (
              <View
                baseUrl={`/financial/expenses`}
                backendUrl={'/expenses'}
                actions={[
                  <ListAction key={0} />,
                  <EditAction key={1} />,
                  data.status !== 'DONE' && (
                    <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
                      <Link to={`/financial/expenses/${id}/confirm`}>
                        <ThumbUpIcon/>
                      </Link>
                    </UserHave>
                  ),
                  data.status !== 'DONE' && (
                    <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
                      <DeleteAction
                        key={2}
                        api={api}
                        successMessage={`resources.${resource}.actions.delete.success`}
                      />
                    </UserHave>
                  ),
                  <DownloadAsPdfButton
                    key={3}
                    api={api}
                    endpoint={`expenses/${id}/view`}
                    destFile="comprovante_despesa.pdf"
                    successMessage={`resources.${resource}.actions.download.success`}
                    errorMessage={`resources.${resource}.actions.download.error`}
                  />
                ]}
              >
                <SimpleShowLayout>
                  <TextField
                    value={data.type.name}
                    label={`resources.${resource}.fields.typeId`}
                  />
                  <TextField
                    value={data.person?.fullName}
                    label={`resources.${resource}.fields.personId`}
                  />
                  <TextField
                    value={data.description}
                    label={`resources.${resource}.fields.description`}
                  />
                  <DateField
                    value={data.scheduled_date}
                    label={`resources.${resource}.fields.scheduled_date`}
                  />
                  <DecimalField
                    value={data.quantity}
                    label={`resources.${resource}.fields.quantity`}
                  />
                  <CurrencyField
                    value={data.amount}
                    label={`resources.${resource}.fields.amount`}
                  />
                  <TextField
                    value={data.category?.name}
                    label={`resources.${resource}.fields.categoryId`}
                  />
                  <TextField
                    value={data.costitem?.costcenter?.name}
                    label={`resources.costcenters.name_one`}
                  />
                  <TextField
                    value={data.costitem?.name}
                    label={`resources.${resource}.fields.costitemId`}
                  />
                  <TextField
                    value={data.aditionalInfo}
                    label={`resources.${resource}.fields.aditionalInfo`}
                  />
                  <TextField
                    value={data.state?.name}
                    label={`resources.${resource}.fields.stateId`}
                  />
                  <TextField
                    value={data.city?.name}
                    label={`resources.${resource}.fields.cityId`}
                  />
                  <TextField
                    value={data.electoralZone?.identifier}
                    label={`resources.${resource}.fields.electoralZoneId`}
                  />
                  <TextField
                    value={i18n.t(`resources.expenses.statuses.${data.status}`)}
                    label={`resources.${resource}.fields.status`}
                  />
                  {data.status == 'DONE' && (
                    <DateField
                      value={data.confirmed_at}
                      showTime
                      label={`resources.${resource}.fields.confirmed_at`}
                    />
                  )}
                  <DateField
                    value={data?.created_at}
                    showTime
                    label={`resources.${resource}.fields.created_at`}
                  />
                </SimpleShowLayout>
              </View>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={tab} index={1}>
            {data && (
              <Upload
                api={api}
                control={control}
                name="file"
                picture={data.picture}
                src={`/expenses/${data.id}/upload`}
                uploadSuccessMessage={`resources.${resource}.actions.upload.success`}
                uploadRemoveMessage={`resources.${resource}.actions.deleteUpload.success`}
                destFileName={data.image?.filename}
              />
            )}
          </CustomTabPanel>
        </>
      )}
    </SimpleContainer>
  );
};
