import buildRestProvider from 'ra-data-simple-rest';
import httpClient from './Response/FinancialHttpClientResponse'

const apiUrl = String(process.env.REACT_APP_API_URL)

export const pdfClient = (path) => {
    const token = localStorage.getItem('token')
    return fetch(`${apiUrl}/${path}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
            'Authorization': `Bearer ${token}`
        },
        }).then((response) => response.blob());
}

export default buildRestProvider(String(process.env.REACT_APP_API_URL), httpClient)