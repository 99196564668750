import { Route, Routes } from "react-router-dom"
import { GuardedComponent } from "src/Components/Financial/Guarded"
import { PermittedRoute } from "src/Components/Auth/PermittedRoute"
import { FinancialResume } from "src/Pages/Financial/Reports/Resume"
import { MovementList } from "src/Pages/Financial/Reports/Movement"
import { CostByCityReport } from "src/Pages/Financial/Reports/Cost-by-city"
import { ChartOfAccountsReport } from "src/Pages/Financial/Reports/Chart-of-accounts"
import { StockMovementReport } from "src/Pages/Stock/Reports/Movement"
import { ReportByPeriodPage } from "src/Pages/Election/Reports/ReportByPeriod"
import { FinancialResumeAccount } from "src/Pages/Financial/Reports/ResumeAccount"
import { ValidateRoute } from "src/Components/Auth/ValidateRoute"
import { ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles"

export const ReportRoutes = () => 
{    
    return (
        <Routes>   
            <Route path="/financial-resume" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <FinancialResume />)} 
            />
            <Route path="/financial-resume-account" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER], <FinancialResumeAccount />)}
            />
            <Route 
                path="/financial-movements" 
                element={PermittedRoute('reports', GuardedComponent(<MovementList />))} 
            />
            <Route 
                path="/cost-by-city" 
                element={PermittedRoute('reports', GuardedComponent(<CostByCityReport />))} 
            />
            <Route 
                path="/chart-of-accounts" 
                element={PermittedRoute('reports', GuardedComponent(<ChartOfAccountsReport />))} 
            />
            <Route 
                path="/stock-movements" 
                element={PermittedRoute('stock', GuardedComponent(<StockMovementReport />))} 
            />
            <Route 
                path="/voters-by-period" 
                element={PermittedRoute('reports', <ReportByPeriodPage />)} 
            />

            <Route path="/*" element={GuardedComponent(<FinancialResume />)} />
        </Routes>
    );
}