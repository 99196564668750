import { Route, Routes } from "react-router-dom";
import { ExpenseCreate, ExpenseEdit, ExpenseList, ExpenseShow } from "src/Pages/Financial/Expenses";
import { ExpenseConfirm } from "src/Pages/Financial/Expenses/ExpenseConfirm";

export const ExpenseRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<ExpenseList />} />
          <Route path="/create" element={<ExpenseCreate />} />
          <Route path=":id/confirm" element={<ExpenseConfirm />} />
          <Route path=":id" element={<ExpenseEdit />} />
          <Route path=":id/show" element={<ExpenseShow />} />
        </Routes>
    )
  }