import { Route, Routes } from "react-router-dom";
import { CampaignCreate, CampaignEdit, CampaignList, CampaignShow } from "src/Pages/Financial/Campaigns";

export const CampaignRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<CampaignList />} />
          <Route path="/create" element={<CampaignCreate />} />
          <Route path=":id" element={<CampaignEdit />} />
          <Route path=":id/show" element={<CampaignShow />} />
        </Routes>
    )
  }