import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import i18next from 'i18next';

export const CustomInput = ({
  name,
  control,
  label,
  defaultValue,
  onChange,
  type = 'text',
  ...rest
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type={type}
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          fullWidth
          label={i18next.t(label)}
          value={field.value}
          onChange={(event) => {
            field.onChange(event.target.value)
            if (onChange) {
              onChange(event)
            }
          }}
          variant='outlined'
          {...rest}
        />
      )}
    />
  );
};
