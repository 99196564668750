import React, { useState } from 'react';
import { MyLoginPage } from '../LoginPage';
import SendEmailPage from '../SendEmailPage';
import ConfirmCodePage from '../ConfirmCodePage';
import ResetPasswordPage from '../ResetPasswordPage';

// import { Container } from './styles';

const AuthPage: React.FC = () => {
  const [page, setPage] = useState('LOGIN');
  const changePage = page => {
    setPage(page);
  };
  const template = page => {
    switch (page) {
      case 'LOGIN':
        return <MyLoginPage changePage={changePage} />;
      case 'SEND_EMAIL':
        return <SendEmailPage changePage={changePage} />;
      case 'CONFIRM_CODE':
        return <ConfirmCodePage changePage={changePage} />;
      case 'RESET_PASSWORD':
        return <ResetPasswordPage changePage={changePage} />;
    }
  };
  return <>{template(page)}</>;
};

export default AuthPage;
