import React, { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapIntentionProps } from '../MapIntentions';
import customIcon from './icon/icon';

const Map = ({data} : MapIntentionProps) => {
  useEffect(() => {
    // Crie o mapa
    const map = L.map('map').setView([0, 0], 2); // Defina a visão inicial para um local padrão
    
    // Verifique se há coordenadas para calcular a média
    if (data.length > 0) {
        // Calcule a média das coordenadas
        const avgLat = data.reduce((sum, item) => sum + Number(item.latitude), 0) / data.length;
        const avgLng = data.reduce((sum, item) => sum + Number(item.longitude), 0) / data.length;
  
        // Atualize a visualização do mapa para a média das coordenadas
        map.setView([avgLat, avgLng], 20); // Ajuste o nível de zoom conforme necessário
      }
      
    // Mapeie e adicione marcadores para cada item na lista
    data.forEach(item => {
      const { latitude, longitude, voter, user, alternativeIntention, candidate, electoralParty } = item;
      const popupContent = `
        <b>Eleitor:</b> ${voter.name}<br />
        <b>Usuário responsável:</b> ${user.name}<br />
        ${!alternativeIntention ? '' : `<b>Intenção de voto alternativa:</b> ${alternativeIntention.name}<br />` }
        ${!candidate ? '' : `<b>Candidato:</b> ${candidate.name} <b>Partido:</b> ${electoralParty.resumeName}`}
      `;

      L.marker([Number(latitude), Number(longitude)], { icon: customIcon })
        .addTo(map)
        .bindPopup(popupContent);
    });

    // Adicione uma camada de mapa (por exemplo, OpenStreetMap)
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors',
    }).addTo(map);

    // unmount map function
    return () => map.remove();

  }, [data]);

  return <>
        <div id="map" style={{ height: '520px', width: '100%' }} />
  </>;
};

export default Map;