import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { DeleteAction } from 'src/Components/Admin/Actions/DeleteAction';
import { EditAction } from 'src/Components/Admin/Actions/EditAction';
import { ListAction } from 'src/Components/Admin/Actions/ListAction';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import { View } from 'src/Components/Admin/View';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { Menu } from 'src/Components/Election/Menu';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetOne from 'src/Hooks/useGetOne';
import { api } from 'src/Services/Elections';
import Grid from '@mui/material/Grid';
import ProfileImage from 'src/Components/Election/ProfileImagemUrl';
import * as Styled from './styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import i18next from 'i18next';

export const CandidateShow = () => {
  const resource = 'candidates';
  const { id } = useParams();
  const [tab, setTab] = React.useState(5);
  const { data, setData } = useGetOne(api, '/candidates', id)

  const open = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/jpeg, image/png'; 
    fileInput.addEventListener('change', handleFileSelect);
    fileInput.click();
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
  
    if (!file) {
      alert('Por favor, selecione um arquivo.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    api.put(`candidates/${id}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
      .then(
          result => {
              if (result && result.status == 200) 
              {
                setData((data) => ({
                  ...data,
                  ...{profileImageUrl: result.data.profileImageUrl}
                }))
              }
          }
      )

  }
  
  const GridRow = (props: { children: React.ReactNode }) => (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
      {props.children}
    </Grid>
  );

  return (
    <SimpleContainer title={`resources.${resource}.name_one`} mdSize={1000}>
      <Menu tab={tab} setTab={setTab} />
      <View
        baseUrl='/election/candidates'
        backendUrl='/candidates'
        actions={[
          <ListAction key={0} />,
          <EditAction key={1} />,
          <DeleteAction
            key={2}
            api={api}
            successMessage={`resources.${resource}.actions.delete.success`}
          />,
          <Link key={3} onClick={open} to='#' title={i18next.t(`resources.${resource}.actions.upload.title`)}>
            <AddAPhotoIcon/>
          </Link>,
        ]}
      >
        <GridRow>
          <Grid item xs={12} md={12}>
            <Styled.Profile>
              <Styled.ProfileImage>
                {data &&
                  <ProfileImage
                    alt={data?.name}
                    imageUrl={data?.profileImageUrl ? api.getUrl(data?.profileImageUrl) : null}
                    size={120}
                    onClick={open}
                  />
                }
              </Styled.ProfileImage>
              <Styled.ProfileData>
                <Styled.ProfileInfo style={{ fontSize: 24, lineHeight: 1 }}>{data?.name.toUpperCase()}</Styled.ProfileInfo>
                <Styled.ProfileInfo>{data?.candidatePosition.name.toUpperCase()}</Styled.ProfileInfo>
                <Styled.ProfileInfo>CPF - {data?.cpf}</Styled.ProfileInfo>
                <Styled.ProfileInfo>{data?.electoralParty?.resumeName + ' - ' + data?.electoralParty?.name}</Styled.ProfileInfo>
              </Styled.ProfileData>
            </Styled.Profile>
          </Grid>

          <Grid item xs={12} md={12}>
            <SimpleShowLayout>
              <TextField
                value={data?.gender?.name}
                label={`resources.${resource}.fields.gender`}
              />
              <TextField
                value={data?.telphone}
                label={`resources.${resource}.fields.telphone`}
              />

              <TextField
                value={data?.city?.name}
                label={`resources.${resource}.fields.city`}
              />
              <TextField
                value={data?.zone?.name}
                label={`resources.${resource}.fields.zone`}
              />
              <TextField
                value={data?.email}
                label={`resources.${resource}.fields.email`}
              />
              <DateField
                showTime
                value={data?.created_at}
                label={`resources.${resource}.fields.created_at`}
              />
            </SimpleShowLayout>
          </Grid>
        </GridRow>
      </View>
    </SimpleContainer>
  );
};
