import * as React from 'react';
import { InboundForm } from './InboundForm';
import { api } from 'src/Services/Financial';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const InboundEdit = () => {
  const { id } = useParams();
  const { data } = useGetOne(api, '/material-inbounds', id);
  const navigate = useNavigate();

  const onSubmit = formData => {
    api.put(`/material-inbounds/${id}`, formData.data).then(result => {
      if (result?.status == 200) {
        navigate(`/stock/inbounds/${result.data.id}/show`);
        showSuccessMessage(`resources.materialInbounds.actions.edit.success`);
      }
    });
  };

  return <>{data && <InboundForm data={data} onSubmit={onSubmit} />}</>;
};
