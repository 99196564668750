import { Route, Routes } from "react-router-dom";
import { UserCreate, UserEdit, UserList, UserShow } from "src/Pages/Election/Users";
import { UserCampaigns } from "src/Pages/Election/Users/Campaigns";

export const UserRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<UserList />} />
          <Route path="/create" element={<UserCreate />} />
          <Route path=":id" element={<UserEdit />} />
          <Route path=":id/show" element={<UserShow />} />
          <Route path=":id/show/campaigns" element={<UserCampaigns />} />
        </Routes>
    )
  }