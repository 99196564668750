import * as Styled from './styles';
import PersonIcon from '@mui/icons-material/Person';

type ProfileImageProps = {
  alt: string;
  imageUrl: string;
  size?: number;
  [key: string]: any;
};

const ProfileImage = ({ alt, imageUrl, size, ...rest }: ProfileImageProps) => {
  return (
    <Styled.ProfileContainer {...rest}>
      {imageUrl 
        ? <Styled.Image src={imageUrl} alt={alt} style={{ width: size ?? 40, height: size ?? 40 }} />
        : <PersonIcon style={{ width: size ?? 40, height: size ?? 40 }} />
      }
    </Styled.ProfileContainer>
  );
};

export default ProfileImage;
