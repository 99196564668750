import React from 'react';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import Typography from '@mui/material/Typography';
import * as Styled from './styles';
import { Person } from '@mui/icons-material';
import { Link } from 'react-router-dom';

interface AccountUsersProps {
  accountId: number;
}

export function AccountUsers({ accountId }: AccountUsersProps) 
{
  const { data: users, setData: setUsers, fetchData: fetchUsers } = useInfiniteFetch(api, `/accounts/${accountId}/users`, 0);

  return (
    <Styled.UsersBox>
      {!users &&
        <Typography>
          Não há usuários associados a esta conta
        </Typography>
      }
      {users && users.map((userAccount, index) => (
        <Styled.UserBox key={index}>
          <Person/>
          <Styled.UserName>
             {userAccount.user.name} ({userAccount.user.role.name})
          </Styled.UserName>
        </Styled.UserBox>  
      ))}
    </Styled.UsersBox>      
  );
}