import { useEffect, useState } from 'react';

const useGetTree = (api, endpoint, filters = {}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ data, setData ] = useState<any | null>(null);

    useEffect(() => {

        function mountTree(entity) 
        {    
            const item = {
                label: entity.name,
                value: entity.id,
                children: null
            }

            if (entity.children && entity.children.length) {
                item.children = entity.children.map(child => mountTree(child))
            }

            return item
        }

        async function fetchMany() {
            const filtersExpr = JSON.stringify(filters);
            api.get(`${endpoint}?filter=${filtersExpr}`)
                .then(response => {
                    setLoading(false)

                    if (response.status == 200){
                        const tree = response.data.map(item => mountTree(item))
                        setData(tree)
                    }
                    else throw new Error('Erro ao montar arvore');
                })
                .catch(error => {
                    setError(error)
                    setLoading(false)
                }
            )
        }
        
        fetchMany()
    }, [endpoint, JSON.stringify(filters)]);
    
    return { data, setData, loading, error }
}

export default useGetTree