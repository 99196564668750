import styled from 'styled-components';

interface CardContainerProps {
  color: string;
}

export const Title = styled.p`
  font-size: 14px;
  font-weight: 400;
  padding: 0.5em;
  color: #fff;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: ${props => props.color};
  border-radius: 8px;
  margin-top: 1em;

  @media (max-width: 767px) {
    min-width: 95%;
    margin-top: 10px;
  }
`;
export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: row;
  width: 50px;
  color: white;
`;

export const Values = styled.h2`
  font-size: 25px;
  padding: 0.5em 0 0 0.5em;
`;
export const Percentage = styled.span`
  font-size: 15px;
  padding: 0.5em;
  margin-top: 15px;
`;
export const Image = styled.img`
  width: 100%;
  height: auto;
`;
