import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import { UserHave } from 'src/Components/Auth/UserHave';
import { Menu } from 'src/Components/Election/Menu';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { ROLE_ADMIN, ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from 'src/Enums/Roles';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { api } from 'src/Services/Elections';

interface BaseFormProps {
  data: any;
  onSubmit: (formData: FormData) => void;
}

export const BaseForm = ({ data, onSubmit }: BaseFormProps) => {
  const resource = 'bases';
  const { control } = useForm();
  const [tab, setTab] = React.useState(2);
  const { options: campaigns } = useGetManyOptions(api, '/campaigns')
  const { options: zones } = useGetManyOptions(api, '/zones');
  const { options: cities } = useGetManyOptions(api, '/city');
  const { options: users } = useGetManyOptions(api, '/users', { roleName: ROLE_BASE_MANAGER }, 'id', 'name',
    (result) => {
      if (result == null) return null
      return result.filter(user => user.baseId == null || user.id == data.supervisorTeam?.id)
    }
  );
  const { options: campaignManagers } = useGetManyOptions(api, '/users', { roleName: ROLE_CAMPAIGN_MANAGER });

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={1000}>
      <Menu tab={tab} setTab={setTab} />
      {data && (
        <Form method='post' control={control} onSubmit={onSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={12}>
              {campaigns &&
                <ReferenceInput
                    name={"campaignId"}
                    control={control}
                    label={`resources.accounts.fields.campaignId`}
                    options={campaigns}
                    defaultValue={data?.campaignId}
                />
              }
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomInput
                name={'name'}
                control={control}
                label={`resources.${resource}.fields.name`}
                defaultValue={data?.name}
              />
            </Grid>

            <UserHave role={[ROLE_ADMIN, ROLE_CANDIDATE]}>
              <Grid item xs={12} md={12}>
                {campaignManagers && (
                  <ReferenceInput
                    name={'userCampaignId'}
                    control={control}
                    label={`resources.${resource}.fields.userCampaignId`}
                    defaultValue={data?.userCampaignManager?.id}
                    options={campaignManagers}
                  />
                )}
              </Grid>
            </UserHave>

            <Grid item xs={12} md={12}>
              {users && (
                <ReferenceInput
                  name={'supervisorTeamId'}
                  control={control}
                  label={`resources.${resource}.fields.supervisorTeamId`}
                  defaultValue={data?.supervisorTeam?.id}
                  options={users}
                />
              )}
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomInput
                name={'postalCode'}
                control={control}
                label={`resources.${resource}.fields.postalCode`}
                defaultValue={data?.postalCode}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomInput
                name={'number'}
                control={control}
                label={`resources.${resource}.fields.number`}
                defaultValue={data?.number}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {zones && (
                <ReferenceInput
                  name={'zoneId'}
                  control={control}
                  label={`resources.${resource}.fields.zoneId`}
                  defaultValue={data?.zone?.id}
                  options={zones}
                />
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <CustomInput
                name={'publicPlace'}
                control={control}
                label={`resources.${resource}.fields.publicPlace`}
                defaultValue={data?.publicPlace}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <CustomInput
                name={'neighborhood'}
                control={control}
                label={`resources.${resource}.fields.neighborhood`}
                defaultValue={data?.neighborhood}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              {cities && (
                <ReferenceInput
                  name={'cityId'}
                  control={control}
                  label={`resources.${resource}.fields.cityId`}
                  defaultValue={data?.city?.id}
                  options={cities}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </SimpleContainer>
  );
};
