import i18next from 'i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { Menu } from 'src/Components/Election/Menu';
import ProfileImage from 'src/Components/Election/ProfileImagemUrl';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';

export const CandidateList = () => {
  const endpoint = '/candidates';
  const resource = 'candidates';
  const [tab, setTab] = React.useState(5);
  const { control } = useForm();
  const { data, setData, fetchData, hasMore, filters, setFilters } =
    useInfiniteFetch(api, endpoint, 25);

  const { options: cities } = useGetManyOptions(api, '/city');
  const { options: positions } = useGetManyOptions(api, '/candidate-position');

  const handleDelete = async (id: number) => {
    try {
      api.delete(endpoint + '/' + id).then(response => {
        if (response && response.status == 200) {
          setData(data.filter(item => item.id !== id));
          showSuccessMessage(`resources.${resource}.actions.delete.success`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name`}>
      <Menu tab={tab} setTab={setTab} />
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton />}
        filters={
          <>
            <SearchInput
              name="search"
              label={i18next.t(`resources.${resource}.filter.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ minWidth: 300 }}
            />
            {cities && (
              <ReferenceInput
                name={'cityId'}
                control={control}
                label={`resources.${resource}.fields.city`}
                defaultValue={filters['cityId'] ?? ''}
                options={cities}
                style={{ minWidth: 250 }}
                onChange={value => {
                  const newFilters = {
                    ...filters,
                    ...{ cityId: value }
                  };
                  setFilters(newFilters);
                }}
              />
            )}
            {positions && (
              <ReferenceInput
                name={'candidatePositionId'}
                control={control}
                label={`resources.${resource}.fields.candidatePositionId`}
                defaultValue={filters['candidatePositionId'] ?? ''}
                options={positions}
                style={{ minWidth: 250 }}
                onChange={value => {
                  const newFilters = {
                    ...filters,
                    ...{ candidatePositionId: value }
                  };
                  setFilters(newFilters);
                }}
              />
            )}
          </>
        }
        columns={[
          {
            header: `resources.${resource}.fields.name`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <InfiniteListStyles.ImageNameContainer>
                  {item.profileImageUrl && (
                    <ProfileImage
                      alt={item.name}
                      imageUrl={api.getUrl(item.profileImageUrl)}
                    />
                  )}
                  {item.name}
                </InfiniteListStyles.ImageNameContainer>
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.email`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.email}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.gender`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.gender.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.zone`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.zone.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.city`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.city.name}
              </InfiniteListStyles.StyledTd>
            )
          },

          {
            header: `resources.${resource}.fields.candidatePositionId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.candidatePosition?.name}
              </InfiniteListStyles.StyledTd>
            )
          },

          {
            header: `resources.${resource}.fields.electoralPartyId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.electoralParty?.resumeName}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`/election/candidates/${item.id}/show`} />
                <EditButton url={`/election/candidates/${item.id}`} />
                <DeleteButton handleDelete={() => handleDelete(item.id)} />
              </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
};
