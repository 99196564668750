import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial'
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { showSuccessMessage } from 'src/Hooks/useMessages'
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { AddButton, ViewButton } from 'src/Components/Buttons';
import { OptionsField } from 'src/Components/Admin/Fields/OptionsField';
import { paymentTypes } from 'src/Enums/HiringPersonPaymentTypes';
import { DecimalField } from 'src/Components/Admin/Fields/DecimalField';
import { HiringPersonStatuses, HiringPersonStatusOptions } from 'src/Enums/HiringPersonStatuses';
import DoneIcon from '@mui/icons-material/Done';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

interface HiringPersonListProps {
  hiringId: string,
  isPending: boolean
}

export const HiringPersonList = ({ hiringId, isPending }: HiringPersonListProps) => 
{
  const endpoint = `/hirings/${hiringId}/persons`
  const resource = 'hiringPersons'
  const baseLink = `/hr/hirings/${hiringId}/show/persons`
  const {data, setData, fetchData, hasMore } = useInfiniteFetch(api, endpoint, 25)

  const handleDelete = async (id: number) => {
    try {
        api.delete(endpoint + '/' + id)
          .then(response => {
            if(response && (response.status == 200)) {
              setData(data.filter((item) => item.id !== id));
              showSuccessMessage(`resources.${resource}.actions.delete.success`);
            }
          })
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData} 
      hasMore={hasMore}
      actions={
        isPending && <AddButton link={`${baseLink}/create`}/>
      }
      columns={
        [
          {
            header: `resources.${resource}.fields.personId`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                {item?.person.fullName}
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.positionId`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                {item?.position.name}
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.payment_type`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                <OptionsField value={item.payment_type} options={paymentTypes} />
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.base_amount`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                <DecimalField value={item.base_amount} hasSymbol={true} />
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.installments`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                {item.installments}
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.status`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                <OptionsField value={item.status} options={HiringPersonStatusOptions} />
              </InfiniteListStyles.StyledTd>
          },
          {
            type: 'action',
            value: (item, index) => (
                <InfiniteListStyles.StyledActionTd key={index}>
                  <ViewButton url={`${baseLink}/${item.id}/show`}/>
                  {isPending && (
                    <>
                      <EditButton url={`${baseLink}/${item.id}`}/>
                      <DeleteButton 
                        handleDelete={() => handleDelete(item.id)}
                      />
                      {(item?.status == HiringPersonStatuses.PENDING) &&
                        <Link 
                          to={`persons/${item.id}/complete`} 
                          title={i18next.t(`resources.${resource}.actions.complete`)} 
                        >
                          <DoneIcon />
                        </Link>
                      }
                    </>
                  )}
                </InfiniteListStyles.StyledActionTd>
            )
          }
        ]
      }
    />
  );
}