import { Route, Routes } from "react-router-dom";
import { PlaceCreate, PlaceEdit, PlaceList, PlaceShow } from "src/Pages/Stock/Places";

export const PlaceRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<PlaceList />} />
          <Route path="/create" element={<PlaceCreate />} />
          <Route path=":id" element={<PlaceEdit />} />
          <Route path=":id/show" element={<PlaceShow />} />
        </Routes>
    )
  }