import * as React from 'react';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { Controller, useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import useGetMany from 'src/Hooks/useGetMany';
import i18next from 'i18next';
import banks from 'src/Enums/Banks';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import useGetTree from 'src/Hooks/useGetTree';
import { Cascader } from 'rsuite';

interface DonationFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const DonationForm = ({ data, onSubmit }: DonationFormProps) => {
    const resource = 'donations'
    const { config } = React.useContext(ConfigContext);
    const { control } = useForm()
    const { options: persons } = useGetManyOptions(api, '/persons', {}, 'id', 'fullName')
    const { data: categories } = useGetTree(api, '/categories/tree/donations')
    const { data: types } = useGetMany(api, '/donations/types')
    const { data: natures } = useGetMany(api, '/donations/natures')

    const onSubmitFn = (formData) => 
    {
        onSubmit(formData)
    }

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmitFn}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {types &&
                                <ReferenceInput
                                    name={"type"}
                                    control={control}
                                    label={`resources.${resource}.fields.type`}
                                    defaultValue={data?.type}
                                    options={types.map(
                                        item => (
                                            { 
                                                value: item, 
                                                label: i18next.t(`const.donationTypes.${item}`)
                                            }
                                        )
                                    )}
                                />
                            }
                            {persons &&
                                <ReferenceInput
                                    name={"personId"}
                                    control={control}
                                    label={`resources.${resource}.fields.personId`}
                                    defaultValue={data?.personId}
                                    options={persons}
                                />
                            }
                            <CustomInput
                                name={"description"}
                                control={control}
                                label={`resources.${resource}.fields.description`}
                                defaultValue={data?.description}
                            />
                        </Grid>
                    </Grid>
            
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={6} md={3}>
                            <DateInput
                                name="donation_date" 
                                control={control}
                                label={`resources.${resource}.fields.donation_date`}
                                defaultValue={data?.donation_date}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DecimalInput
                                name={"amount"}
                                control={control}
                                label={`resources.${resource}.fields.amount`}
                                defaultValue={data?.amount}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {natures &&
                                <ReferenceInput
                                    name={"nature"}
                                    control={control}
                                    label={`resources.${resource}.fields.nature`}
                                    defaultValue={data?.nature}
                                    options={natures.map(
                                        item => (
                                            { 
                                                value: item, 
                                                label: i18next.t(`const.donationNatures.${item}`)
                                            }
                                        )
                                    )}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={6}>
                            <CustomInput
                                name={"receipt_number"}
                                control={control}
                                label={`resources.${resource}.fields.receipt_number`}
                                defaultValue={data?.receipt_number}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomInput
                                name={"document_number"}
                                control={control}
                                label={`resources.${resource}.fields.document_number`}
                                defaultValue={data?.document_number}
                            />
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {banks &&
                                <ReferenceInput
                                    name={"bank"}
                                    control={control}
                                    label={`resources.${resource}.fields.bank`}
                                    defaultValue={data?.bank}
                                    options={banks}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={3}>
                            <CustomInput
                                name={"agency"}
                                control={control}
                                label={`resources.${resource}.fields.agency`}
                                defaultValue={data?.agency}
                            /> 
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <CustomInput
                                name={"agency_digit"}
                                control={control}
                                label={`resources.${resource}.fields.agency_digit`}
                                defaultValue={data?.agency_digit}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <CustomInput
                                name={"bank_account"}
                                control={control}
                                label={`resources.${resource}.fields.bank_account`}
                                defaultValue={data?.bank_account}
                            /> 
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <CustomInput
                                name={"bank_account_digit"}
                                control={control}
                                label={`resources.${resource}.fields.bank_account_digit`}
                                defaultValue={data?.bank_account_digit}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {categories &&
                                <Controller
                                    name={"categoryId"}
                                    control={control}
                                    defaultValue={data.categoryId}
                                    render={({ field }) => (
                                        <Cascader
                                            block={true}
                                            menuWidth={250}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t(`resources.${resource}.fields.categoryId`)}
                                            data={categories.filter(item => item.sequence != '1')} 
                                            onChange={field.onChange}
                                            value={field.value}
                                            defaultValue={data.categoryId}
                                        />
                                    )}
                                />
                            }
                        </Grid>
                    </Grid>
                    
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"aditionalInfo"}
                                control={control}
                                label={`resources.${resource}.fields.aditionalInfo`}
                                defaultValue={data?.aditionalInfo}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </SimpleContainer>
    );
}