import React from 'react';
import provider from 'src/Providers/Financial/ReportsDataProvider'
import moment from 'moment';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { DateInput } from 'src/Components/Inputs/DateInput';
import i18next from 'i18next';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import useLoader from 'src/Hooks/useLoader';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { api } from 'src/Services/Elections';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { Grid } from '@mui/material';
import { useGetIdentity } from 'react-admin';
import { ROLE_BASE_MANAGER } from 'src/Enums/Roles';

export const CostByCityReport = () => {
    const resource = 'costByCity'
    const { control, formState: { errors }  } = useForm();
    const { config } = React.useContext(ConfigContext);
    const [electionId, setElectionId] = React.useState(config.electionId);
    const [accountId, setAccountId] = React.useState(config.accountId);
    const [campaignId, setCampaignId] = React.useState(config.campaignId);
    const { options: states } = useGetManyOptions(api, '/state', {}, 'id', 'uf')
    const loader = useLoader()
    const { options: elections } = useGetManyOptions(api, '/elections');
    const { options: campaigns } = useGetManyOptions(api, '/campaigns', { electionId });
    const { options: accounts } = useGetManyOptions(api, '/accounts', { campaignId });
    const { data: identity } = useGetIdentity()

    const onSubmit = async(form) => {

        if (accountId)
        {
            const startDateAux = moment(form.data.dateStart)
            const endDateAux = moment(form.data.dateEnd)

            loader.showLoader()

            const response = await provider.exportCostByZone(
                form.data.accountId, 
                form.data.state, 
                startDateAux.toDate(), 
                endDateAux.toDate())

            loader.hideLoader()

            if (response.status != 201) {
                showSuccessMessage(`resources.reports.${resource}.messages.error`)
                return false
            }

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.setAttribute('target', '_blank');
            a.download = 'custo_por_zona.xls';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            showSuccessMessage(`resources.reports.${resource}.messages.success`)
        }
    }

    return (
        <SimpleContainer
          title={`resources.reports.${resource}.name`}
          mdSize={900}
        >
            <loader.Loader />
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
                submitButtonLabel='actions.export'
            >
                {elections && (
                    <ReferenceInput
                        name={'electionId'}
                        label={i18next.t(`resources.reports.${resource}.fields.electionId`)}
                        control={control}
                        defaultValue={electionId ?? ''}
                        options={elections}
                        rules={{ required: 'Pleito Obrigatório' }}
                        helperText={errors.electionId ? errors.electionId?.message : ''}
                        onChange={value => { 
                        setElectionId(value)
                        }}
                    />
                )}
                {electionId && campaigns && (
                    <ReferenceInput
                        name={'campaignId'}
                        label={i18next.t(`resources.reports.${resource}.fields.campaignId`)}
                        control={control}
                        defaultValue={campaignId ?? ''}
                        options={campaigns}
                        rules={{ required: 'Campanha Obrigatória' }}
                        helperText={errors.campaignId ? errors.campaignId?.message : ''}
                        onChange={value => { 
                        setCampaignId(value)
                        }}
                    />
                )}
                {campaignId && accounts && identity && (
                    <ReferenceInput
                        name={'accountId'}
                        label={i18next.t(`resources.reports.${resource}.fields.accountId`)}
                        control={control}
                        defaultValue={accountId ?? null}
                        options={accounts}
                        rules={ (identity.role.name == ROLE_BASE_MANAGER) ? { required: 'Conta Obrigatória' }: {}}
                        helperText={errors.accountId ? errors.accountId?.message : ''}
                        onChange={value => {
                        setAccountId(value);
                        }}
                    />
                )}
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item md={6} xs={12} sm={12}>
                        {states && (
                            <ReferenceInput 
                                name="state" 
                                control={control}
                                options={states}
                                label={`resources.reports.${resource}.fields.state`}
                                rules={{
                                    required: i18next.t(`resources.reports.${resource}.rules.state.required`)
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <DateInput 
                            control={control} 
                            name="dateStart" 
                            label={`resources.reports.${resource}.fields.dateStart`}
                            rules={{
                                required: i18next.t(`resources.reports.${resource}.rules.dateStart.required`)
                            }}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <DateInput 
                            control={control} 
                            name="dateEnd" 
                            label={`resources.reports.${resource}.fields.dateEnd`} 
                            rules={{
                                required: i18next.t(`resources.reports.${resource}.rules.dateEnd.required`)
                            }}
                        />
                    </Grid>
                </Grid>      
            </Form>
        </SimpleContainer>
    );
}