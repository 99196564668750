import { useContext } from "react";
import { ConfigContext } from "src/Hooks/useConfigContext";
import { ConfigurationsPage } from "src/Pages/Financial/Configurations"

export const GuardedComponent = (component) => 
{
    const { config } = useContext(ConfigContext)

    if (!config?.accountId) {
        return <ConfigurationsPage/>
    }
    return component
}