import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';
import { Menu } from 'src/Components/Election/Menu';
import { Link, useParams } from 'react-router-dom';
import { View } from 'src/Components/Admin/View';
import { ViewAction } from 'src/Components/Admin/Actions/ViewAction';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UserCampaignsForm from './form';
import Typography from '@mui/material/Typography';
import { PriceChange } from '@mui/icons-material';
import { UserCampaignAccounts } from './Accounts/index';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import useGetOne from 'src/Hooks/useGetOne';
import i18next from 'i18next';
import { UserHave } from 'src/Components/Auth/UserHave';
import { ROLE_CANDIDATE } from 'src/Enums/Roles';

export function UserCampaigns() {
  const { id } = useParams();
  const [isEditing, setIsEditing] = React.useState(false)
  const endpoint = `/users/${id}/campaigns`;
  const resource = 'users.campaigns';
  const [tab, setTab] = React.useState(0);
  const { data: user } = useGetOne(api, '/users', id);
  const { data: userCampaigns, setData: setUserCampaigns, fetchData: fetchUserCampaigns, hasMore } = useInfiniteFetch(api, endpoint, 0);
  const [accountBox, setAccountBox] = React.useState<number[]>([]);

  const handleDelete = async (id) => {
    try {
      api.delete(endpoint + '/' + id).then((response) => {
        if (response && response.status == 200) 
        {
          setUserCampaigns(userCampaigns.filter(userCampaign => userCampaign.id !== id));
          showSuccessMessage(`resources.${resource}.actions.delete.success`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name_other`} mdSize={1200}>
      <Menu tab={tab} setTab={setTab} />
      <View
        baseUrl='/election/users'
        backendUrl='/users'
        actions={[
          <ViewAction key={0} title='resources.users.actions.view.title' />,
          <UserHave 
            key={1} 
            role={[ROLE_CANDIDATE]}
          >
            <Link to='#' 
              onClick={() => setIsEditing(true)}  
              title={i18next.t('resources.users.actions.add-campaign.title')}
            >
              <AddCircleOutlineIcon />
            </Link>
          </UserHave>
          
        ]}
      >
        <SimpleShowLayout>
          <TextField
            value={user?.name}
            label={`resources.users.name_one`}
          />
          <TextField
            value={user?.email}
            label={`resources.users.fields.email`}
          />
          <TextField
            value={user?.role?.name}
            label={`resources.users.fields.role`}
          />
        </SimpleShowLayout>
        
        {isEditing ? (
          <UserCampaignsForm 
            userId={id}
            userCampaigns={userCampaigns}
            setUserCampaigns={setUserCampaigns}
            fetchUserCampaigns={fetchUserCampaigns}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <InfiniteList
            data={userCampaigns}
            loadMoreData={fetchUserCampaigns}
            hasMore={hasMore}
            columns={[
              {
                header: `resources.${resource}.grid.fields.electionId`,
                width: '30%'
              },
              {
                header: `resources.${resource}.grid.fields.name`,
                width: '50%'
              },
              {
                type: 'action'
              },
            ]}
            rows={{
              value: (item, index) => (
                <InfiniteListStyles.StyledTd key={index} colspan={3}>
                   <div style={{ width: '100%' }}> 
                        <div style={{ width: '30%', float: 'left' }}>
                          {item.campaign.election.name}
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                          {item.campaign.name}
                        </div>
                        <div style={{ float: 'right', display: 'flex', gap: 10 }}>
                          <Link
                            to={`#`}
                            title={'Contas'}
                            onClick={() => {
                              if (accountBox.indexOf(index as number) < 0) {
                                setAccountBox([...accountBox, index as number])
                              } else {
                                setAccountBox(accountBox.filter(item => item !== index))
                              }
                            }}
                          >
                            <PriceChange />
                          </Link>
                          <UserHave 
                            key={1} 
                            role={[ROLE_CANDIDATE]}
                          >
                            <DeleteButton handleDelete={() => handleDelete(item.id)} />
                          </UserHave>
                        </div>
                  </div>

                  {id && accountBox.indexOf(index as number) >= 0 && (
                    <UserCampaignAccounts 
                      userId={id}
                      campaignId={item.campaign.id}
                    />
                  )}
                
                </InfiniteListStyles.StyledTd>
              )
            }}
          />
        )}
      </View>
    </SimpleContainer>
  );
}
