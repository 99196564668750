import styled from "styled-components";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';

export const StyledView = styled(Box)`
    padding: 20px 20px 30px 20px;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px #00000033;
    box-shadow: 0px 1px 1px 0px #00000024;
    box-shadow: 0px 1px 3px 0px #0000001F;
`;

export const BackButton = styled(Button)`
    margin-top: 20px;
`;

export const StyledToolBar = styled(Stack)`
    padding: 16px;
`