import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Financial'
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const CostcenterEdit = () => {
    const resource = 'costcenters'
    const { control } = useForm();
    const redirect = useRedirect()
    const { id } = useParams()
    const { data } = useGetOne(api, '/' + resource, id);

    const onSubmit = (formData) => {
        api.put(`/costcenters/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    showSuccessMessage(`resources.${resource}.actions.edit.success`)
                    redirect(`/financial/costcenters/${result.data.id}/show`)
                }
            })
    }
    
    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={900}
        >
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
            >
                {data && (
                    <CustomInput
                        name={"name"}
                        control={control}
                        label={`resources.${resource}.fields.name`}
                        defaultValue={data.name}
                    />
                )}
            </Form>
        </SimpleContainer>
    );
}