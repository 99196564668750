import { Route, Routes } from "react-router-dom"
import { PermittedRoute } from "src/Components/Auth/PermittedRoute"
import { GuardedComponent } from "src/Components/Financial/Guarded";
import { PositionRoutes } from "./PositionRoutes";
import { HiringRoutes } from "./HiringRoutes";
import { HiringsReport } from "src/Pages/Hr/Reports/HiringsReport";
import { PaymentsReport } from "src/Pages/Hr/Reports/PaymentsReport";
import { HiringList } from "src/Pages/Hr/Hirings";
import { ValidateRoute } from "src/Components/Auth/ValidateRoute";
import { ROLE_ADMIN, ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles";

export const HRRoutes = () => 
{    
    return (
        <Routes> 
            <Route 
                path="/positions/*" 
                element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER], <PositionRoutes />)} 
            />
            <Route 
                path="/hirings/*" 
                element={GuardedComponent(<HiringRoutes />)} 
            />
            <Route path="/hirings-report" element={<HiringsReport />} />
            <Route path="/payments-report" element={<PaymentsReport />} />
            <Route path="/*" element={<HiringList />} />
        </Routes>
    );
}