import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';

import { useNavigate } from 'react-router-dom';
import { userCan, userIs } from 'src/Components/Layout/Can';
import {
  ROLE_ADMIN,
  ROLE_BASE_MANAGER,
  ROLE_CAMPAIGN_MANAGER,
  ROLE_CANDIDATE,
  ROLE_SUPERVISOR
} from 'src/Enums/Roles';
import i18next from 'i18next';
import { UserHave } from 'src/Components/Auth/UserHave';

interface MenuProps {
  tab: number;
  setTab: (tab: number) => void;
}

export const Menu = ({ tab, setTab }: MenuProps) => {
  const navigate = useNavigate();

  const tabStyle: React.CSSProperties = {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.5rem'
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <Tabs
      value={tab}
      onChange={(event, value) => {
        switch (value) {
          case 0:
            navigate('/election/users');
            break;
          case 1:
            navigate('/election/voters');
            break;
          case 2:
            navigate('/election/bases');
            break;
          case 3:
            navigate('/election/research');
            break;
          case 4:
            navigate('/election/parties');
            break;
          case 5:
            navigate('/election/candidates');
            break;
        }
      }}
      aria-label="basic tabs example"
    >
      {userIs([ROLE_CANDIDATE, ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_SUPERVISOR]) && 
        <Tab
          value={0}
          style={tabStyle}
          icon={<PeopleIcon />}
          label={i18next.t(
            `${i18next.t(`resources.users.name`, { count: 2 })}`
          )}
          {...a11yProps(0)}
        />
      }
      {userIs([ROLE_CANDIDATE, ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_SUPERVISOR]) && 
        <Tab
          value={1}
          style={tabStyle}
          icon={<GroupsIcon />}
          label={i18next.t(`resources.voters.name`, { count: 2 })}
          {...a11yProps(1)}
        />
      }
      {userIs([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]) && 
        <Tab
          value={2}
          style={tabStyle}
          icon={<LibraryBooksIcon />}
          label={i18next.t(`resources.bases.name`, { count: 2 })}
          {...a11yProps(2)}
        />
      }
      {userIs([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]) && 
        <Tab
          value={3}
          style={tabStyle}
          icon={<SearchIcon />}
          label={i18next.t(`resources.research.name`, { count: 2 })}
          {...a11yProps(3)}
        />
      }
      {userIs([ROLE_ADMIN]) && 
        <Tab
          value={4}
          style={tabStyle}
          icon={<PeopleIcon />}
          label={i18next.t(`resources.parties.name`, { count: 2 })}
          {...a11yProps(4)}
        />
      }
      {userIs([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]) && 
        <Tab
          value={5}
          style={tabStyle}
          icon={<AccountCircleIcon />}
          label={i18next.t(`resources.candidates.name`, { count: 2 })}
          {...a11yProps(5)}
        />
      }
    </Tabs>
  );
};
