import { ReactNode } from "react";
import { userCan } from "src/Components/Layout/Can";
import ForbiddenPage from "src/Components/Status/Forbidden";
import { ROLE_ADMIN } from "src/Enums/Roles";

export const ValidateRoute = (role: string | string[], children: ReactNode) => {

    const hasRole = (role: string | string[]) => 
    {
        const userDataJson = localStorage.getItem('userData');


        if (!userDataJson) return false;

        const userData = JSON.parse(userDataJson);

        if (userData.role.name == ROLE_ADMIN) {
            return true;
        }

        if (!Array.isArray(role)) {
            return (userData.role?.name === role)
        } else {
            return role.indexOf(userData.role.name) >= 0
        }
    }
    
    if (hasRole(role)) {
        return children;
    } else {
        return <ForbiddenPage />
    }
}