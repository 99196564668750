import * as React from 'react';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import { ConfigContext } from 'src/Hooks/useConfigContext';

interface MaterialFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const MaterialForm = ({ data, onSubmit }: MaterialFormProps) => {
    const resource = 'materials'
    const { control } = useForm();
    const { config } = React.useContext(ConfigContext);
    const { options: types } = useGetManyOptions(api, '/material-types')

    const onSubmitFn = (formData) => {
        onSubmit(formData)
    }

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmitFn}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {types &&
                                <ReferenceInput
                                    name={"materialTypeId"}
                                    control={control}
                                    label={`resources.${resource}.fields.materialTypeId`}
                                    defaultValue={data?.materialTypeId}
                                    options={types}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"name"}
                                control={control}
                                label={`resources.${resource}.fields.name`}
                                defaultValue={data?.name}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"description"}
                                control={control}
                                label={`resources.${resource}.fields.description`}
                                defaultValue={data?.description}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <DecimalInput
                                name={"defaultPrice"}
                                control={control}
                                label={`resources.${resource}.fields.defaultPrice`}
                                defaultValue={data?.defaultPrice}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </SimpleContainer>
    );
}