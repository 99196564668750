import * as React from 'react';
import { formatCurrency, formatFloat } from "src/Utils/Formatters"
import { TextField, TextFieldProps } from "../TextField"

interface DecimalFieldProps {
    hasSymbol?: boolean;
}

export const DecimalField = (props: TextFieldProps & DecimalFieldProps) => {
    const newProps = {
        ...props,
        ...{
            value: 
                ((props.value == '') || (props.value == null))? '': (
                    props.hasSymbol ? 
                        formatCurrency(parseFloat(props.value))
                        :formatFloat(props.value)
                )
        }
    }
    return <TextField {...newProps}/>
}