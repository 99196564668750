import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { api } from 'src/Services/Financial';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';
import i18next from 'i18next';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import Grid from '@mui/material/Grid';
import { useRedirect } from 'react-admin';

export const CampaignEdit = () => {
  const { control } = useForm();
  const theme = useTheme();
  const redirect = useRedirect();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { id } = useParams();
  const { data } = useGetOne(api, '/campaigns', id);
  const { options: elections } = useGetManyOptions(api, '/elections')
  const [, setCategories] = React.useState<any[]>([]);

  const onSubmit = formData => {
    api.put(`/campaigns/${id}`, formData.data).then(result => {
      if (result?.status == 200) {
        showSuccessMessage(i18next.t(`resources.campaigns.actions.update.success`));
        redirect(`/election/campaigns/${id}/show`);
      }
    });
  };

  React.useEffect(() => {
    const loadCampaignId = async () => {
      if (data) 
      {
        setCategories([]);
        // get category tree from campaignId
        await api
          .get(`/categories/tree/expenses?campaignId=${id}`)
          .then(result => {
            if (result && (result.status == 200 || result.status == 201)) {
              setCategories(
                result.data.map(item => ({ value: item.id, label: item.name }))
              );
            }
          });
      }
    };

    loadCampaignId();
    //})
  }, [data]);

  return (
    <SimpleContainer
      title="resources.campaigns.name"
      style={{ maxWidth: isSmScreen ? '100%' : 900 }}
    >
      <Form method="post" control={control} onSubmit={onSubmit}>
        {elections &&
          <ReferenceInput
              name={"electionId"}
              control={control}
              label={`resources.campaigns.fields.electionId`}
              defaultValue={data?.electionId}
              options={elections}
          />
        }
        {data && (
          <>
          <CustomInput
            name={'name'}
            control={control}
            label={i18next.t(`resources.campaigns.fields.name`)}
            defaultValue={data.name}
          />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={12}>
              <DecimalInput
                  name={"amount"}
                  control={control}
                  label={`resources.campaigns.fields.amount`}
                  defaultValue={data.amount}
              />
            </Grid>
          </Grid>
          </>
        )} 
      </Form>
    </SimpleContainer>
  );
};
