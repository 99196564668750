import { useMediaQuery, useTheme } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import i18next from 'i18next';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const LhinceBreadcrumbs = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const createPath = {
    path: 'create',
    breadcrumb: i18next.t('actions.create')
  };
  const updatePath = { path: ':id', breadcrumb: i18next.t('actions.update') };
  const showPath = { path: ':id/show', breadcrumb: i18next.t('actions.show') };
  const defaultPaths = [createPath, showPath, updatePath];

  const routes = [
    {
      path: '/',
      breadcrumb: i18next.t('resources.reports.elections.comparative.begin')
    },
    {
      path: '/change-password',
      breadcrumb: i18next.t('auth.change_password.title')
    },
    {
      path: 'election',
      breadcrumb: i18next.t('menu.elections.title')
    },
    {
      path: 'election/intention-stats',
      breadcrumbs: i18next.t('resources.reports.elections.intentions.name')
    },
    {
      path: 'election/elections',
      breadcrumb: i18next.t('resources.elections.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'election/campaigns',
      breadcrumb: i18next.t('resources.campaigns.name', { count: 2 }),
      children: defaultPaths.concat({
        path: ':id/show/users',
        breadcrumb: i18next.t('resources.campaigns.users.name', { count: 2 })
      })
    },
    {
      path: 'election/users',
      breadcrumb: i18next.t('resources.users.name', { count: 2 }),
      children: defaultPaths
        .concat({
          path: ':id/show/accounts',
          breadcrumb: 'Contas'
        })
        .concat({
          path: ':id/show/campaigns',
          breadcrumb: 'Campanhas'
        })
    },
    {
      path: 'election/voters',
      breadcrumb: i18next.t('resources.voters.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'election/bases',
      breadcrumb: i18next.t('resources.bases.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'election/research',
      breadcrumb: i18next.t('resources.research.name', { count: 2 }),
      children: defaultPaths.concat({
        path: ':id/show/research-candidate',
        breadcrumb: 'Candidatos da Pesquisa'
      })
    },
    {
      path: 'election/parties',
      breadcrumb: i18next.t('resources.parties.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'election/candidates',
      breadcrumb: i18next.t('resources.candidates.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'election/report',
      breadcrumb: i18next.t('resources.reports.elections.research.name')
    },
    {
      path: 'election/comparative',
      breadcrumb: i18next.t('resources.reports.elections.comparative.name')
    },
    {
      path: 'election/maps',
      breadcrumb: i18next.t('resources.reports.elections.maps.name')
    },
    {
      path: 'election/resume-table',
      breadcrumb: i18next.t('resources.reports.elections.resumeResults.name')
    },
    {
      path: 'financial',
      breadcrumb: i18next.t('menu.financial.title')
    },
    {
      path: 'financial/accounts',
      breadcrumb: i18next.t('resources.accounts.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'financial/categories',
      breadcrumb: i18next.t('resources.categories.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'reports',
      breadcrumb: i18next.t('resources.reports.name')
    },
    {
      path: 'reports/financial-resume',
      breadcrumb: i18next.t('resources.reports.resume.name')
    },
    {
      path: 'reports/financial-resume-account',
      breadcrumb: i18next.t('resources.reports.financial.resumeAccount.name')
    },
    {
      path: 'reports/chart-of-accounts',
      breadcrumb: i18next.t('resources.reports.chartOfAccounts.name')
    },
    {
      path: 'reports/financial-movements',
      breadcrumb: i18next.t('resources.reports.movements.name')
    },
    {
      path: 'reports/cost-by-city',
      breadcrumb: i18next.t('resources.reports.costByCity.name')
    },
    {
      path: '/configurations',
      breadcrumb: i18next.t('resources.configurations.name', { count: 2 })
    },
    {
      path: 'financial/donations',
      breadcrumb: i18next.t('resources.donations.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'financial/expenses',
      breadcrumb: i18next.t('resources.expenses.name', { count: 2 }),
      children: defaultPaths.concat({
        path: ':id/confirm',
        breadcrumb: 'Confirmação'
      })
    },
    {
      path: 'financial/expense-types',
      breadcrumb: i18next.t('resources.expenseTypes.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'financial/costcenters',
      breadcrumb: i18next.t('resources.costcenters.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'financial/costitems',
      breadcrumb: i18next.t('resources.costitems.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'financial/persons',
      breadcrumb: i18next.t('resources.persons.name', { count: 2 }),
      children: defaultPaths
    },
    { path: 'stock', breadcrumb: i18next.t('menu.stock.title') },
    {
      path: 'stock/material-types',
      breadcrumb: i18next.t('resources.materialTypes.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'stock/materials',
      breadcrumb: i18next.t('resources.materials.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'stock/place-types',
      breadcrumb: i18next.t('resources.placeTypes.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'stock/places',
      breadcrumb: i18next.t('resources.places.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'stock/inbounds',
      breadcrumb: i18next.t('resources.materialInbounds.name', { count: 2 }),
      children: defaultPaths.concat({
        path: ':id/show/materials',
        breadcrumb: 'Adicionar Materiais'
      })
    },
    {
      path: 'stock/outbounds',
      breadcrumb: i18next.t('resources.materialOutbounds.name', { count: 2 }),
      children: defaultPaths.concat({
        path: ':id/show/materials',
        breadcrumb: 'Adicionar Materiais'
      })
    },
    {
      path: 'stock/reports',
      breadcrumb: i18next.t('resources.reports.name', { count: 2 })
    },
    {
      path: 'reports/stock-movements',
      breadcrumb: i18next.t('resources.reports.stock.movements.name', {
        count: 2
      })
    },
    { path: 'hr', breadcrumb: i18next.t('menu.hr.title') },
    {
      path: 'hr/positions',
      breadcrumb: i18next.t('resources.positions.name', { count: 2 }),
      children: defaultPaths
    },
    {
      path: 'hr/hirings',
      breadcrumb: i18next.t('resources.hirings.name', { count: 2 }),
      children: defaultPaths
        .concat({
          path: ':hiringId/show/persons',
          breadcrumb: 'Contratados'
        })
        .concat({
          path: ':hiringId/show/persons/create',
          breadcrumb: 'Adicionar'
        })
        .concat({
          path: ':hiringId/show/persons/:id',
          breadcrumb: 'Alterar'
        })
        .concat({
          path: ':id/confirm',
          breadcrumb: 'Confirmar'
        })
        .concat({
          path: ':hiringId/show/persons/:id/complete',
          breadcrumb: 'Completar'
        })
        .concat({
          path: ':hiringId/show/persons/:id/show',
          breadcrumb: 'Visualizar'
        })
    },
    {
      path: 'hr/hirings-report',
      breadcrumb: i18next.t('resources.reports.hr.hirings.name', { count: 2 })
    },
    {
      path: 'hr/payments-report',
      breadcrumb: i18next.t('resources.reports.hr.payments.name', { count: 2 })
    },
    {
      path: 'marketing/charges',
      breadcrumb: i18next.t('resources.charges.name', { count: 2 }),
      children: defaultPaths.concat({
        path: ':id/confirm',
        breadcrumb: 'Confirmação'
      })
    }
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Breadcrumbs
      separator=">"
      aria-label="breadcrumb"
      maxItems={isSmall ? 2 : 99}
      style={{ marginTop: 8, marginBottom: 20 }}
    >
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Link
          underline="hover"
          color="inherit"
          key={match.pathname}
          href={match.pathname}
        >
          {breadcrumb}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default LhinceBreadcrumbs;
