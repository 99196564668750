import React from 'react';
import { useForm } from 'react-hook-form';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { useInfiniteFetchWithParams } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import i18next from 'i18next';
import { DateInput } from 'src/Components/Inputs/DateInput';
import Grid from '@mui/material/Grid';
import { showErrorMessage } from 'src/Hooks/useMessages';

interface VotersByPeriodTotalsPageProps {
  dateStart: string;
  setDateStart: any;
  dateEnd: string;
  setDateEnd: any;
}

export function VotersByPeriodTotalsPage({ dateStart, setDateStart, dateEnd, setDateEnd }: VotersByPeriodTotalsPageProps) {
  const endpoint = '/voters-by-period/totals';
  const resource = 'resources.reports.elections.votersByPeriod';
  const { control } = useForm();

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t('resources.home.messages.invalid_date'));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(start).toISOString();
  };

  const { data, fetchData, hasMore, filters, setFilters, setParams } =
    useInfiniteFetchWithParams(api, endpoint, { date: null }, 25, {
      date: getDateBounds(dateStart)
    });

  React.useEffect(() => {
    if (dateStart && dateEnd) {
      const dateStartBound = getDateBounds(dateStart);
      const dateEndBound = getDateBounds(dateEnd);

      if (dateStartBound) {
        setParams({ dateStart: dateStartBound, dateEnd: dateEndBound });
        setFilters({
          ...filters,
          ...{
            dateStart: dateStartBound, dateEnd: dateEndBound
          }
        });
      }
    }
  }, [dateStart, dateEnd]);

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      filters={
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateStart"
              label={`${resource}.filters.dateStart`}
              onChange={e => {
                setDateStart(e.target.value);
              }}
              defaultValue={dateStart}
              data-id="datefilter"
            />
          </Grid>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateEnd"
              label={`${resource}.filters.dateEnd`}
              onChange={e => {
                setDateEnd(e.target.value);
              }}
              defaultValue={dateEnd}
              data-id="datefilterEnd"
            />
          </Grid>
        </Grid>
      }
      columns={[
        {
          header: `${resource}.totals.results.name`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.name}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.totals.results.totalSupervisors`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index} style={{ textAlign: 'center'}}>
              {item.totalSupervisors}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.totals.results.totalOperators`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index} style={{ textAlign: 'center'}}>
              {item.totalOperators}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.totals.results.neighborhood`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.neighborhood}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.totals.results.publicPlace`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.publicPlace}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.totals.results.zoneName`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.zoneName}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `${resource}.totals.results.totalDay`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index} style={{ textAlign: 'center'}}>
              {item.total}
            </InfiniteListStyles.StyledTd>
          )
        }
      ]}
    />
  );
}
