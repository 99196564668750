import * as React from 'react';
import { Stack } from '@mui/material';

export const SimpleShowLayout = (props: any) => {
    const { children, divider, spacing = 2, ...rest } = props;
    return (
        <Stack
            spacing={spacing}
            divider={divider}
            {...rest}
        >
            {children}
        </Stack>
    );
};