import { useEffect, useState } from "react";
import * as d3 from "d3";
import * as Styled from '../styles';
import { ZonesAutocompleteInput } from "../Inputs/ZonesAutocompleteInput";
import i18next from "i18next";
import {
  Grid
} from '@mui/material';

const MARGIN = { top: 10, right: 10, bottom: 30, left: 200 };

type HeatmapProps = {
  data: any[],
  setData: any,
  control: any,
  resource: string
};

export const Heatmap = ({ data, setData, control, resource }: HeatmapProps) => {
  type HeatmapData = { x: string; y: string; value: number }[];
  const COL_SIZE = 120;
  const ROW_SIZE = 70;
  let heatData: HeatmapData = [];
  let candidates: string[] = [];
  const zones: string[] = [];
  const [allZones, setAllZones] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState(data);
  const [allRects, setAllRects] = useState<any[]>([]);
  const [xLabels, setXLabels] = useState<any[]>([]);
  const [yLabels, setYLabels] = useState<any[]>([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [boundsHeight, setBoundsHeight] = useState(0);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    const allZones: string[] = [];

    for (const item of data) {
      if (!allZones.includes(item.electoralZone)) {
        allZones.push(item.electoralZone);
      }
    }

    allZones.sort();
    setAllZones(allZones);

    candidates = [];
    heatData = [];

    for (const item of filteredData) {
      heatData.push({
        x: item.electoralZone,
        y: item.candidateName,
        value: item.votes,
      });

      if (!candidates.includes(item.candidateName)) {
        candidates.push(item.candidateName);
      }

      if (!zones.includes(item.electoralZone)) {
        zones.push(item.electoralZone);
      }
    }

    candidates.sort();
    zones.sort();

    // groups
    const allYGroups = candidates;
    const allXGroups = zones;

    const width = (zones.length * COL_SIZE) + MARGIN.right + MARGIN.left;
    const height = (candidates.length * ROW_SIZE) + MARGIN.top + MARGIN.bottom;
    setWidth(width);
    setHeight(height);

    // bounds = area inside the axis
    const boundsWidth = width - MARGIN.right - MARGIN.left;
    const boundsHeight = height - MARGIN.top - MARGIN.bottom;
    setBoundsHeight(boundsHeight);

    // x and y scales
    const xScale = d3
      .scaleBand()
      .range([0, boundsWidth])
      .domain(allXGroups)
      .padding(0.1);

    const yScale = d3
      .scaleBand()
      .range([boundsHeight, 0])
      .domain(allYGroups)
      .padding(0.1);

    const [min, max] = d3.extent(heatData.map((d) => d.value));

    // Build the rectangles
    setAllRects(heatData.map((d, i) => {
      return (
        <g key={`${d.x}-${d.y}`}>
          <rect
            key={`rect-${d.x}-${d.y}`}
            r={4}
            x={xScale(d.x) + 10}
            y={yScale(d.y) + 25}
            width={xScale.bandwidth()}
            height={yScale.bandwidth()}
            opacity={1}
            fill={(d.value < 500) ? '#F8E0A9' : (d.value < 1000) ? '#F4BE40' : '#E86C1E'}
            rx={5}
            stroke={"white"}
          >
            <title>Zona {d.x}: {d.value} {d.value > 1 ? 'votos': 'voto'}</title>
          </rect>
          <text 
            key={`text-${d.x}-${d.y}`}
            x={xScale(d.x) + 10 + xScale.bandwidth()/2} 
            y={yScale(d.y) + 25 + yScale.bandwidth()/2} 
            fontFamily="Arial" 
            fontSize="20" 
            textAnchor="middle" 
            alignmentBaseline="middle"
            stroke="#000"
          >
            {d.value}
          </text>
        </g>
      );
    }));

    setXLabels(zones.map((name, i) => {
      const xPos = xScale(name) ?? 0;
      return (
        <text
          key={`xLabel-${i}`}
          x={xPos + xScale.bandwidth() / 2 + 10}
          y={10}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
        >
          {name}
        </text>
      );
    }));

    setYLabels(candidates.map((name, i) => {
      const yPos = yScale(name) ?? 0;
      return (
        <text
          key={`yLabel-${i}`}
          x={-5}
          y={yPos + yScale.bandwidth() / 2 + 25}
          textAnchor="end"
          dominantBaseline="middle"
          style={{ 
            fontFamily: "Roboto",
            fontSize: "14px" 
          }}
        >
          {name}
        </text>
      );
    }));
  }, [filteredData]);

  const legends = [
    { label: '0 - 500', color: '#F8E0A9' },
    { label: '500 - 1000', color: '#F4BE40' },
    { label: '> 1.000', color: '#E86C1E' }
  ].map((legend, index) =>
    <g key={`legend-${index}`}>
      <rect
        r={4}
        x={120 * index}
        y={40 + index}
        width={25}
        height={25}
        opacity={1}
        fill={legend.color}
        rx={5}
        stroke={"white"}
      /> 
      <text
        x={(120 * index) + 25 + 5 }
        y={56 + index}
        width={100}
        fontSize={12}
      >
        {legend.label}
      </text>
    </g>
  );

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>
          Mapa de Calor: votos por Zona
        </Styled.Title>
        <Styled.Filters>
          {allZones && 
            <ZonesAutocompleteInput 
              zones={allZones}
              data={data}
              filterData={setFilteredData}
              control={control} 
              label={i18next.t(`${resource}.heatmap.zone.label`)}
            />
          }
        </Styled.Filters>
      </Styled.Header>
      
      {filteredData && 
        <>
        <Styled.HeatMap>
          <Styled.StyledSVG width={width} height={height}>
            <g
              width={width}
              height={boundsHeight}
              transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
            >
              {allRects}
              {xLabels}
              {yLabels}
            </g>
          </Styled.StyledSVG>
        </Styled.HeatMap>

        <Styled.HeatMapLegend>
          <svg width={360} height={100}>
            <g
              style={{ fontFamily: "Roboto"}}
              width={360}
              height={100}
            >
              {legends}
            </g>
          </svg>
        </Styled.HeatMapLegend>
        </>
      }
    </Styled.Container>
  );
};
