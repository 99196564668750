import { Route, Routes } from "react-router-dom";
import { InboundCreate, InboundEdit, InboundList, InboundShow } from "src/Pages/Stock/Inbounds";

export const InboundRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<InboundList />} />
          <Route path="/create" element={<InboundCreate />} />
          <Route path=":id" element={<InboundEdit />} />
          <Route path=":id/show" element={<InboundShow />} />
        </Routes>
    )
  }