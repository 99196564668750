import { Route, Routes } from 'react-router-dom';
import { ChargeRoutes } from './ChargeRoutes';
import { ValidateRoute } from 'src/Components/Auth/ValidateRoute';
import { ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from 'src/Enums/Roles';
import { GuardedComponent } from 'src/Components/Financial/Guarded';

export const MarketingRoutes = () => {
  return (
    <Routes>
      <Route path="/charges/*" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER], GuardedComponent(<ChargeRoutes />))} />
      <Route path="/*" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER], GuardedComponent(<ChargeRoutes />))} />
    </Routes>
  );
};
