import * as React from 'react';
import { api } from 'src/Services/Financial'
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { showErrorMessage, showSuccessMessage } from 'src/Hooks/useMessages';
import { DateInput } from 'src/Components/Inputs/DateInput';
import i18next from 'i18next';
import moment from 'moment';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import useLoader from 'src/Hooks/useLoader';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useGetIdentity } from 'react-admin';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { ROLE_BASE_MANAGER } from 'src/Enums/Roles';
import { Grid } from '@mui/material';

export const ChartOfAccountsReport = () => 
{
    const resource = 'chartOfAccounts'
    const { control, formState: { errors } } = useForm();
    const { config } = React.useContext(ConfigContext);
    const [electionId, setElectionId] = React.useState(config.electionId);
    const [accountId, setAccountId] = React.useState(config.accountId);
    const [campaignId, setCampaignId] = React.useState(config.campaignId);
    const { options: elections } = useGetManyOptions(api, '/elections');
    const { options: campaigns } = useGetManyOptions(api, '/campaigns', { electionId });
    const { options: accounts } = useGetManyOptions(api, '/accounts', { campaignId });
    const { data: identity } = useGetIdentity()
    const loader = useLoader()

    const onSubmit = async(form) => 
    {    
        const startDateAux = moment(form.data.dateStart)
        const endDateAux = moment(form.data.dateEnd)

        loader.showLoader()
        
        api.get('reports/chart-of-accounts', {
            params: {
                campaignId: form.data.campaignId,
                accountId: form.data.accountId, 
                startDate: startDateAux.toDate(), 
                endDate: endDateAux.toDate()
            },
            responseType: 'arraybuffer'
        }).then(async response => {

            if (response) {  
                loader.hideLoader() 
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.setAttribute('target', '_blank');
                a.download = 'plano_de_contas.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

                showSuccessMessage(`resources.reports.${resource}.messages.success`)
            }
        }).
        catch(error => {
            loader.hideLoader()
            console.log(error)
            showErrorMessage(`resources.reports.${resource}.messages.error`);
        })
    }

    return (
        <SimpleContainer
          title={`resources.reports.${resource}.name`}
          mdSize={900}
        >
            <loader.Loader />
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
                submitButtonLabel='actions.export'
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item md={5} xs={12} sm={12}>
                        {elections && (
                            <ReferenceInput
                                name={'electionId'}
                                label={i18next.t(`resources.reports.${resource}.fields.electionId`)}
                                control={control}
                                defaultValue={electionId ?? ''}
                                options={elections}
                                rules={{ required: 'Pleito Obrigatório' }}
                                helperText={errors.electionId ? errors.electionId?.message : ''}
                                onChange={value => { 
                                setElectionId(value)
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item md={7} xs={12} sm={12}>
                        {electionId && campaigns && (
                            <ReferenceInput
                                name={'campaignId'}
                                label={i18next.t(`resources.reports.${resource}.fields.campaignId`)}
                                control={control}
                                defaultValue={campaignId ?? ''}
                                options={campaigns}
                                rules={{ required: 'Campanha Obrigatória' }}
                                helperText={errors.campaignId ? errors.campaignId?.message : ''}
                                onChange={value => { 
                                setCampaignId(value)
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                        {campaignId && accounts && identity && (
                            <ReferenceInput
                                name={'accountId'}
                                label={i18next.t(`resources.reports.${resource}.fields.accountId`)}
                                control={control}
                                defaultValue={accountId ?? null}
                                options={accounts}
                                rules={ (identity.role.name == ROLE_BASE_MANAGER) ? { required: 'Conta Obrigatória' }: {}}
                                helperText={errors.accountId ? errors.accountId?.message : ''}
                                onChange={value => {
                                setAccountId(value);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <DateInput 
                            control={control} 
                            name="dateStart" 
                            label={`resources.reports.${resource}.fields.dateStart`}
                            rules={{
                                required: i18next.t(`resources.reports.${resource}.rules.dateStart.required`)
                            }}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <DateInput 
                            control={control} 
                            name="dateEnd" 
                            label={`resources.reports.${resource}.fields.dateEnd`} 
                            rules={{
                                required: i18next.t(`resources.reports.${resource}.rules.dateEnd.required`)
                            }}
                        />
                    </Grid>
                </Grid>
            </Form>
        </SimpleContainer>
    );
}