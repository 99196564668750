import React, { useState } from 'react';
import { Stats } from 'src/Components/Election/Stats';
import CalendarRangeComponents from 'src/Components/Inputs/CalendarRangeComponents';
import { format, addDays } from 'date-fns';
import { ResearchRanking } from 'src/Components/Election/Charts/ResearchRanking';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import i18n from 'src/i18n';
import * as Styled from './styles';

export const ResearchReport = () => {
  const resource = 'resources.reports.elections.research';
  const [dateStart, setDateStart] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [dateEnd, setDateEnd] = useState(
    format(addDays(new Date(), 7), 'yyyy-MM-dd')
  );

  return (
    <>
      <SimpleContainer title={`${resource}.name`}>
        <Stats />
        <Styled.Filters>
          <Styled.Filter>
            <CalendarRangeComponents
              labelText={i18n.t(`${resource}.fields.period.label`)}
              changeRange={rangeFromChild => {
                setDateStart(rangeFromChild.startDate);
                setDateEnd(rangeFromChild.endDate);
              }}
            />
          </Styled.Filter>
        </Styled.Filters>
        <ResearchRanking dateStart={dateStart} dateEnd={dateEnd} />
      </SimpleContainer>
    </>
  );
};
