import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

interface IViewButton {
    url: string
}

export const ViewButton = (props: IViewButton) => {
    return <Link to={props.url ?? ''}>
        <VisibilityIcon
            sx={{ margin: 'auto', fontSize: 18 }}
        />
    </Link>
}