import accounts from './accounts.json';
import actions from './actions.json';
import bases from './bases.json';
import campaigns from './campaigns.json';
import candidates from './candidates.json';
import categories from './categories.json';
import chargeItems from './charge_items.json';
import configurations from './configurations.json';
import core from './core.json';
import costcenters from './costcenters.json';
import costitems from './costitems.json';
import donationNatures from './donation-natures.json';
import donationTypes from './donation-types.json';
import donations from './donations.json';
import elections from './elections.json';
import expenseTypes from './expenseTypes.json';
import expenses from './expenses.json';
import home from './home';
import hirings from './hirings.json';
import hiringPersons from './hiring-persons.json';
import hiringPersonPayments from './hiring-person-payments.json';
import inputs from './inputs.json';
import materialTypes from './materialTypes.json';
import materials from './materials.json';
import materialInbounds from './material_inbounds.json';
import materialInboundItems from './material_inbound_items.json';
import materialOutbounds from './material_outbounds.json';
import materialOutboundItems from './material_outbound_items.json';
import menu from './menu.json';
import parties from './parties.json';
import persons from './persons.json';
import places from './places.json';
import placeTypes from './placeTypes.json';
import positions from './positions.json';
import reports from './reports';
import research from './research.json';
import users from './users.json';
import voters from './voters.json';
import maps from './reports/elections/maps.json';
import programs from './programs.json';
import charges from './charges.json';
import components from './components';
import auth from './auth';

const messages = {
  actionColumn: 'Ações',
  actions,
  auth,
  components,
  core,
  inputs,
  menu,
  number: {
    currency: 'BRL'
  },
  const: {
    donationTypes,
    donationNatures
  },
  resources: {
    accounts,
    bases,
    campaigns,
    candidates,
    categories,
    charges,
    chargeItems,
    configurations,
    costcenters,
    costitems,
    donations,
    elections,
    expenses,
    expenseTypes,
    hirings,
    hiringPersons,
    hiringPersonPayments,
    home,
    materials,
    materialInbounds,
    materialInboundItems,
    materialOutbounds,
    materialOutboundItems,
    materialTypes,
    parties,
    persons,
    positions,
    places,
    placeTypes,
    programs,
    reports,
    users,
    voters,
    research,
    maps
  }
};

export default messages;
