import { Api } from '../api';

class FinancialApi extends Api 
{
    insertAccountId() {
      const config = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config') as string) : null
      if (config && config.accountId) {
        this.axiosInstance.defaults.headers['x-account-id'] = config.accountId
        this.axiosInstance.defaults.headers['x-campaign-id'] = config.campaignId
      }
    }

    initAxios() {
      super.initAxios()
      this.insertAccountId()
    }
}

export const api = new FinancialApi(`${String(process.env.REACT_APP_API_URL)}/`)