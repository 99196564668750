import * as React from 'react';
import { api } from 'src/Services/Financial';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import { useGetIdentity } from 'react-admin';
import i18next from 'i18next';

export const ConfigurationsPage = () => {
  const resource = 'configurations';
  const { data: identity } = useGetIdentity();
  const { config, setConfig } = React.useContext(ConfigContext);
  const [electionId, setElectionId] = React.useState(null);
  const [accountId, setAccountId] = React.useState(null);
  const [campaignId, setCampaignId] = React.useState(null);

  const { control, setValue, handleSubmit, formState: { errors } } = useForm();
  const { options: elections } = useGetManyOptions(api, '/elections');
  const { options: campaigns } = useGetManyOptions(api, '/campaigns', { electionId });
  const { options: accounts } = useGetManyOptions(api, '/accounts', { campaignId });
  const [categories, setCategories] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (identity) {
      const config = localStorage.getItem('config')
        ? JSON.parse(localStorage.getItem('config') as string)
        : null;
      if (config) {
        setElectionId(config.electionId);
        setCampaignId(config.campaignId);
        setAccountId(config.accountId);
      }
    }
  }, [identity]);

  const onSubmit = form => {
    api
      .post('/configurations', {
        electionId: form.electionId,
        accountId: form.accountId,
        campaignId: form.campaignId
      })
      .then(result => {
        if (result && (result.status == 200 || result.status == 201)) {
          const newConfig = {
            ...config,
            ...{
              electionId: form.electionId,
              accountId: form.accountId,
              campaignId: form.campaignId
            }
          };
          setConfig(newConfig);

          showSuccessMessage(i18next.t(`resources.configurations.messages.success`));
        }
      });
  };

  const onError = response => {
    console.log(errors, response);
  };

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={900}>
      <Form
        method="post"
        control={control}
        onSubmit={handleSubmit(onSubmit, onError)}
        onError={onError}
      >
        {elections && (
          <ReferenceInput
            name={'electionId'}
            label={i18next.t(`resources.${resource}.fields.electionId`)}
            control={control}
            defaultValue={electionId ?? ''}
            options={elections}
            rules={{ required: 'Pleito Obrigatório' }}
            helperText={errors.electionId ? errors.electionId?.message : ''}
            onChange={value => { 
              setElectionId(value)
            }}
          />
        )}
        {electionId && campaigns && (
          <ReferenceInput
            name={'campaignId'}
            label={i18next.t(`resources.${resource}.fields.campaignId`)}
            control={control}
            defaultValue={campaignId ?? ''}
            options={campaigns}
            rules={{ required: 'Campanha Obrigatória' }}
            helperText={errors.campaignId ? errors.campaignId?.message : ''}
            onChange={value => { 
              setCampaignId(value)
            }}
          />
        )}
        {campaignId && accounts && (
          <ReferenceInput
            name={'accountId'}
            label={i18next.t(`resources.${resource}.fields.accountId`)}
            control={control}
            defaultValue={accountId ?? null}
            options={accounts}
            rules={{ required: 'Conta Obrigatória' }}
            helperText={errors.accountId ? errors.accountId?.message : ''}
            onChange={value => {
              setAccountId(value);
            }}
          />
        )}
      </Form>
    </SimpleContainer>
  );
};
