export const formatDateString = (date: Date) => {
  if (!date) return '';

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return day + '/' + month + '/' + year;
};

export const toLocaleDateString = (data: Date) => {
  return new Date(data).toLocaleDateString('pt-br');
};

export function formatDateOfBirth(dateOfBirth) {
  if (!dateOfBirth) return '';

  const date = new Date(dateOfBirth);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const formatCurrency = (amount: number) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  return formatter.format(amount);
};

export const formatFloat = (amount: number) => {
  return new Intl.NumberFormat('pt-BR').format(amount);
};

export function formatCEP(cep: any) {
  // Converte o valor do CEP para string
  const cepString = cep.toString();

  // Remove caracteres não numéricos
  const cleanedCEP = cepString.replace(/\D/g, '');

  // Aplica a formatação do CEP (#####-###)
  return cleanedCEP.replace(/^(\d{5})(\d{3})$/, '$1-$2');
}

// FORMATAR CPF
export function formatCPF(cpf: any) {
  // Remove caracteres não numéricos
  const cleanedCPF = cpf.replace(/\D/g, '');

  // Aplica a formatação do CPF (###.###.###-##)
  return cleanedCPF.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
}

// FORMATAR NÚMEROS TELEFONE
export function formatPhone(phoneNumber) {
  if (!phoneNumber) return '';

  // Remove caracteres não numéricos, como espaços, traços e parênteses
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Verifica se o número tem 11 dígitos
  if (cleanedPhoneNumber.length === 11) {
    return `(${cleanedPhoneNumber.slice(0, 2)}) ${cleanedPhoneNumber.slice(
      2,
      7
    )}-${cleanedPhoneNumber.slice(7)}`;
  } else {
    // Se o número não tem 11 dígitos, apenas retorna o número limpo
    return cleanedPhoneNumber;
  }
}

export function removePhoneMask(phone: string) {
  return phone.replace(/\D/g, '');
}
