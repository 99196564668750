import Logo from 'src/Assets/logo';
import background from 'src/Assets/background.png';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { Form } from 'src/Components/Admin/Form';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

export const MyLoginPage = (props: any) => {
  const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down('lg'));

  const defaultTheme = createTheme({
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              textTransform: 'none',
              height: '2.5rem',
              fontWeight: 700,
              borderRadius: 8,
              background: '#FF6A46',
              '&:hover': {
                backgroundColor: '#f18468'
              },
              '&:focus': {
                backgroundColor: '#f18468'
              }
            }
          }
        ]
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            borderRadius: 8,
            '& .MuiInputBase-input': {
              backgroundColor: '#fff',
              borderRadius: 8,
              paddingTop: 0,
              paddingBottom: 0,
              height: '3rem'
            }
          }
        }
      }
    }
  });

  const { control } = useForm();

  const submit = e => {
    login(e.data).catch(() =>
      notify('Credencias inválidas', { type: 'error' })
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={5}
          md={8}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundColor: '#ff3402',
            backgroundSize: 'cover'
          }}
        />

        <Grid
          item
          xs={12}
          sm={7}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{ backgroundColor: '#323B46', color: '#fff' }}
        >
          <Box
            sx={{
              my: isSmaller ? 15 : 30,
              mx: isSmaller ? 0 : 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Logo />

            <Form
              method="post"
              control={control}
              onSubmit={submit}
              showSaveButton={false}
              showCancelButton={false}
              style={{
                boxShadow: 'unset',
                background: '#323B46',
                color: '#fff'
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" gutterBottom>
                    Faça seu Login
                  </Typography>
                  <CustomInput
                    name="username"
                    control={control}
                    label=""
                    variant="filled"
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant="body2" gutterBottom>
                    Senha
                  </Typography>
                  <CustomInput
                    name="password"
                    control={control}
                    label=""
                    type="password"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}></Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      color: '#FF6A46',
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => props.changePage('SEND_EMAIL')}
                  >
                    Esqueci minha senha
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Entrar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
