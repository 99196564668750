import { useEffect, useState } from 'react';

const useGetMany = (api, endpoint, defaultFilters = {}, callback?) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ data, setData ] = useState<any | null>(null);
    const [filters, setFilters] = useState(defaultFilters);

    const fetch = async() => {
        const filtersExpr = JSON.stringify(filters);
        api.get(`${endpoint}?filter=${filtersExpr}`)
            .then(response => {
                setLoading(false)

                if (response.status == 200)
                {
                    let info = response.data
                    if (callback) {
                        info = callback(info)   
                    }
                    setData(info)
                }
                else throw new Error('Erro ao buscar dado');
            })
            .catch(error => {
                setError(error)
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        setFilters(defaultFilters)
    }, [JSON.stringify(defaultFilters)])

    useEffect(() => {
        fetch()
    }, [endpoint, JSON.stringify(filters)]);
    
    return { data, setData, fetch, filters, setFilters, loading, error }
}

export default useGetMany