import { Route, Routes } from "react-router-dom";
import { PersonCreate, PersonEdit, PersonList, PersonShow } from "src/Pages/Financial/Persons";

export const PersonRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<PersonList />} />
          <Route path="/create" element={<PersonCreate />} />
          <Route path=":id" element={<PersonEdit />} />
          <Route path=":id/show" element={<PersonShow />} />
        </Routes>
    )
  }