import * as React from "react";
import { api } from "src/Services/Financial";
import { useParams } from 'react-router-dom';
import useGetOne from "src/Hooks/useGetOne";
import { View } from "src/Components/Admin/View";
import { SimpleContainer } from "src/Components/Layout/SimpleContainer";
import { SimpleShowLayout } from "src/Components/Admin/View/Layouts/SimpleShowLayout";
import { ListAction, EditAction, DeleteAction} from "src/Components/Admin/Actions";
import { TextField } from "src/Components/Admin/Fields/TextField"
import { DateField } from "src/Components/Admin/Fields/DateField"

export const CostcenterShow = () => {
    const resource = 'costcenters'
    const endpoint = 'costcenters'
    const { id } = useParams()
    const { data } = useGetOne(api, '/' + endpoint, id);

    return (
        <SimpleContainer 
            title={`resources.${resource}.name_one`}
            mdSize={900}
        >
            <View
                baseUrl={`/financial/costcenters`}
                backendUrl={"/" + endpoint}
                actions={[
                    <ListAction key={0} />,
                    <EditAction key={1} />,   
                    <DeleteAction key={2} api={api} successMessage={`resources.${resource}.actions.delete.success`}/>
                ]}
            >
                {data && (
                <SimpleShowLayout>
                    <TextField value={data.name} label={`resources.${resource}.fields.name`} />
                    <DateField value={data.created_at} label={`resources.${resource}.fields.created_at`} />
                </SimpleShowLayout>
                )}
            </View>
        </SimpleContainer>
    );
}