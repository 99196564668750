import * as React from 'react';
import { HiringForm } from './HiringForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

export const HiringCreate = () => 
{
    const navigate = useNavigate()

    const onSubmit = (formData) => {
        api.post('/hirings', formData.data)
            .then(result => {
                if (result?.status == 201) {
                    showSuccessMessage(i18next.t(`resources.hirings.actions.create.success`))
                    navigate(`/hr/hirings/${result.data.id}/show`)
                }
            })
    }

    return (
        <HiringForm
            data={{
                personId: null
            }}
            onSubmit={onSubmit}
        />  
    );
}