import React from 'react';
import { ROLE_ADMIN } from 'src/Enums/Roles';

interface UserIsProps {
    user?: any; 
    role: string | string[];
}

interface UserHaveProps {
    user?: any; 
    role: string | string[];
    children: React.ReactNode;
}

export const userIs = ({ user, role }:UserIsProps) => {

    let userData = user;

    if (!userData) {
        const userDataJson = localStorage.getItem('userData');

        if (!userDataJson) return false;

        userData = JSON.parse(userDataJson);
    }

    if (userData.role.name == ROLE_ADMIN) {
        return true
    }

    if (!Array.isArray(role)) {
        return (userData.role?.name === role)
    } else {
        return role.indexOf(userData.role.name) >= 0
    }
}

export const UserHave = ({ user, role, children }: UserHaveProps) => {
    if (userIs({ user, role })) {
        return <>{children}</>;
    }
    return null;
}