import { Admin, CustomRoutes, useAuthState, useGetIdentity } from 'react-admin';
import { Route } from 'react-router-dom';
import { LhinceLayout } from 'src/Components/Layout/Layout';
import { MyLoginPage } from 'src/Pages/Auth/LoginPage';
import dataProvider from 'src/Providers/DataProvider';
import i18nProvider from 'src/Providers/I18nProvider';
import authProvider from 'src/authProvider';
import { theme } from 'src/Components/Layout/Theme';
import ForbiddenPage from 'src/Components/Status/Forbidden';
import { HomePage } from 'src/Pages/Election/Home';
import { ConfigurationsPage } from 'src/Pages/Financial/Configurations';
import { ElectionRoutes } from './Elections';
import { FinancialRoutes } from './Financial';
import { StockRoutes } from './Stock';
import { HRRoutes } from './Hr';
import { MarketingRoutes } from './Marketing';
import SendEmailPage from 'src/Pages/Auth/SendEmailPage';
import AuthPage from 'src/Pages/Auth/AuthPage';
import { ReportRoutes } from './Reports';
import React, { useState } from 'react';
import { Footer } from 'src/Components/Layout/Footer';
import { ChangePassword } from 'src/Pages/Auth/ChangePassword';

export const LhinceRoutes = () => {
  const { authenticated } = useAuthState();
 
  return <React.Fragment>
    <Admin
      title="Lhince"
      dataProvider={dataProvider}
      layout={LhinceLayout}
      i18nProvider={i18nProvider}
      loginPage={AuthPage}
      theme={theme}
      authProvider={authProvider}
      requireAuth // Bloquear todas as páginas, somente navegar após o login.
    >
      <CustomRoutes>
        <Route path="/" element={<HomePage />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/reset-password" element={<SendEmailPage />} />
        <Route path="/configurations" element={<ConfigurationsPage />} />
        {authenticated && <Route path="/home" element={<HomePage />} />}
        {authenticated && (
          <Route path="/election/*" element={<ElectionRoutes />} />
        )}
        {authenticated && (
          <Route path="/financial/*" element={<FinancialRoutes />} />
        )}
        {authenticated && <Route path="/stock/*" element={<StockRoutes />} />}
        {authenticated && <Route path="/hr/*" element={<HRRoutes />} />}
        {authenticated && <Route path="/marketing/*" element={<MarketingRoutes />} />}
        {authenticated && <Route path="/reports/*" element={<ReportRoutes />} />}
        
        <Route path="/403" element={<ForbiddenPage />} />
      </CustomRoutes>
    </Admin>
    <Footer />
    
  </React.Fragment>
};
