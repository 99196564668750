import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';
import { PartyForm } from './PartyForm';

export const PartyCreate = () => {
  const navigate = useNavigate();

  const onSubmit = (formData) => {
    api.post('/electoral-party', formData.data).then((result) => {
      if (result?.status == 201) {
        showSuccessMessage(
          i18next.t(`resources.parties.actions.create.success`) +
            ':' +
            result.data.id
        );
        navigate(`/election/parties/${result.data.id}/show`);
      }
    });
  };

  return (
    <PartyForm
      data={{
        personId: null,
      }}
      onSubmit={onSubmit}
    />
  );
};
