import { Route, Routes } from "react-router-dom";
import { AccountCreate, AccountEdit, AccountList, AccountShow } from "src/Pages/Financial/Accounts";

export const AccountRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<AccountList />} />
          <Route path="/create" element={<AccountCreate />} />
          <Route path=":id" element={<AccountEdit />} />
          <Route path=":id/show" element={<AccountShow />} />
        </Routes>
    )
  }