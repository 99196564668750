import portugueseMessages from "@henriko/ra-language-portuguese";
import polyglotI18nProvider from "ra-i18n-polyglot";
import myMessages from "src/I18n/pt-BR";

const messages = {
  pt: Object.assign(myMessages, portugueseMessages)
}

const i18nProvider = polyglotI18nProvider(
        (locale) => messages[locale], "pt", 
        {
          allowMissing: true
        }
      );

export default i18nProvider;
