import { Route, Routes } from "react-router-dom";
import { BaseCreate, BaseEdit, BaseList, BaseShow } from "src/Pages/Election/Bases";

export const BaseRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<BaseList />} />
          <Route path="/create" element={<BaseCreate />} />
          <Route path=":id" element={<BaseEdit />} />
          <Route path=":id/show" element={<BaseShow />} />
        </Routes>
    )
  }