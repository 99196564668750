import React, { useEffect } from 'react';
import { ROLE_OPERATOR } from 'src/Enums/Roles';
import { HomeTabs, TAB_TOTAL, TAB_VOTERS, TAB_WORKERS, TAB_ZONES } from './Tabs';
import { useGetIdentity } from 'react-admin';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { api } from 'src/Services/Elections';
import { showErrorMessage } from 'src/Hooks/useMessages';
import { Title as LayoutTitle } from 'src/Components/Layout/Title';
import i18next from 'i18next';
import { VotersByPeriodPage } from './Voters';
import { VotersByPeriodWorkersPage } from './Workers';
import { VotersByPeriodZonesPage } from './Zones';
import { VotersByPeriodTotalsPage } from './Totals';

export const ReportByPeriodPage = () => 
{
  const [dateStart, setDateStart] = React.useState(
    localStorage.getItem('votersByPeriod.dateStart') ||
      new Date().toISOString().substring(0, 10)
  );
  const [dateEnd, setDateEnd] = React.useState(
    localStorage.getItem('votersByPeriod.dateEnd') ||
      new Date().toISOString().substring(0, 10)
  );
  const [activeDays, setActiveDays] = React.useState(
    (localStorage.getItem('votersByPeriod.activeDays') || 1) as number
  );
  const [total, setTotal] = React.useState<any>({
    total: 0,
    goal: 0
  });

  const { data: identity } = useGetIdentity();

  const [tab, setTab] = React.useState(
    parseInt(localStorage.getItem('votersByPeriod.tab') as string) || TAB_VOTERS
  );

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t('resources.home.messages.invalid_date'));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;

    return new Date(start).toISOString();
  };

  return (
    <>
      <SimpleContainer hasBreadcrumbs={false}>
        <LayoutTitle
          text={i18next.t('resources.reports.elections.votersByPeriod.name')}
        ></LayoutTitle>
        <HomeTabs user={identity} tab={tab} setTab={setTab} />
        {tab == TAB_WORKERS && identity?.role?.name != ROLE_OPERATOR && (
          <VotersByPeriodWorkersPage 
            dateStart={dateStart} 
            setDateStart={setDateStart} 
            dateEnd={dateEnd} 
            setDateEnd={setDateEnd}
            activeDays={activeDays}
            setActiveDays={setActiveDays}
          />
        )}
        {tab == TAB_VOTERS && (
          <VotersByPeriodPage 
            dateStart={dateStart} 
            setDateStart={setDateStart} 
            dateEnd={dateEnd} 
            setDateEnd={setDateEnd}
          />
        )}
        {tab == TAB_ZONES && (
          <VotersByPeriodZonesPage 
            dateStart={dateStart} 
            setDateStart={setDateStart} 
            dateEnd={dateEnd} 
            setDateEnd={setDateEnd}
          />
        )}
        {tab == TAB_TOTAL && (
          <VotersByPeriodTotalsPage 
            dateStart={dateStart} 
            setDateStart={setDateStart} 
            dateEnd={dateEnd} 
            setDateEnd={setDateEnd}
          />
        )}

      </SimpleContainer>
    </>
  );
};
