import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from 'chartjs-plugin-annotation';
import { api } from "src/Services/Elections";
import {
  alpha
} from '@mui/material';

interface IndividualBarChartProps {
  data: any;
}

Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);

export const IndividualBarChart = ({ data }: IndividualBarChartProps) => 
{
    const [options, setOptions] = useState<any>({});
    const [barData, setBarData] = useState<any>({
      datasets: [],
      labels: []
    })

    const colors = [
      '#2f68ba',
      '#ff0000',
      '#578441',
      '#FF9F40B2',
      '#008080B2',
      '#800080B2',
      '#9d3bf7',
      '#ffff00',
      '#008000',
      '#000080B2',
    ];

    useEffect(() => {
      setOptions({
        animation: false,
        responsive: true,
        indexAxis: 'y',
        maintainAspectRatio: false,
        scales: {
          x: {
            grid:{
                color: '#bbb',
            },
            border: {
                dash: [2,4],
            },  
            ticks: {
              callback: function(value) {
                  return value + '%';
              }
            }
          },
          y: {
            grid: {
                color: '#fff',
            },
            border: {
              dash: [2,4],
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: data[0].name
          },
          legend: {
            display: false
          },
          datalabels: {
            color: "white",
            formatter: function(value) {
              return parseFloat(value).toFixed(2) + '%';
            },
            anchor: "end",
            offset: 0,
            align: function(context) {
              return context.dataset.data[context.dataIndex] < 15 ? 'end' : 'start';
            },
            font: {
              weight: 'bold',
              size: 14,
            }
          }
        }
      })
  
      const researches = data[0].researches.map(research => research.research_date)
  
      if (data.length) {
        setBarData({
          labels: researches,
          datasets: [
            {
              label: data[0].candidateName,
              data: data[0].researches.map(research => research.votes),
              backgroundColor: colors.map(color => alpha(color, 0.7)),
              borderColor: 'rgb(54, 162, 235)',
              borderRadius: 8,
              barThickness: 40,
              borderWidth: 1
            }
          ]
        })
      }
    }, [data])

    return (  
      data && (
        <div className="chart-container" style={{ position: "relative", margin: "0 auto", width: "80vw", height: "40vh" }}>
          <Bar data={barData} options={options} plugins={[ChartDataLabels]}  />
        </div>
      )
    )
}
