import * as React from "react";
import { api } from "src/Services/Financial";
import { Link, useParams } from 'react-router-dom';
import useGetOne from "src/Hooks/useGetOne";
import { View } from "src/Components/Admin/View";
import { SimpleContainer } from "src/Components/Layout/SimpleContainer";
import { SimpleShowLayout } from "src/Components/Admin/View/Layouts/SimpleShowLayout";
import { ListAction } from "src/Components/Admin/Actions/ListAction";
import { EditAction } from "src/Components/Admin/Actions/EditAction";
import { DeleteAction } from "src/Components/Admin/Actions/DeleteAction";
import { TextField } from "src/Components/Admin/Fields/TextField"
import { DateField } from "src/Components/Admin/Fields/DateField"
import { OptionsField } from "src/Components/Admin/Fields/OptionsField";
import { paymentTypes } from "src/Enums/HiringPersonPaymentTypes";
import { DecimalField } from "src/Components/Admin/Fields/DecimalField";
import { HiringPersonPaymentList } from "./HiringPersonPaymentList";
import { UserHave } from "src/Components/Auth/UserHave";
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles";
import DoneIcon from '@mui/icons-material/Done';
import i18next from "i18next";
import { HiringPersonStatusOptions } from "src/Enums/HiringPersonStatuses";

export const HiringPersonShow = () => {
    const resource = 'hiringPersons'
    const { hiringId, id } = useParams()
    const basePath = `/hr/hirings/${hiringId}/show`
    const { data: hiringData } = useGetOne(api, `/hirings`, hiringId);
    const { data } = useGetOne(api, `/hirings/${hiringId}/persons`, id);
    
    return (
        <SimpleContainer 
            title={`resources.${resource}.name_one`}
            mdSize={900}
        >
            <View
                baseUrl={basePath}
                backendUrl={`/hirings/${hiringId}/persons/${id}`}
                actions={[
                    <ListAction key={0} url={basePath} />,
                    (hiringData?.status == 'PENDING') && <EditAction key={1} url={`${basePath}/persons`} />,   
                    (hiringData?.status == 'PENDING') && <DeleteAction key={2} api={api} successMessage={`resources.${resource}.actions.delete.success`}/>,
                    <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]} key={3}> 
                        {hiringData && (hiringData?.status == 'PENDING') &&
                            <Link 
                                to={`/hr/hirings/${hiringId}/show/persons/${id}/complete`} 
                                title={i18next.t(`resources.${resource}.actions.complete`)} 
                            >
                                <DoneIcon />
                            </Link>
                        }
                    </UserHave>
                    
                ]}
            >
                <SimpleShowLayout>
                    <TextField value={data?.person.fullName} label={`resources.${resource}.fields.personId`} />
                    <TextField value={data?.position.name} label={`resources.${resource}.fields.positionId`} />
                    <OptionsField value={data?.payment_type} label={`resources.${resource}.fields.payment_type`} options={paymentTypes} />
                    <DateField value={data?.due_date} label={`resources.${resource}.fields.due_date`} />
                    <DecimalField hasSymbol={true} value={data?.base_amount} label={`resources.${resource}.fields.base_amount`} />
                    <OptionsField value={data?.status} options={HiringPersonStatusOptions} label={`resources.${resource}.fields.status`}  />
                    <TextField value={data?.installments} label={`resources.${resource}.fields.installments`} />
                    <DateField showTime value={data?.created_at} label={`resources.${resource}.fields.created_at`} />
                    <DateField showTime value={data?.updated_at} label={`resources.${resource}.fields.updated_at`} />
                </SimpleShowLayout>

                {data && hiringData &&
                    <HiringPersonPaymentList
                        hiringId={hiringData.id}
                        hiringPersonId={data.id}
                        isPending={hiringData.status !== 'APPROVED'}
                    />
                }
            </View>
        </SimpleContainer>
    );
}