import { Route, Routes } from "react-router-dom";
import { MaterialTypeCreate, MaterialTypeEdit, MaterialTypeList, MaterialTypeShow } from "src/Pages/Stock/MaterialTypes";

export const MaterialTypeRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<MaterialTypeList />} />
          <Route path="/create" element={<MaterialTypeCreate />} />
          <Route path=":id" element={<MaterialTypeEdit />} />
          <Route path=":id/show" element={<MaterialTypeShow />} />
        </Routes>
    )
  }