import * as React from 'react';
import { HiringForm } from './HiringForm';
import { api } from 'src/Services/Financial';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';
import i18next from 'i18next';

export const HiringEdit = () => {
  const { id } = useParams();
  const { data } = useGetOne(api, '/hirings', id);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data && data.status == 'APPROVED') {
      throw new Error(
        i18next.t('resources.hirings.errors.approved_not_updated')
      );
    }
  }, [data]);

  const onSubmit = formData => {
    api.put(`/hirings/${id}`, formData.data).then(result => {
      if (result?.status == 200) {
        navigate(`/hr/hirings/${result.data.id}/show`);
        showSuccessMessage(`resources.hirings.actions.edit.success`);
      }
    });
  };

  return <>{data && <HiringForm data={data} onSubmit={onSubmit} />}</>;
};
