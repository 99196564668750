import * as React from 'react';
import { useState } from 'react'
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { Controller, useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import useGetTree from 'src/Hooks/useGetTree';
import { Cascader } from 'rsuite';
import i18next from 'i18next';

interface DonationFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const ExpenseForm = ({ data, onSubmit }: DonationFormProps) => {
    const resource = 'expenses'
    const { control } = useForm();
    const { options: types } = useGetManyOptions(api, '/expense-types')
    const { options: states } = useGetManyOptions(api, '/state', {}, 'id', 'uf')
    const [uf, setUf] = useState(data ? data.stateId : '')
    const { options: cities } = useGetManyOptions(api, '/city', { uf })
    const [cityId, setCityId] = useState(data ? data.cityId : '')
    const { options: electoralZones } = useGetManyOptions(api, '/electoral-zones', { cityId }, 'id', 'identifier')

    const onSubmitFn = (formData) => 
    {
        onSubmit(formData)
    }

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmitFn}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {types &&
                                <ReferenceInput
                                    name={"typeId"}
                                    control={control}
                                    label={`resources.${resource}.fields.typeId`}
                                    defaultValue={data?.typeId}
                                    options={types}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"description"}
                                control={control}
                                label={`resources.${resource}.fields.description`}
                                defaultValue={data?.description}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={6} md={3}>
                            <DateInput
                                name="scheduled_date" 
                                control={control}
                                label={`resources.${resource}.fields.scheduled_date`}
                                defaultValue={data?.scheduled_date}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DecimalInput
                                name={"amount"}
                                control={control}
                                label={`resources.${resource}.fields.amount`}
                                defaultValue={data?.amount}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DecimalInput
                                name={"quantity"}
                                control={control}
                                label={`resources.${resource}.fields.quantity`}
                                defaultValue={data?.quantity}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"aditionalInfo"}
                                control={control}
                                label={`resources.${resource}.fields.aditionalInfo`}
                                defaultValue={data?.aditionalInfo}
                            />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={2}>
                            {states && (
                                <ReferenceInput 
                                    name="stateId" 
                                    control={control}
                                    options={states}
                                    label={`resources.${resource}.fields.stateId`}
                                    defaultValue={data.stateId}
                                    onChange={value => { 
                                        setUf(value)
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {cities && (cities.length > 0) && (
                                <ReferenceInput
                                    name="cityId" 
                                    control={control}
                                    options={cities}
                                    label={`resources.${resource}.fields.cityId`}
                                    defaultValue={data.cityId}
                                    onChange={value => { 
                                        setCityId(value)
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {electoralZones && (electoralZones.length > 0) && (
                                <ReferenceInput
                                    name="electoralZoneId" 
                                    control={control}
                                    options={electoralZones}
                                    label={`resources.${resource}.fields.electoralZoneId`}
                                    defaultValue={data.electoralZoneId}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </SimpleContainer>
    );
}