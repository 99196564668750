import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import { Menu } from 'src/Components/Election/Menu';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { DocumentInput } from 'src/Components/Inputs/DocumentInput';
import { PhoneInput } from 'src/Components/Inputs/PhoneInput';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { api } from 'src/Services/Elections';

interface CandidateFormProps {
  data: any;
  onSubmit: (formData: FormData) => void;
}

const GridRow = (props: { children: React.ReactNode }) => (
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
    {props.children}
  </Grid>
);

export const CandidateForm = ({ data, onSubmit }: CandidateFormProps) => {
  const resource = 'candidates';
  const { control } = useForm();
  const [tab, setTab] = React.useState(5);

  const { options: zones } = useGetManyOptions(api, '/zones');
  const { options: cities } = useGetManyOptions(api, '/city');
  const { options: genders } = useGetManyOptions(api, '/gender');
  const { options: positions } = useGetManyOptions(api, '/candidate-position');
  const { options: parties } = useGetManyOptions(
    api,
    '/electoral-party',
    {},
    'id',
    'resumeName'
  );
  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={1200}>
      <Menu tab={tab} setTab={setTab} />
      {data && (
        <Form method="post" control={control} onSubmit={onSubmit}>
          <GridRow>
            <Grid item xs={12} md={6}>
              <CustomInput
                name={'name'}
                control={control}
                label={`resources.${resource}.fields.name`}
                defaultValue={data?.name}
              />
            </Grid>
          </GridRow>

          <GridRow>
            <Grid item xs={12} md={3}>
              <DocumentInput
                name={'cpf'}
                control={control}
                label={`resources.${resource}.fields.cpf`}
                defaultValue={data?.cpf}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              {genders && (
                <ReferenceInput
                  name={'genderId'}
                  control={control}
                  label={`resources.${resource}.fields.gender`}
                  defaultValue={data?.gender?.id}
                  options={genders}
                />
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <PhoneInput
                name={'telphone'}
                control={control}
                label={`resources.${resource}.fields.telphone`}
                defaultValue={data?.telphone}
              />
            </Grid>
          </GridRow>

          <GridRow>
            <Grid item xs={12} md={8}>
              {positions && (
                <ReferenceInput
                  name={'candidatePositionId'}
                  control={control}
                  label={`resources.${resource}.fields.candidatePositionId`}
                  defaultValue={data?.candidatePosition?.id}
                  options={positions}
                />
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              {parties && (
                <ReferenceInput
                  name={'electoralPartyId'}
                  control={control}
                  label={`resources.${resource}.fields.electoralPartyId`}
                  defaultValue={data?.electoralParty?.id}
                  options={parties}
                />
              )}
            </Grid>

            <Grid item xs={12} md={8}>
              {cities && (
                <ReferenceInput
                  name={'cityId'}
                  control={control}
                  label={`resources.${resource}.fields.city`}
                  defaultValue={data?.city?.id}
                  options={cities}
                />
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              {zones && (
                <ReferenceInput
                  name={'zoneId'}
                  control={control}
                  label={`resources.${resource}.fields.zone`}
                  defaultValue={data?.zone?.id}
                  options={zones}
                />
              )}
            </Grid>
          </GridRow>

          <GridRow>
            <Grid item xs={12} md={12}>
              <CustomInput
                name={'email'}
                control={control}
                label={`resources.${resource}.fields.email`}
                defaultValue={data?.email}
              />
            </Grid>
          </GridRow>
        </Form>
      )}
    </SimpleContainer>
  );
};
