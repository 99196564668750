import axios from 'axios';
import { handleError } from 'src/Services/Handlers/handleError';

export class Api {
  protected axiosInstance;
  private apiUrl;
  private authToken;

  constructor(apiUrl) {
    const broadcastChannel = new BroadcastChannel('auth');
    broadcastChannel.onmessage = eventMessage => {
      const data = eventMessage.data;

      if (data.type == 'signIn') {
        this.authToken = data.token;
        this.initAxios();
      }
    };
    this.authToken = localStorage.getItem('token');
    this.apiUrl = apiUrl;
    this.initAxios();
  }

  getApiUrl() {
    return this.apiUrl;
  }

  getUrl(endpoint) {
    return this.apiUrl + endpoint;
  }

  initAxios() {
    this.authToken = localStorage.getItem('token');
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        'x-custom-lang': localStorage.getItem('language') ?? 'pt-BR',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://lhince.com',
        'Access-Control-Allow-Methods': 'GET, HEAD, PUT, PATCH, POST, DELETE',
        'Access-Control-Allow-Credentials': 'true'
      }
    });
  }

  async get(endpoint, options = {}) {
    this.initAxios();

    return await this.axiosInstance.get(endpoint, options)
      .catch (error => handleError(error));
  }

  async post(endpoint, data, options = {}) {
    this.initAxios();
    return await this.axiosInstance
      .post(endpoint, data, options)
      .catch(error => handleError(error));
  }

  async put(endpoint, data = {}, options = {}) {
    this.initAxios();
    return await this.axiosInstance
      .put(endpoint, data, options)
      .catch(error => handleError(error));
  }

  async patch(endpoint, data = {}, options = {}) {
    this.initAxios();
    return await this.axiosInstance
      .patch(endpoint, data, options)
      .catch(error => handleError(error));
  }

  async delete(endpoint) {
    this.initAxios();
    return await this.axiosInstance
      .delete(endpoint)
      .catch(error => handleError(error));
  }

  async handleRequestError(error) {
    handleError(error);
  }
}
