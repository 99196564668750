import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom'

interface IEditButton {
    handleEdit?: () => void,
    url?: string
}

export const EditButton = (props: IEditButton) => {

    return <Link to={props.url ?? ''}>
            <EditIcon
                onClick={props.handleEdit}
                sx={{ 
                    margin: 'auto',
                    fontSize: 18 
                }}
            />
        </Link>
}