import * as React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { api } from 'src/Services/Financial'
import { useForm } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import i18next from 'i18next';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import useGetOne from 'src/Hooks/useGetOne';
import { formatCurrency } from 'src/Utils/Formatters';

interface ChargeItemFormProps {
    chargeId: string;
    id: string;
    onCancel: () => void
}

export const ChargeItemForm = ({ chargeId, id, onCancel }: ChargeItemFormProps) => {
    const resource = 'chargeItems'
    const { control, watch } = useForm();
    const { data } = useGetOne(api, `charges/${chargeId}/items`, id)
    const watchFields = watch(["quantity", "unitPrice"]);
    const [total, setTotal] = React.useState(0)

    const onSubmitFn = (formData) => {
        formData.data.chargeId = chargeId
        formData.data.materialId = data.materialId
        api.put(`/charges/${chargeId}/items/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    showSuccessMessage(i18next.t(`resources.${resource}.actions.edit.success`))
                }
            })
    }

    React.useEffect(() => {
        const [quantity, unitPrice] = watchFields    
        setTotal(
            parseFloat(quantity && quantity.length ? quantity : 0) * 
            parseFloat(unitPrice && unitPrice.length ? unitPrice : 0)
        )
      }, [watchFields]);

      React.useEffect(() => {
        if (data)
            setTotal(parseFloat(data.unitPrice) * parseFloat(data.quantity))
      }, [data]);

    return (
        <>
        {!data 
            ? <Skeleton animation="wave" />
            : (
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmitFn}
                onCancel={onCancel}
            >
                <Alert severity="info">Edite os dados do item</Alert>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" gutterBottom>
                            {data?.material.name}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {data?.material.description}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={2}>
                        <DecimalInput
                            name={"quantity"}
                            control={control}
                            label={`resources.${resource}.fields.quantity`}
                            defaultValue={data?.quantity as number}
                        />
                    </Grid>

                    <Grid item xs={12} md={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <DecimalInput
                            name={"unitPrice"}
                            control={control}
                            label={`resources.${resource}.fields.unitPrice`}
                            defaultValue={data?.unitPrice as number}
                            currencySymbol={'R$'}
                        />
                    </Grid>
                </Grid>

                <hr style={{ opacity: 1 }} />

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography variant="body1">
                            Total do item: 
                        </Typography>
                        <Typography variant="body1" style={{ marginLeft: 4, fontWeight: "bold"}}>
                            {formatCurrency(total)}
                        </Typography>
                    </Grid>
                </Grid>
            </Form>
            )
        }
        </>
    )
}