import list from './list.json';
import calendarRange from './calendarRange.json';

const messages = {
  others: 'Outros',
  no_research: 'Não há pesquisas a exibir',
  description: 'Descrição',
  invalid_resource: 'Recurso inválido',
  loading: 'Carregando...',
  list,
  calendarRange
};

export default messages;
