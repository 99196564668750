import * as React from "react";
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

interface EditActionProps {
    url?: string;
    baseUrl?: string;
    id?: string;
}

export const EditAction = ({ url, baseUrl, id }: EditActionProps) => {

    if (!url) url = baseUrl;

    if (!id) {
        const params = useParams()
        id = params.id
    } 

    return (
        <Link to={url + '/' + id}>
            <EditIcon/>
        </Link>
    );
}

EditAction.defaultProps = {
    baseUrl: ''
}