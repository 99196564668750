import * as React from "react";
import { api } from "src/Services/Financial";
import { useParams } from 'react-router-dom';
import useGetOne from "src/Hooks/useGetOne";
import { View } from "src/Components/Admin/View";
import { SimpleContainer } from "src/Components/Layout/SimpleContainer";
import { SimpleShowLayout } from "src/Components/Admin/View/Layouts/SimpleShowLayout";
import { ListAction } from "src/Components/Admin/Actions/ListAction";
import { EditAction } from "src/Components/Admin/Actions/EditAction";
import { DeleteAction } from "src/Components/Admin/Actions/DeleteAction";
import { TextField } from "src/Components/Admin/Fields/TextField"
import { DateField } from "src/Components/Admin/Fields/DateField"

export const PositionShow = () => {
    const resource = 'positions'
    const { id } = useParams()
    const { data } = useGetOne(api, '/positions', id);
    
    return (
        <SimpleContainer 
            title={`resources.${resource}.name_one`}
            mdSize={900}
        >
            <View
                baseUrl="/hr/positions"
                backendUrl="/positions"
                actions={[
                    <ListAction key={0} />,
                    <EditAction key={1} />,   
                    <DeleteAction key={2} api={api} successMessage={`resources.${resource}.actions.delete.success`}/>
                ]}
            >
                <SimpleShowLayout>
                    <TextField value={data?.name} label={`resources.${resource}.fields.name`} />
                    <TextField value={data?.description} label={`resources.${resource}.fields.description`} />
                    <DateField showTime value={data?.created_at} label={`resources.${resource}.fields.created_at`} />
                </SimpleShowLayout>
            </View>
        </SimpleContainer>
    );
}