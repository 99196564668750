import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import background from 'src/Assets/background.png';

import Logo from 'src/Assets/logo';
import { updatePassword } from 'src/Services/ResetPassword';
import { Alert, Snackbar } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';

const ResetPasswordPage = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  const defaultTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderColor: '#FF6A46',
            width: '100%',
            backgroundColor: '#FFF',
            borderRadius: '6px',
            height: '3rem',
            '& .MuiInputBase-input': {
              backgroundColor: '#fff',
              borderRadius: 8,
              paddingTop: 0,
              paddingBottom: 0,
              height: '3rem'
            }
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              textTransform: 'none',
              height: '2.5rem',
              fontWeight: 700,
              borderRadius: 8,
              background: '#FF6A46',
              '&:hover': {
                backgroundColor: '#f18468'
              },
              '&:focus': {
                backgroundColor: '#f18468'
              }
            }
          }
        ]
      }
    }
  });

  function isValidPassword(password: string) {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }

  const handleSubmit = () => {
    if (isValidPassword(password) && password === confirmPassword) {
      const email = localStorage.getItem('resetEmail');
      const body = {
        email: email,
        password: password
      };
      setIsLoading(true);
      updatePassword(body)
        .then(() => props.changePage('LOGIN'))
        .catch(() => {
          setErrorMessage('A senha escolhida não é válida');
          setOpenError(true);
        })
        .finally(() => setIsLoading(false));
    } else {
      setErrorMessage('A senha escolhida não é válida');
      setOpenError(true);
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setShowConfirmationPassword(show => !show);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={5}
          md={8}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundColor: '#ff3402',
            backgroundSize: 'cover'
          }}
        />

        <Grid
          item
          xs={12}
          sm={7}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{ backgroundColor: '#323B46', color: '#fff' }}
        >
          <Box
            sx={{
              my: 30,
              mx: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Logo />

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" gutterBottom>
                  Digite a sua senha
                </Typography>
                <OutlinedInput
                  id="outlined-basic"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" gutterBottom>
                  Confirme sua senha
                </Typography>
                <OutlinedInput
                  id="outlined-basic"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  type={showConfirmationPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleSubmit}
                >
                  Confirmar
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="text"
                  sx={{ mt: 2, mb: 2, color: '#FF6A46' }}
                  onClick={() => props.changePage('LOGIN')}
                >
                  Voltar ao login
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key={'bottom' + 'right'}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;
