import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment'; 
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import { Form } from 'src/Components/Admin/Form';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { Controller, useForm } from 'react-hook-form';
import i18next from 'i18next';
import useGetTree from 'src/Hooks/useGetTree';
import { api } from 'src/Services/Financial';
import { Cascader } from 'rsuite';
import { showErrorMessage, showSuccessMessage } from 'src/Hooks/useMessages';
import useLoader from 'src/Hooks/useLoader';
import { handleError } from 'src/Services/Handlers/handleError';

export const StockMovementReport = () => {
    const resource = 'stock.movements'
    const { control } = useForm();
    const { config } = React.useContext(ConfigContext);
    const { accountId } = config ?? { accountId: null }
    const { data: places } = useGetTree(api, '/places/tree')
    const loader = useLoader()

    const onSubmit = async(form) => 
    {
        const dueDate = moment(form.data.dueDate)

        loader.showLoader()

        api.get(`/reports/stock/movements`, {
            params: {
                accountId,
                placeId: form.data.placeId,
                dueDate: dueDate.toDate()
            },
            responseType: 'arraybuffer'
        })
        .then(async response => 
        {
            if (response) {   
                loader.hideLoader()
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.setAttribute('target', '_blank');
                a.download = 'stock_movements.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

                showSuccessMessage(`resources.reports.${resource}.messages.success`)
            }
        }).
        catch(error => {
            loader.hideLoader()
            console.log(error)
            handleError(error)
        })
            
    }

    return (
        <SimpleContainer
          title={`resources.reports.${resource}.name`}
        >
            <loader.Loader/>
            <Form
                method='post' 
                control={control}
                onSubmit={onSubmit}
                submitButtonLabel='actions.search'
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={12}>
                    {places &&
                        <Controller
                            name={"placeId"}
                            control={control}
                            render={({ field }) => (
                                <Cascader
                                    parentSelectable
                                    block={true}
                                    menuWidth={200}
                                    style={{ width: '100%' }}
                                    placeholder={i18next.t(`resources.reports.${resource}.fields.placeId`)}
                                    data={places} 
                                    onChange={field.onChange}
                                    value={field.value}
                                />
                            )}
                        />
                    }
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={6} md={2}>
                        <DateInput 
                            control={control} 
                            name="dueDate" 
                            label={`resources.reports.${resource}.fields.dueDate`}
                            hasTime={true}
                            rules={{
                                required: i18next.t(`resources.reports.${resource}.rules.dueDate.required`)
                            }}
                        />
                    </Grid>
                   
                </Grid>
            </Form>

    </SimpleContainer>
    );
}