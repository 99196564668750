import * as Styled from 'src/Pages/Election/styles';
import i18next from 'i18next';
import React from 'react';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { useForm } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import { Menu } from 'src/Components/Election/Menu';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { DocumentInput } from 'src/Components/Inputs/DocumentInput';
import { PasswordInput } from 'src/Components/Inputs/PasswordInput';
import { PhoneInput } from 'src/Components/Inputs/PhoneInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { ROLE_ADMIN, ROLE_BASE_MANAGER, ROLE_OPERATOR, ROLE_SUPERVISOR } from 'src/Enums/Roles';
import { api } from 'src/Services/Elections';
import { showErrorMessage } from 'src/Hooks/useMessages';

interface UserFormProps {
  data?: any;
  onSubmit: (formData: FormData) => void;
  edit?: boolean
}

export const UserForm = ({ data, onSubmit, edit }: UserFormProps) => {
  const resource = 'users';
  const [tab, setTab] = React.useState(0);
  const { control, watch } = useForm();
  const [baseId, setBaseId] = React.useState(data?.baseId);
  const { options: genders } = useGetManyOptions(api, '/gender');
  const { options: zones } = useGetManyOptions(api, '/zones');
  const { options: city } = useGetManyOptions(api, '/city');
  const { options: roles } = useGetManyOptions(api, '/role', {
    filterLevels: true
  });
  const { options: users } = useGetManyOptions(
    api,
    '/users',
    { baseId: baseId, roleName: ROLE_SUPERVISOR }
  );
  const { options: bases } = useGetManyOptions(api, '/bases');
  const { options: campaigns } = useGetManyOptions(api, '/campaigns');
  
  const roleId = watch('roleId', data?.role?.id);
  const roleOperator = roles?.find(item => item.label === ROLE_OPERATOR)?.value;

  const getRoleId = (roleName) => {
    return roles?.find(item => item.label === roleName)?.value;
  }

  React.useEffect(() => {
    setBaseId(data?.baseId);
  }, [data])

  const validateAndSubmit = (formData) => {

    if (formData.data.password && formData.data.confirmPassword && formData.data.password != formData.data.confirmPassword) {
      showErrorMessage(`resources.${resource}.validations.confirmPassword`)
      return false;
    }
  
    onSubmit(formData);
  }

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={900}>
      <Menu tab={tab} setTab={setTab} />
      {data && (
        <Form method="post" control={control} onSubmit={validateAndSubmit}>
          <CustomInput
            name={'name'}
            control={control}
            label={`resources.${resource}.fields.username`}
            defaultValue={data?.name}
          />
          <Styled.InputsContainer>
            <Styled.InputRow>
              <CustomInput
                name={'email'}
                control={control}
                label={`resources.${resource}.fields.email`}
                defaultValue={data?.email}
              />
            </Styled.InputRow>
          </Styled.InputsContainer>
          <Styled.InputsContainer>
            <Styled.InputRow>
              <DocumentInput
                name="cpf"
                control={control}
                label={`resources.${resource}.fields.cpf`}
                defaultValue={data?.cpf}
              />
              <DateInput
                name={'birthdate'}
                control={control}
                label={`resources.${resource}.fields.birthdate`}
                defaultValue={data?.birthdate}
              />
              <PhoneInput
                name={'telphone'}
                control={control}
                label={`resources.${resource}.fields.telphone`}
                defaultValue={data?.telphone}
              />
            </Styled.InputRow>
          </Styled.InputsContainer>
          {!edit &&
            <Styled.InputsContainer>
              <Styled.InputRow>
                <PasswordInput
                  name="password"
                  control={control}
                  label={`resources.${resource}.fields.password`}
                  defaultValue={data?.password}
                />
                <PasswordInput
                  name="confirmPassword"
                  control={control}
                  label={`resources.${resource}.fields.confirmPassword`}
                />
              </Styled.InputRow>
            </Styled.InputsContainer>
          }
          <Styled.InputsContainer>
            <Styled.InputRow>
              {genders && (
                <ReferenceInput
                  name={'genderId'}
                  control={control}
                  label={`resources.${resource}.fields.gender`}
                  defaultValue={data?.gender?.id}
                  options={genders}
                />
              )}
              {city && (
                <ReferenceInput
                  name={'cityId'}
                  control={control}
                  label={`resources.${resource}.fields.city`}
                  defaultValue={data?.city?.id}
                  options={city}
                />
              )}
              {zones && (
                <ReferenceInput
                  name={'zoneId'}
                  control={control}
                  label={`resources.${resource}.fields.zone`}
                  defaultValue={data?.zone?.id}
                  options={zones}
                />
              )}
            </Styled.InputRow>
          </Styled.InputsContainer>
          <Styled.InputsContainer>
            <Styled.InputRow>
              {roles && (
                <ReferenceInput
                  name={'roleId'}
                  control={control}
                  label={`resources.${resource}.fields.role`}
                  defaultValue={data?.role?.id}
                  options={roles}
                />
              )}
            </Styled.InputRow>
          </Styled.InputsContainer>
          {!edit && roles && roleId && campaigns &&
            ![getRoleId(ROLE_ADMIN)].includes(roleId) && (
            <Styled.InputsContainer>
              <Styled.InputRow>
                <ReferenceInput
                  name={'campaignId'}
                  control={control}
                  label={`resources.${resource}.fields.campaignId`}
                  options={campaigns}
                />
              </Styled.InputRow>
            </Styled.InputsContainer>
          )}
          {console.log(data)}
          {console.log(baseId)}
          {bases && roleId && [getRoleId(ROLE_BASE_MANAGER), getRoleId(ROLE_SUPERVISOR), getRoleId(ROLE_OPERATOR)].includes(roleId) && (
            <Styled.InputsContainer>
              <Styled.InputRow>
                <ReferenceInput
                  name={'baseId'}
                  control={control}
                  label={`resources.${resource}.fields.baseId`}
                  defaultValue={baseId}
                  options={bases}
                  onChange={value => {
                    /*setUserFilters({
                      baseId: value
                    });*/
                    setBaseId(value)
                  }}
                />
              </Styled.InputRow>
            </Styled.InputsContainer>
          )}
          {data && users && baseId && roleId == roleOperator && (
            <Styled.InputsContainer>
              <Styled.InputRow>
                <ReferenceInput
                  name={'userManagerId'}
                  control={control}
                  label={`resources.${resource}.fields.userManagerId`}
                  defaultValue={data.userManager ? data.userManager.id : null}
                  options={users}
                />
              </Styled.InputRow>
            </Styled.InputsContainer>
          )}
          <Styled.InputsContainer>
            <Styled.InputRow>
              <CustomInput
                name={'goal'}
                control={control}
                label={`resources.${resource}.fields.goal`}
                placeholder={i18next.t(
                  `resources.${resource}.placeholders.goal`
                )}
                defaultValue={data?.goal}
                type={'number'}
                inputProps={{ maxLength: 4 }}
              />

              <CustomInput
                name={'totalGoal'}
                control={control}
                label={`resources.${resource}.fields.totalGoal`}
                placeholder={i18next.t(
                  `resources.${resource}.placeholders.totalGoal`
                )}
                defaultValue={data?.totalGoal}
                type={'number'}
                inputProps={{ maxLength: 4 }}
              />
            </Styled.InputRow>
          </Styled.InputsContainer>
        </Form>
      )}
    </SimpleContainer>
  );
};
