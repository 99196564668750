import * as React from 'react';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';

interface HiringPersonFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const HiringPersonForm = ({ data, onSubmit }: HiringPersonFormProps) => {
    const resource = 'hiringPersons'
    const { control } = useForm();
    const { options: positions } = useGetManyOptions(api, '/positions')
    const { options: persons } = useGetManyOptions(api, '/persons', { }, 'id', 'fullName')

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={900}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmit}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {persons &&
                                <ReferenceInput
                                    name={"personId"}
                                    control={control}
                                    label={`resources.${resource}.fields.personId`}
                                    defaultValue={data?.personId}
                                    options={persons}
                                />
                            }
                        </Grid>
                    </Grid>
                   
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={12}>
                            {positions &&
                                <ReferenceInput
                                    name={"positionId"}
                                    control={control}
                                    label={`resources.${resource}.fields.positionId`}
                                    defaultValue={data?.positionId}
                                    options={positions}
                                />
                            }
                        </Grid>
                    </Grid>

                </Form>
            )}
        </SimpleContainer>
    );
}