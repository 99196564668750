import { useNavigate } from 'react-router-dom';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { UserForm } from 'src/Pages/Election/Users/UserForm';
import { api } from 'src/Services/Elections';
import { removePhoneMask } from 'src/Utils/Formatters';

export const UserCreate = () => {
  const navigate = useNavigate();
  const resource = 'users';
  const endpoint = '/users';

  const onSubmit = formData => {
    let cleanedTelphone = '';
    if (formData.data.telphone)
      cleanedTelphone = removePhoneMask(formData.data.telphone);

    const data = {
      ...formData.data,
      telphone: cleanedTelphone
    };

    api.post(endpoint, data).then(result => {
      if (result?.status === 201) {
        showSuccessMessage(
          `resources.${resource}.actions.create.success`
        );
        navigate(`/election/users/${result.data.id}/show`);
      }
    });
  };

  return <UserForm data={{ id: null }} onSubmit={onSubmit} />;
};
