import { useEffect, useState } from 'react';

const useGetOne = (api, endpoint, id) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ data, setData ] = useState<any | null>(null);

    async function fetchData() {
        if (id) {
            api.get(`${endpoint}/${id}`)
                .then(response => {
                    setLoading(false)

                    if (response.status == 200){
                        setData(response.data)
                    }
                    else throw new Error('Erro ao buscar dado');
                })
                .catch(error => {
                    setError(error)
                    setLoading(false)
                }
            )
        }
    }

    useEffect(() => {
        fetchData()
    }, [endpoint, id]);
    
    return { data, setData, fetchData, loading, error }
}

export default useGetOne