import React from 'react';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import Typography from '@mui/material/Typography';
import * as Styled from './styles';
import { Group, Check, Close, PriceChange, PersonAdd } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AccountUsers } from './Users';
import UsersAddDialog from './Users/form';

interface UserCampaignAccountsProps {
  userId: string;
  campaignId: number;
}

export function UserCampaignAccounts({ userId, campaignId }: UserCampaignAccountsProps) 
{
  const { data: accounts, setData: setAccounts, fetchData: fetchAccounts } = useInfiniteFetch(api, '/accounts', 0, { campaignId });
  const { data: userAccounts, setData: setUserAccounts, fetchData: fetchUserAccounts } = useInfiniteFetch(api, `/users/${userId}/accounts`, 0);
  const [usersBox, setUsersBox] = React.useState<string[]>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  
  const vincular = (accountId: string) => {
    if (!confirm('Confirma Vinculamento da Conta para o Usuário?')) return false;
  
    api.post(`/users/${userId}/accounts`, {
      userId,
      accountIds: [accountId]
    }).then(result => {
      setUserAccounts([])
      fetchUserAccounts();
    })
  }

  const desvincular = (accountId: string) => {
    if (!confirm('Confirma desvinculamento da Conta para o Usuário?')) return false;

    const userAccount = userAccounts.find(item => item.accountId == accountId);

    api.delete(`/users/${userId}/accounts/${userAccount.id}`)
      .then(result => {
        setUserAccounts([])
        fetchUserAccounts()
      })
  };

  return (
    <Styled.AccountsBox>
      {!accounts &&
        <Typography>
          Não há bases cadastradas
        </Typography>
      }
      {userAccounts && accounts.map((account, index) => {
        const found = userAccounts.find(item => item.accountId == account.id) != null;
        return ( 
          <Styled.AccountBox key={index} aria-class="accountBox">
            <Styled.AccountData> 
              <Styled.AccountName 
                  style={{ 
                    color: found ? '': 'gray',
                    fontWeight: found ? 'bold': ''
                  }}>
                <PriceChange style={{ color: found ? 'green': 'inherit' }} />
                {account.name}
              </Styled.AccountName>

              <i>
                {!found && 
                  <Link 
                    to='#'
                    title={'Habilitar Conta para o Usuário'}
                    onClick={() => vincular(account.id)}
                  >
                    <Check/>
                  </Link>
                }
                {found && (
                  <>
                    <Link 
                      to='#'
                      title={'Administradores e Gerentes'}
                      onClick={() => {
                        if (usersBox.indexOf(account.id) < 0) {
                          setUsersBox([...usersBox, account.id])
                        } else {
                          setUsersBox(usersBox.filter(item => item !== account.id))
                        }
                      }}
                    >
                      <Group />
                    </Link>
                    {(usersBox.indexOf(account.id) < 0) &&
                      <Link
                        to='#'
                        title={'Desvincular Conta'}
                        onClick={() => desvincular(account.id)}
                      >
                        <Close/>
                      </Link>
                    }
                  </>
                )}
              </i>
            </Styled.AccountData>

            {usersBox.indexOf(account.id) >= 0 && (
              <AccountUsers 
                accountId={account.id}
              />
            )}
          </Styled.AccountBox>
        )  
      })}
      <UsersAddDialog open={openDialog} setOpen={setOpenDialog} />
    </Styled.AccountsBox>      
  );
}