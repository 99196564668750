import React, { useState } from 'react';
import { useAuthState } from 'react-admin';

export const Footer = () => {

    const { authenticated } = useAuthState();
    const [userData, setUserData] = useState<any>(null)

    React.useEffect(() => {
        const json = localStorage.getItem('userData');
        if (json != null) {
            setUserData(JSON.parse(json));
        } else {
            setUserData(null);
        }
    }, []);

    const broadcastChannel = new BroadcastChannel('auth');
    broadcastChannel.onmessage = (eventMessage) => {
        const json = localStorage.getItem('userData');
        if (json != null) {
            setUserData(JSON.parse(json));
        } else {
            setUserData(null);
        }
    }
    
    return (
        <div style={{
            position: 'fixed', 
            right: 0, 
            bottom: 0, 
            left: 0, 
            zIndex: 100,
            padding: 6,
            backgroundColor: '#efefef',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}>
            {authenticated && userData && 
                <div>
                <strong>Autenticado como {userData.name} ({userData.role.name})</strong>
                </div>
            }
            <div>
                <strong>® 2024 App. Todos os direitos reservados. Produzido por HYDEIA TECNOLOGIA</strong>
            </div>
        </div>
    );
}