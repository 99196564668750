import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
  height: 600px;
  background-color: #fff;
  padding: 30px 20px;

  @media (max-width: 767px) {
    /* Estilos específicos para dispositivos móveis */
    /* Adicione os estilos desejados aqui */
    flex-direction: column;
    padding: 20px;
    height: auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 16px;
  border-bottom: 1px solid #ACACAC;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  color: #505D6F;
`;

export const Map = styled.div`
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 450px;
  background-color: #fff;

  @media (max-width: 767px) {
    /* Estilos específicos para dispositivos móveis */
    /* Adicione os estilos desejados aqui */
    flex-direction: column;
    height: auto;
  }
`;
