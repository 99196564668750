import { Route, Routes } from "react-router-dom";
import { MaterialCreate, MaterialEdit, MaterialList, MaterialShow } from "src/Pages/Stock/Materials";

export const MaterialRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<MaterialList />} />
          <Route path="/create" element={<MaterialCreate />} />
          <Route path=":id" element={<MaterialEdit />} />
          <Route path=":id/show" element={<MaterialShow />} />
        </Routes>
    )
  }