import * as React from "react";
import { Link, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { showSuccessMessage } from "src/Hooks/useMessages";
import { handleError } from "src/Services/Handlers/handleError";
import { useRedirect } from "react-admin";

interface DeleteActionProps {
    api: any,
    url?: string;
    baseUrl?: string;
    backendUrl?: string,
    id?: string;
    successMessage: string,
    onSuccess?: () => void
}

export const DeleteAction = ({ api, url, baseUrl, backendUrl, id, successMessage, onSuccess }: DeleteActionProps) => {
    const redirect = useRedirect()
    if (!url) url = baseUrl;

    if (!id) {
        const params = useParams()
        id = params.id
    }

    if (!onSuccess) {
        onSuccess = () => {
            redirect(url as string)
        }
    }

    const handleDelete = async () => {
        if (!window.confirm('Deseja realmente excluir este item?'))  return false;

        try {
            api.delete(`${backendUrl}/${id}`)
                .then(response => {
                    if (response?.status == 200) {
                        showSuccessMessage(successMessage);
                        if (onSuccess) {
                            onSuccess();
                        }
                    }
                })
            
        } catch (error) {
            handleError(error)
        }
    };

    return (
        <Link to={"#"} onClick={handleDelete}>
            <DeleteIcon/>
        </Link>
    );
}

DeleteAction.defaultProps = {
    baseUrl: '',
    backendUrl: ''
}