import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { api } from 'src/Services/Financial';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';
import i18next from 'i18next';
import { useRedirect } from 'react-admin';

export const ElectionEdit = () => {
  const { control } = useForm();
  const redirect = useRedirect()
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { id } = useParams();
  const { data } = useGetOne(api, '/elections', id);

  const onSubmit = formData => {
    api.put(`/elections/${id}`, formData.data).then(result => {
      if (result?.status == 200) {
        showSuccessMessage(i18next.t(`resources.elections.actions.update.success`));
        redirect(`/election/elections/${id}/show`);
      }
    });
  };

  return (
    <SimpleContainer
      title="resources.elections.name"
      style={{ maxWidth: isSmScreen ? '100%' : 900 }}
    >
      <Form method="post" control={control} onSubmit={onSubmit}>
        {data && (
          <CustomInput
            name={'name'}
            control={control}
            label={i18next.t(`resources.elections.fields.name`)}
            defaultValue={data.name}
          />
        )}
      </Form>
    </SimpleContainer>
  );
};
