import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { Controller, useForm } from 'react-hook-form';
import { api } from 'src/Services/Financial'
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import useGetOne from 'src/Hooks/useGetOne';
import { useParams } from 'react-router-dom';
import useGetTree from 'src/Hooks/useGetTree';
import { Cascader } from 'rsuite';
import i18next from 'i18next';

export const CategoryEdit = () => {
    const resource = 'categories'
    const { control } = useForm();
    const redirect = useRedirect()
    const { data: categories } = useGetTree(api, '/categories/tree')
    const { id } = useParams()
    const { data } = useGetOne(api, '/categories', id);

    const onSubmit = (formData) => 
    {
        api.put(`/categories/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    showSuccessMessage(`resources.${resource}.actions.edit.success`)
                    redirect(`/financial/categories/${result.data.id}/show`)
                }
            })
    }
    
    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={900}
        >
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
            >
                {data && (
                    <>
                        {categories &&
                            <Controller
                                name={"parentId"}
                                control={control}
                                defaultValue={data.parentId}
                                render={({ field }) => (
                                    <Cascader
                                        parentSelectable
                                        block={true}
                                        menuWidth={250}
                                        style={{ width: '100%' }}
                                        placeholder={i18next.t(`resources.${resource}.fields.parentId`)}
                                        data={categories.filter(item => item.sequence != '2')} 
                                        onChange={field.onChange}
                                        value={field.value}
                                        defaultValue={data.parentId}
                                    />
                                )}
                            />
                        }   
                    
                        <CustomInput
                            name={"name"}
                            control={control}
                            label={`resources.${resource}.fields.name`}
                            defaultValue={data.name}
                        />
                    </>
                )}
            </Form>
        </SimpleContainer>
    );
}