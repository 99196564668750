import React, { useEffect } from 'react';
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE, ROLE_OPERATOR } from 'src/Enums/Roles';
import { RegistrationsCardComponents } from 'src/Components/Cards/RegistrationsCard';
import { HomeTabs, TAB_TOTAL, TAB_VOTERS, TAB_WORKERS, TAB_ZONES } from './Tabs';
import { useGetIdentity } from 'react-admin';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { HomeVotersPage } from './Voters';
import { HomeZonesPage } from './Zones';
import { HomeWorkersPage } from './Workers';
import { api } from 'src/Services/Elections';
import { showErrorMessage } from 'src/Hooks/useMessages';
import { Title as LayoutTitle } from 'src/Components/Layout/Title';
import i18next from 'i18next';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { HomeTotalsPage } from './Totals';

export const HomePage = () => {
  const [dateRef, setDateRef] = React.useState(
    localStorage.getItem('home.dateRef') ||
      new Date().toISOString().substring(0, 10)
  );
  const [total, setTotal] = React.useState<any>({
    total: 0,
    totalDay: 0,
    totalWeek: 0,
    totalMonth: 0,
    goal: 0
  });
  const { data: identity } = useGetIdentity();
  const [tab, setTab] = React.useState(
    parseInt(localStorage.getItem('home.tab') as string) || TAB_VOTERS
  );

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t('resources.home.messages.invalid_date'));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;

    return new Date(start).toISOString();
  };

  useEffect(() => {
    if (identity && dateRef) {
      const dateBounds = getDateBounds(dateRef);

      if (dateBounds) {
        const filter = {
          date: dateBounds
        };

        localStorage.setItem('home.dateRef', dateRef);

        api.get('/home/totals', { params: filter }).then(result => {
          if (result && result.status == 200) {
            setTotal(result.data);
          }
        });
      }
    }
  }, [dateRef, identity]);

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={total?.totalDay}
              percentage={(total.goal
                ? ((100 * parseInt(total?.totalDay)) / total.goal).toFixed(0)
                : 0
              ).toString()}
              color={total?.totalDay < total.goal ? '#ed1651' : '#2E7D32'}
              title={i18next.t('resources.home.totals.results.registerDay')}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={total?.totalWeek}
              color="#ff8542"
              title={i18next.t('resources.home.totals.results.registerWeek')}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={total?.totalMonth}
              color="#3ebac8"
              title={i18next.t('resources.home.totals.results.registerMonth')}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={total?.total}
              percentage={(total.totalGoal
                ? ((100 * parseInt(total?.total)) / total.totalGoal).toFixed(0)
                : 0
              ).toString()}
              color={total?.total < total.totalGoal ? '#ed1651' : '#2E7D32'}
              title={i18next.t('resources.home.totals.results.totalGeneral')}
            />
          </Grid>
        </Grid>
      </Container>

      <SimpleContainer hasBreadcrumbs={false}>
        <LayoutTitle
          text={i18next.t('resources.home.title')}
        ></LayoutTitle>
        <HomeTabs user={identity} tab={tab} setTab={setTab} />
        {tab == TAB_WORKERS && identity?.role?.name != ROLE_OPERATOR && (
          <HomeWorkersPage dateRef={dateRef} setDateRef={setDateRef} />
        )}
        {tab == TAB_VOTERS && (
          <HomeVotersPage dateRef={dateRef} setDateRef={setDateRef} />
        )}
        {tab == TAB_ZONES && (
          <HomeZonesPage dateRef={dateRef} setDateRef={setDateRef} />
        )}
        {tab == TAB_TOTAL && ((identity?.role?.name == ROLE_CAMPAIGN_MANAGER) || (identity?.role?.name == ROLE_CANDIDATE)) && (
          <HomeTotalsPage dateRef={dateRef} setDateRef={setDateRef} />
        )}
      </SimpleContainer>
    </>
  );
};
