import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { api } from 'src/Services/Financial';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import i18next from 'i18next';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import Grid from '@mui/material/Grid';
import DecimalInput from 'src/Components/Inputs/DecimalInput';

export const AccountCreate = () => {
  const { control } = useForm();
  const redirect = useRedirect();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { options: campaigns } = useGetManyOptions(api, '/campaigns')

  const onSubmit = formData => {
    api.post('/accounts', formData.data).then(result => {
      if (result?.status == 201) {
        showSuccessMessage(
          i18next.t(`resources.accounts.actions.create.success`)
        );
        redirect(`/financial/accounts/${result.data.id}/show`);
      }
    });
  };

  return (
    <SimpleContainer
      title="resources.accounts.name"
      style={{ maxWidth: isSmScreen ? '100%' : 900 }}
    >
      <Form method="post" control={control} onSubmit={onSubmit}>
        {campaigns &&
            <ReferenceInput
                name={"campaignId"}
                control={control}
                label={`resources.accounts.fields.campaignId`}
                options={campaigns}
            />
        }
        <CustomInput
          name={'name'}
          control={control}
          label={i18next.t(`resources.accounts.fields.account_name`)}
          defaultValue=""
        />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={12}>
            <DecimalInput
                name={"amount"}
                control={control}
                label={`resources.accounts.fields.amount`}
            />
          </Grid>
        </Grid>
      </Form>
    </SimpleContainer>
  );
};
