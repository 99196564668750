import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Financial'
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SelectInput } from 'src/Components/Inputs/SelectInput';
import { types } from 'src/Enums/PersonTypes'
import { documentTypes } from 'src/Enums/PersonDocumentTypes'
import useGetOne from 'src/Hooks/useGetOne';
import { useParams } from 'react-router-dom';

export const PersonEdit = () => {
    const resource = 'persons'
    const { control } = useForm();
    const redirect = useRedirect()
    const { id } = useParams()
    const { data } = useGetOne(api, '/persons', id);

    const onSubmit = (formData) => 
    {
        api.put(`/persons/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    showSuccessMessage(`resources.${resource}.actions.edit.success`)
                    redirect(`/financial/persons/${result.data.id}/show`)
                }
            })
    }
    
    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
            >
                {data && (
                    <>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                            <Grid item xs={12} md={3}>
                                <SelectInput 
                                    name="type" 
                                    control={control}
                                    options={types}
                                    label={`resources.${resource}.fields.type`}
                                    defaultValue={data.type}
                                />
                            </Grid>
                        </Grid>

                        <CustomInput
                            name={"fullName"}
                            control={control}
                            label={`resources.${resource}.fields.fullName`}
                            defaultValue={data.fullName}
                        />

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                            <Grid item xs={12} md={4}>
                                <SelectInput 
                                    name="identifierType" 
                                    control={control}
                                    options={documentTypes}
                                    label={`resources.${resource}.fields.identifierType`}
                                    defaultValue={data.identifierType}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <CustomInput
                                    name={"identifier"}
                                    control={control}
                                    label={`resources.${resource}.fields.identifier`}
                                    defaultValue={data.identifier}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Form>
        </SimpleContainer>
    );
}
