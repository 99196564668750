import * as React from 'react';
import Grid from '@mui/material/Grid';
import { api } from 'src/Services/Financial'
import { useForm } from 'react-hook-form';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import locationDataProvider from 'src/Providers/LocationDataProvider'
import { SelectInput } from 'src/Components/Inputs/SelectInput';
import { ConfigContext } from 'src/Hooks/useConfigContext';

interface ChargeFormProps {
    data: any;
    onSubmit: (formData: FormData) => void
}

export const ChargeForm = ({ data, onSubmit }: ChargeFormProps) => {
    const resource = 'charges'
    const { control } = useForm();
    const { config } = React.useContext(ConfigContext);
    const { accountId } = config ?? { accountId: null }
    const { options: types } = useGetManyOptions(api, '/expense-types')
    const { options: states } = useGetManyOptions(api, '/state', {}, 'id', 'uf')
    const [uf, setUf] = React.useState(data ? data.stateId : '')
    const { options: cities } = useGetManyOptions(api, '/city', { uf })
    const [cityId, setCityId] = React.useState(data ? data.cityId : '')
    const { options: electoralZones } = useGetManyOptions(api, '/electoral-zones', { cityId }, 'id', 'identifier')

    const onSubmitFn = (formData) => {
        formData.data.accountId = accountId
        onSubmit(formData)
    }

    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={'100%'}
        >
            {data && (
                <Form 
                    method='post' 
                    control={control}
                    onSubmit={onSubmitFn}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={9}>
                            <CustomInput
                                name={"title"}
                                control={control}
                                label={`resources.${resource}.fields.title`}
                                defaultValue={data?.title}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <DateInput
                                name="due_date" 
                                control={control}
                                label={`resources.${resource}.fields.due_date`}
                                defaultValue={data?.due_date}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <CustomInput
                                name={"description"}
                                control={control}
                                label={`resources.${resource}.fields.description`}
                                defaultValue={data?.description}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {types &&
                                <ReferenceInput
                                    name={"typeId"}
                                    control={control}
                                    label={`resources.${resource}.fields.typeId`}
                                    defaultValue={data?.typeId}
                                    options={types}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                        <Grid item xs={12} md={2}>
                            {states && (
                                <ReferenceInput 
                                    name="stateId" 
                                    control={control}
                                    options={states}
                                    label={`resources.${resource}.fields.stateId`}
                                    defaultValue={data.stateId}
                                    onChange={value => { 
                                        setUf(value)
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {cities && (cities.length > 0) && (
                                <ReferenceInput
                                    name="cityId" 
                                    control={control}
                                    options={cities}
                                    label={`resources.${resource}.fields.cityId`}
                                    defaultValue={data.cityId}
                                    onChange={value => { 
                                        setCityId(value)
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {electoralZones && (electoralZones.length > 0) && (
                                <ReferenceInput
                                    name="electoralZoneId" 
                                    control={control}
                                    options={electoralZones}
                                    label={`resources.${resource}.fields.electoralZoneId`}
                                    defaultValue={data.electoralZoneId}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </SimpleContainer>
    );
}