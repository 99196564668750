import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { format, addDays } from 'date-fns';

import * as Styled from './styles';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as locales from 'react-date-range/dist/locale';
import i18next from 'i18next';

interface CalendarRangeProps {
  labelText: string;
  changeRange: (range) => void;
}

export const CalendarRangeComponents: React.FC<CalendarRangeProps> = ({
  labelText,
  changeRange
}) => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const [open, setOpen] = useState(false);
  const refOne: any = useRef(null);

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);

  const hideOnEscape = e => {
    if (e.key == 'Escape') {
      setOpen(false);
    }
  };

  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleDateRange = range => {
    const rangeFormatted = {
      startDate: format(range[0].startDate, 'yyyy-MM-dd'),
      endDate: format(range[0].endDate, 'yyyy-MM-dd')
    };

    setRange(range);
    changeRange(rangeFormatted);
  };

  return (
    <>
      <Styled.SpanBox>{labelText}</Styled.SpanBox>
      <Styled.Container>
        <Styled.InputBox
          value={` ${format(range[0].startDate, 'dd/MM/yyyy')} ${i18next.t(
            'components.calendarRange.until'
          )} ${format(range[0].endDate, 'dd/MM/yyyy')} `}
          readOnly
          className="inputBox"
          onClick={() => setOpen(open => !open)}
          title={i18next.t('components.calendarRange.title')}
        />
        <div ref={refOne}>
          {open && (
            <DateRange
              date={new Date()}
              onChange={item => handleDateRange([item.selection])}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              direction="horizontal"
              className="calendarElement"
              locale={locales['pt']}
            />
          )}
        </div>
      </Styled.Container>
    </>
  );
};

export default CalendarRangeComponents;
