import { Route, Routes } from "react-router-dom";
import { PlaceTypeCreate, PlaceTypeEdit, PlaceTypeList, PlaceTypeShow } from "src/Pages/Stock/PlaceTypes";

export const PlaceTypeRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<PlaceTypeList />} />
          <Route path="/create" element={<PlaceTypeCreate />} />
          <Route path=":id" element={<PlaceTypeEdit />} />
          <Route path=":id/show" element={<PlaceTypeShow />} />
        </Routes>
    )
  }