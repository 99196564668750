import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { ResearchForm } from 'src/Pages/Election/Research/ResearchForm';
import { api } from 'src/Services/Elections';

export const ResearchEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const endpoint = '/research';
  const resource = 'research';

  const { data } = useGetOne(api, endpoint, id);

  const onSubmit = (formData) => {
    api.patch(`/research/${id}`, formData.data).then((result) => {
      if (result?.status === 200) {
        showSuccessMessage(`resources.${resource}.actions.create.success`);
        navigate(`/election/research/${result.data.id}/show`);
      }
    });
  };

  return <ResearchForm data={data} onSubmit={onSubmit} />;
};
