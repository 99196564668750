import { Route, Routes } from "react-router-dom";
import { PartyCreate, PartyEdit, PartyList, PartyShow } from "src/Pages/Election/Parties";

export const PartyRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<PartyList />} />
          <Route path="/create" element={<PartyCreate />} />
          <Route path=":id" element={<PartyEdit />} />
          <Route path=":id/show" element={<PartyShow />} />
        </Routes>
    )
  }