import * as React from 'react';
import { PositionForm } from './PositionForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const PositionEdit = () => {
    const { id } = useParams()
    const { data } = useGetOne(api, '/positions', id);
    const navigate = useNavigate(); 
    
    const onSubmit = (formData) => {
        api.put(`/positions/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    navigate(`/hr/positions/${result.data.id}/show`)
                    showSuccessMessage(`resources.positions.actions.edit.success`)
                }
            })
    }

    return (
        <>
        {data && (
            <PositionForm
                data={data}
                onSubmit={onSubmit}
            />  
        )}
        </>
    );
}