import React from 'react';
import { Controller } from 'react-hook-form';
import i18next from "i18next";
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import InputAdornment from '@mui/material/InputAdornment';

interface DecimalInputProps {
    name: string,
    control: any,
    label: string,
    defaultValue?: number | null,
    currencySymbol?: string,
    [key: string]: any;
}

const DecimalInput = ({ name, control, label, defaultValue, currencySymbol, ...rest }: DecimalInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue.toString() : ""}
      render={({ field, fieldState }) => (
        <CurrencyTextField
          {...field}
          label={i18next.t(label)}
          value={field.value}
          variant="outlined"
          outputFormat="string"
          currencySymbol=""
          decimalCharacter=","
          digitGroupSeparator="."
          onChange={(event, value)=> {
            return field.onChange(value)
          }}
          InputProps={
            currencySymbol 
              ? {
                startAdornment: (
                  <InputAdornment position="start">{currencySymbol}</InputAdornment>
                )
              }
              : {  
                  startAdornment: (
                    <></>
                  )
                }
          }
          error={fieldState.invalid}
          helperText={fieldState.error ? fieldState.error.message : null}
          {...rest}
        />
      )}
    />
  );
};

export default DecimalInput;
