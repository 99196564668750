import * as React from 'react';
import Box from '@mui/material/Box';
import { ToastContainer } from 'react-toastify';
import { Title } from 'src/Components/Layout/Title';
import Breadcrumbs from 'src/Components/Layout/Breadcrumbs';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import 'react-toastify/dist/ReactToastify.css';

interface SimpleContainerProps {
  children: React.ReactNode;
  title?: string;
  hasBreadcrumbs?: boolean;
  mdSize?: number | string;
  [key: string]: any;
}

export const SimpleContainer = ({
  children,
  title,
  hasBreadcrumbs,
  mdSize,
  ...props
}: SimpleContainerProps) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!mdSize) {
    mdSize = '100%';
  }

  return (
    <Box
      style={{
        backgroundColor: '#F9FBFA',
        maxWidth: isSmScreen ? '100%' : mdSize,
        marginLeft: isSmScreen ? '3%' : 0,
        marginRight: isSmScreen ? '3%' : 0
      }}
      {...props}
    >
      {title && <Title text={title as string} />}
      {(hasBreadcrumbs ?? true) && <Breadcrumbs />}
      {children}
    </Box>
  );
};
