import { ReactNode } from "react";
import { userCan } from "src/Components/Layout/Can";
import ForbiddenPage from "src/Components/Status/Forbidden";

export const PermittedRoute = (resource: string, children: ReactNode) => {
    if (userCan({ resource })) {
        return children;
    } else {
        return <ForbiddenPage />
    }
}