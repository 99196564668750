import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logoSrc from 'src/Assets/logo.png'
import styled, { keyframes } from "styled-components";

const useLoader = () =>  {
    const [loading, setLoading] = useState(false);

    const showLoader = () => {
      setLoading(true)
    }

    const hideLoader = () => {
      setLoading(false)
    }

    const beatAnimation = keyframes`
      0% {
          transform: scale(1);
      }
      50% {
          transform: scale(0.8); /* Adjust this value to control the scale during contraction */
      }
      100% {
          transform: scale(1);
      }
    `;

    const BouncingImage = styled.img`
      width: auto;
      height: 150px;
      animation: ${beatAnimation} 1s infinite;
    `;

    // Wrapper component for positioning, etc.
    const ImageWrapper = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    // Your React component using the bouncing image
    const BouncingImageComponent = ({ src, alt }: any) => {
      return (
          <ImageWrapper>
              <BouncingImage src={src} alt={alt} height={100} />
          </ImageWrapper>
      );
    };

    const Loader = () => 
      loading ? (
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <BouncingImageComponent src={logoSrc} alt="logo" />
          </Backdrop>
        </div>
      ): null;

    return { loading, Loader, showLoader, hideLoader }
}

export default useLoader