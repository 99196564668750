import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';
import { CandidateForm } from './CandidateForm';
import { removePhoneMask } from 'src/Utils/Formatters';

export const CandidateEdit = () => {
  const { id } = useParams();
  const { data } = useGetOne(api, '/candidates', id);
  const navigate = useNavigate();

  const onSubmit = formData => {
    const cleanedTelphone = removePhoneMask(formData.data.telphone);

    const data = {
      ...formData.data,
      telphone: cleanedTelphone
    };

    api.put(`/candidates/${id}`, data).then(result => {
      if (result?.status == 200) {
        navigate(`/election/candidates/${result.data.id}/show`);
        showSuccessMessage(`resources.candidates.actions.edit.success`);
      }
    });
  };

  return <>{data && <CandidateForm data={data} onSubmit={onSubmit} />}</>;
};
