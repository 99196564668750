import { useErrorBoundary } from "react-error-boundary";

interface ErrorPageProps {
    error: any;
    resetErrorBoundary: any;
}

export default function ErrorPage({ error }: ErrorPageProps) {

    const { resetBoundary } = useErrorBoundary();

    return (
        <div className={"error-page"}>
            <div className={"oops"}>Oops!</div>
            <div className={"message"}>Something went wrong...</div>
            <pre>{error.message}</pre>
            <button onClick={resetBoundary}>Tentar novamente</button>
        </div>
    );
}