import buildRestProvider from 'ra-data-simple-rest';
import httpClient from './Response/FinancialHttpClientResponse'
import { api } from 'src/Services/Elections';

const apiUrl = `${String(process.env.REACT_APP_API_URL)}/pooling-intention`
const dataProvider = buildRestProvider(apiUrl, httpClient)

export default {
    ...dataProvider,
    getStats: () => 
        api.get(`pooling-intention/stats`),
    getStatsByBases: (filters) => 
        api.get(`pooling-intention/stats-bases?periodFrom=${filters.periodFrom}&periodTo=${filters.periodTo}`),
    getStatsByZones: (filters) => 
        api.get(`pooling-intention/stats-zones?periodFrom=${filters.periodFrom}&periodTo=${filters.periodTo}`),
    getStatsByUsers: (filters) => 
        api.get(`pooling-intention/stats-users?periodFrom=${filters.periodFrom}&periodTo=${filters.periodTo}`),
    getResearchRanking: (researchId, from, to) => 
        api.get(`pooling-intention/stats-users?researchId=${researchId}&periodFrom=${from}&periodTo=${to}`)
}