import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { DeleteAction } from 'src/Components/Admin/Actions/DeleteAction';
import { EditAction } from 'src/Components/Admin/Actions/EditAction';
import { ListAction } from 'src/Components/Admin/Actions/ListAction';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import { View } from 'src/Components/Admin/View';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { Menu } from 'src/Components/Election/Menu';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetOne from 'src/Hooks/useGetOne';
import { api } from 'src/Services/Elections';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const ResearchShow = () => {
  const { id } = useParams();
  const endpoint = '/research';
  const resource = 'research';
  const [tab, setTab] = React.useState(3);
  const { data } = useGetOne(api, endpoint, id);

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={900}>
      <Menu tab={tab} setTab={setTab} />
      <View
        baseUrl="/election/research"
        backendUrl="/research"
        actions={[
          <ListAction key={0} />,
          <EditAction key={1} />,
          <DeleteAction
            key={2}
            api={api}
            successMessage={`resources.${resource}.actions.delete.success`}
          />,
          <Link
            key={3}
            to={`/election/research/${id}/show/research-candidate`}
            title="Candidatos da Pesquisa"
          >
            <AccountCircleIcon />
          </Link>
        ]}
      >
        <SimpleShowLayout>
          <TextField
            value={data?.description}
            label={`resources.${resource}.fields.name`}
          />
          <TextField
            value={data?.researchType.name}
            label={`resources.${resource}.fields.research_name`}
          />
          <TextField
            value={data?.position?.name}
            label={`resources.${resource}.fields.positionId`}
          />
          <TextField
            value={data?.range}
            label={`resources.${resource}.fields.range`}
          />
          <TextField
            value={data?.reference}
            label={`resources.${resource}.fields.reference`}
          />
          <DateField
            value={data?.start_date}
            label={`resources.${resource}.fields.start_date`}
          />
          <DateField
            value={data?.end_date}
            label={`resources.${resource}.fields.end_date`}
          />
          <DateField
            value={data?.created_at}
            label={`resources.${resource}.fields.created_at`}
            hasTime={true}
          />
        </SimpleShowLayout>
      </View>
    </SimpleContainer>
  );
};
