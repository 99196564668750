import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { api } from 'src/Services/Financial';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import i18next from 'i18next';

export const ElectionCreate = () => {
  const { control } = useForm();
  const redirect = useRedirect();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = formData => {
    api.post('/elections', formData.data).then(result => {
      if (result?.status == 201) {
        showSuccessMessage(
          i18next.t(`resources.elections.actions.create.success`)
        );
        redirect(`/election/elections/${result.data.id}/show`);
      }
    });
  };

  return (
    <SimpleContainer
      title="resources.elections.name"
      style={{ maxWidth: isSmScreen ? '100%' : 900 }}
    >
      <Form method="post" control={control} onSubmit={onSubmit}>
        <CustomInput
          name={'name'}
          control={control}
          label={i18next.t(`resources.elections.fields.name`)}
          defaultValue=""
        />
      </Form>
    </SimpleContainer>
  );
};
