import { AuthProvider } from 'react-admin';
import i18next from 'i18next';

const doLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
  localStorage.removeItem('permissions');
  localStorage.removeItem('accountId');
  const broadcastChannel = new BroadcastChannel('auth');
  broadcastChannel.postMessage({ type: 'signOut' });
};

const authProvider: AuthProvider = {
  login: ({ username: email, password }) => {
    const baseUrl = process.env.REACT_APP_API_URL;

    const request = new Request(baseUrl + '/auth/login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });

    return (
      fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        //.then(({ access_token }) => {
        .then(async response => {
          const data = response.data;
          const access_token = response.access_token;

          localStorage.setItem('token', access_token);
          localStorage.setItem('userData', JSON.stringify(data));
          localStorage.setItem(
            'permissions',
            JSON.stringify(response.permissions)
          );

          const config = {
            electionId: data.configuration?.electionId,
            campaignId: data.configuration?.campaignId,
            accountId: data.configuration?.accountId
          };

          if (data.configuration) {
            localStorage.setItem('config', JSON.stringify(config));
          }

          // broadcast token
          const broadcastChannel = new BroadcastChannel('auth');
          broadcastChannel.postMessage({ type: 'signIn', token: access_token });

          setTimeout(function () {
            doLogout();
            window.location.reload();
          }, 1000 * 60 * 60);

          return Promise.resolve();
        })
        .catch(error => {
          console.error('Erro de autenticação:', error);
          throw new Error(i18next.t('auth.messages.invalid_credentials'));
        })
    );
  },
  logout: () => {
    doLogout();
    return Promise.resolve();
  },
  getIdentity: () => {
    const json = localStorage.getItem('userData');

    if (json == null) return Promise.reject();

    const userData = JSON.parse(json);

    return Promise.resolve({
      id: userData.id,
      fullName: userData.name,
      role: userData.role
    });
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  checkError: error => {
    if (!error) return Promise.reject();

    const status = error.status;
    if (status === 401 || status === 403) {
      doLogout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const permissions = localStorage.getItem('permissions');
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  }
};

export default authProvider;
