import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Financial'
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';

export const MaterialTypeCreate = () => {
    const resource = 'materialTypes'
    const { control } = useForm();
    const redirect = useRedirect()

    const onSubmit = (formData) => 
    {
        api.post('/material-types', formData.data)
        .then(result => {
            if (result?.status == 201) {
            showSuccessMessage(`resources.${resource}.actions.create.success` )
            redirect(`/stock/material-types/${result.data.id}/show`)
            }
        })
    }
    
    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={900}
        >
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
            >
                <CustomInput
                    name={"name"}
                    control={control}
                    label={`resources.${resource}.fields.name`}
                    defaultValue=""
                />
            </Form>
        </SimpleContainer>
    );
}