import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';

import GroupsIcon from '@mui/icons-material/Groups';
import PlaceIcon from '@mui/icons-material/Place';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import i18next from 'i18next';
import { ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE, ROLE_OPERATOR } from 'src/Enums/Roles';

interface HomeTabsProps {
  user: any;
  tab: number;
  setTab: any;
}

export const TAB_BASES = 0
export const TAB_WORKERS = 1
export const TAB_VOTERS = 2
export const TAB_ZONES = 3
export const TAB_TOTAL = 4

export const HomeTabs = ({ user, tab, setTab }: HomeTabsProps) => 
{
  const resource = 'resources.reports.elections.votersByPeriod'

  const tabStyle: React.CSSProperties = {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.5rem',
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  let labelWorkers: string

  switch(user?.role.name) {
    case ROLE_CAMPAIGN_MANAGER: 
    case ROLE_CANDIDATE: 
      labelWorkers = 'baseManagers'
      break;
    case ROLE_BASE_MANAGER: 
      labelWorkers = 'supervisors'
      break;
    default: 
      labelWorkers = 'operators'
      break;
  }

  return (
    <>
      <Tabs
        value={tab}
        onChange={(event, value) => {
          setTab(value)
          localStorage.setItem('reportsByPeriod.tab', value)
        }}
        aria-label='Tabs'
      >
        {user?.role.name != ROLE_OPERATOR &&       
          <Tab
            value={TAB_WORKERS}
            style={tabStyle}
            icon={<SupervisorAccountIcon />}
            label={i18next.t(`${resource}.tabs.${labelWorkers}`)}
            {...a11yProps(TAB_WORKERS)}
          />
        }
        
        <Tab
          value={TAB_VOTERS}
          style={tabStyle}
          icon={<GroupsIcon />}
          label={i18next.t(`${resource}.tabs.voters`)}
          {...a11yProps(TAB_VOTERS)}
        />
        <Tab
          value={TAB_ZONES}
          style={tabStyle}
          icon={<PlaceIcon />}
          label={i18next.t(`${resource}.tabs.zones`)}
          {...a11yProps(TAB_ZONES)}
        />
        <Tab
          value={TAB_TOTAL}
          style={tabStyle}
          icon={<SupervisorAccountIcon />}
          label={i18next.t(`${resource}.tabs.total`)}
          {...a11yProps(TAB_TOTAL)}
        />
      </Tabs>
    </>
  );
}