import { useNavigate } from 'react-router-dom';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { ResearchForm } from 'src/Pages/Election/Research/ResearchForm';
import { api } from 'src/Services/Elections';

export const ResearchCreate = () => {
  const navigate = useNavigate();
  const resource = 'research';
  const endpoint = '/research';

  const onSubmit = (formData) => {
    api.post(endpoint, formData.data).then((result) => {
      if (result?.status === 201) {
        showSuccessMessage(`resources.${resource}.actions.create.success`);
        navigate(`/election/research/${result.data.id}/show`);
      }
    });
  };

  return <ResearchForm data={{ id: null }} onSubmit={onSubmit} />;
};
