import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { showErrorMessage, showSuccessMessage } from 'src/Hooks/useMessages';
import { Form } from 'src/Components/Admin/Form';
import { api } from 'src/Services/Elections';
import { useForm } from 'react-hook-form';
import { PasswordInput } from 'src/Components/Inputs/PasswordInput';

export const ChangePassword = () => 
{
  const resource = 'auth.change_password';
  const navigate = useNavigate();
  const { control, watch } = useForm();

  const onSubmit = formData => {

    if (formData.data.newPassword != formData.data.confirmPassword) {
      showErrorMessage(`${resource}.errors.new_passwords_not_equals`)
      return false
    }
  
    const data = {
      ...formData.data
    };

    api.put(`/auth/change-password`, data).then(result => {
      if (result?.status == 200) {
        showSuccessMessage(
          i18next.t(`${resource}.successMessage`)
        );
        navigate(`/`);
      }
    });
  };

  return <SimpleContainer title={`${resource}.title`} mdSize={500}>
    <Form method="post" control={control} onSubmit={onSubmit} submitButtonLabel="actions.update">
      <PasswordInput
        name="currentPassword"
        control={control}
        label={`${resource}.fields.currentPassword`}
      />
      <PasswordInput
        name="newPassword"
        control={control}
        label={`${resource}.fields.newPassword`}
      />
      <PasswordInput
        name="confirmPassword"
        control={control}
        label={`${resource}.fields.confirmPassword`}
      />
    </Form>
  </SimpleContainer>
};
