import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { DecimalField } from 'src/Components/Admin/Fields/DecimalField';
import Button from '@mui/material/Button';
import i18next from 'i18next';
import { ReturnAction } from 'src/Components/Admin/Actions/ReturnAction';

interface InboundItemsSearchProps {
  inboundId: string;
  setAcao: (acao: string) => void;
}

export const InboundItemsSearch = ({
  inboundId,
  setAcao
}: InboundItemsSearchProps) => {
  const endpoint = '/materials';
  const resource = 'materialInboundItems';
  const { data, fetchData, hasMore, filters, setFilters } = useInfiniteFetch(
    api,
    endpoint,
    25
  );

  const onSelect = async materialId => {
    api
      .get(`/materials/${materialId}`)
      .then(result => {
        if (result && result.status == 200) {
          return Promise.resolve(result.data);
        }
        return Promise.reject(
          i18next.t(`resources.materialInboundItems.actions.read.error`)
        );
      })
      .then(material => {
        const item = {
          inboundId,
          materialId,
          unitPrice: material.defaultPrice,
          quantity: 1,
          totalPrice: material.defaultPrice
        };
        api.post(`/material-inbounds/${inboundId}/items`, item).then(result => {
          if (result && result.status == 201) {
            showSuccessMessage(
              i18next.t(`resources.materialInboundItems.actions.create.success`)
            );
          }
        });
      });
  };

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      actions={
        <ReturnAction
          onClick={() => setAcao('list')}
          title="Retornar aos itens"
          label="Retornar aos itens"
        />
      }
      filters={
        <>
          <SearchInput
            name="search"
            label={i18next.t('resources.materialInboundItems.filter.search')}
            filters={filters}
            setFilters={setFilters}
            style={{ minWidth: 300 }}
          />
        </>
      }
      columns={[
        {
          header: `resources.materials.fields.name`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.name}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.materials.fields.materialTypeId`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.materialType.name}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.materials.fields.defaultPrice`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              <DecimalField value={item.defaultPrice} hasSymbol={true} />
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          type: 'action',
          value: (item, index) => (
            <InfiniteListStyles.StyledActionTd key={index}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                size="medium"
                title={`Adicionar ${item.name}`}
                onClick={() => onSelect(item.id)}
              >
                {i18next.t(`resources.${resource}.actions.create.add`)}
              </Button>
            </InfiniteListStyles.StyledActionTd>
          )
        }
      ]}
    />
  );
};
