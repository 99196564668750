import styled from "styled-components";

export const UsersBox = styled.div`
  display: flex;
  width: 98%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px; 
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid rgb(221, 221, 221);
`;

export const UserBox = styled.div`
  flex: 0 0 100%;
  
  & svg {
    margin-right: 10px;
  }

  & i {
    display: none;
    float: right;
    margin: 10px;

    & svg {
      margin-left: 10px;
    }
  }

  &:hover {
    background-color: rgb(255, 244, 244);

    & i {
      display: inline;
    }
  }
`;

export const UserName= styled.span`
  line-height: 40px;

  & svg {
    margin-top: -5px;
    margin-left: 5px;
    color: green;
  }
`