import { Route, Routes } from "react-router-dom"
import { PermittedRoute } from "src/Components/Auth/PermittedRoute"
import { MaterialRoutes } from "./MaterialRoutes";
import { GuardedComponent } from "src/Components/Financial/Guarded";
import { MaterialTypeRoutes } from "./MaterialTypeRoutes";
import { PlaceRoutes } from "./PlaceRoutes";
import { PlaceTypeRoutes } from "./PlaceTypeRoutes";
import { InboundRoutes } from "./InboundRoutes";
import { OutboundRoutes } from "./OutboundRoutes";
import { ROLE_ADMIN, ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles";
import { ValidateRoute } from "src/Components/Auth/ValidateRoute";

export const StockRoutes = () => 
{    
    return (
        <Routes> 
            <Route 
                path="/materials/*" 
                element={GuardedComponent(ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<MaterialRoutes />)))} 
            />
            <Route 
                path="/material-types/*" 
                element={GuardedComponent(ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], GuardedComponent(<MaterialTypeRoutes />)))} 
            />
            <Route 
                path="/places/*" 
                element={GuardedComponent(ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <PlaceRoutes />))} 
            />
            <Route 
                path="/place-types/*" 
                element={GuardedComponent(ValidateRoute([ROLE_ADMIN], <PlaceTypeRoutes />))} 
            />
            <Route 
                path="/inbounds/*" 
                element={GuardedComponent(ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER], <InboundRoutes />))} 
            />
            <Route 
                path="/outbounds/*" 
                element={GuardedComponent(ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER], <OutboundRoutes />))} 
            />
            <Route 
                path="/*" 
                element={GuardedComponent(ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER], <InboundRoutes />))} 
            />
        </Routes>
    );
}