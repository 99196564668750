import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const InputRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 28px;

  .MuiAutocomplete-root {
    width: 100%;
  }
`;
