import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import i18next from 'i18next';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useForm } from 'react-hook-form';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';

export const CampaignList = () => {
  const { control } = useForm();
  const { options: elections } = useGetManyOptions(api, '/elections')
  const { data, setData, fetchData, hasMore, filters, setFilters } =
    useInfiniteFetch(api, '/campaigns', 25);
  
  const formatter = new Intl.NumberFormat([], {
    style: 'currency',
    currency: 'BRL'
  });

  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/campaigns/${id}`);
      setData(data.filter(item => item.id !== id));
      showSuccessMessage('resources.campaigns.actions.delete.success');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={'resources.campaigns.name'} mdSize={1200}>
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton />}
        filters={
          <>
            {elections &&
              <ReferenceInput
                name="electionId"
                control={control}
                label={`resources.campaigns.filter.electionId`}
                options={elections}
                style={{ minWidth: 300 }}
                onChange={value => {
                  const newFilters = {
                    ...filters,
                    ...{ electionId: value }
                  };
                  setFilters(newFilters);
                }}
              />
            }
            <SearchInput
              name="search"
              label={i18next.t(`resources.campaigns.filter.search`)}
              filters={filters}
              setFilters={setFilters}
            />
          </>
        }
        columns={[
          {
            header: 'resources.campaigns.fields.electionId',
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.election?.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: 'resources.campaigns.fields.name',
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.campaigns.fields.amount`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {formatter.format(parseFloat(item.amount))}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: 'Ações',
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`${item.id}/show`} />
                <EditButton url={`${item.id}`} />
                <DeleteButton handleDelete={() => handleDelete(item.id)} />
              </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
};
