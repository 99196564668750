import { Route, Routes } from "react-router-dom"
import { UserRoutes } from "./UserRoutes"
import { VoterRoutes } from "./VoterRoutes"
import { BaseRoutes } from "./BaseRoutes"
import { ResearchRoutes } from "./ResearchRoutes"
import { PartyRoutes } from "./PartyRoutes"
import { CandidateRoutes } from "./CandidateRoutes"
import { ResearchReport } from "src/Pages/Election/Reports/Research"
import { Comparative } from "src/Pages/Election/Reports/Comparative"
import { IntentionStatsPages } from "src/Pages/Election/IntentionStats"
import { MapsResearch } from "src/Pages/Election/Reports/Maps"
import { HomePage } from "src/Pages/Election/Home"
import { ROLE_ADMIN, ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE, ROLE_SUPERVISOR } from "src/Enums/Roles"
import { ValidateRoute } from "src/Components/Auth/ValidateRoute"
import { ResumeResultsTSE } from "src/Pages/Election/Reports/ResumeResulsTSE"
import { ElectionsRoutes } from "./ElectionsRoutes"
import { CampaignRoutes } from "./CampaignRoutes"

export const ElectionRoutes = () => 
{    
    return (
        <Routes> 
            <Route path="/elections/*" element={ValidateRoute(ROLE_ADMIN, <ElectionsRoutes />)} />
            
            <Route path="/campaigns/*" element={ValidateRoute(ROLE_CANDIDATE, <CampaignRoutes />)} />
            
            <Route path="/users/*" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER, ROLE_SUPERVISOR], <UserRoutes />)} />

            <Route path="/voters/*" element={<VoterRoutes />} />

            <Route path="/bases/*" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <BaseRoutes />)} />

            <Route path="/research/*" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <ResearchRoutes />)} />

            <Route path="/parties/*" element={ValidateRoute(ROLE_CANDIDATE, <PartyRoutes />)} />
    
            <Route path="/candidates/*" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <CandidateRoutes />)} />

            <Route path="/report" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <ResearchReport />)} />

            <Route path="/comparative" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER],<Comparative />)} />
            
            <Route path="/intention-stats" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <IntentionStatsPages />)} />
            
            <Route path="/maps" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <MapsResearch />)} />
            
            <Route path="/resume-table" element={ValidateRoute([ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER], <ResumeResultsTSE />)} />

            <Route path="/*" element={<HomePage />} />
        </Routes>
    );
}