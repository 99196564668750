import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Elections';
import {
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TablePagination
} from '@mui/material';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { ResearchesAutocompleteInput } from './Inputs/ResearchesAutocompleteInput';
import { CandidatesAutocompleteInput } from './Inputs/CandidatesAutocompleteInput';
import { Form } from 'src/Components/Admin/Form';
import { DataTable } from './DataTable/index';
import { TotalVotesCardComponents } from 'src/Components/Cards/TotalVotes';

export const ResumeResultsTSE = () => {
  const resource = 'resources.reports.elections.resumeResults';
  const { control, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [cityId, setCityId] = useState('');
  const [electoralZoneId, setElectoralZoneId] = useState('');
  const [researchesSelected, setResearchesSelected] = useState<any>([]);
  const [candidatesSelected, setCandidatesSelected] = useState<any>([]);
  const [votesByPlaceData, setVotesByPlaceData] = useState<any>(null);
  const [totalVotesByPlaceData, setTotalVotesByPlaceData] = useState<any>(null);
  const [totalVotesData, setTotalVotesData] = useState<number | null>(null);
  const [totalSectionsByElectoralZoneData, setTotalSectionsByElectoralZoneData] = useState<any>(null);
  const [votesByPlacePage, setVotesByPlacePage] = useState(0);
  const [totalVotesByPlacePage, setTotalVotesByPlacePage] = useState(0);
  const [totalSectionsByElectoralZonePage, setTotalSectionsByElectoralZonePage] = useState(0);
  const [votesByPlaceRowsPerPage, setVotesByPlaceRowsPerPage] = useState(10);
  const [totalVotesByPlaceRowsPerPage, setTotalVotesByPlaceRowsPerPage] = useState(10);
  const [totalSectionsByElectoralZoneRowsPerPage, setTotalSectionsByElectoralZoneRowsPerPage] = useState(10);
  const { options: positions } = useGetManyOptions(api, '/candidate-position');
  const {
    options: researches,
    filters: researchFilters,
    setFilters: setResearchFilters
  } = useGetManyOptions(api, '/research', {}, 'id', 'description');
  const { options: zones } = useGetManyOptions(api, '/zones');
  const { options: cities } = useGetManyOptions(api, '/city');
  const { options: electoralZones } = useGetManyOptions(api, '/electoral-zones', { cityId }, 'id', 'identifier');
  const { options: electoralPlaces } = useGetManyOptions(api, '/electoral-place', { electoralZoneId }, 'id', 'name');
  const { options: states } = useGetManyOptions(api, '/state', {}, 'id', 'uf');
  const { options: parties } = useGetManyOptions(api, '/electoral-party', {}, 'id', 'resumeName');

  useEffect(() => {
    if (researchesSelected && researchesSelected.length) {
      api.get('/research-candidate/in-researches', {
        params: {
          researchIds: researchesSelected.map(option => option.value)
        }
      }).then(result => {
        if (result && result.status === 200) {
          setCandidates(result.data);
        }
      });
    } else {
      setCandidates([]);
    }
  }, [researchesSelected]);

  useEffect(() => {
    updateCardTotalVotes();
  }, [totalVotesByPlaceData]);

  const fetchVotesByPlace = (filters, page = votesByPlacePage, pageSize = votesByPlaceRowsPerPage) => {
    const params = {
      ...filters,
      page: page + 1, // ajustar para refletir a base 1
      pageSize
    };

    setLoading(true);
    api.get('/pooling-intention/resume-results-table/votes-by-place', { params })
      .then(result => {
        setLoading(false);
        if (result && result.status === 200) {
          setVotesByPlaceData(result.data);
        }
      })
      .catch(() => setLoading(false));
  };

  const fetchTotalVotesByPlace = (filters, page = totalVotesByPlacePage, pageSize = totalVotesByPlaceRowsPerPage) => {
    const params = {
      ...filters,
      page: page + 1, // ajustar para refletir a base 1
      pageSize
    };

    setLoading(true);
    api.get('/pooling-intention/resume-results-table/total-votes-by-place', { params })
      .then(result => {
        setLoading(false);
        if (result && result.status === 200) {
          setTotalVotesByPlaceData(result.data);
        }
      })
      .catch(() => setLoading(false));
  };

  const fetchTotalSectionByElectoralZone = (filters, page = totalSectionsByElectoralZonePage, pageSize = totalSectionsByElectoralZoneRowsPerPage) => {
    const params = {
      ...filters,
      page: page + 1, // ajustar para refletir a base 1
      pageSize
    };

    setLoading(true);
    api.get('/pooling-intention/resume-results-table/total-sections', { params })
      .then(result => {
        setLoading(false);
        if (result && result.status === 200) {
          setTotalSectionsByElectoralZoneData(result.data);
        }
      })
      .catch(() => setLoading(false));
  };

  const getFilters = () => ({
    researchIds: getValues('researches') ? getValues('researches').map(option => option.value) : [],
    candidateIds: getValues('candidates') ? getValues('candidates').map(option => option.id) : [],
    partyId: getValues('partyId'),
    zoneId: getValues('zoneId'),
    electoralZoneId: getValues('electoralZoneId'),
    electoralPlaceId: getValues('electoralPlaceId'),
    stateId: getValues('stateId'),
    cityId: getValues('cityId'),
    neighborhood: getValues('neighborhood'),
    address: getValues('address'),
    periodFrom: getValues('votesFrom'),
    periodTo: getValues('votesTo')
  });

  const handleVotesByPlacePageChange = (event, newPage) => {
    setVotesByPlacePage(newPage);
    fetchVotesByPlace(getFilters(), newPage, votesByPlaceRowsPerPage);
  };

  const handleVotesByPlaceRowsPerPageChange = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setVotesByPlaceRowsPerPage(rowsPerPage);
    setVotesByPlacePage(0);
    fetchVotesByPlace(getFilters(), 0, rowsPerPage);
  };

  const handleTotalVotesByPlacePageChange = (event, newPage) => {
    setTotalVotesByPlacePage(newPage);
    fetchTotalVotesByPlace(getFilters(), newPage, totalVotesByPlaceRowsPerPage);
  };

  const handleTotalVotesByPlaceRowsPerPageChange = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setTotalVotesByPlaceRowsPerPage(rowsPerPage);
    setTotalVotesByPlacePage(0);
    fetchTotalVotesByPlace(getFilters(), 0, rowsPerPage);
  };

  const handleTotalSectionsByElectoralZonePageChange = (event, newPage) => {
    setTotalSectionsByElectoralZonePage(newPage);
    fetchTotalSectionByElectoralZone(getFilters(), newPage, totalSectionsByElectoralZoneRowsPerPage);
  };

  const handleTotalSectionsByElectoralZoneRowsPerPageChange = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setTotalSectionsByElectoralZoneRowsPerPage(rowsPerPage);
    setTotalSectionsByElectoralZonePage(0);
    fetchTotalSectionByElectoralZone(getFilters(), 0, rowsPerPage);
  };

  const calculateTotalVotes = (data) => {
    if (!Array.isArray(data)) {
      return 0;
    }
  
    const totalVotes = data.reduce((accumulated, current) => {
      return accumulated + current.totalVotes;
    }, null); // Valor inicial do acumulador é 0
  
    return totalVotes;
  }
  
  const updateCardTotalVotes = () => {
    if (!totalVotesByPlaceData || totalVotesByPlaceData.data.length <= 0) {
      setTotalVotesData(null);
      return;
    }
  
    const totalVotes = calculateTotalVotes(totalVotesByPlaceData.data);
    setTotalVotesData(totalVotes);
  }
  

  const onSubmit = (form) => {
    setCandidatesSelected(form.data.candidates);
    setVotesByPlacePage(0);
    setTotalVotesByPlacePage(0);
    fetchVotesByPlace(getFilters(), 0, votesByPlaceRowsPerPage);
    fetchTotalVotesByPlace(getFilters(), 0, totalVotesByPlaceRowsPerPage);
    fetchTotalSectionByElectoralZone(getFilters(),0, totalSectionsByElectoralZoneRowsPerPage)
  };

  const of = i18next.t(`${resource}.tables.labelPageOf`);
  const moreThan = i18next.t(`${resource}.tables.labelMoreThan`);

  return (
    <SimpleContainer title={`${resource}.name`}>
      <Form
        method='post'
        control={control}
        onSubmit={onSubmit}
        submitButtonLabel='actions.search'
        style={{ marginBottom: "10px" }}
      >
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            {positions && (
              <ReferenceInput
                name={'positionId'}
                control={control}
                label={i18next.t(`${resource}.filters.positionId.label`)}
                options={positions}
                onChange={value => {
                  setResearchFilters({
                    ...researchFilters,
                    ...{ positionId: value }
                  });
                }}
              />
            )}
          </Grid>
          <Grid item md={8} xs={12}>
            {researches && (
              <ResearchesAutocompleteInput
                researches={researches}
                control={control}
                label={i18next.t(`${resource}.filters.researches.label`)}
                setResearchesSelected={setResearchesSelected}
              />
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            {candidates && researchesSelected && (
              <CandidatesAutocompleteInput
                candidates={candidates}
                control={control}
                label={i18next.t(`${resource}.filters.candidates.label`)}
              />
            )}
          </Grid>
          <Grid item md={12}>
            <Accordion
              sx={{
                boxShadow: 'unset',
                '& .MuiAccordionSummary-root': {
                  padding: 0,
                  color: '#1976d2',
                  minHeight: 30,
                  '&:hover': {
                    color: '#4c9fef'
                  },
                  '&.Mui-expanded': {
                    minHeight: 30
                  }
                },
                '& .MuiAccordionDetails-root': {
                  paddingX: 0
                },
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                  '&.Mui-expanded': {
                    margin: 0
                  }
                }
              }}
            >
              <AccordionSummary>
                {i18next.t(`${resource}.actions.advanced`)}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    {parties && (
                      <ReferenceInput
                        name={'partyId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.partyId.label`)}
                        options={parties}
                      />
                    )}
                  </Grid>
                  <Grid item md={1} xs={12}>
                    {states && (
                      <ReferenceInput
                        name={'state'}
                        control={control}
                        label={i18next.t(`${resource}.filters.state.label`)}
                        options={states}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {cities && (
                      <ReferenceInput
                        name={'cityId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.cityId.label`)}
                        options={cities}
                        defaultValue={cityId}
                        onChange={value => {
                          setCityId(value);
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={2} xs={12}>
                    {zones && (
                      <ReferenceInput
                        name={'zoneId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.zoneId.label`)}
                        options={zones}
                      />
                    )}
                  </Grid>
                  <Grid item md={2} xs={12}>
                    {electoralZones && (
                      <ReferenceInput
                        name={'electoralZoneId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.electoralZoneId.label`)}
                        options={electoralZones}
                        defaultValue={electoralZoneId}
                        onChange={value => {
                          setElectoralZoneId(value);
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {electoralPlaces && (
                      <ReferenceInput
                        name={'electoralPlaceId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.electoralPlaceId.label`)}
                        options={electoralPlaces}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CustomInput
                      name={'neighborhood'}
                      control={control}
                      label={`${resource}.filters.neighborhood.label`}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <CustomInput
                      name={'address'}
                      control={control}
                      label={`${resource}.filters.address.label`}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Form>
      {loading && <CircularProgress />}
      {totalVotesData && (
        <Styled.Container>
          <TotalVotesCardComponents 
            color='green'
            quantity={totalVotesData.toString()}
            title={i18next.t(`${resource}.results.totalVotesGeneralTitle`)}
          />
        </Styled.Container>
      )}
      {votesByPlaceData && (
        <Styled.Container>
          <Styled.Header>
            <Styled.Title>
              {i18next.t(`${resource}.results.votesByPlaceTitle`)}
            </Styled.Title>
          </Styled.Header>
          <DataTable data={votesByPlaceData.data} columns={[
            { header: i18next.t(`${resource}.tables.votesByPlace.electoralZone`), key: 'electoralZone' },
            { header: i18next.t(`${resource}.tables.votesByPlace.electoralSection`), key: 'electoralSection' },
            { header: i18next.t(`${resource}.tables.votesByPlace.placeName`), key: 'placeName' },
            { header: i18next.t(`${resource}.tables.votesByPlace.placeAddress`), key: 'placeAddress' },
            { header: i18next.t(`${resource}.tables.votesByPlace.placeNeighborhood`), key: 'placeNeighborhood' },
            { header: i18next.t(`${resource}.tables.votesByPlace.placeIdentifier`), key: 'placeIdentifier' },
            { header: i18next.t(`${resource}.tables.votesByPlace.zoneName`), key: 'zoneName' },
            { header: i18next.t(`${resource}.tables.votesByPlace.votersSupported`), key: 'votersSupported' },
            { header: i18next.t(`${resource}.tables.votesByPlace.votersConfirmed`), key: 'votersConfirmed' },
          ]} />
          <TablePagination
            component="div"
            count={votesByPlaceData.totalItems}
            page={votesByPlacePage}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} ${of} ${count !== -1 ? count : `${moreThan} ${to}`}`
            }}
            labelRowsPerPage={i18next.t(`${resource}.tables.labelRowsPerPage`)}
            onPageChange={handleVotesByPlacePageChange}
            rowsPerPage={votesByPlaceRowsPerPage}
            onRowsPerPageChange={handleVotesByPlaceRowsPerPageChange}
          />
        </Styled.Container>
      )}
      {totalVotesByPlaceData && (
        <Styled.Container>
          <Styled.Header>
            <Styled.Title>
              {i18next.t(`${resource}.results.totalVotesByPlaceTitle`)}
            </Styled.Title>
          </Styled.Header>
          <DataTable data={totalVotesByPlaceData.data} columns={[
            { header: i18next.t(`${resource}.tables.totalVotesByPlace.electoralZone`), key: 'electoralZone' },
            { header: i18next.t(`${resource}.tables.totalVotesByPlace.placeName`), key: 'placeName' },
            { header: i18next.t(`${resource}.tables.totalVotesByPlace.totalVotes`), key: 'totalVotes' },
          ]} />
          <TablePagination
            component="div"
            count={totalVotesByPlaceData.totalItems}
            page={totalVotesByPlacePage}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} ${of} ${count !== -1 ? count : `${moreThan} ${to}`}`
            }}
            labelRowsPerPage={i18next.t(`${resource}.tables.labelRowsPerPage`)}
            onPageChange={handleTotalVotesByPlacePageChange}
            rowsPerPage={totalVotesByPlaceRowsPerPage}
            onRowsPerPageChange={handleTotalVotesByPlaceRowsPerPageChange}
          />
        </Styled.Container>
      )}
      {totalSectionsByElectoralZoneData && (
        <Styled.Container>
          
          <Styled.Container>
            <Styled.Header>
              <Styled.Title>
                {i18next.t(`${resource}.results.totalSectionsByElectoralZoneTitle`)}
              </Styled.Title>
            </Styled.Header>
            <DataTable data={totalSectionsByElectoralZoneData.data} columns={[
              { header: i18next.t(`${resource}.tables.totalSectionsByElectoralZone.electoralZone`), key: 'electoralZone' },
              { header: i18next.t(`${resource}.tables.totalSectionsByElectoralZone.quantityLocals`), key: 'quantityLocals' },
              { header: i18next.t(`${resource}.tables.totalSectionsByElectoralZone.quantitySections`), key: 'quantitySections' },
            ]} />
            <TablePagination
              component="div"
              count={totalSectionsByElectoralZoneData.totalItems}
              page={totalSectionsByElectoralZonePage}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} ${of} ${count !== -1 ? count : `${moreThan} ${to}`}`
              }}
              labelRowsPerPage={i18next.t(`${resource}.tables.labelRowsPerPage`)}
              onPageChange={handleTotalSectionsByElectoralZonePageChange}
              rowsPerPage={totalSectionsByElectoralZoneRowsPerPage}
              onRowsPerPageChange={handleTotalSectionsByElectoralZoneRowsPerPageChange}
            />
          </Styled.Container>
        </Styled.Container>
      )}
    </SimpleContainer>
  );
};
