import * as React from 'react';
import { OutboundForm } from './OutboundForm';
import { api } from 'src/Services/Financial';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

export const OutboundCreate = () => {
  const navigate = useNavigate();

  const onSubmit = formData => {
    api.post('/material-outbounds', formData.data).then(result => {
      if (result?.status == 201) {
        showSuccessMessage(
          i18next.t(`resources.materialOutbounds.actions.create.success`)
        );
        navigate(`/stock/outbounds/${result.data.id}/show`);
      }
    });
  };

  return (
    <OutboundForm
      data={{
        personId: null
      }}
      onSubmit={onSubmit}
    />
  );
};
