import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { DecimalField } from 'src/Components/Admin/Fields/DecimalField';
import Button from '@mui/material/Button';
import i18next from 'i18next';
import { ReturnAction } from 'src/Components/Admin/Actions/ReturnAction';
import { ConfigContext } from 'src/Hooks/useConfigContext';

interface OutboundItemsSearchProps {
  outboundId: string;
  placeId: string;
  dueDate: string;
  setAcao: (acao: string) => void;
}

export const OutboundItemsSearch = ({
  outboundId,
  placeId,
  dueDate,
  setAcao
}: OutboundItemsSearchProps) => {
  const endpoint = '/material-outbounds/availables';
  const resource = 'materialOutboundItems.search';
  const { config } = React.useContext(ConfigContext);
  const { data, fetchData, hasMore, filters, setFilters } = useInfiniteFetch(
    api,
    endpoint,
    25,
    {
      placeId,
      dueDate
    }
  );

  const onSelect = async (materialId, amount) => {
    const item = {
      outboundId,
      materialId,
      unitPrice: amount,
      quantity: 1,
      totalPrice: amount
    };

    api.post(`/material-outbounds/${outboundId}/items`, item).then(result => {
      if (result && result.status == 201) {
        showSuccessMessage(i18next.t(`resources.${resource}.messages.success`));
      }
    });
  };

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      actions={
        <ReturnAction
          onClick={() => setAcao('list')}
          title={i18next.t(`resources.${resource}.return.title`)}
          label={i18next.t(`resources.${resource}.return.title`)}
        />
      }
      filters={
        <>
          <SearchInput
            name="search"
            label={i18next.t('resources.materialOutboundItems.filter.search')}
            filters={filters}
            setFilters={setFilters}
            style={{ minWidth: 300 }}
          />
        </>
      }
      columns={[
        {
          header: `resources.${resource}.fields.id`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item?.name}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.fields.type`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item?.typeName}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.fields.unitPrice`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              <DecimalField value={item?.amount} hasSymbol={true} />
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.fields.quantity`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              <DecimalField value={item?.quantity} />
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          type: 'action',
          value: (item, index) => (
            <InfiniteListStyles.StyledActionTd key={index}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                size="medium"
                title={
                  i18next.t(`resources.${resource}.addButton.title`) +
                  ' ' +
                  item?.name
                }
                onClick={() =>
                  onSelect(item?.id, parseFloat(item?.amount).toFixed(2))
                }
              >
                {i18next.t(`resources.${resource}.addButton.text`)}
              </Button>
            </InfiniteListStyles.StyledActionTd>
          )
        }
      ]}
    />
  );
};
