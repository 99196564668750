import * as React from "react";
import { Link } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

interface ListActionProps {
    baseUrl?: string;
    url?: string;
}

export const ListAction = ({ url, baseUrl }: ListActionProps) => {

    if (!url) {
        url = baseUrl
    }
    
    return (
        <Link to={url as string}>
            <FormatListBulletedIcon/>
        </Link>
    );
}

ListAction.defaultProps = {
    baseUrl: '',
    url: ''
}