import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Elections';
import {
  Alert,
  CircularProgress,
  Grid, 
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { ResearchesAutocompleteInput } from './Inputs/ResearchesAutocompleteInput';
import { CandidatesAutocompleteInput } from './Inputs/CandidatesAutocompleteInput';
import { Form } from 'src/Components/Admin/Form';
import { List } from './Lists/List';
import { ListComparative } from './Lists/ListComparative';
import { LineChartComponents } from './Graphs/LineChart';
import { BarChart } from './Graphs/BarChart';
import { Heatmap } from './Graphs/HeatMapChart';
import { IndividualBarChart } from './Graphs/IndividualBarChart';

export const Comparative = () => 
{
  const resource = 'resources.reports.elections.comparative';
  const { control } = useForm();
  const [loading, setLoading] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [cityId, setCityId] = useState('')
  const [researchesSelected, setResearchesSelected] = useState<any>([])
  const [candidatesSelected, setCandidatesSelected] = useState<any>([])
  const [data, setData] = useState<any>(null)
  const [comparativeData, setComparativeData] = useState<any>(null)
  const [dataPerZone, setDataPerZone] = useState<any>(null)
  const { options: positions } = useGetManyOptions(api, '/candidate-position');
  const {
    options: researches, 
    filters: researchFilters,
    setFilters: setResearchFilters 
  } = useGetManyOptions(api, '/research', {}, 'id', 'description');
  const { options: zones } = useGetManyOptions(api, '/zones');
  const { options: cities } = useGetManyOptions(api, '/city');
  const { options: electoralZones } = useGetManyOptions(api, '/electoral-zones', { cityId }, 'id', 'identifier');
  const { options: states } = useGetManyOptions(api, '/state', {}, 'id', 'uf');
  const { options: parties } = useGetManyOptions(api, '/electoral-party', {}, 'id', 'resumeName');
  
  
  // Load candidates base on researches selected
  useEffect(() => {
    if(researchesSelected && researchesSelected.length) 
    {
      api.get('/research-candidate/in-researches', {
        params: {
          researchIds: researchesSelected.map(option => option.value)
        }
      }).then(result => {
        if (result && result.status == 200) {
          setCandidates(result.data)
        }
      })
    } else {
      setCandidates([])
    }

  }, [researchesSelected])

  // Make Request based on filters
  const onSubmit = (form) => 
  {
    let request: Promise<any>
    setLoading(true)
    const params = {
      researchIds: form.data.researches ? form.data.researches.map(option => option.value) : [],
      candidateIds: form.data.candidates ? form.data.candidates.map(option => option.id) : [],
      partyId: form.data.partyId,
      zoneId: form.data.zoneId,
      electoralZoneId: form.data.electoralZoneId,
      stateId: form.data.stateId,
      cityId: form.data.cityId,
      neighborhood: form.data.neighborhood,
      address: form.data.address
    }

    setCandidatesSelected(form.data.candidates)
    
    if (form.data.votesFrom) {
      params['periodFrom'] = form.data.votesFrom
    }

    if (form.data.votesTo) {
      params['periodTo'] = form.data.votesTo
    }

    if (form.data.researches && form.data.researches.length > 1) 
    {
      setData(null)
      params['researchIds'] = form.data.researches ? form.data.researches.map(option => option.value) : []
      request = api.get('/pooling-intention/ranking-candidates-comparative', {
        params
      }).then(result => {
        setLoading(false)
        if (result && result.status == 200) {
          setComparativeData(result.data)
        }
      })
    } else {
      setComparativeData(null)
      params['researchId'] = form.data.researches ? form.data.researches[0].value : [],
      request = api.get(`pooling-intention/ranking-candidates`, {
        params
      }).then(result => {
        setLoading(false)
        if (result && result.status == 200) {
          setData(result.data.candidates)
          setDataPerZone(result.data.zones)
        }
      })
    }

    request.then(result => {
      setLoading(false)
      if (result && result.status == 200) {
        setData(result.data)
      }
    }).catch(() => setLoading(false))
  }

  return (
    <SimpleContainer title={`${resource}.name`}>
      <Form 
          method='post' 
          control={control}
          onSubmit={onSubmit}
          submitButtonLabel='actions.search'
          style={{ marginBottom: "10px" }}
      >
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            {positions && (
              <ReferenceInput
                name={'positionId'}
                control={control}
                label={i18next.t(`${resource}.filters.positionId.label`)}
                options={positions}
                onChange={value => {
                  setResearchFilters({
                    ...researchFilters,
                    ...{ positionId: value }
                  })
                }}
              />
            )}
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput 
              control={control} 
              name="researchesFrom" 
              label={i18next.t(`${resource}.filters.researchesFrom.label`)}
              onChange={e => {
                const newFilters = { 
                  ...researchFilters,
                  ...{ startDate: e.target.value } 
                }
                setResearchFilters(newFilters)
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput 
              control={control} 
              name="researchesTo" 
              label={i18next.t(`${resource}.filters.researchesTo.label`)}
              onChange={e => {
                const newFilters = { 
                  ...researchFilters,
                  ...{ endDate: e.value } 
                }
                setResearchFilters(newFilters)
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput 
              control={control} 
              name="votesFrom" 
              label={i18next.t(`${resource}.filters.votesFrom.label`)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput 
              control={control} 
              name="votesTo" 
              label={i18next.t(`${resource}.filters.votesTo.label`)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {researches && (
              <ResearchesAutocompleteInput 
                researches={researches} 
                control={control} 
                label={i18next.t(`${resource}.filters.researches.label`)}
                setResearchesSelected={setResearchesSelected}
              />
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            {candidates && researchesSelected && (
              <CandidatesAutocompleteInput 
                candidates={candidates} 
                control={control} 
                label={i18next.t(`${resource}.filters.candidates.label`)}
              />
            )}
          </Grid>
          <Grid item md={12}>
            <Accordion
              sx={{
                boxShadow: 'unset',
                '& .MuiAccordionSummary-root': {
                  padding: 0,
                  color: '#1976d2',
                  minHeight: 30,
                  '&:hover': {
                    color: '#4c9fef'
                  },
                  '&.Mui-expanded': {
                    minHeight: 30
                  }
                },
                '& .MuiAccordionDetails-root': {
                  paddingX: 0
                },
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                  '&.Mui-expanded': {
                    margin: 0
                  }
                }
              }}
            >
              <AccordionSummary>
                {i18next.t(`${resource}.actions.advanced`)}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    {parties && (
                      <ReferenceInput
                        name={'partyId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.partyId.label`)}
                        options={parties}
                      />
                    )}
                  </Grid>
                  <Grid item md={1} xs={12}>
                    {states && (
                      <ReferenceInput
                        name={'state'}
                        control={control}
                        label={i18next.t(`${resource}.filters.state.label`)}
                        options={states}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {cities && (
                      <ReferenceInput
                        name={'cityId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.cityId.label`)}
                        options={cities}
                        defaultValue={cityId}
                        onChange={value => { 
                          setCityId(value)
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={2} xs={12}>
                    {zones && (
                      <ReferenceInput
                        name={'zoneId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.zoneId.label`)}
                        options={zones}
                      />
                    )}
                  </Grid>
                  <Grid item md={2} xs={12}>
                    {electoralZones && (
                      <ReferenceInput
                        name={'electoralZoneId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.electoralZoneId.label`)}
                        options={electoralZones}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CustomInput
                      name={'neighborhood'}
                      control={control}
                      label={`${resource}.filters.neighborhood.label`}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <CustomInput
                      name={'address'}
                      control={control}
                      label={`${resource}.filters.address.label`}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Form>
      {loading && <CircularProgress />}

      {(comparativeData || data)  && ( 
        <>
        <Styled.Container>
          <Styled.Header>
            <Styled.Title>
              Pesquisa
            </Styled.Title>
          </Styled.Header>
          {comparativeData && (
            <>
              {(comparativeData.length == 0) && <Alert severity="info">Sem dados para exibir.</Alert>}
              {(comparativeData.length > 0) && (
                <>
                  {(candidatesSelected.length > 1)
                    ? <LineChartComponents data={comparativeData} />
                    : <IndividualBarChart data={comparativeData} />
                  }
                  <ListComparative data={comparativeData} />
                </>
              )}
            </>
          )}
          {data && (data.length == 0) && <Alert severity="info">Sem dados para exibir.</Alert>}
          {data && data.length > 0 && (
            <>
              <div style={{ height: 100 + 80 * data.length, width: '80%' }}>
              <Styled.Graph style={{ height: '95%', width: '90%' }}>
                <BarChart data={data} />
              </Styled.Graph>
              </div>

              <List data={data} />
            </>
          )}
        </Styled.Container>

        {data && dataPerZone && 
          <Heatmap 
            data={dataPerZone} 
            setData={setDataPerZone} 
            control={control} 
            resource={resource} 
          />}
        </>
      )}
    </SimpleContainer>
  );
};
