import React from 'react';
import styles from './index.module.scss';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { formatCurrency } from 'src/Utils/Formatters';
import { PieChartComponents } from 'src/Components/Reports/PieChartComponents';
import { StatCard } from 'src/Components/Reports/Cards/StatCard';
import provider from 'src/Providers/Financial/ReportsDataProvider';
import { api } from 'src/Services/Financial';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import i18next from 'i18next';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetOne from 'src/Hooks/useGetOne';


export const FinancialResumeAccount = () => {
  const { config } = React.useContext(ConfigContext);
  const { campaignId, accountId } = config ?? { campaignId: null, accountId: null };
  const [expensesGraphData, setExpensesGraphData] = useState<any>(null);
  const [totalBalance, setTotalBalance] = useState<any>(null);
  const [finalAmount, setFinalAmount] = useState(0);
  const { data: account } = useGetOne(api, '/accounts', accountId);

  const getDoughnutOptions = (title: string) => {
    return {
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center'
        },
        datalabels: {
          formatter: function (value, context) {
            return value + '%';
          }
        },
        tooltip: {
          callbacks: {
            label: context => {
              return (
                'Valor: ' +
                formatCurrency(context.dataset.otherData[context.dataIndex])
              );
            }
          }
        },
        title: {
          display: true,
          text: title,
          font: {
            size: 24
          }
        }
      }
    }
  }

  useEffect(() => {
    if (accountId) {
     
      provider.getTotalBalance(accountId).then(result => {
        setTotalBalance(result.data);
      });
      provider.listExpensesGraph(accountId).then(result => {
        setExpensesGraphData(result.data);
      });
      
    }
  }, [accountId]);

  useEffect(() => {
    if (account && totalBalance) {
      const total =
        - parseFloat(account.amount) - totalBalance.totalExpenses;
      setFinalAmount(parseFloat(total.toFixed(2)));
    }
  }, [totalBalance, account]);

  return (
    <>
      <SimpleContainer title={`resources.reports.financial.resumeAccount.name`}>
        <Grid container spacing={2} style={{ marginTop: 0, marginBottom: 15 }}>
          {totalBalance && (
            <>
              <Grid item md={3} xs={12}>
                <StatCard
                  label={i18next.t(
                    `resources.reports.financial.resumeAccount.table.amount`
                  )}
                  value={formatCurrency(account.amount ?? 0)}
                  backgroundColor="rgba(98, 153, 211, 1)"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <StatCard
                  label={i18next.t(
                    `resources.reports.financial.resumeAccount.table.expenses`
                  )}
                  value={formatCurrency(totalBalance ?? 0)}
                  backgroundColor="rgba(233, 104, 104, 1)"
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <StatCard
                  label={i18next.t(
                    `resources.reports.financial.resumeAccount.table.total_credit`
                  )}
                  value={formatCurrency(
                    account.amount -
                      totalBalance
                  )}
                />
              </Grid>
            </>
          )}

          <div className={styles.report__graphs}>
            {expensesGraphData && (
              <div className={styles.report__graphs__graph}>
                <PieChartComponents
                  data={expensesGraphData.map(item => item.percent)}
                  labels={expensesGraphData.map(item => item.costcenterName)}
                  options={getDoughnutOptions(i18next.t(`resources.reports.financial.resume.graph.expenses`))}
                  otherData={expensesGraphData.map(item => item.amount)}
                />
              </div>
            )}
          </div>
        </Grid>
      </SimpleContainer>
    </>
  );
};
