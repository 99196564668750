import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';
import { CandidateForm } from './CandidateForm';
import { removePhoneMask } from 'src/Utils/Formatters';

export const CandidateCreate = () => {
  const navigate = useNavigate();

  const onSubmit = formData => {
    const cleanedTelphone = removePhoneMask(formData.data.telphone);

    const data = {
      ...formData.data,
      telphone: cleanedTelphone
    };

    api.post('/candidates', data).then(result => {
      if (result?.status == 201) {
        showSuccessMessage(
          i18next.t(`resources.candidates.actions.create.success`) +
            ':' +
            result.data.id
        );
        navigate(`/election/candidates/${result.data.id}/show`);
      }
    });
  };

  return (
    <CandidateForm
      data={{
        personId: null
      }}
      onSubmit={onSubmit}
    />
  );
};
