import * as Styled from './styles';

type GoalAchievedProps = {
  goal: number;
  value: number;
  text?: string
};

export const GoalAchieved = ({ goal, value, text }: GoalAchievedProps) => {
  const backgroundColor = (value >= goal) ? '#2E7D32' : '#FF0C00';

  return (
    <Styled.Container backgroundColor={backgroundColor}>
      <span>{(text  ? text : value)}</span>
    </Styled.Container>
  );
};
