import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { api } from 'src/Services/Elections';
import {
  Alert,
  Autocomplete,
  CircularProgress,
  Grid,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { Controller, useForm } from 'react-hook-form';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import ProfileImage from 'src/Components/Election/ProfileImagemUrl';
import { Form } from 'src/Components/Admin/Form';
import {
  MapIntentionProps,
  MapIntentions
} from 'src/Components/Election/MapIntentions';

export const MapsResearch = () => {
  const resource = 'resources.reports.elections.maps';
  const { control, watch } = useForm();
  const { options: positions } = useGetManyOptions(api, '/candidate-position');
  const {
    options: researches,
    filters: researchFilters,
    setFilters: setResearchFilters
  } = useGetManyOptions(api, '/research', {}, 'id', 'description');
  const { options: zones } = useGetManyOptions(api, '/zones');
  const { options: bases } = useGetManyOptions(api, '/bases');
  const { options: cities } = useGetManyOptions(api, '/city');
  const { options: states } = useGetManyOptions(api, '/state');
  const { options: parties } = useGetManyOptions(
    api,
    '/electoral-party',
    {},
    'id',
    'resumeName'
  );
  const [candidates, setCandidates] = useState([]);
  const watchResearches = watch(['researches']);
  const [comparativeData, setComparativeData] = useState<MapIntentionProps>();
  const [loading, setLoading] = useState(false);

  // Load candidates base on researches selected
  useEffect(() => {
    if (watchResearches[0] && watchResearches[0].length) {
      api
        .get('/research-candidate/in-researches', {
          params: {
            researchIds: watchResearches[0].map(option => option.value)
          }
        })
        .then(result => {
          if (result && result.status == 200) {
            setCandidates(result.data);
          }
        });
    } else {
      setCandidates([]);
    }
  }, [watchResearches[0]]);

  const buildQueryString = params => {
    const queryStringParams = Object.entries(params)
      .filter(([key, value]) => value !== null && value !== '')
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`
      )
      .join('&');

    return queryStringParams.length > 0 ? `?${queryStringParams}` : '';
  };

  // Make Request based on filters
  const onSubmit = form => {
    setLoading(true);

    if (!form.data.researches) {
      setLoading(false);
      return;
    }

    const params = {
      researchId: form.data.researches ? form.data.researches.value : null,
      candidatePositionId: form.data.positionId
        ? form.data.positionId.value
        : null,
      candidateIds: form.data.candidates
        ? form.data.candidates.map(option => option.id)
        : [],
      partyId: form.data.partyId,
      zoneId: form.data.zoneId,
      baseId: form.data.baseId,
      stateId: form.data.stateId,
      cityId: form.data.cityId,
      neighborhood: form.data.neighborhood,
      address: form.data.address
    };

    if (form.data.votesFrom) {
      params['periodFrom'] = form.data.votesFrom;
    }

    if (form.data.votesTo) {
      params['periodTo'] = form.data.votesTo;
    }

    params['researchId'] = form.data.researches.value;

    const queryStringParams = buildQueryString(params);

    const request: Promise<any> = api
      .get(`pooling-intention/maps-pooling-intentions${queryStringParams}`)
      .then(result => {
        setLoading(false);
        if (result && result.status == 200) {
          setComparativeData(result.data);
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <SimpleContainer title={`${resource}.name`}>
      <Form
        method="post"
        control={control}
        onSubmit={onSubmit}
        submitButtonLabel="actions.search"
        style={{ marginBottom: '10px' }}
      >
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            {positions && (
              <ReferenceInput
                name={'positionId'}
                control={control}
                label={i18next.t(`${resource}.filters.positionId.label`)}
                options={positions}
                onChange={value => {
                  setResearchFilters({
                    ...researchFilters,
                    ...{ positionId: value }
                  });
                }}
              />
            )}
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput
              control={control}
              name="researchesFrom"
              label={i18next.t(`${resource}.filters.researchesFrom.label`)}
              onChange={e => {
                const newFilters = {
                  ...researchFilters,
                  ...{ startDate: e.target.value }
                };
                setResearchFilters(newFilters);
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput
              control={control}
              name="researchesTo"
              label={i18next.t(`${resource}.filters.researchesTo.label`)}
              onChange={e => {
                const newFilters = {
                  ...researchFilters,
                  ...{ endDate: e.value }
                };
                setResearchFilters(newFilters);
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput
              control={control}
              name="votesFrom"
              label={i18next.t(`${resource}.filters.votesFrom.label`)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <DateInput
              control={control}
              name="votesTo"
              label={i18next.t(`${resource}.filters.votesTo.label`)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {researches && (
              <Controller
                name={'researches'}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    multiple={false}
                    options={researches}
                    noOptionsText={i18next.t(
                      'inputs.autocomplete.noOptionsText'
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.label}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={i18next.t(
                          `${resource}.filters.researches.label`
                        )}
                        placeholder={i18next.t(
                          `${resource}.filters.researches.label`
                        )}
                      />
                    )}
                    onChange={(e, selected) => {
                      field.onChange(selected);
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item md={12}>
            {candidates &&
              watchResearches[0] &&
              watchResearches[0].length > 1 && (
                <Controller
                  name={'candidates'}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      options={candidates}
                      noOptionsText={i18next.t(
                        'inputs.autocomplete.noOptionsText'
                      )}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.profileImageUrl && (
                            <ProfileImage
                              alt={option.name}
                              imageUrl={api.getUrl(option.profileImageUrl)}
                            />
                          )}
                          {option.name}
                        </li>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={i18next.t(
                            `${resource}.filters.candidates.label`
                          )}
                          placeholder={i18next.t(
                            `${resource}.filters.candidates.label`
                          )}
                        />
                      )}
                      onChange={(e, selected) => {
                        field.onChange(selected);
                      }}
                    />
                  )}
                />
              )}
          </Grid>
          <Grid item md={12} xs={12}>
            <Accordion
              sx={{
                boxShadow: 'unset',
                '& .MuiAccordionSummary-root': {
                  padding: 0,
                  color: '#1976d2',
                  minHeight: 30,
                  '&:hover': {
                    color: '#4c9fef'
                  },
                  '&.Mui-expanded': {
                    minHeight: 30
                  }
                },
                '& .MuiAccordionDetails-root': {
                  paddingX: 0
                },
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                  '&.Mui-expanded': {
                    margin: 0
                  }
                }
              }}
            >
              <AccordionSummary>
                {i18next.t(`${resource}.actions.advanced`)}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    {parties && (
                      <ReferenceInput
                        name={'partyId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.partyId.label`)}
                        options={parties}
                      />
                    )}
                  </Grid>
                  <Grid item md={2} xs={12}>
                    {zones && (
                      <ReferenceInput
                        name={'zoneId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.zoneId.label`)}
                        options={zones}
                      />
                    )}
                  </Grid>
                  <Grid item md={2} xs={12}>
                    {bases && (
                      <ReferenceInput
                        name={'baseId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.baseId.label`)}
                        options={bases}
                      />
                    )}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    {states && (
                      <ReferenceInput
                        name={'state'}
                        control={control}
                        label={i18next.t(`${resource}.filters.state.label`)}
                        options={states}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {cities && (
                      <ReferenceInput
                        name={'cityId'}
                        control={control}
                        label={i18next.t(`${resource}.filters.cityId.label`)}
                        options={cities}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CustomInput
                      name={'neighborhood'}
                      control={control}
                      label={`${resource}.filters.neighborhood.label`}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <CustomInput
                      name={'address'}
                      control={control}
                      label={`${resource}.filters.address.label`}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Form>
      {loading && <CircularProgress />}
      {comparativeData && (
        <Box style={{ backgroundColor: '#F9FBFA' }}>
          {comparativeData.data.length == 0 && (
            <Alert severity="info">Sem dados para exibir.</Alert>
          )}
          {comparativeData.data.length > 0 && (
            <>
              <MapIntentions data={comparativeData.data} />
            </>
          )}
        </Box>
      )}
    </SimpleContainer>
  );
};
