import { FC } from 'react';
import i18next from 'i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

interface ListProps {
  data: {
    id: string;
    candidateName: string;
    resumeName: string;
    votes: number;
    percentage: number;
  }[];
}

export const List: FC<ListProps> = ({ data }) => 
{
  return (
    <Table style={{ marginBottom: 50, marginTop: 10 }}>
      <TableHead>
        <TableRow>
          <TableCell>
            {i18next.t('resources.reports.elections.comparative.results.name')}
          </TableCell>
          <TableCell>
            {i18next.t('resources.reports.elections.comparative.results.votes')}
          </TableCell>
          <TableCell>
            {i18next.t(
              'resources.reports.elections.comparative.results.percentage'
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(candidate => (
          <TableRow
            key={candidate.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {candidate.candidateName == 'OTHER'
                ? i18next.t('resources.candidates.others')
                : `${candidate.candidateName} (${candidate.resumeName})`}
            </TableCell>
            <TableCell component="th" scope="row">
              {Intl.NumberFormat().format(candidate.votes)}
            </TableCell>
            <TableCell component="th" scope="row">
              {candidate.percentage.toString().replace('.', ',') + '%'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
