import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { api } from 'src/Services/Elections';
import * as Styled from 'src/Pages/Election/styles';
import { DateInput } from 'src/Components/Inputs/DateInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { Menu } from 'src/Components/Election/Menu';
import { PhoneInput } from 'src/Components/Inputs/PhoneInput';
import { DocumentInput } from 'src/Components/Inputs/DocumentInput';

interface VotersFormProps {
  data?: any;
  onSubmit: (formData: FormData) => void;
}

export const VotersForm = ({ data, onSubmit }: VotersFormProps) => {
  const resource = 'voters';
  const { control } = useForm();
  const [tab, setTab] = React.useState(1);

  const { options: genders } = useGetManyOptions(api, '/gender');
  const { options: zones } = useGetManyOptions(api, '/zones');
  const { options: city } = useGetManyOptions(api, '/city');
  const [electoralZones, setElectoralZone] = React.useState<any[]>([]);

  // Watch the cityId field
  const selectedCityId = useWatch({ control, name: 'cityId' });

  useEffect(() => {
    if (selectedCityId) {
      const fetchElectoralZones = async () => {
        try {
          const response = await api.get(`/electoral-zones/findByCity/${selectedCityId}`);
          setElectoralZone(response.data.map(x => ({ label: x.identifier, value: x.id })));
        } catch (error) {
          console.error('Error fetching electoral zones:', error);
        }
      };
      fetchElectoralZones();
    } else {
      setElectoralZone([]); // Clear options if no city is selected
    }
  }, [selectedCityId]);

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={900}>
      <Menu tab={tab} setTab={setTab} />
      {data && (
        <Form method="post" control={control} onSubmit={onSubmit}>
          <CustomInput
            name={'name'}
            control={control}
            label={`resources.${resource}.fields.name`}
          />
          <Styled.InputRow>
            <CustomInput
              name={'email'}
              control={control}
              label={`resources.${resource}.fields.email`}
            />
            <DocumentInput
              name="cpf"
              control={control}
              label={`resources.${resource}.fields.cpf`}
            />
          </Styled.InputRow>
          <CustomInput
            name={'publicPlace'}
            control={control}
            label={`resources.${resource}.fields.publicPlace`}
          />
          <Styled.InputRow>
            <CustomInput
              name={'number'}
              type="number"
              control={control}
              label={`resources.${resource}.fields.number`}
            />
            <CustomInput
              name={'neighborhood'}
              control={control}
              label={`resources.${resource}.fields.neighborhood`}
            />
            <CustomInput
              name={'postalCode'}
              type="number"
              control={control}
              label={`resources.${resource}.fields.postalCode`}
            />
          </Styled.InputRow>
          <Styled.InputRow></Styled.InputRow>
          <Styled.InputRow>
            <DateInput
              name={'birthDate'}
              control={control}
              label={`resources.${resource}.fields.birthDate`}
            />
            <PhoneInput
              name={'telphone'}
              control={control}
              label={`resources.${resource}.fields.telphone`}
            />
          </Styled.InputRow>
          <Styled.InputRow>
            {genders && (
              <ReferenceInput
                name={'genderId'}
                control={control}
                label={`resources.${resource}.fields.gender`}
                defaultValue={null}
                options={genders}
              />
            )}
            {zones && (
              <ReferenceInput
                name={'zoneId'}
                control={control}
                label={`resources.${resource}.fields.zone`}
                defaultValue={null}
                options={zones}
              />
            )}
            {city && (
              <ReferenceInput
                name={'cityId'}
                control={control}
                label={`resources.${resource}.fields.city`}
                defaultValue={null}
                options={city}
              />
            )}
            {electoralZones && (
              <ReferenceInput
                name={'electoralZoneId'}
                control={control}
                label={`resources.${resource}.fields.electoralZone`}
                defaultValue={null}
                options={electoralZones}
              />
            )}
          </Styled.InputRow>
        </Form>
      )}
    </SimpleContainer>
  );
};
