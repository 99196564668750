import i18next from 'i18next';
import React from 'react';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { IsActiveStatus } from 'src/Components/Election/IsActiveStatus';
import { Menu } from 'src/Components/Election/Menu';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';

export function VotersList() {
  const endpoint = '/voters';
  const resource = 'voters';
  const [tab, setTab] = React.useState(1);
  const { data, fetchData, hasMore, filters, setFilters } = useInfiniteFetch(
    api,
    endpoint,
    25
  );

  return (
    <SimpleContainer title={`resources.${resource}.name`}>
      <Menu tab={tab} setTab={setTab} />
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton />}
        filters={
          <SearchInput
            name="search"
            label={i18next.t(`resources.${resource}.filter.search`)}
            filters={filters}
            setFilters={setFilters}
            style={{ minWidth: 300 }}
          />
        }
        columns={[
          {
            header: `resources.${resource}.fields.name`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.name}
                <br />
                {item.cpf}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.email`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.email}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.postalCode`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.postalCode}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.birthDate`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DateField value={item.birthDate} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.zone`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.zone.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.electoralZone`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.electoralZone.identifier}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.gender`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.gender.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.city`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.city.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.status`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <IsActiveStatus isActive={item.isActive} />
              </InfiniteListStyles.StyledTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
}
