import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';
import { Menu } from 'src/Components/Election/Menu';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useForm } from 'react-hook-form';
import i18next from 'i18next';

export function UserList() {
  const endpoint = '/users';
  const resource = 'users';
  const { control } = useForm();
  const [tab, setTab] = React.useState(0);
  const { data, setData, fetchData, hasMore, filters, setFilters } =
    useInfiniteFetch(api, endpoint, 25);
  const { options: roles } = useGetManyOptions(api, '/role', { filterLevels: true });

  const handleDelete = async (id: number) => {
    try {
      api.delete(endpoint + '/' + id).then(response => {
        if (response && response.status == 200) {
          setData(data.filter(item => item.id !== id));
          showSuccessMessage(`resources.${resource}.actions.delete.success`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name`}>
      <Menu tab={tab} setTab={setTab} />
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton link="/election/users/create" />}
        filters={
          <>
            <SearchInput
              name="search"
              label={i18next.t(`resources.${resource}.filter.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ minWidth: 300 }}
            />
            {roles && (
              <ReferenceInput
                name={'roleId'}
                control={control}
                label={`resources.${resource}.fields.role`}
                options={roles}
                style={{ minWidth: 300 }}
                onChange={selected => {
                  const newFilters = {
                    ...filters,
                    ...{ roleId: selected }
                  };

                  setFilters(newFilters);
                }}
              />
            )}
          </>
        }
        columns={[
          {
            header: `resources.${resource}.fields.name`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.email`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.email}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.telphone`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.telphone}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.cpf`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.cpf}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.birthdate`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DateField value={item.birthdate} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.baseId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.base?.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.role`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.role?.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`/election/users/${item.id}/show`} />
                <EditButton url={`/election/users/${item.id}`} />
                <DeleteButton handleDelete={() => handleDelete(item.id)} />
              </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
}
