import React from 'react';
import { useGetIdentity } from 'react-admin';
import { useForm } from 'react-hook-form';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useInfiniteFetchWithParams } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import Grid from '@mui/material/Grid';
import {
  ROLE_ADMIN,
  ROLE_CAMPAIGN_MANAGER,
  ROLE_OPERATOR
} from 'src/Enums/Roles';
import { DateInput } from 'src/Components/Inputs/DateInput';
import i18next from 'i18next';
import { showErrorMessage } from 'src/Hooks/useMessages';
import { CustomInput } from 'src/Components/Inputs/CustomInput';

interface VotersByPeriodPageProps {
  dateStart: string;
  setDateStart: any;
  dateEnd: string;
  setDateEnd: any;
}

export function VotersByPeriodPage({ dateStart, setDateStart, dateEnd, setDateEnd }: VotersByPeriodPageProps) {
  const endpoint = '/voters-by-period/voters';
  const resource = 'resources.reports.elections.votersByPeriod';
  const { control } = useForm();
  const { data: identity } = useGetIdentity();
  const { options: zones } = useGetManyOptions(api, '/zones');

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t(`${resource}.messages.invalid_date`));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(start).toISOString();
  };

  const { data, fetchData, hasMore, filters, setFilters, setParams } =
    useInfiniteFetchWithParams(api, endpoint, { date: null }, 25, {
      date: getDateBounds(dateStart)
    });

  React.useEffect(() => {
    if (dateStart && dateEnd) {
      const dateStartBound = getDateBounds(dateStart);
      const dateEndBound = getDateBounds(dateEnd);

      if (dateStartBound) {
        setParams({ dateStart: dateStartBound, dateEnd: dateEndBound });
        setFilters({
          ...filters,
          ...{
            dateStart: dateStartBound, dateEnd: dateEndBound
          }
        });
      }
    }
  }, [dateStart, dateEnd]);

  let columns = [
    {
      header: `${resource}.voters.results.name`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.name}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.cpf`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.cpf}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.email`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.email}
        </InfiniteListStyles.StyledTd>
      )
    }
  ];

  if (
    identity &&
    (identity.role?.name == ROLE_ADMIN ||
      identity.role?.name == ROLE_CAMPAIGN_MANAGER)
  ) {
    columns = columns.concat([
      {
        header: `${resource}.voters.results.basename`,
        value: (item, index) => (
          <InfiniteListStyles.StyledTd key={index}>
            {item.basename}
          </InfiniteListStyles.StyledTd>
        )
      }
    ]);
  }

  if (identity && identity.role?.name != ROLE_OPERATOR) {
    columns = columns.concat([
      {
        header: `${resource}.voters.results.username`,
        value: (item, index) => (
          <InfiniteListStyles.StyledTd key={index}>
            {item.username}
          </InfiniteListStyles.StyledTd>
        )
      }
    ]);
  }

  columns = columns.concat([
    {
      header: `${resource}.voters.results.publicPlace`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.publicPlace}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.postalCode`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.postalCode}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.birthDate`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          <DateField value={item.birthDate} />
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.zoneId`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.zoneName}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.electoralZone`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.electoralZone}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.genderId`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.genderName}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.telphone`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          {item.telphone}
        </InfiniteListStyles.StyledTd>
      )
    },
    {
      header: `${resource}.voters.results.created_at`,
      value: (item, index) => (
        <InfiniteListStyles.StyledTd key={index}>
          <DateField showTime={true} value={item.created_at} />
        </InfiniteListStyles.StyledTd>
      )
    }
  ]);

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      filters={
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateStart"
              label={`${resource}.filters.dateStart`}
              onChange={e => {
                setDateStart(e.target.value);
              }}
              defaultValue={dateStart}
              data-id="datefilter"
            />
          </Grid>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateEnd"
              label={`${resource}.filters.dateEnd`}
              onChange={e => {
                setDateEnd(e.target.value);
              }}
              defaultValue={dateEnd}
              data-id="datefilterEnd"
            />
          </Grid>
          <Grid item xs={12} md={3} data-id="grid">
            <SearchInput
              name="search"
              label={i18next.t(`${resource}.filters.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={3} data-id="grid">
            {zones && (
              <ReferenceInput
                name={'zoneId'}
                control={control}
                label={`${resource}.filters.zoneId`}
                defaultValue={null}
                options={zones}
                style={{ minWidth: 150 }}
                onChange={value => {
                  const newFilters = {
                    ...filters,
                    ...{ zoneId: value }
                  };
                  setFilters(newFilters);
                }}
              />
            )}
          </Grid>
        </Grid>
      }
      columns={columns}
    />
  );
}
