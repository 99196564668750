import React from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import i18next from 'i18next';

interface ZonesAutocompleteInputProps {
  zones: any[];
  control: any;
  label: string;
  data: any[];
  filterData: any;
}

export const ZonesAutocompleteInput = ({
  zones,
  data,
  filterData,
  control,
  label
}: ZonesAutocompleteInputProps) => {
  return (
    <Controller
      name={'zoneFilter'}
      control={control}
      render={({ field }) => (
        <Autocomplete
          multiple
          fullWidth={true}
          options={zones}
          noOptionsText={i18next.t('inputs.autocomplete.no_register_found')}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={label}
              style={{ width: '100%' }}
            />
          )}
          onChange={(e, selected) => {
            field.onChange(selected);
            if (selected && selected.length > 0)
              filterData(
                data.filter(item => selected.indexOf(item.electoralZone) >= 0)
              );
            else filterData(data);
          }}
        />
      )}
    />
  );
};
