import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { DeleteAction } from 'src/Components/Admin/Actions/DeleteAction';
import { EditAction } from 'src/Components/Admin/Actions/EditAction';
import { ListAction } from 'src/Components/Admin/Actions/ListAction';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import { View } from 'src/Components/Admin/View';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { Menu } from 'src/Components/Election/Menu';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetOne from 'src/Hooks/useGetOne';
import { api } from 'src/Services/Elections';
import { Flag } from '@mui/icons-material';
import { ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE, ROLE_OPERATOR, ROLE_SUPERVISOR } from 'src/Enums/Roles';
import i18next from 'i18next';
import { UserHave } from 'src/Components/Auth/UserHave';

export const UserShow = () => {
  const { id } = useParams();
  const endpoint = '/users';
  const resource = 'users';
  const [tab, setTab] = React.useState(0);
  const { data } = useGetOne(api, endpoint, id);

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={1200}>
      <Menu tab={tab} setTab={setTab} />
      <View
        baseUrl="/election/users"
        backendUrl="/users"
        actions={[
          <ListAction key={0} />,
          <EditAction key={1} />,
          <DeleteAction
            key={2}
            api={api}
            successMessage={`resources.${resource}.actions.delete.success`}
          />,
          <UserHave key={3} role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}>
            <UserHave user={data} role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER]}>
              <Link
                to={`/election/users/${id}/show/campaigns`}
                title={i18next.t('resources.users.campaigns.name_other')}
              >
                <Flag />
              </Link>
            </UserHave>
          </UserHave>
        ]}
      >
        <SimpleShowLayout>
          <TextField
            value={data?.name}
            label={`resources.${resource}.fields.name`}
          />
          <TextField
            value={data?.email}
            label={`resources.${resource}.fields.email`}
          />
          <TextField
            value={data?.role?.name}
            label={`resources.${resource}.fields.role`}
          />
          <TextField value={data?.cpf} label="resources.users.fields.cpf" />
          <TextField
            value={data?.telphone}
            label={`resources.${resource}.fields.telphone`}
          />
          <DateField
            value={data?.birthdate}
            label={`resources.${resource}.fields.birthdate`}
          />

          {data && [ROLE_BASE_MANAGER, ROLE_SUPERVISOR, ROLE_OPERATOR].includes(data?.role.name)  && (
            <TextField
              value={data?.base?.name}
              label={`resources.${resource}.fields.baseId`}
            />
          )}
          {data && data.role?.name == ROLE_OPERATOR && (
            <TextField
              value={data?.userManager?.name}
              label={`resources.${resource}.fields.userManagerId`}
            />
          )}
          {data && (
            <TextField
              value={data?.goal}
              label={`resources.${resource}.fields.goal`}
            />
          )}
          {data && (
            <TextField
              value={data?.totalGoal}
              label={`resources.${resource}.fields.totalGoal`}
            />
          )}
        </SimpleShowLayout>
      </View>
    </SimpleContainer>
  );
};
