import { useEffect, useState } from 'react';
import { RegistrationsCardComponents } from 'src/Components/Cards/RegistrationsCard';
import { api } from 'src/Services/Elections';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import * as Styled from './styles';
import i18next from 'i18next';

export const Stats = () => {
  const [stats, setStats] = useState({
    totalRecords: 0,
    currentDateRecords: 0,
    currentPercentage: 0,
    lastWeekTotalRecords: 0,
    lastWeekTotalPercentage: 0,
    monthTotalRecords: 0,
    monthTotalPercentage: 0,
    yearTotalRecords: 0,
    yearTotalPercentage: 0
  });

  const applyTwoPlacesDecimal = (data: number) => data.toFixed(2);

  const calculatePercentages = data => {
    if (!data.totalRecords || data.totalRecords <= 0) return;
    data.currentPercentage = applyTwoPlacesDecimal(
      (data.currentDateRecords / data.totalRecords) * 100
    );
    data.lastWeekTotalPercentage = applyTwoPlacesDecimal(
      (data.lastWeekTotalRecords / data.totalRecords) * 100
    );
    data.monthTotalPercentage = applyTwoPlacesDecimal(
      (data.monthTotalRecords / data.totalRecords) * 100
    );
    data.yearTotalPercentage = applyTwoPlacesDecimal(
      (data.yearTotalRecords / data.totalRecords) * 100
    );
  };

  const changeStats = data => {
    calculatePercentages(data);
    setStats(data);
  };

  useEffect(() => {
    const getStats = async () => {
      api.get('pooling-intention/stats').then(response => {
        if (response) {
          changeStats(response.data);
        }
      });
    };

    getStats();
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={
                stats.currentDateRecords !== undefined
                  ? stats.currentDateRecords.toString()
                  : ''
              }
              percentage={
                stats.currentPercentage !== undefined
                  ? stats.currentPercentage.toString()
                  : ''
              }
              color="#AFBD77"
              title={i18next.t('resources.home.totals.results.registerDay')}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={
                stats.lastWeekTotalRecords !== undefined
                  ? stats.lastWeekTotalRecords.toString()
                  : ''
              }
              percentage={
                stats.lastWeekTotalPercentage !== undefined
                  ? stats.lastWeekTotalPercentage.toString()
                  : ''
              }
              color="#5CC6D0"
              title={i18next.t('resources.home.totals.results.registerWeek')}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={
                stats.yearTotalRecords !== undefined
                  ? stats.monthTotalRecords.toString()
                  : ''
              }
              percentage={
                stats.monthTotalPercentage !== undefined
                  ? stats.monthTotalPercentage.toString()
                  : ''
              }
              color="#FF8542"
              title={i18next.t('resources.home.totals.results.registerMonth')}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <RegistrationsCardComponents
              quantity={
                stats.yearTotalRecords !== undefined
                  ? stats.yearTotalRecords.toString()
                  : ''
              }
              percentage={
                stats.yearTotalPercentage !== undefined
                  ? stats.yearTotalPercentage.toString()
                  : ''
              }
              color="#ED1651"
              title={i18next.t('resources.home.totals.results.registerYear')}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
