import React from 'react';
import { Trash } from 'phosphor-react';
import * as Styled from './styles';

interface IDeleteButton {
    handleDelete: any
}

export const DeleteButton = (props: IDeleteButton) => {
    return <Styled.DeleteButton>
        <Trash
            style={{ margin: 'auto', cursor: 'pointer' }}
            size={18}
            color="#FF0000"
            onClick={() => {
                if (window.confirm('Deseja realmente excluir este item?')) {
                    props.handleDelete();
                }
            }}
        />
    </Styled.DeleteButton>
}