import React from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import i18next from 'i18next';
import { StyledSelect } from './styles';
import { KeyboardArrowDown, Logout, Settings, Key, AccountCircle } from '@mui/icons-material';
import { UserHave } from 'src/Components/Auth/UserHave';
import { ROLE_BASE_MANAGER, ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from 'src/Enums/Roles';
import { useLogout, useRedirect } from 'react-admin';

const AccountSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const logout = useLogout();
  const redirect = useRedirect();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToConfigurations = () => {
    redirect('/configurations');
    handleClose();
  }

  const goToChangePassword = () => {
    redirect('/change-password');
    handleClose();
  }

  return (
    <>
      <Button
          aria-controls="account-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<AccountCircle />}
          endIcon={<KeyboardArrowDown />}
          sx={{
            backgroundColor: 'transparent',
          }}
      >
        Conta
      </Button>
      <Menu
        id="account-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              marginRight: 2,
            }
          }
        }}
      >
        <MenuItem onClick={goToChangePassword}>
          <Key/> {i18next.t('menu.footer.changePassword')}
        </MenuItem>
        <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER, ROLE_BASE_MANAGER]}>
          <MenuItem onClick={goToConfigurations}>
            <Settings /> {i18next.t('menu.footer.configurations')}
          </MenuItem>
        </UserHave>
        <MenuItem onClick={() => logout()}>
          <Logout /> {i18next.t('menu.footer.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountSelector;
