export enum HiringPersonPaymentType {
    UNIQUE = 'UNIQUE',
    WEEKLY = 'WEEKLY',
    BIWEEKLY = 'BIWEEKLY',
    MONTHLY = 'MONTHLY',
    CUSTOM = 'CUSTOM'
}

export const paymentTypes = [
    { value: 'UNIQUE', label: 'resources.hiringPersons.paymentTypes.unique' },
    { value: 'WEEKLY', label: 'resources.hiringPersons.paymentTypes.weekly' },
    { value: 'BIWEEKLY', label: 'resources.hiringPersons.paymentTypes.biweekly' },
    { value: 'MONTHLY', label: 'resources.hiringPersons.paymentTypes.monthly' },
    { value: 'CUSTOM', label: 'resources.hiringPersons.paymentTypes.custom' },
];