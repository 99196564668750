import * as React from 'react';
import { useState } from 'react';
import { showErrorMessage, showSuccessMessage } from 'src/Hooks/useMessages';
import { Link } from 'react-router-dom'
import { api } from 'src/Services/Financial';
import * as Styled from 'src/Components/Buttons/DefaultButton/styles';
import i18next from 'i18next';
import useLoader from 'src/Hooks/useLoader';

interface ExportChargesButtonProps {
    filters: any
}

const ExportChargesButton = ({ filters }: ExportChargesButtonProps) => 
{
    const loader = useLoader()

    const handleClick = () => {
        loader.showLoader()

        api.get('/charges/export', {
            params: {
                filter: JSON.stringify(filters)
            },
            responseType: 'arraybuffer'
        })
            .then(response => 
            {            
                loader.hideLoader()        
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'charges.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                showSuccessMessage('resources.charges.actions.export.action')
            })
            .catch(error => {
                loader.hideLoader()
                console.log(error)
            })
            .finally(() => {
                loader.hideLoader()
            });
    };
    return (
        <Styled.ButtonDiv>
            <loader.Loader />
            <Link to={'#'}>
                <Styled.StyledButton 
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    size="medium"
                >{i18next.t('actions.export')}
                </Styled.StyledButton>
            </Link>
        </Styled.ButtonDiv>
    );
};

export default ExportChargesButton;