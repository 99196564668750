import { Route, Routes } from "react-router-dom";
import { ElectionCreate, ElectionEdit, ElectionList, ElectionShow } from "src/Pages/Election/Elections";

export const ElectionsRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<ElectionList />} />
          <Route path="/create" element={<ElectionCreate />} />
          <Route path=":id" element={<ElectionEdit />} />
          <Route path=":id/show" element={<ElectionShow />} />
        </Routes>
    )
  }