import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Controller, Control } from 'react-hook-form';
import i18next from 'i18next';

export interface ReferenceInputOption {
  label: string;
  value: string | number;
}

interface AutocompleteInputProps {
  control: Control;
  name: string;
  label?: string;
  defaultValue?: any;
  options: ReferenceInputOption[];
  rules?: any;
  helperText?: any;
  onChange?: any;
  [key: string]: any;
}

const ReferenceInput = ({
  control,
  name,
  options,
  label,
  defaultValue,
  rules = {},
  helperText,
  onChange,
  ...rest
}: AutocompleteInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          noOptionsText={i18next.t('inputs.autocomplete.noOptionsText')}
          options={options}
          getOptionLabel={option => {
            if (option && option.label) {
              return option.label;
            }

            return '';
          }}
          onChange={(e, selected) => {
            field.onChange(selected ? selected.value : null);
            if (onChange) {
              onChange(selected ? selected.value : null);
            }
          }}
          value={
            field.value && options.length > 0
              ? options.find(item => item.value == field.value)
              : null
          }
          onBlur={() => field.onBlur()}
          renderInput={params => (
            <TextField
              {...params}
              label={label ? i18next.t(label) : ''}
              variant={rest.variant || 'outlined'}
              InputLabelProps={{
                children: label ? i18next.t(label) : ''
              }}
              helperText={helperText}
            />
          )}
          {...rest}
        />
      )}
    />
  );
};

export default ReferenceInput;
