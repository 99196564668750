import * as React from "react";
import { Button } from "react-admin";
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';

export function DownloadButton({ imageSrc, name })
{   
    const downloadImage = async() => {
        const response = await fetch(imageSrc)
    
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.setAttribute('target', '_blank');
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }
    return <Button 
        label="Download" 
        onClick={downloadImage} 
        startIcon={<DownloadIcon/>}
        color='secondary'
    />;
}

DownloadButton.propTypes = {
    imageSrc: PropTypes.string,
    name: PropTypes.string
};