import i18next from 'i18next';
import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { Menu } from 'src/Components/Election/Menu';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { api } from 'src/Services/Elections';

export const PartyList = () => {
  const endpoint = '/electoral-party';
  const resource = 'parties';
  const [tab, setTab] = React.useState(4);
  const { data, setData, fetchData, hasMore, filters, setFilters } =
    useInfiniteFetch(api, endpoint, 25);

  const handleDelete = async (id: number) => {
    try {
      api.delete(endpoint + '/' + id).then(response => {
        if (response && response.status == 200) {
          setData(data.filter(item => item.id !== id));
          showSuccessMessage(`resources.${resource}.actions.delete.success`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name`}>
      <Menu tab={tab} setTab={setTab} />
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton />}
        filters={
          <SearchInput
            name="search"
            label={i18next.t(`resources.${resource}.fields.research`)}
            filters={filters}
            setFilters={setFilters}
            style={{ minWidth: 300 }}
          />
        }
        columns={[
          {
            header: `resources.${resource}.fields.name`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.resumeName`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.resumeName}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`/election/parties/${item.id}/show`} />
                <EditButton url={`/election/parties/${item.id}`} />
                <DeleteButton handleDelete={() => handleDelete(item.id)} />
              </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
};
