import * as React from 'react';
import { useParams } from 'react-router-dom';
import { DeleteAction } from 'src/Components/Admin/Actions/DeleteAction';
import { EditAction } from 'src/Components/Admin/Actions/EditAction';
import { ListAction } from 'src/Components/Admin/Actions/ListAction';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { TextField } from 'src/Components/Admin/Fields/TextField';
import { View } from 'src/Components/Admin/View';
import { SimpleShowLayout } from 'src/Components/Admin/View/Layouts/SimpleShowLayout';
import { Menu } from 'src/Components/Election/Menu';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetOne from 'src/Hooks/useGetOne';
import { api } from 'src/Services/Elections';

export const BaseShow = () => {
  const resource = 'bases';
  const { id } = useParams();
  const [tab, setTab] = React.useState(2);
  const { data } = useGetOne(api, '/bases', id);

  return (
    <SimpleContainer title={`resources.${resource}.name_one`} mdSize={900}>
      <Menu tab={tab} setTab={setTab} />
      <View
        baseUrl='/election/bases'
        backendUrl='/bases'
        actions={[
          <ListAction key={0} />,
          <EditAction key={1} />,
          <DeleteAction
                key={2}
                api={api}
                successMessage={`resources.${resource}.actions.delete.success`}
              />
        ]}
      >
        <SimpleShowLayout>
          <TextField
            value={data?.campaign.name}
            label={`resources.${resource}.fields.campaignId`}
          />
          <TextField
            value={data?.name}
            label={`resources.${resource}.fields.name`}
          />
          <TextField
            value={data?.userCampaignManager?.name}
            label={`resources.${resource}.fields.userCampaignId`}
          />
          <TextField
            value={data?.supervisorTeam?.name}
            label={`resources.${resource}.fields.supervisorTeamId`}
          />
          <DateField
            showTime
            value={data?.created_at}
            label={`resources.${resource}.fields.created_at`}
          />
        </SimpleShowLayout>
      </View>
    </SimpleContainer>
  );
};
