export enum PersonDocumentType {
    CPF = 'CPF',
    RG = 'RG',
    CNPJ = 'CNPJ',
    PASSPORT = 'PASSAPORTE',
    RNE = 'RNE'
}

export const documentTypes = [
    { value: 'CPF', label: 'resources.persons.documentTypes.cpf' },
    { value: 'RG', label: 'resources.persons.documentTypes.rg' },
    { value: 'CNPJ', label: 'resources.persons.documentTypes.cnpj' },
    { value: 'PASSAPORTE', label: 'resources.persons.documentTypes.passport' },
    { value: 'RNE', label: 'resources.persons.documentTypes.rne' },
];