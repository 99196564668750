import React, { ReactNode } from 'react';
import * as Styled from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import i18next from 'i18next';

type InfiniteListColumnProps = {
  header?: string;
  textAlign?: string;
  type?: string;
  value?: (item: any, index?: number) => JSX.Element;
  width?: number | string;
};

type RowFooterProps = {
  value: (item: any, index?: number) => JSX.Element;
};

type InfiniteListRowProps = {
  value: (item: any, index?: number) => JSX.Element;
};

interface InfiniteListProps {
  data: any[];
  loadMoreData: () => void;
  hasMore: boolean;
  actions?: ReactNode;
  filters?: ReactNode;
  columns: Array<InfiniteListColumnProps>;
  rows?: InfiniteListRowProps;
  rowFooters?: RowFooterProps;
  footer?: ReactNode;
  beforeToolbar?: ReactNode;
}

export const InfiniteList: React.FC<InfiniteListProps> = ({
  data,
  loadMoreData,
  hasMore,
  actions,
  filters,
  columns,
  rows,
  rowFooters,
  footer,
  beforeToolbar
}) => {
  return (
    <Styled.StyledInfiniteList>
      <Styled.Toolbar data-id="toolbar">
        <Styled.Filters data-id="filters">{filters}</Styled.Filters>
        {actions && <Styled.Actions data-id="actions">{actions}</Styled.Actions>}
      </Styled.Toolbar>
      {beforeToolbar}
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={hasMore && <h4>{i18next.t('components.loading')}</h4>}
      >
        <Styled.StyledTable>
          <Styled.StyledThead>
            <Styled.StyledTr>
              {columns.map((column, index) =>
                column?.type == 'action' ? (
                  <Styled.StyledActionTh
                    key={index}
                    style={{
                      textAlign: column.textAlign ? column.textAlign : 'right',
                      width: column.width ?? 'auto'
                    }}
                  >
                    {i18next.t('components.list.columns.actions')}
                  </Styled.StyledActionTh>
                ) : column.header ? (
                  <Styled.StyledTh
                    key={index}
                    style={{
                      textAlign: column.textAlign ? column.textAlign : 'left',
                      width: column.width ?? 'auto'
                    }}
                  >
                    {i18next.t(column.header)}
                  </Styled.StyledTh>
                ) : (
                  <></>
                )
              )}
            </Styled.StyledTr>
          </Styled.StyledThead>
          <Styled.StyledTbody>
            {data.map((item, index) => (
              <>
              {rows &&
                <Styled.StyledTr key={index}>
                  {rows.value(item, index)}
                </Styled.StyledTr>
              }
              {!rows &&
                <Styled.StyledTr key={index}>
                  {columns.map(column => column.value? column.value(item, index) : null)}
                </Styled.StyledTr>
              }
              {rowFooters && (
                <Styled.StyledTr key={data.length + index}>
                  {rowFooters.value(item.index)}
                </Styled.StyledTr>  
              )}
              </>
            ))}
            {data.length == 0 && (
              <Styled.StyledTr>
                <Styled.StyledTd colspan={columns.length}>
                  {i18next.t('components.list.no_register_found')}
                </Styled.StyledTd>
              </Styled.StyledTr>
            )}
            {footer}
          </Styled.StyledTbody>
        </Styled.StyledTable>
      </InfiniteScroll>
    </Styled.StyledInfiniteList>
  );
};
