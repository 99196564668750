import { Route, Routes } from 'react-router-dom';
import {
  ResearchCreate,
  ResearchEdit,
  ResearchList,
  ResearchShow
} from 'src/Pages/Election/Research';
import { ResearchCandidate } from 'src/Pages/Election/Research/ResearchCandidate';

export const ResearchRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ResearchList />} />
      <Route path="/create" element={<ResearchCreate />} />
      <Route path=":id" element={<ResearchEdit />} />
      <Route path=":id/show" element={<ResearchShow />} />
      <Route
        path=":id/show/research-candidate"
        element={<ResearchCandidate />}
      />
    </Routes>
  );
};
