import { Route, Routes } from "react-router-dom";
import { DonationCreate, DonationEdit, DonationList, DonationShow } from "src/Pages/Financial/Donations";

export const DonationRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<DonationList />} />
          <Route path="/create" element={<DonationCreate />} />
          <Route path=":id" element={<DonationEdit />} />
          <Route path=":id/show" element={<DonationShow />} />
        </Routes>
    )
  }