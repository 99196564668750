import { Api } from 'src/Services/api';

export const api = new Api(`${String(process.env.REACT_APP_API_URL)}/`);

export const sendEmailForConfirmation = async (body: any) => {
  return await api.post('auth/email', body);
};

export const sendConfirmationCode = async (body: any) => {
  return await api.post('auth/confirm-code', body);
};

export const updatePassword = async (body: any) => {
  return await api.patch('auth/reset-password', body);
};
