import * as React from 'react';
import { TextField, TextFieldProps } from "../TextField"
import i18next from 'i18next';

interface SelectOption {
    value: string;
    label: string;
}

interface OptionsFieldProps {
    options: SelectOption[]
}

export const OptionsField = (props: TextFieldProps & OptionsFieldProps) => {

    const { options, value } = props;

    const selectedOption = options.find((option) => option.value === value);

    const label = selectedOption ? selectedOption.label : '';

    const newProps = {
        ...props,
        ...{
            value: i18next.t(label)
        }
    }
    return <TextField {...newProps}/>
}