import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Financial'
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const CostitemEdit = () => {
    const resource = 'costitems'
    const { control } = useForm();
    const { id } = useParams()
    const redirect = useRedirect()
    const { data } = useGetOne(api, '/costitems', id);
    const { options: costcenters } = useGetManyOptions(api, '/costcenters')

    const onSubmit = (formData) => 
    {
        api.put(`/costitems/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    showSuccessMessage(`resources.${resource}.actions.edit.success`)
                    redirect(`/financial/costitems/${result.data.id}/show`)
                }
            })
    }
    
    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={900}
        >
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
            >
                {data && (
                    <>
                        {costcenters && (
                            <ReferenceInput
                                name={"costcenterId"}
                                control={control}
                                label={`resources.${resource}.fields.costcenterId`}
                                defaultValue={data.costcenterId}
                                options={costcenters}
                            />
                        )}
                        <CustomInput
                            name={"name"}
                            control={control}
                            label={`resources.${resource}.fields.name`}
                            defaultValue={data.name}
                        />
                    </>
                )}
            </Form>
        </SimpleContainer>
    );
}