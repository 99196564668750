import * as React from 'react';
import { Controller, Control } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import i18next from "i18next";

export interface SelectOption {
  value: string;
  label: string;
}

interface SelectInputProps {
  name: string;
  control: Control;
  label: string;
  defaultValue?: string;
  options: SelectOption[];
  onChange?: (event: any) => void;
  [key: string]: any;
}

export const SelectInput = ({ name, control, label, options, defaultValue, onChange, ...rest }: SelectInputProps) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        rules={rest.rules}
        render={({
          field,
          fieldState: { error }
        }) => (
          <TextField
            helperText={error ? error.message : null}
            size="small"
            error={!!error}
            fullWidth
            label={i18next.t(label)}
            value={field.value}
            variant="outlined"
            select
            SelectProps={{
              native: false,
              onChange: (e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              },
            }}
            {...rest}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {i18next.t(option.label)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    );
  };