import React, { ErrorInfo } from 'react';
import './i18n'
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from './Pages/ErrorPage';
import App from './App';

const logError = (error: Error, info: ErrorInfo) => {
  console.log('log', error)
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <ErrorBoundary fallbackRender={ErrorPage} onError={logError}>
      <App/>
    </ErrorBoundary>
    <ToastContainer position="top-center" />
  </>
);
