import * as React from 'react';
import { ExpenseForm } from './ExpenseForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

export const ExpenseCreate = () => 
{
    const navigate = useNavigate()

    const onSubmit = (formData) => {
        api.post('/expenses', formData.data)
        .then(result => {
            if (result?.status == 201) {
                showSuccessMessage(i18next.t(`resources.expenses.actions.create.success`) )
                navigate(`/financial/expenses/${result.data.id}/show`)
            }
        })
    }

    return (
        <ExpenseForm
            data={{
                personId: null
            }}
            onSubmit={onSubmit}
        />  
    );
}