import * as React from 'react';
import * as Styled from './styles';

interface ViewProps {
    baseUrl: string;
    backendUrl: string;
    actions?: any;
    children: React.ReactNode,
    [key: string]: any;
}

export const View = ({ baseUrl, backendUrl, actions, children, ...rest}: ViewProps) => 
{
    return <Styled.StyledView {...rest}>
        {actions && (
            <Styled.StyledToolBar 
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
            >
                {actions.filter(action => action !== false).map(
                    action => {
                        let props = {}
                        if (action.props.baseUrl != null) 
                        {
                            props = {
                                baseUrl
                            }
                        }
                
                        if (action.props.backendUrl != null) 
                        {
                            props = {
                                ...props,
                                ... {
                                    backendUrl
                                }
                            }
                        }

                        return React.cloneElement(action, props)
                    }
                )}
            </Styled.StyledToolBar>
        )}
        {children}
    </Styled.StyledView>
}