import { useEffect, useState } from 'react';
import useGetMany from './useGetMany';
import { ReferenceInputOption } from 'src/Components/Inputs/ReferenceInput';

const useGetManyOptions = (api, endpoint, defaultFilters = {}, idColumn = 'id', nameColumn = 'name', callback?) => {
    const [options, setOptions] = useState<ReferenceInputOption[] | null>(null);
    const { data, setData, fetch, filters, setFilters, loading, error } = useGetMany(api, endpoint, defaultFilters, callback)

    useEffect(() => {
        setOptions(
            (data == null) 
                ? null 
                : data.map(
                    item => ({
                        label: item[nameColumn],
                        value: item[idColumn]
                    })
                )
        )
    }, [data]);
    
    return { options, setData, fetch, filters, setFilters, loading, error }
}

export default useGetManyOptions