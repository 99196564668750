import { useEffect, useRef, useState } from 'react';
import { handleError } from 'src/Services/Handlers/handleError';

export function useInfiniteFetch(api, endpoint, take = 25, defaultFilters = {}, callback?) {
    const {data, setData, fetchData, hasMore, filters, setFilters} = 
        useInfiniteFetchWithParams(api, endpoint, true, take, defaultFilters, callback)
    return {data, setData, fetchData, hasMore, filters, setFilters};
}

export function useInfiniteFetchWithParams(api, endpoint, params, take = 25, defaultFilters = {}, callback?) {
    const skipRef = useRef(0);
    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState(defaultFilters);
    const [paramsState, setParams] = useState(params)
    const [isLoading, setIsLoading] = useState(false);
    
    const fetchData = async () => {
        try {

            if (isLoading) return 

            setIsLoading(true)

            const filtersExpr = JSON.stringify(filters);
            const response = await api.get(`${endpoint}?filter=${filtersExpr}${take ? '&take=' + take : ''}${take ? '&skip=' + skipRef.current : ''}`)

            setIsLoading(false)

            if (response) {

                let data = response.data
                
                if (!callback) {
                    setData((prevData) => [...prevData, ...data]);
                } else {
                    data = callback(response.data)
                }

                skipRef.current += data.length

                if ((data.length === 0) || (data.length < take) || (take == 0)) {
                    setHasMore(false); 
                }
                
            } else {
                setHasMore(false); 
            }
        } catch (error) {
            handleError(error)
            setHasMore(false)
        }
    }

    useEffect(() => {
        setData([]);
        setHasMore(true);
        skipRef.current = 0;


        if (!paramsState) return;

        for (const param in paramsState) 
        {
            if (!paramsState[param]) return
        }

        fetchData();
    }, [filters, paramsState]);

    return {data, setData, fetchData, hasMore, setHasMore, setParams, filters, setFilters, isLoading};
}