import React, { useState } from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import { OptionsField } from 'src/Components/Admin/Fields/OptionsField';
import chargeStatuses from 'src/Enums/ChargeStatuses';
import Badge from '@mui/material/Badge';
import { SelectInput } from 'src/Components/Inputs/SelectInput';
import { useForm } from 'react-hook-form';
import { DateInput } from 'src/Components/Inputs/DateInput';
import ExportChargesButton from './actions/ExportButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfigContext } from 'src/Hooks/useConfigContext';

export const ChargeList = () => 
{
  const endpoint = '/charges'
  const resource = 'charges'
  const { control } = useForm()
  const { config } = React.useContext(ConfigContext);
  const {data, setData, fetchData, hasMore, filters, setFilters } = useInfiniteFetch(api, endpoint, 25)

  const handleDelete = async (id: number) => {
    try {
      api.delete(endpoint + '/' + id).then(response => {
        if (response && response.status == 200) {
          setData(data.filter(item => item.id !== id));
          showSuccessMessage(`resources.${resource}.actions.delete.success`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer
      title={`resources.${resource}.name`}
    >
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={
          <>
          <AddButton/>
          <ExportChargesButton filters={filters} />
          </>
        }
        filters={   
          <>
            <SearchInput
              name="search" 
              placeholder={`resources.${resource}.filter.search`}
              filters={filters}
              setFilters={setFilters}
              style={{ minWidth: 300 }}
            />
            <SelectInput 
              name="state" 
              control={control}
              options={chargeStatuses}
              label={`resources.${resource}.filter.status`}
              style={{ minWidth: 150 }}
              onChange={e => {
                const newFilters = {
                  ...filters,
                  ...{ status: e.target.value }
                }
                setFilters(newFilters);
              }}
            />
            <DateInput 
              control={control} 
              name="dateStart" 
              label={`resources.${resource}.filter.dateStart`}
              onChange={e => {
                const newFilters = {
                  ...filters,
                  ...{ dateStart: e.target.value }
                }
                setFilters(newFilters);
              }}
            />
            <DateInput 
              control={control} 
              name="dateEnd" 
              label={`resources.${resource}.filter.dateEnd`}
              onChange={e => {
                const newFilters = {
                  ...filters,
                  ...{ dateEnd: e.target.value }
                }
                setFilters(newFilters);
              }} 
            />      
          </>
        }
        columns={[
          {
            header: `resources.${resource}.fields.title`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.title}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.typeId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.type?.name}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.personId`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.person?.fullName}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.due_date`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DateField value={item.due_date} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.status`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <Badge
                  color={item.status == 'APPROVED' ? 'success' : 'warning'}
                  variant="dot"
                >
                  <OptionsField value={item.status} options={chargeStatuses} />
                </Badge>
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`${item.id}/show`} />
                {item.status !== 'APPROVED' && (
                  <EditButton url={`${item.id}`} />
                )}
                {item.status !== 'APPROVED' && (
                  <DeleteButton handleDelete={() => handleDelete(item.id)} />
                )}
              </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
};
