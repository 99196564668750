import styled from "styled-components";
import Select from '@mui/material/Select';

export const StyledSelect = styled(Select)`
  color: '#FF6A46' !important;
  border: 'transparent';
  & > svg {
    color: '#FF6A46'
  }
`

export const styles = {
  containerFlag: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
  },
  flagImage: { width: '25px', height: '25px', marginLeft: '10px' }
};