import { Route, Routes } from "react-router-dom";
import { PositionCreate, PositionEdit, PositionList, PositionShow } from "src/Pages/Hr/Positions";

export const PositionRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<PositionList />} />
          <Route path="/create" element={<PositionCreate />} />
          <Route path=":id" element={<PositionEdit />} />
          <Route path=":id/show" element={<PositionShow />} />
        </Routes>
    )
  }