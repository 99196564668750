import i18next from "i18next";

export enum HiringPersonStatuses {
    PENDING = 'PENDING',
    COMPLETE = 'COMPLETE'
}

export const HiringPersonStatusOptions = [
    { 
        value: 'PENDING', label: i18next.t('resources.hiringPersons.statuses.PENDING'),
    },
    {
        value: 'COMPLETE', label: i18next.t('resources.hiringPersons.statuses.COMPLETE') 
    }
];