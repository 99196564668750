import React from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ProfileImage from 'src/Components/Election/ProfileImagemUrl';
import { api } from 'src/Services/Elections';
import i18next from 'i18next';

interface ResearchesAutocompleteInputProps {
  candidates: any[];
  control: any;
  label: string;
}

export const CandidatesAutocompleteInput = ({
  candidates,
  control,
  label
}: ResearchesAutocompleteInputProps) => {
  return (
    <Controller
      name={'candidates'}
      control={control}
      render={({ field }) => (
        <Autocomplete
          multiple
          options={candidates}
          noOptionsText={i18next.t('inputs.autocomplete.noOptionsText')}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.profileImageUrl && (
                <ProfileImage
                  alt={option.name}
                  imageUrl={api.getUrl(option.profileImageUrl)}
                />
              )}
              {option.name}
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={label}
            />
          )}
          onChange={(e, selected) => {
            field.onChange(selected);
          }}
        />
      )}
    />
  );
};
