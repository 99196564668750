import * as React from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import { DownloadButton } from 'src/Components/Buttons/DownloadButton';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { Card, CardContent, CardMedia, Input } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Api } from 'src/Services/api'
import { Button } from 'react-admin';

interface UploadProps {
    api: Api,
    control: Control,
    name: string,
    picture: any,
    src: string,
    destFileName: string,
    uploadSuccessMessage: string,
    uploadRemoveMessage: string,
}

export const Upload = ({ 
    api, 
    control, 
    name, 
    picture,
    src, 
    destFileName,
    uploadSuccessMessage, 
    uploadRemoveMessage 
}: UploadProps) => {
    const [imageUrl, setImageUrl] = React.useState(picture ? picture.src : null);
    const { setValue, getValues, handleSubmit } = useForm();

    const onSubmit = async() => {
        const selectedFile = getValues(name)

        if (selectedFile) {
            const formData = new FormData();
            formData.append(name, selectedFile);

            api.put(src, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(
                    result => {
                        if (result && result.status == 200) 
                            showSuccessMessage(uploadSuccessMessage)
                    }
                )
        }
        
    }
    const remove = async() => {
        api.delete(src).then( 
            (result) => {
                setImageUrl('');
                setValue(name, null);
                if (result && result.status == 200) 
                    showSuccessMessage(uploadRemoveMessage)
            }
        )
    }

    const handleFileChange = React.useCallback((event) => {
        const file = event.target.files[0];
    
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e && e.target) {
                setImageUrl(e.target.result as string);
            }
          };
          reader.readAsDataURL(file);
        }

        setValue(name, file);
    }, [setValue, setImageUrl, name])

    return (
        <Form 
            control={control}
            onSubmit={handleSubmit(onSubmit)} 
        >
            <Card style={{  }}>
                {imageUrl &&
                    <CardMedia
                        component="img"
                        alt="Uploaded Image"
            
                        image={imageUrl || '/placeholder-image.jpg'}
                        style={{
                            objectFit: 'contain',
                            height: 300
                        }}
                    />
                }
                <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) => (
                        <Input
                            type="file"
                            inputProps={{ accept: 'image/*' }}
                            onChange={(event) => {
                                event.stopPropagation()
                                field.onChange(event);
                                handleFileChange(event);
                            }}
                        />
                        )}
                    />
                    {imageUrl && (
                        <div>
                            <DownloadButton imageSrc={imageUrl} name={destFileName} />
                            <Button 
                                label="Remover" 
                                onClick={remove} 
                                startIcon={<DeleteIcon/>}
                                color='secondary'
                            />
                        </div>
                    )}
                    
                </CardContent>
            </Card>
        </Form>
    );
}