import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { formatCPF } from 'src/Utils/Formatters';
import TextField from '@mui/material/TextField';
import i18next from 'i18next';

export const DocumentInput = ({
  name,
  control,
  label,
  defaultValue,
  onChange,
  type = 'text',
  ...rest
}: any) => {
  const [formattedValue, setFormattedValue] = useState(defaultValue);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type={type}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          fullWidth
          label={i18next.t(label)}
          value={formattedValue}
          inputProps={{ maxLength: 11 }}
          onChange={event => {
            const newValue = event.target.value;
            const formattedCPF = formatCPF(newValue);
            setFormattedValue(formattedCPF);
            field.onChange(newValue);
            if (onChange) {
              onChange(event);
            }
          }}
          variant="outlined"
          {...rest}
        />
      )}
    />
  );
};
