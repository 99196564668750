import * as React from "react";
import { api } from "src/Services/Financial";
import { Link, useParams } from 'react-router-dom';
import useGetOne from "src/Hooks/useGetOne";
import { View } from "src/Components/Admin/View";
import { SimpleContainer } from "src/Components/Layout/SimpleContainer";
import { SimpleShowLayout } from "src/Components/Admin/View/Layouts/SimpleShowLayout";
import { ListAction } from "src/Components/Admin/Actions/ListAction";
import { EditAction } from "src/Components/Admin/Actions/EditAction";
import { DeleteAction } from "src/Components/Admin/Actions/DeleteAction";
import { TextField } from "src/Components/Admin/Fields/TextField"
import { DateField } from "src/Components/Admin/Fields/DateField"
import i18next from "i18next";
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';
import { OutboundItemList } from "../OutboundItems/OutboundItemsList";
import { OutboundItemsSearch } from "../OutboundItems/OutboundItemsSearch";
import { OutboundItemsForm } from "../OutboundItems/OutboundItemsForm";
import { showSuccessMessage } from "src/Hooks/useMessages";

interface SelectedItemProps {
    id: string,
    outboundId: string
}

export const OutboundShow = () => 
{
    const resource = 'materialOutbounds'
    const endpoint = '/material-outbounds'
    const { id } = useParams()
    const [outboundId ] = React.useState(id)
    const [selected, setSelected] =  React.useState<SelectedItemProps | null>(null)
    const { data, fetchData } = useGetOne(api, '/material-outbounds', outboundId);
    const { data: placeData } = useGetOne(api, '/places', data?.placeId);
    const [acao, setAcao] = React.useState('list')
    const colors: { [key: string]: string } = {
        "PENDING": 'red',
        "APPROVED": 'green',
        "CANCELED": 'red'
    };

    const confirmOutbound = () => {
        if (confirm(i18next.t(`resources.${resource}.actions.confirm.dialog`))) {
            api.put(`${endpoint}/${id}/confirm`)
                .then(result => {
                    if (result && result.status == 200) 
                    {
                        fetchData()
                        showSuccessMessage(`resources.${resource}.actions.confirm.success`)
                    }
                })
        }
    }

    const cancelConfirmOutbound = () => {
        if (confirm(i18next.t(`resources.${resource}.actions.cancelConfirm.dialog`))) {
            api.put(`${endpoint}/${id}/cancel-confirm`)
                .then(result => {
                    if (result && result.status == 200) 
                    {
                        fetchData()
                        showSuccessMessage(`resources.${resource}.actions.cancelConfirm.success`)
                    }
                })
        }
    }
    
    return (
        <>
            <SimpleContainer 
                title={`resources.${resource}.name_one`}
            >
                {(acao == 'list') && (
                    <>
                        {data && (
                        <View
                            baseUrl="/stock/outbounds"
                            backendUrl={endpoint}
                            actions={[
                                (data.status !== 'APPROVED') && (
                                    <Link key={4} 
                                        to={'#'} 
                                        title={i18next.t(`resources.${resource}.actions.add.title`)} 
                                        onClick={() => setAcao('search')}
                                    >
                                        <AddIcon />
                                    </Link>
                                ),
                                <ListAction key={0} />,
                                (data.status !== 'APPROVED') && (
                                    <EditAction key={1} />
                                ),
                                (data.status !== 'APPROVED') && (
                                    <DeleteAction 
                                        key={2} 
                                        api={api} 
                                        successMessage={`resources.${resource}.actions.delete.success`}
                                    />
                                ),
                                <Link 
                                    key={5} 
                                    to={'#'} 
                                    title={i18next.t(`resources.${resource}.actions.${(data.status != 'APPROVED') ? 'confirm' : 'cancelConfirm'}.title`)} 
                                    onClick={(data.status != 'APPROVED') ? confirmOutbound : cancelConfirmOutbound}
                                    style={{ color: (data.status != 'APPROVED') ? '#428F32' : 'red' }}
                                >
                                    {(data.status !== 'APPROVED') ? (
                                        <CheckIcon />
                                    ):(
                                        <ReplayIcon />
                                    )}
                                </Link>,
                            ]}
                        >
                            <SimpleShowLayout>
                                <DateField value={data?.outbound_date} label={`resources.${resource}.fields.outbound_date`} showTime={true} />
                                <TextField value={placeData?.fullPath} label={`resources.${resource}.fields.placeId`} />
                                <TextField value={data?.description} label={`resources.${resource}.fields.description`} />
                                <DateField showTime value={data?.created_at} label={`resources.${resource}.fields.created_at`} />
                                <TextField value={data?.user?.name} label={`resources.${resource}.fields.userId`} />
                                <TextField 
                                    value={i18next.t(`resources.${resource}.statuses.${data?.status}`).toUpperCase()} 
                                    label={`resources.${resource}.fields.status`} 
                                    style={{ color: colors[data?.status]  }}
                                />
                            </SimpleShowLayout>
                        </View>
                        )}

                        <Divider flexItem />

                        <OutboundItemList 
                            outboundId={id as string} 
                            isPending={data?.status == 'PENDING'}
                            setSelected={setSelected} 
                            setAcao={setAcao}
                        />
                    </>
                )}

                {(acao == 'search') && data && (
                    <OutboundItemsSearch 
                        outboundId={id as string}
                        placeId={data.placeId}
                        dueDate={data.outbound_date}
                        setAcao={setAcao} 
                    />
                )}

                {(acao == 'form') && selected && (
                    <OutboundItemsForm 
                        outboundId={selected.outboundId as string} 
                        id={selected.id as string}
                        onCancel={() => {
                            setSelected(null)
                            setAcao('list')
                        }} 
                    />
                )}
            </SimpleContainer>
        </>
    );
}