import * as React from 'react';
import { DonationForm } from './DonationForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const DonationEdit = () => {
    const { id } = useParams()
    const { data } = useGetOne(api, '/donations', id);
    const navigate = useNavigate(); 
    
    const onSubmit = (formData) => {
        api.put(`/donations/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    navigate(`/financial/donations/${result.data.id}/show`)
                    showSuccessMessage(`resources.donations.actions.edit.success`)
                }
            })
    }

    return (
        <DonationForm
            data={data}
            onSubmit={onSubmit}
        />  
    );
}