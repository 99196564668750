import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from 'src/Services/Elections';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Form } from 'src/Components/Admin/Form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import i18next from 'i18next';

const UserCampaignsForm = ({ userId, userCampaigns, setUserCampaigns, fetchUserCampaigns, onCancel }: any) => 
{
  const { control } = useForm();
  const { data: campaigns } = useInfiniteFetch(api, '/campaigns', 0);

  const onSubmit = (formData) => 
  {
    const sendData = {
      userId: userId,
      campaignIds: (formData.data.campaigns.map(campaign => campaign.id))
    }

    api.post(`/users/${userId}/campaigns`, sendData).then((result) => 
    {
      if (result?.status === 201) {
        showSuccessMessage(
          `resources.users.campaigns.actions.create.success`
        );

        setUserCampaigns([])
        fetchUserCampaigns()
        onCancel()
      }
    });

  };

  return (
    <Form method='post' control={control} onSubmit={onSubmit} onCancel={onCancel}>
      {campaigns && (
        <Controller
          name={'campaigns'}
          control={control}
          render={({ field }) => (
            <Autocomplete
              multiple
              options={campaigns.filter(campaign => !userCampaigns.some(userCampaign => userCampaign.campaign.id == campaign.id))}
              noOptionsText='resources.users.campaigns.noOptionsText'
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => 
                <TextField {...params} 
                  label={i18next.t("resources.users.campaigns.fields.campaigns")} 
                  placeholder={i18next.t("resources.users.campaigns.fields.campaigns")}
                />}
              onChange={(e, selected) => {
                field.onChange(selected)
              }}
            />
          )}
        />
      )}
    </Form>
  );
};

export default UserCampaignsForm;
