import React from 'react';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import i18next from 'i18next';

import ptBRFlag from 'src/Assets/flags/pt-BR.png';
import enUSFlag from 'src/Assets/flags/en-US.png';
import esFlag from 'src/Assets/flags/es.png';
import ptPTFlag from 'src/Assets/flags/pt-PT.png';
import { StyledSelect, styles } from './styles';
import { ConfigContext } from 'src/Hooks/useConfigContext';

const LanguageSelector: React.FC = () => {
  //const [lang, setLang] = React.useState('pt-BR');
  const { language, setLanguage } = React.useContext(ConfigContext);

  const handleChange = (event: any) => {
    i18next.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };
  return (
    <StyledSelect
      labelId="language-select-label"
      id="language-select"
      value={language}
      defaultValue={'pt-BR'}
      onChange={handleChange}
      sx={{
        color: '#fff',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
        '.MuiSvgIcon-root': {
            color: '#fff'
        }
      }}
    >
      <MenuItem value={'pt-BR'}>
        <Box component="div" sx={styles.containerFlag}>
          <Box component="img" src={ptBRFlag} sx={styles.flagImage} />
          Português (Brasil)
        </Box>
      </MenuItem>
      <MenuItem value={'en-US'}>
        <Box component="div" sx={styles.containerFlag}>
          <Box component="img" src={enUSFlag} sx={styles.flagImage} />
          Inglês
        </Box>
      </MenuItem>
      <MenuItem value={'es'}>
        <Box component="div" sx={styles.containerFlag}>
          <Box component="img" src={esFlag} sx={styles.flagImage} />
          Espanhol
        </Box>
      </MenuItem>
      <MenuItem value={'pt-PT'}>
        <Box component="div" sx={styles.containerFlag}>
          <Box component="img" src={ptPTFlag} sx={styles.flagImage} />
          Português (Portugal)
        </Box>
      </MenuItem>
    </StyledSelect>
  );
};

export default LanguageSelector;
