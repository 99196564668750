import React from 'react';
import { useForm } from 'react-hook-form';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import { useInfiniteFetchWithParams } from 'src/Hooks/useInfiniteFetch';
import { api } from 'src/Services/Elections';
import i18next from 'i18next';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { GoalAchieved } from 'src/Components/Election/GoalAchieved';
import Grid from '@mui/material/Grid';
import { showErrorMessage } from 'src/Hooks/useMessages';
import { CustomInput } from 'src/Components/Inputs/CustomInput';

interface VotersByPeriodWorkersPageProps {
  dateStart: string;
  setDateStart: any;
  dateEnd: string;
  setDateEnd: any;
  activeDays: number;
  setActiveDays: any;
}

export function VotersByPeriodWorkersPage({ dateStart, setDateStart, dateEnd, setDateEnd, activeDays, setActiveDays }: VotersByPeriodWorkersPageProps) {
  const endpoint = '/voters-by-period/workers';
  const resource = 'reports.elections.votersByPeriod.workers';
  const { control } = useForm();

  const getDateBounds = date => {
    if (isNaN(new Date(date).getTime())) {
      showErrorMessage(i18next.t(`resources.${resource}.messages.invalid_date`));
      return null;
    }

    const time = new Date(date).getTime();
    const start = time + new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(start).toISOString();
  };

  const { data, fetchData, hasMore, setParams, filters, setFilters } =
    useInfiniteFetchWithParams(api, endpoint, { date: null }, 25, {
      dateStart: getDateBounds(dateStart),
      dateEnd: getDateBounds(dateEnd)
    });

    React.useEffect(() => {
      if (dateStart && dateEnd) {
        const dateStartBound = getDateBounds(dateStart);
        const dateEndBound = getDateBounds(dateEnd);
  
        if (dateStartBound) {
          setParams({ dateStart: dateStartBound, dateEnd: dateEndBound });
          setFilters({
            ...filters,
            ...{
              dateStart: dateStartBound, dateEnd: dateEndBound
            }
          });
        }
      }
    }, [dateStart, dateEnd]);

  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData}
      hasMore={hasMore}
      filters={
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }} style={{ width: '100%'}}>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateStart"
              label={`resources.reports.elections.votersByPeriod.filters.dateStart`}
              onChange={e => {
                setDateStart(e.target.value);
              }}
              defaultValue={dateStart}
              data-id="datefilter"
            />
          </Grid>
          <Grid item xs={12} md={2} data-id="grid">
            <DateInput
              control={control}
              name="dateEnd"
              label={`resources.reports.elections.votersByPeriod.filters.dateEnd`}
              onChange={e => {
                setDateEnd(e.target.value);
              }}
              defaultValue={dateEnd}
              data-id="datefilterEnd"
            />
          </Grid>
          <Grid item xs={12} md={1} data-id="grid">
            <CustomInput
                name={'activeDays'}
                control={control}
                label={`resources.reports.elections.votersByPeriod.filters.activeDays`}
                type={'number'}
                inputProps={{ maxLength: 4 }}
                defaultValue={activeDays}
                onChange={e => {
                  setActiveDays(e.target.value);
                }}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchInput
              name="search"
              label={i18next.t(`resources.reports.elections.votersByPeriod.filters.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      }
      columns={[
        {
          header: `resources.${resource}.results.name`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.name}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.baseName`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.baseName}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.zoneName`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.zoneName}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.telphone`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.telphone}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.email`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.email}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.goal`,
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index}>
              {item.goal * activeDays}
            </InfiniteListStyles.StyledTd>
          )
        },
        {
          header: `resources.${resource}.results.total`,
          textAlign: 'center',
          value: (item, index) => (
            <InfiniteListStyles.StyledTd key={index} style={{ textAlign: 'center'}}>
              <GoalAchieved goal={activeDays * item.goal} value={item.total}/>
            </InfiniteListStyles.StyledTd>
          )
        }
      ]}
    />
  );
}
