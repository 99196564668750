import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import { Menu } from 'src/Components/Election/Menu';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
interface PartyFormProps {
  data: any;
  onSubmit: (formData: FormData) => void;
}

export const PartyForm = ({ data, onSubmit }: PartyFormProps) => {
  const resource = 'parties';
  const { control } = useForm();
  const [tab, setTab] = React.useState(4);

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={1000}>
      <Menu tab={tab} setTab={setTab} />
      {data && (
        <Form method='post' control={control} onSubmit={onSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={12}>
              <CustomInput
                name={'name'}
                control={control}
                label={`resources.${resource}.fields.name`}
                defaultValue={data?.name}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomInput
                name={'resumeName'}
                control={control}
                label={`resources.${resource}.fields.resumeName`}
                defaultValue={data?.resumeName}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </SimpleContainer>
  );
};
