import { Route, Routes } from "react-router-dom";
import { CandidateCreate, CandidateEdit, CandidateList, CandidateShow } from "src/Pages/Election/Candidates";

export const CandidateRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<CandidateList />} />
          <Route path="/create" element={<CandidateCreate />} />
          <Route path=":id" element={<CandidateEdit />} />
          <Route path=":id/show" element={<CandidateShow />} />
        </Routes>
    )
  }