import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { api } from 'src/Services/Financial';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import i18next from 'i18next';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import Grid from '@mui/material/Grid';

export const CampaignCreate = () => {
  const { control } = useForm();
  const redirect = useRedirect();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { options: elections } = useGetManyOptions(api, '/elections')

  const onSubmit = formData => {
    api.post('/campaigns', formData.data).then(result => {
      if (result?.status == 201) {
        showSuccessMessage(
          i18next.t(`resources.campaigns.actions.create.success`)
        );
        redirect(`/election/campaigns/${result.data.id}/show`);
      }
    });
  };

  return (
    <SimpleContainer
      title="resources.campaigns.name"
      style={{ maxWidth: isSmScreen ? '100%' : 900 }}
    >
      <Form method="post" control={control} onSubmit={onSubmit}>
        {elections &&
          <ReferenceInput
              name={"electionId"}
              control={control}
              label={`resources.campaigns.fields.electionId`}
              defaultValue={""}
              options={elections}
          />
        }
        <CustomInput
          name={'name'}
          control={control}
          label={i18next.t(`resources.campaigns.fields.name`)}
          defaultValue=""
        />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={12}>
            <DecimalInput
                name={"amount"}
                control={control}
                label={`resources.campaigns.fields.amount`}
                defaultValue={0}
            />
          </Grid>
        </Grid>
      </Form>
    </SimpleContainer>
  );
};
