import * as React from 'react';
import { InboundForm } from './InboundForm';
import { api } from 'src/Services/Financial';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

export const InboundCreate = () => {
  const navigate = useNavigate();

  const onSubmit = formData => {
    api.post('/material-inbounds', formData.data).then(result => {
      if (result?.status == 201) {
        showSuccessMessage(
          i18next.t(`resources.materialInbounds.actions.create.success`)
        );
        navigate(`/stock/inbounds/${result.data.id}/show`);
      }
    });
  };

  return (
    <InboundForm
      data={{
        personId: null
      }}
      onSubmit={onSubmit}
    />
  );
};
