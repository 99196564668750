import styled from 'styled-components';

export const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  & p {
    font-weight: bold;
  }
`;

export const ProfileImage = styled.div`
  width: 250px;
  display: flex;
  justify-content: flex-end;
`;

export const ProfileData = styled.div`
`;

export const ProfileInfo = styled.p`
  line-height: 14px;
`;