import { FC } from 'react';
import i18next from 'i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

interface ListProps {
  data: {
    id: string;
    name: string;
    avatar: string;
    color?: string;
    researches: {
      research_date: string;
      votes: number;
      percentage: number;
    }[];
  }[];
}

export const ListComparative: FC<ListProps> = ({ data }) => {
  return (
    <Table style={{ marginBottom: 50, marginTop: 50 }}>
      <TableHead>
        <TableRow>
          <TableCell>
            {i18next.t('resources.reports.elections.comparative.results.name')}
          </TableCell>
          {data[0].researches.map(research => (
            <TableCell key={research.research_date}>
              {research.research_date}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(candidate => (
          <TableRow
            key={candidate.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {candidate.name == 'OTHERS'
                ? i18next.t('resources.candidates.others')
                : candidate.name}
            </TableCell>
            {candidate.researches.map(research => (
              <TableCell key={`${candidate.name}-${research.research_date}`}>
                {Intl.NumberFormat().format(research.votes)}
                {` (${parseFloat(research.percentage.toString()).toFixed(2).toString().replace('.', ',')}%)`}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
