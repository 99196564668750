import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import i18next from 'i18next';
import { DateInput } from 'src/Components/Inputs/DateInput';
import DecimalInput from 'src/Components/Inputs/DecimalInput';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Elections';
import { showSuccessMessage } from 'src/Hooks/useMessages';

interface HiringPersonPaymentFormProps {
    hiringId: string,
    hiringPersonId: string;
    data: any;
    open: any;
    handleClose: any;
    fetchPayments: any
}

export default function HiringPersonPaymentForm({ hiringId, hiringPersonId, data, open, handleClose, fetchPayments }: HiringPersonPaymentFormProps) 
{
    const resource = 'hiringPersonPayments'
    const endpoint = `/hirings/${hiringId}/persons/${hiringPersonId}/payments`
    const { control, setValue, getValues } = useForm()

    React.useEffect(() => {
        if (data) {
            setValue('due_date', data.due_date)
            setValue('amount', data.amount)
        }
    }, [data])
    
    return <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const due_date = getValues('due_date')
            const amount = getValues('amount')
            const formData = { due_date, amount }

            let callback: any = null
            if (!data.id) callback = api.post(endpoint, formData)
            else callback = api.put(endpoint + '/' + data.id, formData)

            callback.then(result => {
                if (result && ((result.status == 200) || (result.status == 201))) {
                    if (!data.id) showSuccessMessage(i18next.t(`resources.hiringPersonPayments.actions.create.success`))
                    else showSuccessMessage(i18next.t(`resources.hiringPersonPayments.actions.edit.success`))

                    fetchPayments();
                    handleClose();
                }
            })

            
          },
        }}
      >
        <DialogTitle>{i18next.t('resources.hiringPersonPayments.form.title')}</DialogTitle>
        <DialogContent>
            {data &&
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={6}>
                        <DateInput
                            name="due_date" 
                            control={control}
                            label={`resources.${resource}.fields.due_date`}
                            defaultValue={data?.due_date}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DecimalInput
                            name={"amount"}
                            control={control}
                            label={`resources.${resource}.fields.amount`}
                            style={{ width: '100%' }}
                            defaultValue={data?.amount}
                        />
                    </Grid>
                </Grid>
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
          <Button type="submit">Salvar</Button>
        </DialogActions>
      </Dialog>
}