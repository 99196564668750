import * as React from 'react';
import { PlaceForm } from './PlaceForm'
import { api } from 'src/Services/Financial'
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';

export const PlaceEdit = () => {
    const { id } = useParams()
    const { data } = useGetOne(api, '/places', id);
    const navigate = useNavigate(); 
    
    const onSubmit = (formData) => {
        api.put(`/places/${id}`, formData.data)
            .then(result => {
                if (result?.status == 200) {
                    navigate(`/stock/places/${result.data.id}/show`)
                    showSuccessMessage(`resources.places.actions.edit.success`)
                }
            })
    }

    return (
        <>
        {data && (
            <PlaceForm
                data={data}
                onSubmit={onSubmit}
            />  
        )}
        </>
    );
}