import * as React from "react";
import { Link, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import i18next from "i18next";

interface EditActionProps {
    url?: string;
    baseUrl?: string;
    id?: string;
    title?: string
}

export const ViewAction = ({ url, baseUrl, id, title }: EditActionProps) => {

    if (!url) url = baseUrl;

    if (!id) {
        const params = useParams()
        id = params.id
    } 

    return (
        <Link to={url + '/' + id + '/show'} title={title ? i18next.t(title) : ''}>
            <VisibilityIcon/>
        </Link>
    );
}

ViewAction.defaultProps = {
    baseUrl: ''
}