import { Route, Routes } from "react-router-dom";
import { VotersCreate, VotersList } from "src/Pages/Election/Voters";

export const VoterRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<VotersList />} />
          <Route path="/create" element={<VotersCreate />} />
        </Routes>
    )
  }