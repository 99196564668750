import React, { useRef } from 'react';
import {
  Grid,
  alpha,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { api } from 'src/Services/Elections';
import i18next from 'i18next';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartProps {
  data: {
    id: string;
    name: string;
    resumeName: string;
    avatar: string;
    color?: string;
    researches: {
      research_date: string;
      votes: number;
      percentage: number;
    }[];
  }[];
}

export const LineChartComponents: React.FC<ChartProps> = ({ data }) => {
  const chartRef = useRef<any>(null);

  const defaultColors = [
    '#2f68ba',
    '#578441',
    '#9d3bf7',
    '#ff0000',
    '#FF9F40B2',
    '#008080B2',
    '#000080B2',
    '#800080B2',
    '#2f68ba',
    '#578441',
    '#9d3bf7',
    '#ff0000',
    '#FF9F40B2',
    '#008080B2',
    '#000080B2',
    '#800080B2',
    '#2f68ba',
    '#578441',
    '#9d3bf7',
    '#ff0000',
    '#FF9F40B2',
    '#008080B2',
    '#000080B2',
    '#800080B2'
  ];

  let maxPercent = 0;
  let minPercent = 100;
  const colors: string[] = [];

  data.forEach((candidate, index) => {
    candidate.color =
      candidate.name == 'OTHERS' ? '#ccc' : defaultColors[index];

    for (const research of candidate.researches) {
      if (research.percentage < minPercent) {
        minPercent = Math.floor(research.percentage);
      }

      if (research.percentage > maxPercent) {
        maxPercent = Math.floor(research.percentage);
      }

      colors.push(
        alpha(
          (candidate.name == 'OTHERS' ? '#ccc' : candidate.color) ??
            defaultColors[index],
          0.7
        )
      );
    }
  });

  const chartData = {
    labels: data[0].researches.map(research => research.research_date),
    datasets: data.map((candidate, index) => ({
      label:
        candidate.name == 'OTHERS'
          ? i18next.t('components.others')
          : candidate.name + '(' + candidate.resumeName + ')',
      data: candidate.researches.map(research => research.percentage),
      borderColor: candidate.color,
      backgroundColor: alpha(
        (candidate.name == 'OTHERS' ? '#ccc' : candidate.color) ??
          defaultColors[index],
        0.7
      ),
      pointRadius: 5,
      pointHoverRadius: 8
    }))
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      x: {
        border: {
          dash: [2, 4]
        },
        position: 'top',
        ticks: {
          padding: 23,
          showLabelBackdrop: true
        }
      },
      y: {
        grid: {
          color: '#999'
        },
        border: {
          dash: [2, 4]
        },
        suggestedMin: minPercent < 5 ? 0 : minPercent - 5,
        suggestedMax: maxPercent < 95 ? maxPercent + 5 : maxPercent,
        ticks: {
          callback: function (value) {
            return value + '%';
          }
        }
      }
    },
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.4
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        formatter: function (value) {
          return parseFloat(value).toFixed(2).toString().replace('.', ',') + '%';
        },
        backgroundColor: function (context) {
          const color: any = context.dataset.backgroundColor;
          return color;
        },
        color: 'white',
        font: {
          weight: 'bold',
          size: 12
        }
      },
      tooltip: {
        callbacks: {
          label: context => {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + '%';
            }
            return label;
          }
        }
      }
    }
  };

  return (
    <>
      {data.length <= 0 ? (
        <div>{i18next.t('components.list.no_register_found')}</div>
      ) : (
        <Grid container>
          <Grid
            item
            md={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <List>
              {data.map(candidate => (
                <ListItem
                  key={candidate.name}
                  sx={{
                    '& .MuiTypography-body1': {
                      color: candidate.color,
                      fontWeight: 700
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={candidate.name}
                      src={api.getUrl(candidate.avatar)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      candidate.name == 'OTHERS'
                        ? i18next.t('components.others')
                        : candidate.name + ` (${candidate.resumeName})`
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={10}>
            <div style={{ width: 'auto', height: 800 }}>
              <Line ref={chartRef} data={chartData} options={chartOptions} />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
