import * as React from 'react';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Form } from 'src/Components/Admin/Form';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { api } from 'src/Services/Financial'
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import { useRedirect } from 'react-admin';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SelectInput } from 'src/Components/Inputs/SelectInput';
import { types } from 'src/Enums/PersonTypes'
import { documentTypes } from 'src/Enums/PersonDocumentTypes'

export const PersonCreate = () => {
    const resource = 'persons'
    const { control } = useForm();
    const redirect = useRedirect()

    const onSubmit = (formData) => 
    {
        api.post('/persons', formData.data)
        .then(result => {
            if (result?.status == 201) {
                showSuccessMessage(`resources.${resource}.actions.create.success` )
                redirect(`/financial/persons/${result.data.id}/show`)
            }
        })
    }
    
    return (
        <SimpleContainer
            title={`resources.${resource}.name`}
            mdSize={1000}
        >
            <Form 
                method='post' 
                control={control}
                onSubmit={onSubmit}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={3}>
                        <SelectInput 
                            name="type" 
                            control={control}
                            options={types}
                            label={`resources.${resource}.fields.type`}
                        />
                    </Grid>
                </Grid>

                <CustomInput
                    name={"fullName"}
                    control={control}
                    label={`resources.${resource}.fields.fullName`}
                    defaultValue=""
                />

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={4}>
                        <SelectInput 
                            name="identifierType" 
                            control={control}
                            options={documentTypes}
                            label={`resources.${resource}.fields.identifierType`}
                        />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <CustomInput
                            name={"identifier"}
                            control={control}
                            label={`resources.${resource}.fields.identifier`}
                            defaultValue=""
                        />
                    </Grid>
                </Grid>
            </Form>
        </SimpleContainer>
    );
}
