import React from 'react';
import styles from './index.module.scss';
import { useState, useEffect } from 'react';
import { useGetIdentity, useRedirect } from 'react-admin';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Grid, Tabs, Tab } from '@mui/material';
import { formatCurrency } from 'src/Utils/Formatters';
import { PieChartComponents } from 'src/Components/Reports/PieChartComponents';
import provider from 'src/Providers/Financial/ReportsDataProvider';
import moment from 'moment';
import Box from '@mui/material/Box';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { ConfigContext } from 'src/Hooks/useConfigContext';
import i18next from 'i18next';
import useGetOne from 'src/Hooks/useGetOne';
import { api } from 'src/Services/Financial';
import { Form } from 'src/Components/Admin/Form';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { DateInput } from 'src/Components/Inputs/DateInput';
import { useForm } from 'react-hook-form';
import useLoader from 'src/Hooks/useLoader';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { ROLE_BASE_MANAGER } from 'src/Enums/Roles';


const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

interface ListMovementResponse {
  data: any[];
}

export const MovementList = () => {
  const resource = 'resources.reports.movements';
  const { config } = React.useContext(ConfigContext);
  const { data: identity } = useGetIdentity()
  const [list, setList] = useState<ListMovementResponse>({ data: [] });
  const [expensesGraphData, setExpensesGraphData] = useState<ListMovementResponse>({ data: [] });
  const [donationsGraphData, setDonationsGraphData] = useState<ListMovementResponse>({ data: [] });

  const [initialBalance, setInitialBalance] = useState(0);
  const [finalBalance, setFinalBalance] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  
  const { control, formState: { errors } } = useForm();
  const [electionId, setElectionId] = React.useState(config.electionId);
  const [accountId, setAccountId] = React.useState(config.accountId);
  const [campaignId, setCampaignId] = React.useState(config.campaignId);
  const { options: elections } = useGetManyOptions(api, '/elections');
  const { options: campaigns } = useGetManyOptions(api, '/campaigns', { electionId });
  const { options: accounts } = useGetManyOptions(api, '/accounts', { campaignId });
  const { data: accountData } = useGetOne(api, '/accounts', accountId);

  const doughnutOptions: any = {
    plugins: {
      legend: {
        display: true,
        position: 'left',
        align: 'center'
      },
      datalabels: {
        formatter: function (value, context) {
          return value + '%';
        }
      },
      tooltip: {
        callbacks: {
          label: context => {
            return (
              `${i18next.t('resources.reports.movements.value')}: ` +
              formatCurrency(context.dataset.otherData[context.dataIndex])
            );
          }
        }
      }
    }
  };

  const redirect = useRedirect();

  useEffect(() => {
    if (accountId == null) {
      redirect('/financial/configurations');
    }
  }, [accountId]);


  useEffect(() => {
    if (accountData) {
      const total = parseFloat(accountData.amount) + finalBalance;
      setFinalAmount(parseFloat(total.toFixed(2)));
    }
  }, [finalBalance, accountData]);

  const onSubmit = async(form) => 
  {    
    const date = moment(form.data.dateStart)
    const finalDate = moment(form.data.dateEnd)

    provider
      .getBalance(form.data.accountId, date.subtract(1, 'day').toDate())
      .then(result => {
        setInitialBalance(result.data.totalDonations - result.data.totalExpenses);
      });

    provider
      .getBalance(form.data.accountId, finalDate.toDate())
      .then(result => {
        setFinalBalance(
          result.data.totalDonations -
            result.data.totalExpenses
        );
      });
    provider
      .listMovement(form.data.accountId, date.toDate(), finalDate.toDate())
      .then(data => {
        setList(data);
      });
    provider
      .listExpensesGraph(form.data.accountId, date.toDate(), finalDate.toDate())
      .then(data => {
        setExpensesGraphData(data);
      });
    provider
      .listDonationsGraph(date.toDate(), finalDate.toDate())
      .then(data => {
        setDonationsGraphData(data);
      });

  }

  return (
    <SimpleContainer title={`${resource}.name`}>
      <Form 
          method='post' 
          control={control}
          onSubmit={onSubmit}
          submitButtonLabel='actions.search'
      >
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
              <Grid item md={5} xs={12} sm={12}>
                  {elections && (
                      <ReferenceInput
                          name={'electionId'}
                          label={i18next.t(`${resource}.filter.electionId`)}
                          control={control}
                          defaultValue={electionId ?? ''}
                          options={elections}
                          rules={{ required: 'Pleito Obrigatório' }}
                          helperText={errors.electionId ? errors.electionId?.message : ''}
                          onChange={value => { 
                          setElectionId(value)
                          }}
                      />
                  )}
              </Grid>
              <Grid item md={7} xs={12} sm={12}>
                  {electionId && campaigns && (
                      <ReferenceInput
                          name={'campaignId'}
                          label={i18next.t(`${resource}.filter.campaignId`)}
                          control={control}
                          defaultValue={campaignId ?? ''}
                          options={campaigns}
                          rules={{ required: 'Campanha Obrigatória' }}
                          helperText={errors.campaignId ? errors.campaignId?.message : ''}
                          onChange={value => { 
                          setCampaignId(value)
                          }}
                      />
                  )}
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                  {campaignId && accounts && identity && (
                      <ReferenceInput
                          name={'accountId'}
                          label={i18next.t(`${resource}.filter.accountId`)}
                          control={control}
                          defaultValue={accountId ?? null}
                          options={accounts}
                          rules={ (identity.role.name == ROLE_BASE_MANAGER) ? { required: i18next.t(`${resource}.filter.rules.accountId.required`) }: {}}
                          helperText={errors.accountId ? errors.accountId?.message : ''}
                          onChange={value => {
                          setAccountId(value);
                          }}
                      />
                  )}
              </Grid>
              <Grid item md={3} xs={12} sm={12}>
                  <DateInput 
                      control={control} 
                      name="dateStart" 
                      label={`${resource}.filter.dateStart`}
                      rules={{
                          required: i18next.t(`${resource}.filter.rules.dateStart.required`)
                      }}
                  />
              </Grid>
              <Grid item md={3} xs={12} sm={12}>
                  <DateInput 
                      control={control} 
                      name="dateEnd" 
                      label={`${resource}.filter.dateEnd`} 
                      rules={{
                          required: i18next.t(`${resource}.filter.rules.dateEnd.required`)
                      }}
                  />
              </Grid>
          </Grid>
      </Form>

      <Tabs
        value={currentTabIndex}
        onChange={(e, tabIndex) => setCurrentTabIndex(tabIndex)}
        variant="fullWidth"
      >
        <Tab label={i18next.t(`${resource}.graph.name`)} />
        <Tab label={i18next.t(`${resource}.movements.name`)} />
      </Tabs>

      {currentTabIndex == 0 && (
        <Box sx={{ p: 2 }} className={styles.report__dataBox}>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 0, marginBottom: 15 }}
          >
            <Grid item md={4} xs={4}>
              <div className={styles.report__dataBox__item}>
                <p>{formatCurrency(initialBalance)}</p>
                <p>
                  {i18next.t(`${resource}.graph.previousBalance`)}
                </p>
              </div>
            </Grid>

            <Grid item md={4} xs={4}>
              <div className={styles.report__dataBox__item}>
                <p>{formatCurrency(finalBalance)}</p>
                <p>{i18next.t(`${resource}.graph.actualBalance`)}</p>
              </div>
            </Grid>

            <Grid item md={4} xs={4}>
              <div className={styles.report__dataBox__item}>
                <p>{formatCurrency(finalAmount)}</p>
                <p>
                  {i18next.t(`${resource}.graph.finalBalance`)}
                </p>
              </div>
            </Grid>

            <hr />

            <div className={styles.report__graphs}>
              {donationsGraphData && (
                <div className={styles.report__graphs__graph}>
                  <h3>{i18next.t(`${resource}.graph.recipes`)}</h3>
                  <PieChartComponents
                    data={donationsGraphData.data.map(item => item.percent)}
                    otherData={donationsGraphData.data.map(item => item.amount)}
                    labels={donationsGraphData.data.map(item =>
                      i18next.t(`const.donationTypes.${item.type}`)
                    )}
                    options={doughnutOptions}
                  />
                </div>
              )}
              {expensesGraphData && (
                <div className={styles.report__graphs__graph}>
                  <h3>{i18next.t(`${resource}.graph.expenses`)}</h3>
                  <PieChartComponents
                    data={expensesGraphData.data.map(item => item.percent)}
                    otherData={expensesGraphData.data.map(item => item.amount)}
                    labels={expensesGraphData.data.map(
                      item => item.costcenterName
                    )}
                    options={doughnutOptions}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Box>
      )}

      {currentTabIndex == 1 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {i18next.t(`${resource}.movements.results.type`)}
              </TableCell>
              <TableCell>
                {i18next.t(`${resource}.movements.results.personId`)}
              </TableCell>
              <TableCell align="center">
                {i18next.t(`${resource}.movements.results.date`)}
              </TableCell>
              <TableCell align="right">
                {i18next.t(`${resource}.movements.results.value`)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.data.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.personName}</TableCell>

                  <TableCell align="center">{row.duedate}</TableCell>
                  <TableCell align="right">
                    {row.type == 'DESPESA' ||
                    row.type == 'SAÍDA POR TRANSFERÊNCIA'
                      ? '(' + formatCurrency(row.amount) + ')'
                      : formatCurrency(row.amount)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </SimpleContainer>
  );
};
