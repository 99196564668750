import React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'src/Components/Admin/Form';
import { Menu } from 'src/Components/Election/Menu';
import { CustomInput } from 'src/Components/Inputs/CustomInput';
import ReferenceInput from 'src/Components/Inputs/ReferenceInput';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import useGetManyOptions from 'src/Hooks/useGetManyOptions';
import { api } from 'src/Services/Elections';
import Grid from '@mui/material/Grid';
import { DateInput } from 'src/Components/Inputs/DateInput';

interface ResearchFormProps {
  data?: any;
  onSubmit: (formData: FormData) => void;
}

export const ResearchForm = ({ data, onSubmit }: ResearchFormProps) => {
  const resource = 'research';
  const { control } = useForm();
  const [tab, setTab] = React.useState(3);

  const { options: researchType } = useGetManyOptions(api, '/research-type');
  const { options: positions } = useGetManyOptions(api, '/candidate-position');

  return (
    <SimpleContainer title={`resources.${resource}.name`} mdSize={1000}>
      <Menu tab={tab} setTab={setTab} />
      {data && (
        <Form method='post' control={control} onSubmit={onSubmit}>
          <CustomInput
            name={'description'}
            control={control}
            placeholder={'ANO - LOCAL - CARGO - REFERÊNCIA'}
            label={`resources.${resource}.fields.research_name`}
            defaultValue={data?.description}
          />
          {researchType && (
            <ReferenceInput
              name={'researchTypeId'}
              control={control}
              label={`resources.${resource}.fields.research_type`}
              defaultValue={data?.researchType?.id}
              options={researchType}
            />
          )}
          {positions && (
            <ReferenceInput
              name={'positionId'}
              control={control}
              label={`resources.${resource}.fields.positionId`}
              defaultValue={data?.positionId}
              options={positions}
            />
          )}
          
          <CustomInput
            name={'range'}
            control={control}
            label={`resources.${resource}.fields.range`}
            defaultValue={data?.range}
          />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12} md={6}>
              <CustomInput
                name={'reference'}
                control={control}
                label={`resources.${resource}.fields.reference`}
                defaultValue={data?.reference}
              />
            </Grid>
            <Grid item xs={6} md={3}>
                <DateInput
                    name="start_date" 
                    control={control}
                    label={`resources.${resource}.fields.start_date`}
                    defaultValue={data?.start_date}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <DateInput
                    name="end_date" 
                    control={control}
                    label={`resources.${resource}.fields.end_date`}
                    defaultValue={data?.end_date}
                />
            </Grid>
          </Grid>
        </Form>
      )}
    </SimpleContainer>
  );
};
