import * as React from 'react';
import { memo } from 'react';
import Typography from '@mui/material/Typography';
import i18next from 'i18next';
import * as Styled from './styles';

export interface TextFieldProps {
  label?: string;
  value: any;
  emptyText?: string;
  [key: string]: any;
}

export const TextField = memo(
  ({ label, value, emptyText, ...rest }: TextFieldProps) => {
    return (
      <Styled.StyledTextField>
        {label && (
          <Styled.StyledLabel>
            <Typography
              component='span'
              color='textSecondary'
              variant='body1'
              sx={{ fontSize: '14px' }}
              {...rest}
            >
              {i18next.t(label)}
            </Typography>
          </Styled.StyledLabel>
        )}
        <Styled.StyledValue>
          <Typography
            component='span'
            variant='body2'
            sx={{
              fontWeight: label ? 600 : 400,
              fontSize: '15px',
              color: '#4F4F4F',
            }}
            {...rest}
          >
            {value != null && typeof value !== 'string'
              ? JSON.stringify(value)
              : value || emptyText}
          </Typography>
        </Styled.StyledValue>
      </Styled.StyledTextField>
    );
  }
);

TextField.displayName = 'TextField';
