import { createContext, useEffect, useState } from 'react';

const defaultConfig = {
    config: {
        electionId: null,
        campaignId: null,
        accountId: null  
    },
    permissions: [],
    userData: null,
    language: 'pt-BR',
    setConfig: (config) => { console.log(config) },
    setLanguage: (language: string) => { console.log(language) },
    setPermissions: (permissions) => { console.log(permissions) },
}

export const ConfigContext = createContext(defaultConfig)

export function useConfigContext() {
    const [config, setConfig] = useState(localStorage.getItem('config')? JSON.parse(localStorage.getItem('config') as string) : null)
    const storedPermissions = localStorage.getItem('permissions')
    const storedUserdata = localStorage.getItem('userData')
    const [permissions, setPermissions] = useState(storedPermissions ? JSON.parse(storedPermissions) : []);
    const [userData, setUserData] = useState(storedUserdata ? JSON.parse(storedUserdata) : null);
    const [language, setLanguage] = useState<string>(localStorage.getItem('language') ? localStorage.getItem('language') as string: 'pt-BR')
   
    const broadcastChannel = new BroadcastChannel('auth');
    broadcastChannel.onmessage = (eventMessage) => 
    {
        const data = eventMessage.data;

        if (data.type == 'signOut') {
            setConfig(null)
            localStorage.removeItem('config')
            setPermissions([])
            localStorage.removeItem('permissions')
            setUserData(null)
            localStorage.removeItem('userData')
        }
    }

    useEffect(() => {
        if (config) {
            localStorage.setItem('config', JSON.stringify(config))
        } else {
            localStorage.removeItem('config')
        }
    }, [config])

    useEffect(() => {
        if (permissions) {
            localStorage.setItem('permissions', JSON.stringify(permissions))
        } else {
            localStorage.removeItem('permissions')
        }
    }, [permissions])

    useEffect(() => {
        if (userData) {
            localStorage.setItem('userData', JSON.stringify(userData))
        } else {
            localStorage.removeItem('userData')
        }
    }, [userData])

    useEffect(() => {
        if (language) {
            localStorage.setItem('language', language)
        } else {
            localStorage.removeItem('language')
        }
    }, [language])

    return {config, setConfig, permissions, setPermissions, userData, setUserData, language, setLanguage};
}