import { FC } from 'react';
import * as Styled from './styles';
import Map from '../Maps';
import i18next from 'i18next';

export interface MapIntentionProps {
  data: {
    latitude: string;
    longitude: string;
    id: string;
    created_at: Date;
    zone: {
      name: string;
    };
    research: {
      description: string;
    };
    voter: {
      name: string;
    };
    user: {
      name: string;
    };
    alternativeIntention: {
      name: string;
    };
    candidate: {
      name: string;
    };
    candidatePosition: {
      name: string;
    };
    electoralParty: {
      resumeName: string;
    };
  }[];
}

export const MapIntentions: FC<MapIntentionProps> = ({ data }) => {
  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>
          {i18next.t('resources.maps.name', { count: 2 })}
        </Styled.Title>
      </Styled.Header>
      <Styled.Map>
        {data != undefined && data.length > 0 ? <Map data={data} /> : undefined}
      </Styled.Map>
    </Styled.Container>
  );
};
