import { useState } from 'react';
import { Stats } from 'src/Components/Election/Stats';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { format, addDays } from 'date-fns';
import { StatsByBases } from 'src/Components/Election/Charts/Base';
import { StatsByZones } from 'src/Components/Election/Charts/Zone';
import { StatsByUsers} from 'src/Components/Election/Charts/User';
import { IElectionChartFilters } from 'src/Interfaces/Election/Charts/filters.interface'
import { 
  Grid
} from '@mui/material';
import { DateInput } from "src/Components/Inputs/DateInput";
import { useForm } from "react-hook-form";
import i18n from "src/i18n";
import { Form } from 'src/Components/Admin/Form';

export const IntentionStatsPages = () => {
    const [filtersAux, setFiltersAux] = useState<IElectionChartFilters>({
      dateStart: format(new Date(), 'yyyy-MM-dd'),
      dateEnd: format(addDays(new Date(), 7), 'yyyy-MM-dd')
    });
    const { control } = useForm();
    const resource = 'resources.reports.elections.intentions';


    // Make Request based on filters
  const onSubmit = form => {
    console.debug(form.data)
    if (form.data.dateStart && form.data.dateEnd) {
      setFiltersAux({
        ...filtersAux,
        dateStart:form.data.dateStart,
        dateEnd: form.data.dateEnd
      })
    }
  };
  
  return (
    <SimpleContainer title={`${resource}.name`}>
      <Stats/>

      <Form
        control={control}
        onSubmit={onSubmit}
        showCancelButton={false}
        submitButtonLabel="actions.search"
        style={{ marginBottom: '10px' }}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <DateInput 
                control={control} 
                name="dateStart" 
                label={i18n.t(`${resource}.fields.period.label`)}
              />
            <DateInput 
                control={control} 
                name="dateEnd" 
                label={i18n.t(`${resource}.fields.period.label`)}
              />
            </Grid>
          </Grid>
      </Form>

      <Grid container spacing={2} direction={"row"} rowSpacing={10} paddingTop={10}>
          <Grid item md={12} xs={12} sm={12}>
            <StatsByBases dateStart={filtersAux.dateStart} dateEnd={filtersAux.dateEnd}/>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <StatsByZones dateStart={filtersAux.dateStart} dateEnd={filtersAux.dateEnd} />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <StatsByUsers dateStart={filtersAux.dateStart} dateEnd={filtersAux.dateEnd}/>
          </Grid>
        </Grid>
    </SimpleContainer>
  );
};
