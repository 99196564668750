import styled from 'styled-components';

interface ContainerProps {
  backgroundColor: string;
}

export const Container = styled.div<ContainerProps>`
  display: inline-block; 
  min-width: 30px;
  max-width: 100%;
  color: #fff;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  padding: 5px 10px;
`;
