import i18next from "i18next";

export default [
    { 
        value: 'PENDING', label: i18next.t('resources.charges.statuses.PENDING'),
    },
    {
        value: 'APPROVED', label: i18next.t('resources.charges.statuses.APPROVED') 
    },
    {
        value: 'CANCELED', label: i18next.t('resources.charges.statuses.CANCELED') 
    },
];