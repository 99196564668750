import React from 'react';

const Logo = () => {
  return (
    <svg
      width="118"
      height="118"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_550_6175)">
        <path
          d="M87.7748 73.7119L91.5796 42.0322L91.5197 42.0486L75.9895 58.3526C81.5496 61.8744 85.7851 67.2963 87.7748 73.7119Z"
          fill="#A09FA2"
        />
        <path
          d="M25.8831 43.5283L33.6509 75.6285L33.918 75.3118C35.4007 69.2892 38.8294 64.042 43.4955 60.2636C35.0845 52.4283 26.4609 44.3255 25.8831 43.5283Z"
          fill="#A09FA2"
        />
        <path
          d="M88.1672 17.631L87.9273 16.0476H87.8946C86.8371 9.85034 85.8069 3.99162 85.2127 0.628174C80.939 11.2973 70.8218 18.7995 59.0148 18.7995C47.2077 18.7995 37.7337 11.7505 33.2475 1.611L28.4778 27.4265C27.5511 32.3625 26.5808 37.5988 26.1229 40.1869L25.8176 41.8304L25.8503 41.8031C25.8503 41.8031 25.8503 41.8249 25.8503 41.8304C25.8503 41.8413 25.8612 41.8631 25.883 41.8904C26.4609 42.6876 35.0845 50.7904 43.4901 58.6257C51.7375 66.3136 59.7779 73.7394 59.7779 73.7394L75.9894 56.7201L91.5196 40.4162L91.585 40.3507C91.8903 39.9794 90.0314 28.5622 88.1726 17.6365L88.1672 17.631Z"
          fill="#F26A49"
        />
      </g>
      <path
        d="M100.271 95.3594H117.09V99.1308H105.572V104.193H115.969V107.965H105.572V113.639H117.43V117.41H100.271V95.3594Z"
        fill="#A09FA2"
      />
      <path
        d="M89.7496 117.75C88.753 117.75 87.8582 117.705 87.0654 117.614C86.2953 117.524 85.4459 117.354 84.5172 117.104C83.4752 116.833 82.6145 116.482 81.9349 116.051C81.2554 115.598 80.6891 114.975 80.2361 114.182C79.7604 113.39 79.5226 112.45 79.5226 111.362V101.679C79.5226 97.2394 82.9316 95.0195 89.7496 95.0195C91.4938 95.0195 93.8268 95.2687 96.7488 95.767V99.7423C94.1213 99.1081 91.8109 98.791 89.8176 98.791C88.9795 98.791 88.3226 98.8136 87.8469 98.8589C87.3939 98.9042 86.9182 99.0175 86.4199 99.1987C85.3553 99.5611 84.823 100.365 84.823 101.611V110.989C84.823 112.982 86.6464 113.979 90.2932 113.979C91.8562 113.979 94.0533 113.65 96.8847 112.993V117.003C94.4611 117.501 92.0827 117.75 89.7496 117.75Z"
        fill="#A09FA2"
      />
      <path
        d="M54.4824 95.3594H59.8508L70.5195 110.411V95.3594H75.2423V117.41H69.8739L59.2052 102.121V117.41H54.4824V95.3594Z"
        fill="#A09FA2"
      />
      <path
        d="M44.4286 101.447H49.729V117.41H44.4286V101.447Z"
        fill="#A09FA2"
      />
      <path
        d="M44.4286 95.0195H49.729V99.2894H44.4286V95.0195Z"
        fill="#F26A49"
      />
      <path
        d="M19.7093 95.3594H25.0097V104.193H34.3873V95.3594H39.6877V117.41H34.3873V107.965H25.0097V117.41H19.7093V95.3594Z"
        fill="#A09FA2"
      />
      <path
        d="M6.10352e-05 95.3594H5.30045V113.639H16.1391V117.41H6.10352e-05V95.3594Z"
        fill="#A09FA2"
      />
      <defs>
        <clipPath id="clip0_550_6175">
          <rect
            width="65.7946"
            height="75.0059"
            fill="white"
            transform="translate(25.8176 0.622803)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
