import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useGetOne from 'src/Hooks/useGetOne';
import ResearchCandidateForm from './form';
import ProfileImage from 'src/Components/Election/ProfileImagemUrl';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { Menu } from 'src/Components/Election/Menu';
import { Link, useParams } from 'react-router-dom';
import { api } from 'src/Services/Elections';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { View } from 'src/Components/Admin/View';
import { ViewAction } from 'src/Components/Admin/Actions/ViewAction';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { SearchInput } from 'src/Components/Inputs/SearchInput';

export function ResearchCandidate() {
  const endpoint = '/research-candidate/find-candidate';
  const resource = 'research.research-candidate';
  const { id } = useParams();
  const [tab, setTab] = useState<number>(3);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({});
  const [allResearchCandidates, setAllResearchCandidates] = useState<any>([]);
  const [researchCandidates, setResearchCandidates] = useState<any>([]);
  const [candidateFiltered, setCandidateFiltered] =
    useState<any>(researchCandidates);
  const { data: researchData } = useGetOne(api, 'research', id);
  const positionId = researchData?.positionId;

  useEffect(() => {
    getCandidates();
    getAllResearchCandidates();
  }, [positionId]);

  useEffect(() => {
    const filteredCandidate = researchCandidates.filter(candidate =>
      candidate?.name.toLowerCase().includes(filters?.search?.toLowerCase())
    );
    if (
      filteredCandidate?.length > 0 ||
      (filteredCandidate?.length === 0 && filters?.search?.length > 0)
    ) {
      setCandidateFiltered(filteredCandidate);
    } else {
      setCandidateFiltered(researchCandidates);
    }
  }, [filters]);

  const getCandidates = async () => {
    const params = `researchId=${id}&candidatePositionId=${positionId}`;
    try {
      const response = await api.get(endpoint + '?' + params);
      if (response && response.status === 200) {
        setResearchCandidates(response.data);
        setCandidateFiltered(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllResearchCandidates = async () => {
    const endpoint = 'research-candidate';
    try {
      const response = await api.get(endpoint);
      if (response && response.status == 200) {
        setAllResearchCandidates(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (candidateId: string) => {
    await getAllResearchCandidates();
    const endpoint = 'research-candidate';
    const researchCandidate = allResearchCandidates.filter(
      item => item?.candidate?.id === candidateId && item?.research?.id === id
    );
    const researchCandidateId = researchCandidate[0]?.id;

    try {
      const response = await api.delete(endpoint + '/' + researchCandidateId);
      if (response && response.status == 200) {
        setResearchCandidates(
          researchCandidates.filter(
            researchCandidate => researchCandidate.id !== id
          )
        );
        showSuccessMessage(`resources.${resource}.actions.delete.success`);
        getCandidates();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name_others`} mdSize={1200}>
      <Menu tab={tab} setTab={setTab} />
      <View
        baseUrl="/election/research"
        backendUrl="/research"
        actions={[
          <ViewAction key={0} title="Visualizar Candidatos da Pesquisa" />,
          <Link
            to="#"
            onClick={() => setIsEditing(true)}
            key={1}
            title="Adicionar Candidato(s)"
          >
            <AddCircleOutlineIcon />
          </Link>
        ]}
      >
        {isEditing ? (
          <ResearchCandidateForm
            researchId={id}
            researchCandidates={researchCandidates}
            setResearchCandidates={setResearchCandidates}
            getCandidates={getCandidates}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <InfiniteList
            data={candidateFiltered}
            loadMoreData={getCandidates}
            hasMore={false}
            filters={
              <SearchInput
                name="search"
                filters={filters}
                setFilters={setFilters}
              />
            }
            columns={[
              {
                header: `resources.${resource}.fields.candidateName`,
                value: (item, index) => (
                  <InfiniteListStyles.StyledTd key={index}>
                    <InfiniteListStyles.ImageNameContainer>
                      {item.profileImageUrl && (
                        <ProfileImage
                          alt={item.name}
                          imageUrl={api.getUrl(item.profileImageUrl)}
                        />
                      )}
                      {item.name}
                    </InfiniteListStyles.ImageNameContainer>
                  </InfiniteListStyles.StyledTd>
                )
              },
              {
                type: 'action',
                value: (candidateId, index) => (
                  <InfiniteListStyles.StyledActionTd key={index}>
                    <DeleteButton
                      handleDelete={() => handleDelete(candidateId.id)}
                    />
                  </InfiniteListStyles.StyledActionTd>
                )
              }
            ]}
          />
        )}
      </View>
    </SimpleContainer>
  );
}
