import * as React from "react";
import { api } from "src/Services/Financial";
import { Link, useParams } from 'react-router-dom';
import i18next from "i18next";
import useGetOne from "src/Hooks/useGetOne";
import { View } from "src/Components/Admin/View";
import { SimpleContainer } from "src/Components/Layout/SimpleContainer";
import { SimpleShowLayout } from "src/Components/Admin/View/Layouts/SimpleShowLayout";
import { ListAction } from "src/Components/Admin/Actions/ListAction";
import { EditAction } from "src/Components/Admin/Actions/EditAction";
import { DeleteAction } from "src/Components/Admin/Actions/DeleteAction";
import { TextField } from "src/Components/Admin/Fields/TextField"
import { DateField } from "src/Components/Admin/Fields/DateField"
import HiringStatuses from "src/Enums/HiringStatuses";
import { OptionsField } from "src/Components/Admin/Fields/OptionsField";
import { HiringPersonList } from "./HiringPersonList";
import { showSuccessMessage } from "src/Hooks/useMessages";
import ReplayIcon from '@mui/icons-material/Replay';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { UserHave } from "src/Components/Auth/UserHave";
import { ROLE_CAMPAIGN_MANAGER, ROLE_CANDIDATE } from "src/Enums/Roles";

export const HiringShow = () => {
    const resource = 'hirings'
    const { id } = useParams()
    const { data, fetchData } = useGetOne(api, '/hirings', id);

    const cancelConfirmHiring = () => {
        if (confirm(i18next.t(`resources.${resource}.actions.cancelConfirm.dialog`))) {
            api.put(`hirings/${id}/cancel-confirm`)
                .then(result => {
                    if (result && result.status == 200) 
                    {
                        fetchData()
                        showSuccessMessage(`resources.${resource}.actions.cancelConfirm.success`)
                    }
                })
        }
    }
    
    return (
        <SimpleContainer 
            title={`resources.${resource}.name_one`}
        >
            {data && (
            <View
                baseUrl="/hr/hirings"
                backendUrl="/hirings"
                actions={[
                    <ListAction key={0} />,
                    (data.status !== 'APPROVED') && <EditAction key={1} />,   
                    (data.status !== 'APPROVED') && <DeleteAction key={2} api={api} successMessage={`resources.${resource}.actions.delete.success`}/>,
                    <UserHave role={[ROLE_CANDIDATE, ROLE_CAMPAIGN_MANAGER]}  key={5} >
                        {(data.status != 'APPROVED') 
                            ? <Link 
                                to={`../${id}/confirm`} 
                                title={i18next.t(`resources.${resource}.actions.confirm.title`)} 
                                style={{ color: '#428F32' }}
                            >
                                <ThumbUpAltIcon />
                            </Link>
                            : <Link 
                                to={'#'} 
                                title={i18next.t(`resources.${resource}.actions.cancelConfirm.title`)} 
                                onClick={cancelConfirmHiring}
                                style={{ color: 'red' }}
                            >
                                <ReplayIcon />
                            </Link>
                        }
                    </UserHave>
                ]}
            >
                <SimpleShowLayout>
                    <TextField value={data?.title} label={`resources.${resource}.fields.title`} />
                    <TextField value={data?.description} label={`resources.${resource}.fields.description`} />
                    <DateField value={data?.start_date} label={`resources.${resource}.fields.start_date`} />
                    <DateField value={data?.end_date} label={`resources.${resource}.fields.end_date`} />
                    <TextField value={data?.type?.name} label={`resources.${resource}.fields.typeId`} />
                    {(data.status == 'APPROVED') && (
                        <> 
                            <TextField value={data?.category?.name} label={`resources.${resource}.fields.categoryId`} />
                            <TextField value={data?.costitem?.costcenter?.name + ' - ' + data?.costitem?.name} label={`resources.costcenters.name_one`} /> 
                        </>
                    )}
                    <TextField value={`${data?.city?.name} (${data?.state?.uf})`} label={`resources.${resource}.fields.cityId`} />
                    <OptionsField value={data?.status} label={`resources.${resource}.fields.status`} options={HiringStatuses} />
                    <DateField showTime value={data?.created_at} label={`resources.${resource}.fields.created_at`} />
                    <TextField value={data?.notes} label={`resources.${resource}.fields.notes`} />
                </SimpleShowLayout>

                {data &&
                    <HiringPersonList 
                        hiringId={data.id}
                        isPending={data.status !== 'APPROVED'}
                    />
                }
            </View>
            )}
        </SimpleContainer>
    );
}