import React, { useRef, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import i18next from 'i18next';
import {Typography} from '@mui/material'

Chart.register(ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

interface DonutChartProps {
  data: number[];
  labels: string[];
  colors?: string[];
  options?: any;
  otherData?: any[];
}

export const PieChartComponents: React.FC<DonutChartProps> = ({
  data,
  otherData,
  labels,
  colors,
  options
}: DonutChartProps) => {
  const chartRef = useRef<any>(null);

  const defaultColors = [
    '#58c7f1',
    '#f88124',
    '#fbd67d',
    '#86cfe5',
    '#344872',
    'rgba(255, 0, 0, 0.7)',
    'rgba(0, 0, 255, 0.7)',
    'rgba(0, 255, 0, 0.7)',
    'rgba(255, 255, 0, 0.7)',
    'rgba(0, 255, 255, 0.7)',
    'rgba(255, 0, 255, 0.7)',
    'rgba(0, 0, 128, 0.7)',
    'rgba(0, 128, 128, 0.7)',
    'rgba(128, 0, 128, 0.7)',
    'rgba(153, 102, 255, 0.7)',
    'rgba(255, 159, 64, 0.7)',
    'rgba(219, 112, 147, 0.7)',
    'rgba(102, 204, 170, 0.7)'
  ];

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destrói o gráfico anterior, se existir
        const chartInstance = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: [],
            datasets: []
          }
        });
        chartInstance.destroy();
      }
    }
  }, []);

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors ?? defaultColors,
        datalabels: {
          color: '#FFF'
        },
        otherData
      }
    ]
  };

  return (
    <>
      {data.length <= 0 ? (
        <Typography gutterBottom align={"center"} variant="body1" component="div">
          {i18next.t('components.list.no_register_found')}
        </Typography>
      ) : (
        <Doughnut ref={chartRef} data={chartData} options={options} />
      )}
    </>
  );
};
