import list from './list.json';
import calendarRange from './calendarRange.json';

const messages = {
  others: 'Others',
  no_research: 'No research to display',
  description: 'Description',
  invalid_resource: 'Invalid resource',
  loading: 'Loading...',
  list,
  calendarRange
};

export default messages;
