import React from 'react';
import * as Styled from './styles';
import img from '../../../Assets/download.png'

interface RegistrationDayProps {
    quantity: string;
    percentage?: string;
    color: string;
    title: string;
}

export const RegistrationsCardComponents: React.FC<RegistrationDayProps> = ({ quantity, percentage, color, title }) => {
    return (
        <Styled.Container color={color}>
            <Styled.CardContainer color={color}>
                <Styled.Values>{quantity}</Styled.Values>
                {percentage &&
                    <Styled.Percentage>({percentage}%)</Styled.Percentage>
                }
            </Styled.CardContainer>

            <Styled.Title>{title}</Styled.Title>
            <Styled.Image src={img} />
        </Styled.Container>
    );
};

