import React from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import i18next from 'i18next';

interface ResearchesAutocompleteInputProps {
  researches: any[];
  control: any;
  label: string;
  setResearchesSelected: (options) => void;
}

export const ResearchesAutocompleteInput = ({
  researches,
  control,
  label,
  setResearchesSelected
}: ResearchesAutocompleteInputProps) => {
  return (
    <Controller
      name={'researches'}
      control={control}
      render={({ field }) => (
        <Autocomplete
          multiple
          options={researches}
          noOptionsText={i18next.t('inputs.autocomplete.noOptionsText')}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={label}
            />
          )}
          onChange={(e, selected) => {
            field.onChange(selected);
            setResearchesSelected(selected);
          }}
        />
      )}
    />
  );
};
