import React from 'react';
import { ROLE_ADMIN } from 'src/Enums/Roles';

interface CanProps {
    children: React.ReactNode;
    resource?: string;
    atLeastOne?: string[]
}

interface UserCanProps {
    resource?: string;
    atLeastOne?: string[],
    customPermissions?: []
}

export const userCan = ({resource, atLeastOne, customPermissions} : UserCanProps) =>{
    const permissionsJson = localStorage.getItem('permissions');
    const userDataJson = localStorage.getItem('userData');

    if (!userDataJson || !permissionsJson) return null;

    const userData = JSON.parse(userDataJson);
    const permissions = customPermissions || JSON.parse(permissionsJson);

    if (userData.role?.name === ROLE_ADMIN) {
        return true;
    }

    if (permissions) 
    {
        if (!atLeastOne && resource) atLeastOne = [resource];

        if (atLeastOne) {
            for(let i =0; i < atLeastOne?.length; i++) {
                const parts = atLeastOne[i]?.split(':')
                const permission = permissions[parts[0]];

                if (permission) 
                {
                    if (permission['all'] || permission[parts[1] ?? 'read']) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
}

export const userIs = (role: string | string[]) => {
    const userDataJson = localStorage.getItem('userData');

    if (!userDataJson) return false;

    const userData = JSON.parse(userDataJson);

    if (userData.role.name == ROLE_ADMIN) {
        return true
    }

    if (!Array.isArray(role)) {
        return (userData.role?.name === role)
    } else {
        return role.indexOf(userData.role.name) >= 0
    }
}

export const Can = ({ children, resource, atLeastOne }: CanProps) => {
    if (userCan({resource, atLeastOne})) {
        return <>{children}</>;
    }
    return null;
}