import i18next from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useGetOne from 'src/Hooks/useGetOne';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { UserForm } from 'src/Pages/Election/Users/UserForm';
import { api } from 'src/Services/Elections';
import { removePhoneMask } from 'src/Utils/Formatters';

export const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useGetOne(api, '/users', id);

  const onSubmit = formData => {
    const cleanedTelphone = removePhoneMask(formData.data.telphone);

    const data = {
      ...formData.data,
      telphone: cleanedTelphone
    };

    api.patch(`/users/${id}`, data).then(result => {
      if (result?.status == 200) {
        showSuccessMessage(
          i18next.t(`resources.users.actions.edit.success`)
        );
        navigate(`/election/users/${result.data.id}/show`);
      }
    });
  };

  return <UserForm data={data} onSubmit={onSubmit} edit={true}/>;
};
