import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface UsersAddDialogProps {
  open: boolean;
  setOpen: (boolean) => void
}

const UsersAddDialog = ({ open, setOpen }: UsersAddDialogProps) => {
  return (
    <Dialog open={open} onClose={()=> setOpen(false)} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">Adicionar Administradores e Gerentes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Teste
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancelar</Button>
        <Button type="submit">Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersAddDialog;
