import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { SimpleContainer } from 'src/Components/Layout/SimpleContainer';
import { AddButton } from 'src/Components/Buttons/AddButton';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { ViewButton } from 'src/Components/Buttons/ViewButton';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { SearchInput } from 'src/Components/Inputs/SearchInput';
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { DecimalField } from 'src/Components/Admin/Fields/DecimalField';
import { OptionsField } from 'src/Components/Admin/Fields/OptionsField';
import hirigStatuses from 'src/Enums/HiringStatuses';
import { DateField } from 'src/Components/Admin/Fields/DateField';
import i18next from 'i18next';

export const HiringList = () => {
  const endpoint = '/hirings';
  const resource = 'hirings';
  const { data, setData, fetchData, hasMore, filters, setFilters } =
    useInfiniteFetch(api, endpoint, 25);

  const handleDelete = async (id: number) => {
    try {
      api.delete(endpoint + '/' + id).then(response => {
        if (response && response.status == 200) {
          setData(data.filter(item => item.id !== id));
          showSuccessMessage(`resources.${resource}.actions.delete.success`);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SimpleContainer title={`resources.${resource}.name`}>
      <InfiniteList
        data={data}
        loadMoreData={fetchData}
        hasMore={hasMore}
        actions={<AddButton />}
        filters={
          <>
            <SearchInput
              name="search"
              label={i18next.t(`resources.hirings.filter.search`)}
              filters={filters}
              setFilters={setFilters}
              style={{ minWidth: 300 }}
            />
          </>
        }
        columns={[
          {
            header: `resources.${resource}.fields.title`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                {item.title}
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.start_date`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DateField value={item.start_date} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.end_date`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <DateField value={item.end_date} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            header: `resources.${resource}.fields.status`,
            value: (item, index) => (
              <InfiniteListStyles.StyledTd key={index}>
                <OptionsField value={item.status} options={hirigStatuses} />
              </InfiniteListStyles.StyledTd>
            )
          },
          {
            type: 'action',
            value: (item, index) => (
              <InfiniteListStyles.StyledActionTd key={index}>
                <ViewButton url={`${item.id}/show`} />
                {item.status !== 'APPROVED' && (
                  <EditButton url={`${item.id}`} />
                )}
                {item.status !== 'APPROVED' && (
                  <DeleteButton handleDelete={() => handleDelete(item.id)} />
                )}
              </InfiniteListStyles.StyledActionTd>
            )
          }
        ]}
      />
    </SimpleContainer>
  );
};
