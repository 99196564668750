import * as React from "react";
import { SimpleContainer } from "src/Components/Layout/SimpleContainer";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { View } from "src/Components/Admin/View";
import { TextField } from "src/Components/Admin/Fields/TextField"
import { DateField } from "src/Components/Admin/Fields/DateField"
import { SimpleShowLayout } from "src/Components/Admin/View/Layouts/SimpleShowLayout";
import { api } from "src/Services/Financial";
import { useParams } from 'react-router-dom';
import useGetOne from "src/Hooks/useGetOne";
import { ListAction } from "src/Components/Admin/Actions/ListAction";
import { EditAction } from "src/Components/Admin/Actions/EditAction";
import { DeleteAction } from "src/Components/Admin/Actions/DeleteAction";

export const ElectionShow = () => {
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { id } = useParams()
    const { data } = useGetOne(api, '/elections', id);
    
    return (
        <SimpleContainer 
            title='resources.elections.name'
            style={{ maxWidth: isSmScreen ? '100%': 900}}
        >
            <View
                baseUrl="/election/elections"
                backendUrl="/elections"
                actions={[
                    <ListAction key={0} />,
                    <EditAction key={1} />,   
                    <DeleteAction key={2} api={api} successMessage="resources.elections.actions.delete.success"/>
                ]}
            >
                <SimpleShowLayout>
                    <TextField value={data?.name} label="resources.elections.fields.name" />
                    <DateField value={data?.created_at} label="resources.elections.fields.created_at" />
                </SimpleShowLayout>
            </View>
        </SimpleContainer>
    );
}