import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { api } from 'src/Services/Elections';
import i18next from 'i18next';

interface BarChartProps {
  data: any;
}

Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);

export const BarChart = ({ data }: BarChartProps) => {
  const [options, setOptions] = useState<any>({});
  const [barData, setBarData] = useState<any>({
    datasets: [],
    labels: []
  });

  const handleResize = (chart) => {
    chart.resize();
  }

  const annotationsarray = data.map((item, index) => {
    const img = new Image();
    if (item.profileImageUrl != null) {
      img.src = api.getUrl(item.profileImageUrl);
    }

    return {
      type: 'label',
      drawTime: 'afterDraw',
      width: 45,
      height: 45,
      xValue: parseFloat(item.percentage),
      yValue: index,
      xAdjust: 30,
      yAdjust: 0,
      xScaleID: 'x',
      yScaleID: 'y',
      borderRadius: 10,
      borderColor: '#000'
    };
  });

  let annotations = {};
  annotationsarray.forEach((annotation, index) => {
    annotations = {
      ...annotations,
      ...{ [index]: annotation }
    };
  });

  useEffect(() => {
    setOptions({
      animation: false,
      responsive: true,
      indexAxis: 'y',
      maintainAspectRatio: false,
      onResize: handleResize,
      scales: {
        x: {
          grid: {
            color: '#bbb'
          },
          border: {
            dash: [2, 4]
          },
          ticks: {
            callback: function (value) {
              return value + '%';
            }
          }
        },
        y: {
          grid: {
            color: '#fff'
          },
          border: {
            dash: [2, 4]
          },
          ticks: {
            autoSkip: false, // Adicione esta linha
            maxRotation: 0, // Adicione esta linha
            minRotation: 0, // Adicione esta linha
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          color: 'black',
          formatter: function (value) {
            return parseFloat(value).toFixed(2).toString().replace('.', ',') + '%';
          },
          anchor: function(context) {
            return context.dataset.data[context.dataIndex] > 3 ? 'end' : 'start';
          },
          offset: function(context) {
            return context.dataset.data[context.dataIndex] > 3 ? 0 : 2;
          },
          clamp: true,
          align: function(context) {
            return context.dataset.data[context.dataIndex] > 3 ? 'start' : 'end';
          },
          font: {
            weight: 'bold',
            size: 14
          }
        },
        annotation: {
          clip: false,
          annotations
        }
      }
    });

    const candidates = data.map(x =>
      x.candidateName == 'OTHER'
        ? i18next.t('components.others')
        : `${x.candidateName}`
    );

    if (data.length) {
      setBarData({
        labels: candidates,
        datasets: [
          {
            label: data[0].researchName,
            data: data.map(item => item.percentage),
            backgroundColor: data.map(item =>
              item.candidateName == 'OTHER' ? '#ddd' : '#8FC6FF'
            ),
            borderColor: data.map(item =>
              item.candidateName == 'OTHER' ? '#ddd' : 'rgb(54, 162, 235)'
            ),
            borderRadius: 8,
            barThickness: 40,
            borderWidth: 1
          },
        ]
      });
    }
  }, [data]);

  return (
    data && (
      <Bar data={barData} options={options} plugins={[ChartDataLabels]} />
    )
  );
};
