import { Controller, useForm } from 'react-hook-form';
import { api } from 'src/Services/Elections';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Form } from 'src/Components/Admin/Form';
import { showSuccessMessage } from 'src/Hooks/useMessages';
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useGetOne from 'src/Hooks/useGetOne';

const ResearchCandidateForm = ({
  researchId,
  researchCandidates,
  setResearchCandidates,
  getCandidates,
  onCancel
}: any) => {
  const { control } = useForm();
  const { data: candidates } = useInfiniteFetch(api, '/candidates', 0);
  const { data: researchData } = useGetOne(api, 'research', researchId);

  const positionType = researchData?.position?.name;

  const candidateList = candidates.filter(
    candidate => candidate.candidatePosition.name === positionType
  );

  const onSubmit = async formData => {
    const endpoint = '/research-candidate';
    const candidateId = formData.data.candidates.map(candidate => candidate.id);
    const sendData = {
      candidateId: candidateId,
      researchId: researchId
    };
    const response = await api.post(endpoint, sendData);
    if (response?.status === 201) {
      showSuccessMessage(
        `resources.research.research-candidate.actions.create.success`
      );

      setResearchCandidates([]);
      getCandidates();
      onCancel();
    }
  };

  return (
    <Form
      method="post"
      control={control}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      {candidateList && (
        <Controller
          name={'candidates'}
          control={control}
          render={({ field }) => (
            <Autocomplete
              multiple
              options={candidateList.filter(
                candidates =>
                  !researchCandidates.some(
                    candidateData => candidateData?.id === candidates?.id
                  )
              )}
              noOptionsText="Sem candidatos para adicionar"
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Adicionar Candidato(s)"
                  placeholder="Adicionar Candidato(s)"
                />
              )}
              onChange={(e, selected) => {
                field.onChange(selected);
              }}
            />
          )}
        />
      )}
    </Form>
  );
};

export default ResearchCandidateForm;
