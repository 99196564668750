import React from 'react';
import { InfiniteList } from 'src/Components/Admin/InfiniteList';
import { api } from 'src/Services/Financial'
import { useInfiniteFetch } from 'src/Hooks/useInfiniteFetch';
import { EditButton } from 'src/Components/Buttons/EditButton';
import { DeleteButton } from 'src/Components/Buttons/DeleteButton';
import { showSuccessMessage } from 'src/Hooks/useMessages'
import * as InfiniteListStyles from 'src/Components/Admin/InfiniteList/styles';
import { DecimalField } from 'src/Components/Admin/Fields/DecimalField';

interface InboundItemListProps {
  inboundId: string,
  isPending: boolean,
  setSelected: (item) => void,
  setAcao: (acao) => void
}

export const InboundItemList = ({ inboundId, isPending, setSelected, setAcao }: InboundItemListProps) => 
{
  const endpoint = `/material-inbounds/${inboundId}/items`
  const resource = 'materialInboundItems'
  const {data, setData, fetchData, hasMore } = useInfiniteFetch(api, endpoint, 25)

  const handleDelete = async (id: number) => {
    try {
        api.delete(endpoint + '/' + id)
          .then(response => {
            if(response && (response.status == 200)) {
              setData(data.filter((item) => item.id !== id));
              showSuccessMessage(`resources.${resource}.actions.delete.success`);
            }
          })
    } catch (error) {
      console.error(error);
    }
  };

  const openItem = (item) => {
    setSelected({ id: item.id, inboundId: item.inboundId })
    setAcao('form')
  } 
  
  return (
    <InfiniteList
      data={data}
      loadMoreData={fetchData} 
      hasMore={hasMore}
      columns={
        [
          {
            header: `resources.${resource}.fields.materialId`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                {item?.material.name}
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.quantity`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                <DecimalField value={item.quantity} />
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.unitPrice`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                <DecimalField value={item.unitPrice} hasSymbol={true} />
              </InfiniteListStyles.StyledTd>
          },
          {
            header: `resources.${resource}.fields.totalPrice`,
            value: (item, index) => 
              <InfiniteListStyles.StyledTd key={index}>
                <DecimalField value={item.totalPrice} hasSymbol={true} />
              </InfiniteListStyles.StyledTd>
          },
          {
            type: 'action',
            value: (item, index) => (
                <InfiniteListStyles.StyledActionTd key={index}>
                  {isPending && (
                    <>
                    <EditButton url={`#`} handleEdit={() => openItem(item)}/>
                    <DeleteButton 
                      handleDelete={() => handleDelete(item.id)}
                    />
                    </>
                  )}
                </InfiniteListStyles.StyledActionTd>
            )
          }
        ]
      }
    />
  );
}